@media screen and (max-width: 1023px) and (pointer: coarse) {
    :where(.isDeviceVersion, #popup-root) {
        #raceTotalSingle div:first-child {
            position: relative;
            height: auto;
            padding: 7px 6px 7px 10px;

            &::after {
                position: absolute;
                right: 6px;
                top: 6px;
                height: calc(100% - 10px);
            }
        }

        .rcOddsTable header {
            background-color: unset;
        }

        .rcOddsTable {
            width: 100%;
        }

        .rc-odds-table-m {
            display: table;
            margin: 0 6px 6px;
            width: calc(100vw - 12px);
            box-shadow: var(--box-shadow);
        }

        .rc-odds-row-m {
            display: table-row;
        }

        .rc-odds-row-m div, .rc-odds-row-m td {
            display: table-cell;
        }

        .rc-odds-row-m .runner-no-colour {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            row-gap: 2px;
            .runner-no , .rc-colour {
                display: block;
            }
        }

        .rc-odds-row-m .rc-text-scratch .draw {
            text-align: right;
            display: block;
            width: 100%;
        }

        .rc-odds-table-header-m {
            display: table-row;
        }

        .rc-odds-table-header-m > td {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            height: 28px;
            padding: 0px;
            font-size: 11px;
        }

        .rc-odds-table-header-m .no, .rc-odds-row-m .no {
            text-align: center;
            width: 10%;
        }

        .rc-odds-table-header-m .any {
            width: 10%;
        }

        .JTCOMBO .rc-odds-table-header-m .no, .JTCOMBO .rc-odds-row-m .no {
            width: 6%;
        }

        .JTCOMBO .rc-odds-row-m .no {
            vertical-align: top;
            padding-top: 9px;
        }

        .rc-odds-table-header-m .horseName {
            width: auto;
            min-width: 140px;
        }

        section.WP, section.horse-racing-race-card, section.PWIN {
            .rc-odds-table-m {
                display: flex;
                flex-direction: column;
                .rc-odds-table-header-m, .rc-odds-row-m {
                    display: flex;
                    padding: 4px 0;
                    .no {
                        width: 28px;
                        padding: 0;
                        &.runner-resTxt {
                            width: 50px;
                        }
                    }
                    .horseName {
                        height: initial;
                        flex: 1;
                        padding: 0;
                        word-break: break-word;
                        min-width: unset;
                    }
                    .draw, .wt {
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                }

                .rc-odds-table-header-m {
                    height: 20px;
                    td {
                        height: unset;
                        // line-height: 12px;
                    }

                    align-items: center;
                    justify-content: center;
                    .any {
                        width: 24px;
                    }
                    >.win, >.place, >.wp {
                        width: 44px;
                        padding-left: 4px;
                        white-space: nowrap;
                    }
                    .wp {
                        padding-left: 0px;
                    }
                }
                .rc-odds-row-m {
                    >.no{
                        .runner-no {
                            line-height: 16px;
                        }
                        .rc-colour {
                            width: 20px;
                            height: 20px;
                            .ColourImageForNoCopy {
                                min-height: 20px;
                                min-width: 20px;
                                background-size: 20px 20px;
                            }
                        }
                    }
                    height: unset;
                    .rc-checkbox.rc-odds-m {
                        width: 44px;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 0 0 4px;
                    }
                    >.table-arrow-m {
                        width: 24px;
                        padding-right: 0;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .horseName>a {
                        display: flex;
                        flex-direction: column;
                        row-gap: 4px;
                    }
                    .horseName>a>div {
                        padding: 0;
                        &.font-size-15 {
                            line-height: 16.5px;
                        }
                        &.joc-trn-row  {
                            column-gap: 4px;
                            position: relative;
                            // &::after {
                            //     content: "";
                            //     height: 12px;
                            //     width: 1px;
                            //     position: absolute;
                            //     left: 50%;
                            //     background-color: #B4B4B4;
                            // }
                            color: #000000;
                            > div {
                                min-width: unset;
                                flex: 1;
                                display: flex;
                                gap: 4px;
                                >.subNameCon {
                                    width: 14px;
                                    height: 14px;
                                    .subName {
                                        border-radius: 2px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        width: 14px;
                                        height: 14px;
                                        font-size: 11px;
                                        line-height: 13px;
                                    }
                                }
                                >.name-str {
                                    font-size: 11px;
                                    line-height: 13px;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    &.full > div {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &.horseName {
                            justify-content: flex-start;
                        }
                    }
                }
            }

            .rc-odds-m span.rc-odds-data-text {
                font-size: 15px;
                height: 16px;
                line-height: 16.5px;
                font-weight: 700;
            }
            .rc-odds-m :is(.favourite, .od-50, .od-20) span.rc-odds-data-text {
                height: 18px;
                line-height: 16px;
            }
            .rc-checkbox.rc-odds-m div:is(.favourite, .od-50, .od-20) {
                row-gap: 0;
            }
        }

        section.PWIN .rc-odds-table-m .rc-odds-table-header-m {
            height: 34px;
        }

        &.ch {
            section.WP, section.horse-racing-race-card, section.PWIN {
                .rc-odds-table-m .rc-odds-row-m .horseName>a>div {
                    &.joc-trn-row  {
                        &::after {
                            height: 15px;
                        }
                    }
                }
            }
        }

        .PWIN .rc-odds-table-header-m .horseName {
            width: 35%;
        }

        .PWIN .pwin-m{
            transform: translateY(4px) translateX(-6px);
        }

        .PWIN .pwin-m.win{
            transform: translateY(4px);
        }

        .PWIN .pwinCol-m {
            font-size: 11px;
            line-height: 12px;
            width: 132px;
            .pwinColContainer{
                display: flex;
                >div{
                    flex: 1;
                    padding: 0;
                    padding-left: 5px;
                    text-align: center;
                }
        }
        }


        .rc-odds-table-header-m .win, .rc-odds-table-header-m .place {
            text-align: center;
            width: 11%;
        }


        .rc-odds-table-header-m .wp {
            width: 11%;
            text-align: center;
            padding: 0px 2px;
            /* white-space: nowrap; */
        }

        .rc-odds-row-m {
            background-color: white;
            height: 36px;
            // box-shadow: var(--box-shadow);
        }

        .rc-odds-row-m div, .rc-odds-row-m td {
            vertical-align: middle;
        }

        .rc-checkbox.rc-odds-m div:not(.checkbox-container) {
            flex-direction: column;
        }

        .rc-odds-row-m .rc-text-scratch {
            text-align: right;
        }

        .rc-odds-row-m.sec {
            background: #F4F4F4;
        }

        .rc-odds-row-m:last-child>td:first-child {
            border-bottom-left-radius: 4px;
        }

        .rc-odds-row-m:last-child>td:last-child {
            border-bottom-right-radius: 4px;
        }

        .rc-odds-row-m:last-child>td:nth-last-of-type(2){
            border-bottom-right-radius: unset;
        }

        .rc-odds-row-m:nth-child(-n+1)>td:first-child {
            border-top-left-radius: 4px;
        }

        .rc-odds-table-compact .rc-odds-row-m:nth-child(-n+2)>td:first-child {
            border-top-left-radius: 0px;
        }

        .rc-odds-table-compact .rc-odds-row-m:nth-child(-n+2)>td:last-child {
            border-top-right-radius: 0px;
        }

        .rc-odds-row-m:nth-child(-n+1)>td:last-child {
            border-top-right-radius: 4px;
        }

        .rc-odds-row-m .horseName {
            height: 54px;
            padding: 4px 0;
            // min-width: 184px;
        }

        .rc-odds-row-m .horseName>a>div {
            display: inline-flex;
            // line-height: 20px;
            justify-content: flex-start;
            width: 100%;
            // padding-bottom: 4px;
            padding: 5px 0;
        }

        .JTCOMBO .rc-odds-row-m .horseName>a>div {
            flex-direction: column;
            row-gap: 4px;
        }

        .standby-horse-table header {
            background-color: #fff;
        }

        .PWIN .rc-odds-row-m .hourseName>a>div {
            display: block;
        }

        .PWIN .joc-trn-row>div {
            display: block;
        }

        .rc-odds-row-m .sec>div {
            min-width: 50%;
        }

        .rc-odds-row-m .wp {
            /* width: 17%; */
            text-align: center;
        }

        .rc-odds-m {
            width: 10%;
        }

        .rc-odds-m .win, .rc-odds-m .pla {
            text-align: center;
            /* width: 29px; */
            line-height: 19px;
        }

        .rc-odds-m .win>a, .rc-odds-m .pla>a {
            display: block;
            width: 28px;
            height: 23px;
            padding-top: 2px;
        }

        .rc-odds-m .win label, .rc-odds-m .pla label, .rc-odds-m .wp label {
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            width: 26px;
            height: 19px;
        }

        .rc-odds-m label {
            margin: 3px 0px;
        }

        .odds-drop-legend {
            padding: 0 12px;
        }

        .jtcAddtoslip{
            display: none;
        }

        .rcPoolInvTop {
            padding: 0px 4px;
            margin-bottom: 10px;
        }

        .poolinv-total-remarks {
            padding: 4px 10px 0px 10px;
            margin-top: 16px;
        }

        .rs-comingle-remarks {
            padding: 4px 0px 0px 0px;
        }

        .merge-comingle-remarks {
            padding: 4px 10px 0px 20px;
        }

        .allup-comingle-remarks {
            padding: 4px 10px 0px 4px;
        }

        .rc-comingle-remarks, .rc-mergepool-remarks {
            padding: 4px 10px 0px 10px;
        }

        .rc-comingle-remarks:last-child{
            padding: 4px 10px 4px 10px;
        }

        .results_msg_bottom {
            padding: 0 6px;
        }

        .bottom-inv-cal {
            display: none;
        }

        .rc-inv-table-row>td {
            display: block;
            width: 100%;
        }

        .rc-inv-table .rc-inv-row td {
            text-align: left;
            padding: 3px 6px 0px 10px;
        }

        .rc-inv-row td:nth-child(2) {
            text-align: right;
            padding: 0 13px 0px 0px;
        }

        .rc-inv-table-row {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .rc-inv-row.m-sec {
            background: #F4F4F4;
        }


        .subNameCon {
            width: 22px;
            vertical-align: top !important;
        }

        .horseName .subName {
            background-color: #dee2e6;
            width: 17px;
            height: 17px;
            font-size: 13px;
            vertical-align: top;
            text-align: center;
            border-radius: 4px;
        }

        .subName {
            line-height: 16px;
        }

        .name-str {
            line-height: 15px;
            vertical-align: unset !important;
            word-break: break-word;
        }

        .race-gear-legend-popup-content {
            border: 0;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            background-color: rgba(0, 0, 0, 0);
        }

        .race-gear-legend-m {
            color: #333;
            display: block;
            text-align: left;
            width: 95vw;
            background-color: #F4F4F4;
            padding: 0;
            height: auto;
            border-radius: 10px;
            box-shadow: 0px 3px 8px #333;
        }

        .race-gear-legend-m div {
            display: block;
            text-align: left;
            height: auto;
            padding: 0;
        }

        .race-gear-legend-m h5 {
            font-size: 15px;
            height: 30px;
            line-height: 30px;
            padding: 0 10px;
            font-weight: bold;
            background-color: #122c68;
            margin: 0;
            color: #fff;
            border-radius: 10px 10px 0 0;
        }

        .race-gear-legend-m .gear-info-list {
            display: flex;
            flex-wrap: wrap;
        }

        .race-gear-legend-m .gear-info-list>div {
            width: 50%;
        }

        .race-gear-legend-m .gear-info-left {
            border-right: solid 1px #ccc;
        }

        .race-gear-legend-m .gear-info-item {
            padding: 0 15px 0 10px;
            display: flex;
            min-height: 30px;
            border-top: 1px solid #ccc;
            align-items: center;
        }

        .race-gear-legend-m .gear-info-list :nth-last-of-type(1) {
            border-radius: 0 0 10px 0;
        }

        .race-gear-legend-m .gear-info-list :nth-last-of-type(2) {
            border-radius: 0 0 0 10px;
        }

        .race-gear-legend-m .active-gear {
            background: #DBE8F3;
        }

        .race-gear-legend-m .gear-info-item .gearinfo-item-name {
            width: 20%;
            color: #003c84;
            font-weight: bold;
        }

        .race-gear-legend-m .gear-info-item .gearinfo-item-value {
            width: calc(80% - 1px);
            font-size: 13px;
        }

        .race-gear-legend-m .gear-info-item .separator {
            width: 1px;
            height: 70%;
            background: #ccc;
            margin-right: 10px;
        }

        .invCollapse-m {
            display: block;
            width: 100%;
        }

        .invCollapse-m>a {
            display: flex;
            justify-content: space-between;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        .investment-cal {
            grid-template-columns: 26% 37% 37%;
            height: auto;
            grid-template-rows: inherit;
            margin: 0px 6px;
        }

        .investment-cal-lbl {
            grid-column: 1 / 3;
            line-height: 36px;
            font-size: 15px;
        }

        .investment-cal-lbl.right-border {
            position: initial;
        }

        .investment-cal-arow {
            display: flex;
            justify-content: flex-end;
            padding-right: 20px;
        }

        .investment-cal-betno, .investment-cal-unitbet, .investment-cal-bettotal {
            flex-direction: column;
            align-items: flex-start;
        }

        .investment-cal-betno>div:first-child,
        .investment-cal-unitbet>div:first-child,
        .investment-cal-bettotal>div:first-child {
            padding-top: 12px;
            padding-bottom: 8px;
        }

        .investment-cal-betno>div:nth-child(2),
        .investment-cal-unitbet>div:nth-child(2),
        .investment-cal-bettotal>div:nth-child(2) {
            margin-bottom: 15px;
        }

        .investment-cal-betno>div:nth-child(2) {
            line-height: 32px;
        }

        .cal-input {
            display: block;
            border-top: 1px solid #cecece;
            padding: 12px;
        }

        .cal-input>div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .mobileCurrentOdds{
            display: flex;
            justify-content: space-between;
            // border-bottom: 1px solid #979797;
            margin-left: 12px;
            margin-right: 12px;
            width: calc(100% - 24px);
            height: 37px;
            .mobileRefreshTime{
                align-items: center;
                padding: 6px 0px;
            }
            .current-odds{
                padding: 6px 0px;
                font-weight: 500;
                align-items: center;
                .dropdown-menu.show {
                    inset: 6px -5px auto auto !important;
                }
                .dropdown-item.active{
                    background-color: #fff;
                    color: #173E96;
                }
                #dropdown-currentodds{
                    background-color: #e7e7e7;
                }
            }
        }

        #collapseInvestmentCal .card-body .card-cell:not(:last-child):after {
            display: none;
        }

        #collapseInvestmentCal .card-body {
            padding: 6px 0px;
        }

        #collapseInvestmentCal .card-body div {
            display: flex;
            padding: 2px 8px;
            justify-content: space-evenly;
            align-items: flex-start;
            justify-items: start;
            flex-wrap: nowrap;
            width: 100%;
            flex-direction: column;
            align-content: center;
        }

        #collapseInvestmentCal .card-body>div:not(:last-child) {
            border-right: 1px solid #d8d8d8;
        }

        #collapseInvestmentCal .card-cell {
            height: 40px;
        }

        .card-cell {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: space-around;
            justify-content: center;
            align-items: flex-start;
        }


        .meeting-info-menu .show.dropdown.btn-group::before {
            width: 100vw;
            height: 100vh;
            content: '';
            position: fixed;
            display: block;
            background: black;
            z-index: 999;
            opacity: .3;
            inset: 0px;
            top: 0;
            left: 0;
        }

        .modal .rc-odds-table-m {
            width: calc(100vw - 35px);
        }

        .modal .rc-odds-row-m td {
            padding: 0 10px;
        }

        .modal .rc-odds-row-m *:not(:last-child):after {
            background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
            background-size: 10px 20px;
            position: absolute;
            right: 0;
            top: 9px;
            width: 1px;
            height: 18px;
            vertical-align: middle;
            content: "";
            float: right;
            opacity: .4;
        }

        /* .meeting-button.slide3, .meeting-button.slide4, .meeting-button.slide {
            grid-template-columns: 160px 32px calc(100% - 160px - 32px - 24px - 24px);
            padding-left: 24px;
        }

        .meeting-button.slide2 {
            grid-template-columns: 88px 32px 210px 0px 0px;
            padding-left: 12px;
        }

        .meeting-button.slide1 {
            grid-template-columns: 48px 32px 250px 0px 0px;
            padding-left: 12px;
        } */

        .meeting-button.slide2 {
            /* grid-template-columns: 88px 32px 210px 0px 0px; */
            /* padding-left: 12px; */
        }

        .meeting-button .slider-container {
            /* width: calc(100vw - 214px);
            height: 36px;
            margin: 0px; */
            /* margin-left: -14px; */
            padding: 0 !important;
        }

        .meeting-button {
            height: 52px;
        }

        .meeting-info-container {
            flex-basis: 58.67%;
            display: flex;
            align-items: center;
            border-right: 1px solid #b4b4b4;
            padding-right: 6px;
            .arrow-icon {
                margin-left: 6px;
                align-self: flex-end;
                min-width: 20px;
            }
        }

        .meeting-info {
            height: auto;
            background: #fff;
            margin: 0px;
            padding: 4px 8px;
            width: 100vw;
            align-items: initial;
            // border-bottom: 1px solid #b4b4b4;
            gap: 0;
            &.meeting-info-isCollapse {
                min-height: unset;
                // height: 32px;
                transition: height 200ms;

                .meeting-info-content-text {
                    height: 18px;
                    line-height: 18px;
                    overflow: hidden;
                }
            }
        }

        .Sticky-activeClass .sticky-inner-wrapper  {
            // overflow: hidden;
        }

        .Sticky-activeClass .meeting-info {
            box-shadow: 0 3px 10px rgb(0 0 0 / 30%);
            margin-bottom: 10px;
        }

        .meeting-info .meeting-info-menu {
            flex-basis: 41.32%;
            justify-content: flex-end;
            display: flex;
            text-align: right;
            padding-left: 8px;
        }

        .meeting-info-desktop-none {
            display: flex;
        }

        .pool-not-defined {
            width: 100%;
            height: 100%;
            /* margin-top: 10px; */
            padding: 0 8px 0 4px;
        }

        .meeting-info-content {
            // border-right: 1px solid #b4b4b4;
            // padding-right: 12px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            justify-content: center;
        }

        .meeting-info-menu .dropdown-menu {
            width: calc(100vw - 7px);
            background-color: #003C84;
            padding-bottom: 0px;
            inset: 11px -14px auto auto !important;
            height: auto;
        }

        .meeting-info-menu .dropdown-menu.show {
            inset: 12px -4px auto auto !important;
        }

        .meeting-info-menu .dropdown-item:before {
            border-color: transparent transparent #003C84;
        }

        .meeting-info-menu .dropdown-item:after {
            border-color: transparent;
            height: 0px;
        }

        .meeting-info-menu .dropdown-item {
            background-color: white;
        }

        .meeting-info-menu .dropdown-item:not(:first-child) {
            display: inline-flex;
            align-items: baseline;
            flex-wrap: nowrap;
            font-size: 15px;
            text-align: center;
            word-break: break-all;
            /* border-left: 1px solid #dee2e6; */
            justify-items: center;
            justify-content: center;
            white-space: normal;
            word-break: break-word;
            color: #333;
            align-content: space-between;
            vertical-align: middle;
            align-items: center;
        }

        .meeting-info-menu .dropdown-item ul {
            list-style-position: outside;
            margin-bottom: 0px;
            padding-bottom: 0px;
        }

        .meeting-info-menu .dropdown-item:nth-last-child(2):nth-last-child(even) {
            border-bottom-left-radius: 4px;
        }

        .meeting-info-menu .dropdown-item:last-child {
            border-bottom-right-radius: 4px;
        }

        .horse-racing-race-card .table-arrow-m {
            width: 8%;
        }

        .table-arrow-m {
            text-align: right;
            padding-right: 10px;
            min-width: 24px;
        }
        .WPQ .table-arrow-m,
        .QTT .table-arrow-m {
            padding-right: 4px;
        }

        // .rcOddsTable .PWIN .table-arrow-m{
        //     min-width: 28px;
        // }

        .meeting-button .live-racing-icon, .meeting-button .separator.live-icon {
            display: none;
        }

        .meeting-info-menu .dropdown-item.active {
            color: #173e96;
        }

        .meeting-button .separator {
            margin: 0 26px 0 19px;
        }

        .modal .rc-odds-row-m {
            font-size: 13px;
        }

        .modal .rc-odds-row-m:nth-child(even) {
            background: #F4F4F4;
        }

        .modal .rc-odds-row-m>td:first-child {
            width: 45%;
        }

        .modal.more .accordion-header .accordion-button:not(.collapsed) {
            color: #333333;
        }

        .modal.more .jpEstTable hr {
            display: none;
        }

        .modal.more .jpEstTable .content {
            display: grid;
            grid-template-columns: 50% 50%;
            padding: unset;
        }

        .modal.more .jpEstTable .content>div {
            margin-bottom: 4px;
        }

        .modal.more .blockChangesMore {
            width: 200px;
        }

        .modal.more .content {
            padding: 0px;
            padding-bottom: 70px;
        }

        .modal.more .content .accordion-button div {
            font-size: 15px;
            font-weight: 500;
        }

        .modal.more .content .accordion-body {
            margin-left: 0;
            padding: 0;
            font-size: 13px;
            background-color: #f4f4f4;
        }
        .modal.more .content > div > :last-child .accordion-button{
            border: 0;
        }
        .modal.more .accordion-item{
            border: none;
        }
        .modal.more .accordion-button:focus{
            box-shadow: none;
            color: #333;
        }
        .modal.more .accordion-button:not(.collapsed){
            background-color: #fff;
            box-shadow: none;
        }
        .modal.more .content .accordion-body>div {
            padding: 12px 24px;
        }

        .modal.more .content .racing .accordion-body>div {
            color: #173e96
        }

        .modal.more .content .racing .accordion-body>div>div,
        .modal.more .content .racing .accordion-body>div>p {
            color: #333333
        }

        .modal.more .content .football .accordion-body>div {
            color: #405a55
        }

        .modal.more .content .marksix .accordion-body>div {
            color: #980b0b
        }

        .modal.more .blockChangesRow {
            padding: 8px 0px;
        }

        .modal.more .content header {
            padding: 12px;
            height: 40px;
            color: white;
            font-size: 15px;
        }

        .modal.more .content .racing header {
            background-color: #6176A0;
        }

        .modal.more .content .football header {
            background-color: #569171;
        }

        .modal.more .content .marksix header {
            background-color: #D28080;
        }

        .modal.more .content .no-child {
            display: flex;
            justify-content: space-between;
            height: 30px;
            background-color: white;
            padding: 5px;
            .leftMenuIcon{
                img{
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .modal.more .content .no-child .external-icon {
            margin-right: 4px;
        }

        .modal.more .content .item, .modal.more .content button {
            padding: 12px;
            height: 40px;
            font-weight: 500;
            font-size: 15px;
            border-bottom: 1px solid #e7e7e7;
        }

        .modal.more .content>div {
            margin-bottom: 20px;

        }

        .modal.more .accordion-item {
            border-radius: 0px;
        }

        .modal.more .content .football .accordion-body>div.noinfo,
        .modal.more .content .marksix .accordion-body>div.noinfo {
            color: #333333;
        }

        .modal.more .slick-dots {
            position: static;
            margin-top: 12px;
        }

        .modal.more .slick-dots li {
            width: 10px;
            height: 10px;
        }

        .modal.more .dot {
            width: 8px;
            height: 8px;
            border-radius: 5px;
            margin: 0px 2px;
            background-color: #b4b4b4;
        }

        .modal.more .slick-active .dot {
            background-color: #6176a0;
        }

        .modal.more .mtg-dt-venue {
            display: flex;
            justify-content: space-between;
        }

        .modal.more .mtg-btn {
            background-color: #6176a0;
            color: white;
            border-radius: 20px;
            width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
        }

        .modal.more .mtg-change {
            display: grid;
            grid-template-columns: 88% 12%;
        }

        .modal.more .mtg-change>div:nth-child(2) {
            display: flex;
            justify-content: flex-end;
        }

        .modal.more .meeting-button {
            grid-template-columns: repeat(8, 12.5%);
            padding-left: 8px;
        }

        .modal .cursor-pointer {
            padding-left: 9px;
            padding-bottom: 3px;
            display: inline;
        }

        .modal .rc-note {
            width: 32px;
            height: 30px;
        }

        .modal .content>p {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .PWIN .rc-odds-m {
            width: 12%;
        }

        .JTCOMBO .meeting-info {
            justify-content: space-between;
        }

        .JTCOMBO .meeting-info .meeting-info-container {
            display: block;
        }

        .JTCOMBO .meeting-info .allupformula {
            display: inline-flex;
            align-items: center;
        }

        .CROSS_ALUP .meeting-info-allup .allupformula, .CWAALLALUP .meeting-info-allup .allupformula, .CWAALUP .meeting-info-allup .allupformula,
        .WPALUP .meeting-info-allup .allupformula, .WPQALUP .meeting-info-allup .allupformula, .FCTALUP .meeting-info-allup .allupformula, .TRIALUP .meeting-info-allup .allupformula {
            display: none;
        }

        .CROSS_ALUP .meeting-info-allup-m, .CWAALLALUP .meeting-info-allup-m, .CWAALUP .meeting-info-allup-m,
        .WPALUP .meeting-info-allup-m, .WPQALUP .meeting-info-allup-m, .FCTALUP .meeting-info-allup-m, .TRIALUP .meeting-info-allup-m {
            display: block;
            background-color: #FFFFFF;
            margin-top: 1px;
            padding: 10px 12px;
        }

        .CROSS_ALUP .meeting-info-allup-m .allupformula, .CWAALLALUP .meeting-info-allup-m .allupformula, .CWAALUP .meeting-info-allup-m .allupformula,
        .WPALUP .meeting-info-allup-m .allupformula, .WPQALUP .meeting-info-allup-m .allupformula, .FCTALUP .meeting-info-allup-m .allupformula, .TRIALUP .meeting-info-allup-m .allupformula {
            display: block !important;
        }

        .JTCOMBO .meeting-info .allupformula .rc-jtc-dropdown {
            width: 100px;
        }

        .JTCOMBO .rc-jtc-allup-dropdown {
            display: block;
        }

        .JTCOMBO .rc-jtc-allup-dropdown .allupformula {
            border-top: 1px solid #3333;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: left;
            padding-left: 14px;
            .dropdown-allup-formulas {
                width: 140px;
            }
        }

        .TT .meeting-info {
            display: flex;
        }

        .TT .dd-arrow-down {
            margin-top: -16px;
        }

        .TT .radio-row-m {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background-color: #FFFFFF;
            align-items: center;
            border-top: 1px solid #dfdfdf;
        }

        .TT .radio-row, .TT .ttqp-btn, .TT .ttqp-addslip, .TT .radio-row .separator {
            display: none;
        }

        .en .TT .radio-row-m .method-title {
            padding: 12px 0px 12px 12px;
            width: 100px;
        }

        .ch .TT .radio-row-m .method-title {
            padding: 20px 0px 12px 12px;
            width: 100px;
        }

        .TT .rc-tt-dropdown {
            border: 1px solid #000000;
            background-color: #FFFFFF;
            width: 148px;
            height: 28px;
            line-height: 20px;
            vertical-align: middle;
            text-align: left;
            margin: 16px 8px;
        }

        .dropdown-allup-formulas.rc-tt-dropdown{
            .dropdown-menu.show{
                width: 220px;
                height: auto;
                inset: 10px 0 auto auto !important;
            }
        }

        .TT .method-title{
            padding-left: 6px;
            margin-right: -8px;
        }

        .TT .rc-tt-dropdown-menu {
            position: absolute;
            transform: translate(0px, 10px);
            width: 300px;
            height: 300px;
            z-index: 1050;
            right: -50%;
        }

        .TT .ttqp-row {
            min-width: 70px;
            width: 70px;
            visibility: visible;
        }

        .TT .ttqp-button {
            margin-right: 8px;
            min-width: 40px;
        }

        .CROSS_ALUP .meeting-info-allup,
        .JTCOMBO .meeting-info-allup, .JTCOMBO .meeting-info-allup-m {
            display: none;
        }

        // .full-column-m {
        //     width: 100%;
        // }

        // .full-column-no-margin-m {
        //     width: calc(100% - 24px);
        // }

        .rc-odds-row-compact.betSlip, .multi-leg-footer-betSlip {
            display: none;
        }

        .rc-odds-table-compact-race-header {
            display: flex;
            width: calc(100vw - 30px);
            height: 36px;
            background-color: unset;
            color: #333333;
            align-items: center;
            /* margin-top: 8px; */
        }

        .rc-odds-table-compact-race-subheader {
            display: block;
            width: calc(100vw - 18px);
            height: auto;
            background-color: #FFFFFF;
            color: #333333;
            border-radius: 4px 4px 0 0;
            padding: 8px 12px;
            line-height: 18px;
        }

        .slick-list .rc-odds-table-compact-race-subheader {
            width: calc(100vw - 30px);
        }

        .rc-odds-table-compact {
            background-color: #dee2e6;
            border-radius: 4px;
            display: table;
            width: calc(100vw - 18px);
            margin: 8px 0px;
            border-collapse: collapse;
        }

        .rc-odds-table-compact.all {
            margin: 0px;
        }

        .slick-list .rc-odds-table-compact {
            width: calc(100vw - 30px);
        }

        .DBL .rc-odds-table-compact, .TBL .rc-odds-table-compact,
        .DT .rc-odds-table-compact, .TT .rc-odds-table-compact, .SIXUP .rc-odds-table-compact {
            width: calc(100vw - 30px);
            margin: 0px;
        }

        .CWA .rc-odds-table-compact, .CWB .rc-odds-table-compact, .CWC .rc-odds-table-compact, .CWAALLALUP .rc-odds-table-compact {
            width: 100%;
            margin: inherit;
        }


        .rc-odds-table-compact .rc-odds-table-header-m {
            display: table-row;
        }

        .rc-odds-table-compact .rc-odds-table-header-m td {
            display: table-cell;
            vertical-align: middle;
            text-align: left;
            min-height: 30px;
            padding: 4px 0px;
            font-size: 11px;
            height: auto;
        }

        .WPQ .rc-odds-table-compact,
        .IWN .rc-odds-table-compact,
        .FCT .rc-odds-table-compact,
        .TCE .rc-odds-table-compact,
        .TRI .rc-odds-table-compact,
        .QTT .rc-odds-table-compact,
        .FF .rc-odds-table-compact {
            margin: 0px 6px;
            width: calc(100% - 12px);
        }

        .WPQ .rc-odds-table-compact>tr:first-child,
        .IWN .rc-odds-table-compact>tr:first-child,
        .FCT .rc-odds-table-compact>tr:first-child,
        .TCE .rc-odds-table-compact>tr:first-child,
        .TRI .rc-odds-table-compact>tr:first-child,
        .QTT .rc-odds-table-compact>tr:first-child,
        .FF .rc-odds-table-compact>tr:first-child {
            background-color: unset;
        }

        .WPQ .rc-odds-table-header-m>td,
        .IWN .rc-odds-table-header-m>td,
        .FCT .rc-odds-table-header-m>td,
        .TCE .rc-odds-table-header-m>td,
        .TRI .rc-odds-table-header-m>td,
        .QTT .rc-odds-table-header-m>td,
        .FF .rc-odds-table-header-m>td {
            background-color: unset;
            padding: 12px 0px 8px 0px;
        }

        .rc-odds-table-compact .rc-odds-table-header-m .no, .rc-odds-table-compact .rc-odds-row-m .no,
        .rc-odds-table-compact .rc-odds-table-header-m .banker, .rc-odds-table-compact .rc-odds-table-header-m .leg,
        .rc-odds-table-compact .rc-odds-row-m .rc-checkbox {
            text-align: center;
            /* width: 8%; */
        }

        .rc-odds-table-compact .rc-odds-table-header-m .horseName {
            margin: 8px 4px;
            padding-left: 0px;
        }

        .rc-odds-table-compact .rc-odds-table .horseName.simulcast {
            margin: 8px 4px;
        }

        .rc-odds-table-compact .rc-odds-table .horseName {
            padding: 8px 4px;
        }

        .rc-odds-table-compact .rc-odds-table-header-m .win {
            width: 12%;
            // text-align: left;
            padding-left: 4px;
        }

        .rc-odds-table-compact .rc-odds-table-header-m .place {
            width: 12%;
            // text-align: left;
        }

        .rc-odds-table-compact .rc-odds-table-header-m .RCNOTE, .rc-odds-table-compact .rc-odds-row-m .RCNOTE {
            display: none;
        }

        .rc-odds-table-compact .rc-odds-row-m {
            display: table-row;
        }

        .rc-odds-table-compact .rc-odds-row-m>td {
            display: table-cell;
            vertical-align: middle;
        }

        .rc-odds-table-compact .favourite a, .rc-odds-table-compact .od-20 a, .rc-odds-table-compact .od-50 a {
            height: 22px;
        }

        .rc-odds-table-compact .table-arrow-m {
            width: 10%;
            display: table-cell;
        }

        .slider-container-venue .slick-list {
            /* width: 80px !important; */
        }

        .WPQ .mobile-tab-set {
            display: grid;
            grid-template-columns: 33% 33% 34%;
            position: relative;
            width: 100%;
            height: 36px;
            font-size: 15px;
            line-height: 32px;
            background-color: #F6F6F6;
            margin-top: 12px;
        }

        .mobile-tab-set>div a {
            color: #122C68;
            font-weight: 700;
        }

        .mobile-tab-set .tab-highlight {
            border-bottom: 4px solid #FEC107;
        }

        .WPQ .mobile-tab-set .line {
            position: absolute;
            bottom: 0;
            transition: left 0.5s ease-in-out, width 0.5s 0.10s;
            height: 4px;
            background-color: #FEC107;
            left: 0;
            width: 33%;
            pointer-events: none;
        }

        .WPQ .radio-button-set,
        .FCT .radio-button-set,
        .TCE .radio-button-set,
        .QTT .radio-button-set {
            display: none;
        }

        .FCT .mobile-tab-set,
        .TCE .mobile-tab-set,
        .QTT .mobile-tab-set {
            display: grid;
            grid-template-columns: repeat(5, 20%);
            position: relative;
            margin-top: 12px;
            height: 48px;
            font-size: 13px;
            background-color: #F6F6F6;
            text-align: center;
        }

        .WPQ .mobile-tab-set>div,
        .FCT .mobile-tab-set>div,
        .TCE .mobile-tab-set>div,
        .QTT .mobile-tab-set>div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .FCT .mobile-tab-set .line,
        .TCE .mobile-tab-set .line,
        .QTT .mobile-tab-set .line {
            position: absolute;
            bottom: 0;
            transition: left 0.5s ease-in-out, width 0.5s 0.10s;
            height: 4px;
            background-color: #FEC107;
            left: 0;
            width: 20%;
            pointer-events: none;
        }

        .QTT .qtt-qp-button-set>div {
            display: inline-block;
        }


        .QTT .qtt-qp-button-set .randomBtn {
            width: 32px;
            height: 32px;
            &:hover {
                color: #122C68;
                background-color: #FFFFFF;
            }
            &:active {
                background-color: #122c68;
                color: #ffffff;
            }
        }

        .qin-odds-table-container .qin-odds-slide-container {
            overflow: hidden;
            margin: 0 6px;
            width: calc(100vw - 12px);
            border-radius: 0 0 4px 4px;
        }

        .qin-odds-slide {
            overflow-x: auto;
        }

        .qin-odds-table-container .show-table {
            border-radius: 4px 4px 0 0;
        }

        .qin-odds-table-container .hide-title {
            position: inherit;
        }

        .qin-odds-table-container .hide-title>.qin-odds-table-title {
            position: relative;
        }

        .qin-odds-table-header>.qin-odds-table-title>div {
            /* position: absolute; */
            background-color: #122C68;
            /* left: 0;
            top: 0; */
            font-weight: bold;
            // width: 98px;
            /* padding-right: 18px; */
            min-height: auto;
        }

        .iwn-odds-table-container, .qin-odds-table-container {
            width: 100vw;
            transition: height 0.2s ease-out;
            overflow: hidden;
            height: 40px;
        }
        .qinHideTable{
            height: 40px !important;
        }

        .odds-table-display-switch {
            box-sizing: border-box;
            margin: 0 6px;
            display: block;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px;
            padding-right: 8px;
            font-size: 15px;
            background: #fff;
            width: calc(100vw - 12px);
            height: 40px;
            border-radius: 4px;
            font-weight: bold;
        }

        .odds-table-display-switch>div {
            margin: 0 8px;
            // position: relative;
            // bottom: 3px;
        }

        .iwn-odds-table-container .iwn-odds-slide-container {
            overflow-x: auto;
            margin: 0 6px;
        }

        .iwn-odds-table-pagination>div:hover {
            background-color: #fff;
            color: #333;
        }

        .iwn-odds-table-pagination, .qin-odds-table-pagination {
            margin-left: 10px;
            margin-right: 10px;
            height: auto;
        }

        .iwn-odds-table-container {
            position: relative;
        }

        .iwn-odds-table>tr>td, .qin-odds-table>tr>td {
            // min-width: 33px;
        }

        .iwn-odds-table-header .iwn-odds-table-title>div, .qin-odds-table-header .qin-odds-table-title>div {
            min-height: 26px;
            min-width: auto;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 4px;
            // margin: 4px 2px !important;

        }

        .iwn-odds-table, .qin-odds-table {
            aspect-ratio: unset !important;
        }

        .qin-odds-table-page3 {
            width: 41.176% !important;
        }

        .iwn-odds-table > tr > td,
        .qin-odds-table > tr > td {
            width: 21px;
            height: 21px;
            min-width: 21px;
            min-height: 21px;
            font-size: 11px;
        }

        .iwn-odds-table .iwn-odds-table-header .iwn-odds-table-title > div {
            height: unset !important;
            min-height: unset !important;
            margin: 2px 0;
        }

        .iwn-odds-table .left-number {
            height: unset !important;
        }

        .qin-odds-table-header .qin-odds-table-title {
            // width: 98px;
        }

        .iwn-table-show .odds-table-display-switch {
            border-radius: 4px 4px 0 0;
        }

        .rc-jtc-search-item {
            /* height: 100px; */
            /* padding: 8px 0px 0px 12px; */
            flex-direction: column;
        }
        .rc-jtc-search-item ul {
            flex-wrap: wrap;
            justify-content: center;
            width: 80%;
        }

        .rc-jtc-search-item ul li {
            flex: auto;
            width: 80%;
            /* justify-content: flex-end; */
        }

        .rc-jtc-btn{
            padding-left: unset;
        }

        .rc-jtc-dropdown {
            margin: 0px;
            padding: 0px;
            width: calc(100% - 4px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #333333;
        }
        .dropdown-allup-formulas{
            width: 100%;
            margin-bottom: 12px;
            .btn-Formulas{
                justify-content: flex-end;
                :first-child{
                    font-weight: 500;
                }
            }

            // &.jkcAllup .dropdown-menu.show {
            //     width: 280px;
            //     inset: 2px -2px auto auto !important;
            // }
        }
        .rcOddsTable>header:not(.JTCOMBO){
            .allupformula{
                align-items: center;
                justify-content: space-between;
                .dropdown-allup-formulas{
                    width: 66%;
                    margin-bottom: 0;
                }
            }
        }
        .rc-jtc-dropdown>div:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            /* width: calc(100% - 30px); */
        }

        .rc-jtc-search-item ul li.rc-jtc-btn {
            /* display: block; */
            padding-top: 8px;
            /* padding-right: 15px; */
            justify-content: center;
            /* margin-left: auto; */
        }

        .rc-jtc-search-item ul li.rc-jtc-btn .race-btn {
            width: 120px
        }

        .rc-jtc-search-item ul li .rc-jtc-dropdown {
            width: 100%;
            color: #333333;
            /* display: block; */
            justify-content: flex-end;
        }

        .rc-jtc-search-item ul li .rc-jtc-dropdown-label {
            position: absolute;
            line-height: 16px;
            left: 8px;
            top: 6px;
            clip-path: inset(0 5px 0 0);
        }

        .rc-jtc-search-item ul li .rc-jtc-dropdown-menu {
            right: 0;
            /* left: 5vw; */
            transform: translate(0px, 50px);
            width: 80vw;
        }



        .rc-jtc-search-item ul li .rc-jtc-dropdown-menu>div>div:first-child,
        .rc-jtc-dropdown-menu ul li .rc-jtc-dropdown-menu>div>div:first-child{
            margin-bottom: 0px !important;
        }

        /* .rc-jtc-search-item ul li:first-child .rc-jtc-dropdown-menu {
            transform: translate(0px, 15px);
        }

        .rc-jtc-search-item ul li:nth-child(2) .rc-jtc-dropdown-menu {
            transform: translate(-40%, 15px);
        }

        .rc-jtc-search-item ul li:nth-child(3) .rc-jtc-dropdown-menu {
            transform: translate(-79%, 15px);
        } */

        /* .rc-jtc-search-item ul li:nth-child(2) .rc-jtc-dropdown-menu:before {
            right: 30vw;
        } */

        /* .rc-jtc-search-item ul li:first-child .rc-jtc-dropdown-menu:before {
            left: 20px;
            right: unset;
        } */
        .HR{
            .masonry-col>div:first-child, .masonry-col>div:last-child {
                width: 100%;
                margin: 0px;
                flex: 1 !important;
            }
        }

        .masonry-col-cwa>div:first-child, .masonry-col-cwa>div:last-child {
            width: 100%;
            margin: 0px;
        }

        .masonry-col-cwa>div>div {
            width: 100%;
        }

        .masonry-col-jkc>div:first-child, .masonry-col-jkc>div:last-child,
        .masonry-col-tnc>div:first-child, .masonry-col-tnc>div:last-child {
            width: 100%;
            margin: 0px;
        }

        .result_dividend {
          margin-top: 12px;
        }

        .RESULTS .masonry-col>div:first-child, .RESULTS .masonry-col>div:last-child {
            width: 100%;
            margin: 0px;
        }

        .RESULTS .masonry-col {
            padding: 0 6px;
        }

        .exp-arrow {
            max-width: 40px;
        }

        .wpq-odds-table-cell .cell-label {
            padding-left: 5px;
            height: 26px;
            width: 60%;
        }

        .wpq-odds-table-cell .cell-value {
            width: 40%;
            height: 26px;
        }

        .wpq-table-switch {
            display: none;
        }

        .wpq-table-switch-m {
            display: flex;
            background-color: #f6f6f6;
            position: relative;
            height: 34px;
            font-weight: bold;
            /* width: 100%; */
        }

        .wpq-table-switch-m div {
            flex: 1;
            line-height: 34px;
            text-align: center;
        }

        .wpq-table-switch-m .wpq-table-line {
            position: absolute;
            height: 4px;
            width: 33%;
            background-color: #FEC107;
            transition: left 0.2s ease-out;
            bottom: 0;
        }

        .meeting-info-investment>div {
            text-align: left;
            display: inline-block;
            padding: 4px 8px 4px 0px;
        }

        .meeting-info-investment .label, .meeting-info-investment .amount-blue {
            /* display: block; */
        }

        .meeting-info-investment>div:not(:first-child) {
            /* border-left: 1px solid #B4B4B4;
            padding-left: 8px; */
        }

        .meeting-info-investment {
            padding: 4px 0 4px 12px;
        }

        .meeting-info-ivestment-2x {
            grid-template-columns: 1fr 1fr;
            gap: 0 8px;
        }

        .meeting-info-ivestment-3x {
            grid-template-columns: 1fr 1fr 1fr;
            gap: 0 3px;
        }

        .multi-leg-2x-table, .multi-leg-3x-table {
            margin-top: 0px;
            display: block;
            .slick-list {
                overflow: unset; // 為了移動端顯示box-shadow
            }
            &.slick-slider {
                -ms-touch-action: pan-y pinch-zoom;
                touch-action: pan-y pinch-zoom;
            }
        }


        .rc-odds-enquiry {
            display: none;
        }

        .rc-odds-enquiry-m {
            display: flex;
            background: white;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            line-height: 23px;
            font-size: 11px;
            padding-top: 10px;
            flex-wrap: wrap;
        }

        .rc-odds-enquiry-m .title {
            width: 100%;
            text-align: right;
            padding: 0 12px 8px 0px;
        }

        .rc-odds-enquiry-m .content {
            width: 100%;
            text-align: center;
            padding-bottom: 10px;
        }

        .rc-odds-enquiry-m .content>div:not(:last-child) {
            display: flex;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 10px;
        }

        .rc-odds-enquiry-m .content>div:not(:last-child) div {
            text-align: center;
            width: 55px;
            font-size: 15px;
        }

        .rc-odds-enquiry-m .content>div:first-child {
            border-bottom: 1px #dee2e6 solid;
        }

        .rc-odds-enquiry-m .race-btn {
            width: 120px;
            margin: 10px 0px 5px 0px;
        }

        .rc-odds-enquiry-m .odds {
            background-color: #DBE6EF;
            border-radius: 4px;
        }

        .QTTOddsTable .accordion-button::after {
            transform: rotate(0deg);
        }

        .QTTOddsTable .accordion-button:not(.collapsed)::after {
            transform: rotate(-180deg);
        }

        .cwin-racecard-table {
            padding: 0px 6px;
        }

        .CWAALLALUP .cwin-racecard-table {
            padding: 0px;
        }

        .QTTOddsTable-m, .cwin-racecard-table-m {
            display: block;
        }

        .multi-leg-slide-btn-prev {
            display: block;
            position: fixed;
            left: 0;
            bottom: 50%;
            z-index: 500;
            width: 21px;
            height: 40px;
            border-radius: 0 20px 20px 0;
            background: #173E96;
        }

        .multi-leg-slide-btn-next {
            display: block;
            position: fixed;
            right: 0;
            bottom: 50%;
            z-index: 500;
            width: 21px;
            height: 40px;
            border-radius: 20px 0 0 20px;
            background: #173E96;
        }

        .expand-clickable-area {
            position: absolute;
            top: -20px;
            right: -20px;
            bottom: -20px;
            left: -20px;
        }

        .multi-leg-slide-bottom-node {
            display: flex;
            position: fixed;
            width: 100%;
            z-index: 500;
            height: 10px;
            justify-content: center;
        }

        .multi-leg-slide-grey-node {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin: 0px 2px;
            background-color: #757575;
        }

        .multi-leg-slide-blue-node {
            width: 10px;
            height: 10px;
            border-radius: 5px;
            margin: 0px 2px;
            background-color: #5278C3;
        }

        .multi-leg-slide-btn-next .arrow-icon {
            margin: 11px 0px 0px 4px;
        }
        .multi-leg-slide-btn-prev .arrow-icon {
            margin: 11px 4px 0px -1px;
        }

        .multi-leg-slide-btn-prev .hide, .multi-leg-slide-btn-next .hide {
            display: none;
        }

        .QTTOddsTable-m>a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            height: 40px;
            background-color: white;
            margin: 0px 6px 0px 6px;
            border-radius: 4px;
            padding-left: 16px;
            padding-right: 8px;
        }

        .cwin-racecard-table-m>a {
            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            height: 36px;
            background-color: white;
            margin: -2px 0px 0px 0px;
            border-radius: 4px;
        }

        .QTTOddsTable-m>a>label, .cwin-racecard-table-m>a>label {
            margin-right: 5px;
        }

        .QTTOddsTable-m .arrow-up, .cwin-racecard-table-m .arrow-up {
            margin-left: 5px;
            margin-top: 6px;
        }


        .QTTOddsTable-m .arrow-down, .cwin-racecard-table-m .arrow-down {
            margin-left: 5px;
            margin-bottom: 3px;
        }

        .qTTOddsTableCollapse.collapse:not(.show), .cwinRaceCardCollapse.collapse:not(.show),
        .rc-odds-table-compact.pr:not(.show), .collapseIconList.collapse:not(.show) {
            display: none;
        }
        .qTTOddsTableCollapse {
            margin: 6px;
            width: calc(100% - 12px);
        }
        /* .qTTOddsTableCollapse.collapse {
            margin: 6px;
            width: calc(100% - 12px);
        } */

        .qTTOddsTableCollapse .m-slide {
            display: flex;
            background-color: #f6f6f6;
            position: relative;
            height: 34px;
            font-size: 15px;
            color: #122C68;
            letter-spacing: 0;
            text-align: center;
            line-height: 16px;
            font-weight: 700;
        }

        .qTTOddsTableCollapse .m-slide div {
            flex: 1 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .en .qTTOddsTableCollapse .m-slide.qtt4Tab {
            height: 40px;
        }

        .en .qTTOddsTableCollapse .m-slide.qtt4Tab div {
            line-height: unset;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 2px;
        }

        .qTTOddsTableCollapse .m-slide .table-line {
            position: absolute;
            height: 4px;
            width: 33%;
            background-color: #fec107;
            transition: left .2s ease-out;
            bottom: 0;
        }


        .qTTOddsTableCollapse .m-slide.qtt4Tab .table-line {
            position: absolute;
            height: 4px;
            width: 25%;
            background-color: #fec107;
            transition: left .2s ease-out;
            bottom: 0;
        }

        .qTTOddsTableCollapse .m-content {
            display: block;
        }

        .qTTOddsTableCollapse .m-content header {
            display: none;
        }

        .cwin-table-container {
            margin: 10px 6px;
        }

        .CWAALLALUP .cwin-table-container {
            margin: 10px 0px;
        }

        .cwin-table-container header {
            background-color: #FFFFFF;
            color: #333333;
            font-weight: bold;
        }

        .cwin-table-header .cwin-detail-switch {
            margin: 0 4px;
        }

        .cwin-table .cwin-table-header>div {
            padding: 5px 0;
        }

        .cwin-table .cwin-table-header .cwin-table-comp {
            padding-left: 10px;
            min-width: 100px;
        }

        .cwin-table .cwin-table-header .cwin-table-detail>div {
            text-align: center;
        }

        .race-result-table .race-result-table-row, .race-result-table .race-result-table-header {
            display: none;
        }

        .race-result-table .race-result-table-row-m, .race-result-table .race-result-table-header-m {
            display: table-row;
        }

        .race-result-table .race-result-table-header-m {
            font-size: 11px;
            // background-color: #dee2e6;
        }

        .race-result-table-container {
            margin: 5px 6px;
        }

        .race-result-table-header-m>div {
            padding: 0 3px;
        }

        &.en .race-result-table-header-m {
            .position {
                width: 13.3333%;
            }

            .runner-no {
                width: 6.4%;
            }

            .wt {
                width: 6.93333%;
            }

            .draw {
                width: 9.0666%;
            }

            .odds {
                width: 12%;
            }
        }

        &.ch .race-result-table-header-m {
            .position {
                width: 15.46666%;
            }

            .runner-no {
                width: 9.0666%;
            }

            .wt {
                width: 9.0666%;
            }

            .draw {
                width: 9.0666%;
            }

            .odds {
                width: 14%;
            }
        }

        .race-result-table>.race-result-table-row-m>div {
            vertical-align: initial;
            padding-top: 5px;
        }

        .dividend-table-container {
            margin-bottom: 12px;
        }

        .dividend-table-container>header {
            height: 36px;
            color: #FFF;
            font-size: 15px;
            font-weight: bold;
            line-height: 36px;
            padding-left: 10px;
            background-color: #122c68;
            border-radius: 4px 4px 0 0;
        }

        .dividend-table .table-cell-r>div:last-child {
            width: 45%;
            text-align: right;
        }

        .dividend-table .table-cell-r>div:first-child {
            width: 55%;
            position: relative;
            padding-left: 10px;
            line-height: 32px;
            min-height: 32px;
            height: 100%;
        }

        .dividend-table .divi-cwin-item>div:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .dividend-table .divi-cwin-item>div:first-child>div {
            line-height: 18px;
            min-height: 18px;
        }

        .dividend-table .divi-cwin-item {
            line-height: 16px;
            min-height: 32px;
        }

        .dividend-table>div>div .switch-btn-icon {
            min-width: 21px;
            max-width: 21px;
        }

        .dividend-table .table-cell-r>div:first-child::after {
            content: '';
            height: 70%;
            width: 1px;
            background-color: #b4b4b4;
            position: absolute;
            left: 0;
            top: 15%;
        }

        .dividend-table .table-cell-r .divi-table-iwn-guarantee {
            line-height: 16px;
        }

        .dividend-table .table-cell-r .divi-table-iwn-ctrl {
            justify-content: space-between;
        }

        .dividend-table .table-cell-r>div:first-child::before {
            content: '';
            height: 70%;
            width: 1px;
            background-color: #b4b4b4;
            position: absolute;
            right: 0;
            top: 15%;
        }

        .dividend-table .divi-cwin-item-l {
            width: 100%;
            justify-content: space-between;
            align-items: center;
        }

        .dividend-table .divi-cwin-item-b {
            line-height: 18px;
        }

        .dividend-table .dividend-table-header>div>div, .dividend-table .dividend-table-header {
            height: 24px;
            line-height: 24px;
            font-size: 11px;
        }

        .dividend-table-header .table-cell-r>div:first-child::before, .dividend-table-header .table-cell-r>div:first-child::after {
            position: inherit;
        }

        .race-result-table-row-m .horseName .joc-trn-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin-top: -4px;
        }

        .race-result-table-row-m .runner-no {
            opacity: 1;
            color: rgba(18,44,104,1);
            font-size: 15px;
            font-weight: 500;
            text-align: center;
            line-height: 16px;
        }

        .race-result-table-row-m {
            .wt, .draw, .odds {
                opacity: 1;
                color: rgba(51,51,51,1);
                font-size: 15px;
                font-weight: 400;
                text-align: center;
                line-height: 16px;
            }
        }

        .race-result-table-row-m .horseName {
            margin-left: 5px;
        }

        .race-result-table-row-m .horseName a>div {
            padding: 0 0 8px 0;
            word-break: break-word;
        }

        .race-result-table-row-m .horseName div {
            text-align: left;
        }

        .race-result-table-row-m .horseName .joc-trn-row>div {
            display: flex;
            margin: 5px 8px 0 0;
        }

        .race-result-table-row-m .race-position-m {
            display: inline-block;
            width: 22px;
            height: 22px;
            line-height: 22px;
            font-weight: bold;
            color: #09254E;
            border-radius: 50%;
            background: #DBE6EF;
        }

        .m-content .quartet-odds-table-cell {
            border-right: unset;
            padding-left: 2px;
        }

        .m-content .odds-table-investment .quartet-odds-table-cell:not(:last-child) {
            border-right: 1px #cccbcb solid;
        }

        .m-content .quartet-odds-table-top20-box .quartet-odds-table-row:not(:first-child) .quartet-odds-table-cell {
            border-bottom: 1px #cccbcb solid;
        }

        .m-content .quartet-odds-table-top10-box>div {
            padding-top: 10px;
        }

        .m-content .quartet-odds-table-cell:not(:last-child)>div {
            border-right: 1px #cccbcb solid;
        }

        .m-content .odds-table-investment .quartet-odds-table-cell:not(:last-child)>div {
            border-right: unset;
        }

        .m-content .quartet-odds-table-cell .cell-value, .m-content .quartet-odds-table-cell .cell-label {
            font-size: 15px;
            color: #122C68;
            font-weight: 500;
        }

        .m-content .wpq-odds-table-top10 .quartet-odds-table-cell {
            width: 25%;
        }

        .m-content .wpq-odds-table-top10 .quartet-odds-table-cell>div {
            padding-right: 10px;
        }

        .m-content .quartet-odds-table-row .quartet-odds-table-cell .header {
            font-size: 11px;
            color: #333333;
            font-weight: 400;
            padding-left: 0px;
            border: unset;
            height: auto;
            padding-top: 10px;
        }

        .m-content .quartet-odds-table-row .combinations {
            text-align: left;
        }

        .m-content .quartet-odds-table-row .odds {
            width: 32px;
            text-align: center;
        }

        .meeting-info-fo-topinfo-container {
            flex-basis: 100%;
        }

        .JKC .JKCIcon, .TNC .TNCIcon {
            text-align: center;
            font-size: 20px;
            color: #333333;
            margin: 0px 10px 0px 10px;
            background-color: #FFFFFF;
            border-radius: 10px 10px 0px 0px;
            width: auto;
        }

        .JKC .jkcicontitle, .TNC .tncicontitle {
            text-align: center;
        }

        .JKC .IconListDownArrow, .TNC .IconListDownArrow {
            display: none;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
            border: unset;
            margin: 0px;
            padding: 0px;
            background-size: cover;
            border-radius: 7px;
        }

        .JKC .IconListUpArrow, .TNC .IconListUpArrow {
            display: inline;
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-color: #FFFFFF;
            border: unset;
            margin: 0px;
            padding: 0px;
            background-size: cover;
            border-radius: 7px;
        }

        .JKC .IconListDownArrow.hide, .TNC .IconListDownArrow.hide {
            display: none;
        }

        .JKC .IconListDownArrow.show, .TNC .IconListDownArrow.show {
            display: inline;
        }

        .JKC .IconListUpArrow.hide, .TNC .IconListUpArrow.hide {
            display: none;
        }

        .JKC .IconListUpArrow.show, .TNC .IconListUpArrow.show {
            display: inline;
        }

        .JKC .icon-container.fadeOut, .TNC .icon-container.fadeOut {
            opacity: 0;
            width: 0;
            height: 0;
            transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

        }

        .JKC .icon-container.fadeIn, .TNC .icon-container.fadeIn {
            opacity: 1;
            transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

        }

        .JKC .meeting-info-fo-topinfo-m-container, .TNC .meeting-info-fo-topinfo-m-container {
            flex-basis: 100%;
            display: flex;

        }

        .JKC .meeting-info-fo-topinfo-m-container .desc, .TNC .meeting-info-fo-topinfo-m-container .desc {
            flex-basis: 60%;
            margin: 10px 2px;
            display: flex;
            vertical-align: middle;
        }

        .JKC .meeting-info-fo-topinfo-m-container .meeting-info-menu, .TNC .meeting-info-fo-topinfo-m-container .meeting-info-menu {
            flex-basis: 40%;
            display: inline-flex;
            vertical-align: middle;
        }

        .JKC .meeting-info-container .separator, .TNC .meeting-info-container .separator {
            background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
            display: none;
            vertical-align: middle;
            margin: 0px;
            padding: 0px;
            width: 2px;
            height: 24px;
            margin: 0px 20px 0px 20px;
        }

        .JKC .nextline, .TNC .nextline {
            display: inline;
        }

        .JKC .icon-container, .TNC .icon-container {
            background-color: #FFFFFF;
            border-radius: 20px;
            margin: 1px 10px 10px 10px;
        }

        .JKC .rc-odds-table-compact, .TNC .rc-odds-table-compact {
            background-color: white;
            display: inline;
        }

        .JKC .rc-odds-table-header div, .TNC .rc-odds-table-header div {
            padding: 0px;
        }

        .JKC .rc-odds-table-compact .sel, .TNC .rc-odds-table-compact .sel {
            width: auto;
            min-width: 0px;
        }

        .JKC .rc-odds-table-compact .jockey, .TNC .rc-odds-table-compact .jockey {
            min-width: 10px;
            padding: 0 5px;
            text-align: left;
        }

        .JKC .meeting-info-container, .TNC .meeting-info-container {
            flex-wrap: wrap;
        }

        .foslider {
            display: inline;
            vertical-align: middle;
            white-space: nowrap;

        }

        .foslider .slick-slider {
            background-color: #F4F4F4;
        }

        .foslider .slick-list {
            background-color: #F4F4F4;
            margin-left: 12px;
        }

        .foslider .slick-track {
            margin: 10px 0px;
            font-weight: 600;
            font-size: 14px;
            min-width: 1060px !important;
        }

        .DBL .meeting-info-container, .TBL .meeting-info-container, .DT .meeting-info-container,
        .TT .meeting-info-container, .SIXUP .meeting-info-container {
            visibility: visible;
        }

        .CROSS_ALUP .meeting-info-container, .CWAALLALUP .meeting-info-container, .CWAALUP .meeting-info-container, .WPALUP .meeting-info-container,
        .WPQALUP .meeting-info-container, .FCTALUP .meeting-info-container, .TRIALUP .meeting-info-container,
        .JKC .meeting-info-container, .TNC .meeting-info-container {
            display: block;
        }

        .banker-keypad {
            display: block;
            background-color: #FFFFFF;
        }

        .banker-keypad-text {
            padding: 0px 0px 8px 8px;
        }

        .banker-keypad-table {
            display: grid;
            width: 100%;
            text-align: center;
            align-items: center;
            grid-template-columns: 8% 12% 12% 12% 12% 12% 12% 12% 8%;
        }

        .banker-keypad-table>div {
            margin: 4px auto;
        }

        .banker-keypad-left {
            grid-row-start: span 2;
        }

        .banker-keypad-right {
            grid-row-end: span 2;
        }

        .banker-keypad-btn {
            width: 32px;
            height: 32px;
            line-height: 32px;
            background-color: #DBE6EF;
            color: #122C68;
            font-weight: bold;
            border: 0;
            border-radius: 8px;
            text-align: center;
        }

        .banker-keypad-btn.active {
            background-color: #122C68;
            color: #FFFFFF;
        }

        .banker-keypad-btn-scr {
            font-size: 11px;
            line-height: 14px;
            background-color: #A3AAB5;
            color: white;
            font-weight: normal;
        }

        .banker-keypad-arrow-btn {
            display: block;
            width: 28px;
            height: 28px;
            border: 0;
            border-radius: 14px;
            background: #CCCCCC;
        }

        .banker-keypad-arrow-btn.active {
            border: solid 1px #DDDDDD;
            background-color: #FFFFFF;
            box-shadow: #DDDDDD 0 0 8px;
        }

        .banker-keypad-arrow-btn.left {
            margin-left: 4px;
        }

        .banker-keypad-arrow-btn.right {
            margin-right: 8px;
        }

        .banker-keypad-arrow-btn.left li {
            margin: 8px 0px 0px 4px;
        }

        .banker-keypad-arrow-btn.right li {
            margin: 8px 4px 0px 0px;
        }

        .rc-tt-dropdown-menu:before {
            right: 92px;
        }

        .rc-cwa-all-head {
            display: flex;
            width: calc(100% - 16px);
            height: 44px;
            background: #FFFFFF;
            border-radius: 4px 4px 0 0;
            color: #333333;
            font-size: 15px;
            justify-content: space-between;
            align-items: center;
            margin: 12px 8px 0px 8px;
        }

        .rc-cwa-all-head .reset-btn {
            display: none;
        }

        .rc-cwa-all-table-header {
            background-color: #F4F4F4;
        }

        .oddbg, .WPQ .evenbg, .DBL .evenbg, .TBL .evenbg, .DT .evenbg, .TT .evenbg, .SIXUP .evenbg {
            background-color: #FFFFFF;
        }

        .evenbg, .WPQ .oddbg, .DBL .oddbg, .TBL .oddbg, .DT .oddbg, .TT .oddbg, .SIXUP .oddbg {
            background-color: #F4F4F4;
        }

        .rc-cwa-all-table {
            margin: 0px 8px;
        }

        .cwin-table-container.win-header {
            display: none;
        }

        .rc-cwa-all-table .rc-odds-table-compact {
            margin: 0px;
        }

        .rc-cwa-all-m {
            display: block;
        }

        .rc-odds-table-compact-race-allup {
            width: calc(100vw - 18px);
        }

        .slick-list .rc-odds-table-compact-race-allup {
            width: calc(100vw - 30px);
            height: 45px;
        }


        .CWA .cwin-table-container {
            margin-bottom: 0px;
        }

        .CWA .rc-odds-table-compact {
            margin-top: 0px;
        }

        .rc-odds-table-compact-race-header>.WINOddsTable-m {
            width: calc(100vw - 18px);
            background: white;
            margin: 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            margin-right: 4px;
            border-radius: 4px;
        }

        .slick-list .rc-odds-table-compact-race-header>.WINOddsTable-m {
            width: calc(100vw - 30px);
        }

        .rc-odds-table-compact-race-header>.WINOddsTable-m a {
            display: flex;
            height: 36px;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            width: inherit;
        }

        .rc-odds-table-compact-race-header>.WINOddsTable-m a * {
            margin: 0 8px;
        }

        .rc-odds-table-compact-race-header>.WINOddsTable-m a .expend {
            margin-bottom: 6px;
        }

        .rc-odds-table-compact-race-header>.WINOddsTable-m a .collaspse {
            margin-top: 6px;
        }

        .CROSS_ALUP .rc-odds-table-header-m {
            background: #F4F4F4;
        }

        .CROSS_ALUP .rc-odds-table-compact.collapse .rc-odds-row-m:first-child {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
        }

        .rc-odds-table-compact-race-header .WINOddsTable {
            display: none;
        }

        .CWAALUP .rc-odds-table-compact {
            margin: 0px;
            width: calc(100vw - 29px);
        }

        .CWAALUP .rc-odds-table-header-m {
            background-color: #F4F4F4;
        }

        .rc-odds-table-compact.CWA .rc-odds-row-m:nth-child(-n+2)>td:first-child,
        .rc-odds-table-compact.CWA .rc-odds-row-m:nth-child(-n+2)>td:last-child {
            border-radius: 0px;
        }

        .CWAALUP .rc-odds-table-compact-race-header {
            width: calc(100vw - 25px);
        }

        .CWAALUP .rc-odds-table-compact-race-subheader {
            width: calc(100vw - 29px);
        }

        .rc-odds-table-compact-race-header.toggle {
            margin-top: 12px;
        }

        .rcOddsTable header.RESULTS>.rcTitle {
            display: flex;
            border-radius: 0;
            padding: 14px 14px 15px 14px;
            background-color: white;
            color: #333;
        }

        .rcOddsTable header.RESULTS>.rcTitle div:last-child {
            display: none;
        }

        .RESULTS .meeting-info-container {
            flex-basis: 100%;
        }

        .RESULTS .meeting-info-content {
            border-right: 0;
            padding-right: 14px;
            display: block;
        }

        .RESULTS .meeting-info-menu {
            display: none;
        }

        .RESULTS .forestable .rc-odds-row>div:first-child::before {
            content: '';
            height: 70%;
            width: 1px;
            background-color: #b4b4b4;
            position: absolute;
            right: 0;
            top: 15%;
        }

        .RESULTS .forestable .rc-odds-row>div:first-child {
            width: 10%;
            position: relative;
            padding-left: 10px;
            line-height: 32px;
            min-height: 32px;
            height: 100%;
        }

        .RESULTS .forestable .rc-odds-row>div:last-child::after {
            content: '';
            height: 70%;
            width: 1px;
            background-color: #b4b4b4;
            position: absolute;
            left: 0;
            top: 15%;
        }

        .RESULTS .forestable .rc-odds-row>div:last-child {
            width: 10%;
            position: relative;
            padding-left: 10px;
            line-height: 32px;
            min-height: 32px;
            height: 100%;
        }

        .RESULTS .subtitleother {
            white-space: nowrap;
            text-align: left;
        }

        .dblTableTop {
            margin-top: 12px;
            display: block;
        }

        .dblTableBtm {
            display: none;
        }

        .meeting-info .foTopInfo {
            display: none;
        }

        .racingBlackMessage{
            justify-content: center;
            padding-left: unset;
            border-radius: unset;
        }

        .JKC .foTopInfo-m, .TNC .foTopInfo-m {
            display: block;
            padding: 12px;
            background-color: #FFFFFF;
            border-top: 1px solid #cccccc;
        }

        .meeting-info-fo-topinfo-container .separator {
            display: none;
        }

        .QTT .qtt-qp-button-set {
            background-color: #ffffff;
            padding: 10px 0px;
            height: auto;
        }

        .QTT .rc-odds-table-compact>tr:nth-child(odd) {
            background-color: #f4f4f4;
        }

        .QTT .rc-odds-table-compact>tr:nth-child(even) {
            background-color: #ffffff;
        }

        .QTT .rc-odds-table-compact>tr:first-child {
            background-color: unset;
        }

        .AddSlipBtn-Content {
            display: none;
        }

        .iwn-odds-slide-container {
            border-radius: 0 0 4px 4px;
        }
        .iwn-odds-table-header > :last-child {
            border-radius: 0 0 0 0;
        }
        .JTCOMBO .meeting-info-allup,
        .CROSS_ALUP .meeting-info-allup,
        .CWAALLALUP .meeting-info-allup,
        .WPALUP .meeting-info-allup,
        .WPQALUP .meeting-info-allup,
        .FCTALUP .meeting-info-allup,
        .TRIALUP .meeting-info-allup{
        margin: 0;
        }

        header.HOME .meeting-info-wplogo {
            display:none;
        }

        header.HOME .meeting-info-wplogo-m-ch {
            background-image: url('../../info/Include/images/worldpool_ch.png');
            background-size: 110px 20px;
            background-repeat: no-repeat;
            background-position: center;
            width:110px;
            height:26px;
            cursor: pointer;
        }

        header.HOME .meeting-info-wplogo-m-en {
            background-image: url('../../info/Include/images/worldpool_en.png');
            background-size: 110px 20px;
            background-repeat: no-repeat;
            background-position: center;
            width:110px;
            height:26px;
            cursor: pointer;
        }
    }
}