.personal-settings {
    font-size: 13px;
    width: min(100%, 800px);
    min-width: 783px; // 预留 17px滚动条位置
    header {
        display: flex;
        align-items: center;
        height: 40px;
        color: white;
        background-color: #003c84;
        padding-left: 24px;
        font-size: 15px;
        border-radius: 4px 4px 0 0;
    }
    main {
        display: flex;
        flex-direction: column;
        gap: 4px;
        margin: 4px;
        margin-bottom: 72px;
    }

    .racing-c,
    .football-c {
        display: grid;
        grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
        grid-auto-rows: 36px;
        grid-gap: 18px 25px;
        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 6px;
            color: #333333;
            &:nth-child(3n + 1),
            &:nth-child(3n + 2) {
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 1px;
                    opacity: 1;
                    background-color: #e4e4e4;
                    top: -9px;
                    bottom: -9px;
                    right: -14px;
                }
            }

            .input-c {
                max-width: 100px;
                height: 36px;
                span {
                    left: 5px;
                    top: 10px;
                }
                input {
                    padding-right: 4px;
                    border: 1px solid #757575;
                }
            }
        }
    }

    .radio-container {
        .radio-checkmark {
            height: 18px;
            width: 18px;
            border: 1.5px solid #003c84;
            &::after {
                background-color: #003c84;
            }
        }
    }

    .racing-notes-c,
    .account-name-c,
    .all-up-setting-c,
    .account-balance-c {
        display: flex;
        gap: 20px;
        .radio-container {
            width: 200px;
        }
    }

    .all-up-setting-c,
    .account-balance-c,
    .account-name-c {
        .text {
            width: 260px;
        }
    }

    .account-name-c {
        margin-bottom: 12px;
    }
    .m6-mul-drow-c {
        line-height: 20px;
        .select {
            width: 100px;
            height: 28px;
            margin: 0 12px;
        }
    }
}

// #save-tooltip {
//     .popover-body {
//         color: #333333;
//         /* font-weight: 500; */
//         padding: 8px;
//         font-family: "Noto Sans TC", sans-serif;
//         font-size: 13px;
//     }
// }


@media only screen and (max-width: 768px)  {
    .personal-settings {
        min-width: unset;
        header {
            height: 64px;
            justify-content: center;
        }

        .racing-c,
        .football-c {
            grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
            grid-auto-rows: 60px;
            grid-gap: 12px 20px;
            padding: 0;
            .item {
                justify-content: space-between;
                flex-direction: column;
                align-items: flex-start;
                &:nth-child(3n + 1),
                &:nth-child(3n + 2) {
                    position: static;
                    &::after {
                        content: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 560px)  {
    .personal-settings {
        .racing-c,
        .football-c {
            grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr);
            gap: 12px 6px;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 700px)  {
    .personal-settings {
        .account-balance-c,
        .account-name-c {
            .text {
                width: 220px;
            }
        }

        .all-up-setting-c {
            display: grid;
            grid-template-columns: 220px 1fr;
            row-gap: 12px;
            column-gap: 20px;
            .text {
                grid-column: span 2;
            }
        }
    }
}

@media only screen and (max-width: 480px)  {
    .personal-settings {
        .all-up-setting-c,
        .account-balance-c,
        .account-name-c {
            .text {
                min-width: 150px;
                width: auto;
            }
        }

        .all-up-setting-c {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 12px;
            column-gap: 20px;
            .text {
                grid-column: span 1;
            }
        }
    }
}
