@import '../common-mixin.scss';
.fbOddsTable header.football-fgsres-title-m>.fbTitle {
    display: none
}

.fb-fgsres-tables-m {
    display: none;
}

.fb-fgsres-table-container {
    width: 100%;
    margin-bottom: 15px;
}

.fb-fgsres-table {
    /* font-weight: bold; */
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.fb-fgsres-table .table-header {
    @include font11();
    height: 22px;
}

.fb-fgsres-table tr {
    height: 44px;
}

.fb-fgsres-table tr.odd {
    background: #fff;
}

.fb-fgsres-table tr.even {
    background: #f4f4f4;
}

.fb-fgsres-table tr:last-child{
    border-radius: 0 0 4px 4px;
}
.fb-fgsres-table tr:last-child > td:last-child{
    border-radius: 0 0 4px 0;
}


.fb-fgsres-table .rfd::after {
    content: '';
    height: 1px;
    position: absolute;
    background: #B6B6B6;
    left: 6px;
    right: 6px;
    top: auto;
    bottom: 0;
}


.fb-fgsres-table  td {
    text-align: center;
    vertical-align: middle;
    position: relative;
}

.fb-fgsres-table tr:not(.table-header) td {
    padding: 6px;
    @include H5();
}

.fb-fgsres-table .TournImage img {
    max-width: 24px;
}

.fb-fgsres-table tr:not(.table-header) td:nth-child(3) img {
    max-width: 24px;
}

.fb-fgsres-table  tr:not(.table-header) td:not(:last-child)::after {
    content: '';
    width: 1px;
    position: absolute;
    background: #e4e4e4;
    top: 6px;
    bottom: 6px;
    right: 0;
}

.white-space-pre {
    white-space: break-spaces;
    white-space: pre-line;
}

.fb-fgsres-table .text-align-left {
    text-align: left;
    padding-left: 8px !important
}

.fb-fgsres-table .color-red {
    color: rgb(222, 53, 11)
}

.keep-same-width-hack{
    height: 0;
    overflow: hidden;
    visibility: hidden;
}


.refund {
    word-break: break-word;
}
