@import '../common-mixin.scss';

.M6Middel section {
    /* width: 828px; */
    /* width: 100%; */
    flex: 1;
}

.M6Middel {
    /* width: 1064px; */
}
.M6Middel .marksixCheckbox input[type='checkbox'] {
    display: none;
}

.M6Middel .marksixCheckbox input[type='checkbox']:checked + span {
    background: #d62d2d -19px top no-repeat;
    width: 18px;
    height: 18px;
}

.M6Middel .pagination-box > div {
    color: #980b0b;
}
.M6Middel .pagination-box .arrow-small {
    border-color: #980b0b;
}
.M6Middel .pagination-box .disable .arrow-small {
    border-color: #fff;
}

.M6Middel .marksixCheckbox input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 4px;
    vertical-align: middle;
    background: #fff 0 0 no-repeat;
    cursor: pointer;
    border: 1px solid #999999;

    overflow: hidden;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    border-radius: 2px;
    font-size: 1rem;
    padding-left: 4px;
}

.M6Middel .marksixCheckbox input[type='checkbox']:checked + span:before {
    content: ' ';
    border-bottom: 2px solid #fff;
    width: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 11px;
    border-right: 2px solid #fff;
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
    padding: 1px 0 0 3px;
    vertical-align: middle;
    text-align: center;
    margin-top: -28px;
}

.M6Middel .marksixCheckbox input[type='checkbox']:checked + span:before {
    margin-top: -4px;
    margin-left: 1px;
}
.marksixMain .radio-container {
    min-height: 16px;
    min-width: 16px;
    padding-right: 25px;
    padding-left: 26px;
}
.marksixMain .radio-container .radio-checkmark {
    right: 0;
    left: auto;
}
.marksixMain .radio-container .radio-checkmark:after {
    background-color: #d62d2d;
}

.bottom-menu.menu-shown a#bottom-menu-marksix.active {
    color: #d62d2d;
}
.LY .AddToSlip {
    margin: 0;
    border: none;
    width: 100px;
}

.M6Middel header {
    background-color: #980b0b;
    height: 36px;
    border-radius: 4px 4px 0 0;
    color: #fff;
    @include font15();
    padding: 0 12px;
    align-items: center;
    width: 100%;
    @include H2();
    @include v-center();
}
.marksix-header {
    justify-content: space-between;
}
.marksix-header > div {
    align-items: center;
}
.marksix-header .marsix-chance-help {
    cursor: pointer;
}
.marksix-header .marsix-chance-help:hover {
    text-decoration: underline;
}

.M6Middel .leftMenuMain .active {
    background-color: #d62d2d;
}

.marksix-help {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    margin-left: 8px;
    background-image: url(../../info/Include/images/ic_help_white.svg);
    background-size: cover;
    cursor: pointer;
}

.marksix-header-info {
    display: none;
}

.marsix-prize-avail-soon {
    text-align: center;
    padding-top: 30px;
    width: calc(100% - 60px);
}

.marksix-fav {
    width: 24px;
    height: 24px;
    background-image: url(../../info/marksix/info/images/heart.svg);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.marksix-fav-active {
    width: 24px;
    height: 24px;
    background-image: url(../../info/marksix/info/images/heart_active.svg);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.marksix-download-icon {
    width: 24px;
    height: 24px;
    background-image: url(../../info/marksix/info/images/download.svg);
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
}

.marksix-delete-icon {
    width: 24px;
    height: 24px;
    background-image: url(../../info/marksix/info/images/delete.svg);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.marksixMain .switch-btn-icon {
    background-color: #cb7b7b;
}
.marksixMain .ms-switch-btn-icon {
    width: 20px;
    height: 20px;
    background-color: #cb7b7b;
    border-radius: 4px;
    margin-right: 8px;
    cursor: pointer;
}

.marksixMain .switch-btn-icon::after {
    content: '';
    position: absolute;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    right: 6px;
    top: 4px;
}

.marksixMain .close-btn-icon-arrow::after {
    transform: rotate(-135deg);
    right: 6px;
    top: 8px;
}

.quick-pick-top-container,
.ball-panel-table-container {
    width: 100%;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 4px;
    // overflow: hidden;
}
.ball-panel-table-container{
    position: relative;
    .singleMessage{
        width: calc(100% - 24px);
        height: 40px;
        position: absolute;
        top: 4px;
        left: 12px;
        .successfulText{
            color: #000000;
            font-weight: 500;
        }
    }
}
.quick-pick-top-container {
    padding-bottom: 0;
    margin-bottom: 15px;
}
.quick-pick-top-container .quick-pick-type-tab,
.banker-selected-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 17%;
    height: 46px;
    background: #f1f1f1;
}
.quick-pick-top-container .quick-pick-type-tab {
    justify-content: space-around;
    padding: 0;
}
.quick-pick-top-container .quick-pick-type-tab div,
.banker-selected-area .banker-selected-text {
    //   width: 220px;
    // flex: 1;
    @include font15();
    font-weight: 400;
    color: #980b0b;
    text-align: center;
    line-height: 39px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 80%;
}

.quick-pick-top-container .quick-pick-type-tab .active-tab,
.banker-selected-area .active {
    border-bottom: 4px solid #fecf13;
    font-weight: 500;
}

.ball-panel-table {
    margin: 8px auto;
    display: table;
    @include font20();
    line-height: 20px;
    font-weight: 900;
    color: #4f4f4f;
    // overflow: hidden;
    padding-bottom: 8px;
    user-select: none;
}

.ball-panel-table .table-cell {
    vertical-align: middle;
    text-align: center;
    width: 69px;
    height: 66px;
}

.ball-panel-table .table-cell .table-cell-ball {
    width: 69px;
    height: 61px;
    margin: 0;
    overflow: hidden;
}
.SINGLESNOWBALL header,
.BANKERSNOWBALL header,
.RANDOMSNOWBALL header,
.MULTIPLESNOWBALL header {
    background-color: #e4960e;
}
.ball-panel-table > div > div > div {
    width: 49px;
    height: 49px;
    margin: 6px 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.ball-panel-table .red-ball {
    border: 2px solid #d9716d;
}

.ball-panel-table .blue-ball {
    border: 2px solid #4b7185;
}

.ball-panel-table .green-ball {
    border: 2px solid #8bb16f;
}
.ball-panel-table .yellow-ball {
    border: 2px solid #fecf13;
}

.marksix-single-middle {
    margin: 15px 0;
    /* width: 828px; */
    border-radius: 4px;
}

.single-select-table {
    display: table;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    width: 100%;
    background-color: #fff;
    padding-bottom: 6px;
}

.CHECKDRAWRESULT .single-select-table .table-row {
    height: 82px;
}

.SINGLE .single-select-table .table-row:nth-child(2n) {
    background-color: #f4f4f4;
}

.single-select-table > div > div {
    vertical-align: top;
    max-height: 68px;
    padding: 6px 0;
}

.single-select-table > div:last-child > div {
    padding-top: 12px;
    padding-bottom: 12px;
}

.single-select-table .table-row-header > div {
    height: 32px;
    /* background-color: #EAEAEA; */
    vertical-align: middle;
    padding:0 12px;
    @include H6();
}

.single-select-table .table-cell-fav-box {
    // width: 70px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.single-select-table .table-cell-fav {
    width: 96px;
    @media only screen and (min-width: 1680px){
        width: 108px;
    }
}

.single-select-table .table-row-header .table-cell-number {
    padding-left: 20px;
}

.single-select-table .table-row:hover:not(.table-row-header) {
    background-color: #f4f4f4;
}

.table-row-arrow {
    position: absolute;
    left: 0;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    background-image: url(../../info/marksix/info/images/selected_arrow.svg);
    background-size: contain;
    background-repeat: no-repeat;
}
.my-favourites-table .table-cell-ball-box,
.single-select-table .table-cell-ball-box {
    min-height: 52px;
    overflow: hidden;
    display: flex;
    /* width: 668px; */
    flex-wrap: wrap;
    padding-left: 12px;
    border-left: 1px solid #e1e1e1;

}
.BANKER .single-select-table .table-cell-ball-box{
    padding-left: unset;
}

.single-select-table .table-cell-banker-ball-box {
    display: flex;
    border-left: 1px solid #e1e1e1;

}

.single-select-table .table-cell-bet-box {
    border-left: 1px solid #e1e1e1;

    padding-top: 4px;
}

.table-cell-bet-box .radio-container:last-child {
    padding-left: 12px;
}

.CHECKDRAWRESULT .single-select-table .table-cell-banker-ball-box {
    border-left: none;
}

.single-select-table .table-cell-banker-text {
    min-width: 60px;
    @include font13();
    font-weight: 500;
    color: #333333;
    line-height: 52px;
    margin-left: 12px;
    @media only screen and (min-width: 1680px){
        min-width: 64px;
    }
}

.table-cell-banker-ball-box .table-cell-ball-box {
    //   width: 608px;
    border-left: none;
}

.single-select-table .table-cell-ctrl-box {
    width: 70px;
    position: relative;
    padding-right: 12px !important;
}

.single-select-table .table-cell-ctrl-box > div {
    justify-content: flex-end;
    align-items: center;
    height: 52px;
    gap: 12px;
}

.amount-display-table {
    width: 100%;
    display: flex;
    padding: 12px;
    background-color: #fff;
    gap: 48px;
    border-top: 1px solid #e1e1e1;

}

.amount-display-table > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.amount-display-table .m6_draw_val_H {
    height: 28px;
    display: flex;
    align-items: center;
}

.amount-display-table .amount-display-btn {
    flex-direction: row;
    gap: 12px;
    justify-content: space-between;
    margin-left: auto;
    align-items: flex-end;
}

.CHECKDRAWRESULT .amount-display-table .amount-display-btn .check-btn {
    background: #cb7b7b;
    color: #ffffff;
}

.amount-display-table div div:first-child {
    align-self: start;
    font-weight: 500;
    color: #980b0b;
    @include H4();
}
.amount-display-table .dropdown .msDDText {
    align-self: unset;
}

.amount-display-table .amount-display-multdraw {
    height: 50px;
    width: 110px;
}
.CHECKDRAWRESULT .amount-display-table .amount-display-multdraw {
    width: 0px;
}
.marksix-calculator .dropdown-toggle::after,
.M6Middel .dropdown-toggle::after {
    content: none;
}
.marksix-calculator .dropdown-menu,
.M6Middel .dropdown-menu {
    min-width: 110px;
    max-height: 300px;
    overflow-y: auto;
}
.marksix-calculator .dropdown-menu .dropdown-item:before,
.M6Middel .dropdown-menu .dropdown-item:before {
    content: none;
}
.marksix-calculator .dropdown-menu.show,
.M6Middel .dropdown-menu.show {
    inset: -2px auto auto auto !important;
}
.marksix-calculator .dropdown-toggle:focus,
.M6Middel .dropdown-toggle:focus {
    box-shadow: none !important;
}
.marksix-calculator .btn-primary:active,
.marksix-calculator .show > .btn-primary.dropdown-toggle,
.M6Middel .btn-primary:active,
.M6Middel .show > .btn-primary.dropdown-toggle {
    background-color: #fff;
    color: #333;
    border-color: #666;
}
.marksix-calculator .dropdown-toggle,
.M6Middel .dropdown-toggle {
    width: 110px;
    height: 28px;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    box-shadow: none;
    align-items: center;
    padding: 0 0 0 12px;
    background-color: #fff;
    border: 1px solid #666;

    color: #333;
    @include font13();
}
.M6Middel .draw-number-dropdown-button .dropdown-toggle {
    padding: 0 8px;
    width: 250px;
    @media only screen and (min-width: 1680px){
        width: 320px;
    }
}
.draw-number-dropdown-button-title {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
.draw-number-dropdown-button-title-number {
    margin: 0 8px 0 auto;
    color: #980b0b;
}
.marksix-calculator .dropdown-toggle .dd-arrow,
.M6Middel .dropdown-toggle .dd-arrow {
    align-self: center;
    margin-top: -5px;
    border-color: #333;
}
.marksix-calculator .dropdown-item.active,
.marksix-calculator .dropdown-item:active,
.M6Middel .dropdown-item.active,
.M6Middel .dropdown-item:active {
    background-color: #cb7b7b;
}
.amount-display-table .amount-display-btn div:first-child {
    align-self: flex-end;
}

.amount-display-table .amount-display-btn div:first-child button {
    background-color: #cb7b7b;
    color: #fff;
}
.amount-display-table .amount-display-btn div:first-child button:hover {
    background-color: #980b0b;
}
.amount-display-table .amount-display-btn button,
.ball-panel-instructions .instructions-btn .add-to-slip {
    width: 100px;
    height: 28px;
    border: 0;
    border-radius: 30px;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    padding: 0;
}


.ball-panel-instructions .instructions-left {
    align-items: center;
}

.instructions-left {
    @include H4();
}
.ball-panel-instructions .switch-btn-icon {
    margin-right: 10px;
}

.ball-panel-instructions {
    display: block;
    padding: 0 12px;
    border-bottom: 1px solid #e1e1e1;
}

.ball-panel-instructions .ball-panel-help-link {
    color: #980b0b;
    cursor: pointer;
}

.ball-panel-instructions span {
    line-height: 24px;
}

.ball-panel-instructions .ball-panel-help-link:hover {
    text-decoration: underline;
}

.ball-panel-instructions-top {
    display: flex;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    font-weight: 500;
}

.amount-diplay-m {
    display: none;
}

.amount-diplay-d {
    display: flex;
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
}
.marksix-random-middle .amount-diplay-d {
    border-top: solid 1px #e1e1e1;

}

.next-draw-table {
    display: flex;
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
    @include H4();
}

.next-draw-noData{
    height: 112px;
    background: #ffffff;
}

.next-draw-table-header{
    padding: 8px 0;
    background: #fff;
    .next-draw-table-r{
        .next-draw-table-item::before{
            content: unset;
        }
    }
    .next-draw-table-l{
        border-right: 1px solid #e9ecef;
    }
    .next-draw-table-item-red-type {
        font-weight: 700;
        color: #CB7B7B;
    }

    .next-draw-table-estAmount{
        display: table;
        padding: 10px 12px;
        .estdiv-row,
        .jackpot-row{
            display: table-row;
            & div{
                display: table-cell;
            }
            & > :first-child{
                padding-right: 12px;
                width: 50%;
            }
        }
        .jackpot-row > :first-child{
            padding-bottom: 8px;
            height: 24px;
        }
        .ch & .estdiv-row > :first-child{
            line-height: 38px;
        }
        @media only screen and (min-width: 1534px){
            .en & .estdiv-row > :first-child{
                line-height: 38px;
            }
        }
        .estLabel{
            vertical-align: top;
            // padding-top: 6px;
        }
        .estFontSize{
            font-size: 36px;
            line-height: 37px;
            font-family: 'Roboto Condensed';
            font-weight: 700;
        }
    }
}

.next-draw-table-r {
    width: 50%;
    background: #fff;
}

.next-draw-table-item {
    position: relative;
    min-height: 22px;
}

.next-draw-table-item::before {
    position: absolute;
    display: inline-block;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    @include width1px();
    background: #b4b4b4;
    transform: scaleX(0.5);
    z-index: 22;
}

.next-draw-table-item,
.next-draw-table-sml-item {
    position: relative;
    display: flex;
    width: 100%;
    padding: 6px 0;
}

.prizesEmpty{
    .next-draw-table-item{
        height: 26px;
        padding: unset;
        padding-left: 12px;
        align-items: center;
        &::before{
            position: absolute;
            display: inline-block;
            content: "";
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            background: #b4b4b4;
            transform: scaleY(0.5);
        }
    }
}

.next-draw-table-sml-item {
    @include H6();
}

.m6-last-draw-table .next-draw-table-item::after,
.m6-last-draw-table .next-draw-table-sml-item::after {
    position: absolute;
    display: inline-block;
    content: '';
    left: 0;
    top: auto;
    bottom: 0;
    @include height1px();
    width: 100%;
    background: #b4b4b4;
    transform: scaleY(0.5);
}

.next-draw-table-item-no-bottom-boreder::after {
    height: 0 !important;
}

.next-draw-table-item div,
.next-draw-table-sml-item div {
    flex: 1;
    padding: 0 12px;
    display: flex;
    align-items: center;
}

.next-draw-table-item div {
    position: relative;
}

.m6-last-draw-table .next-draw-table-item div::after {
    position: absolute;
    display: inline-block;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    @include width1px();
    background: #b4b4b4;
    transform: scaleX(0.5);
}

.next-draw-table-item div:last-child::after {
    width: 0 !important;
}

.next-draw-table-sml-auto-width {
    flex: unset !important;
    width: 60px !important;
}
.m6-last-draw-table{
    .lastDrawResult{
        font-size: 15px;
        color: #980b0b;
        .lastDrawLabel{
            color: #333;
        }
    }
}


.next-draw-table-item-red-type {
    font-weight: 500;
    .m6-last-draw-table &{
        background: #f2ecec;
    }
}

.m6-last-draw-table .next-draw-table-item-grey-bg {
    background: #f6f6f6;
}

.next-draw-table-result {
    padding: 5px 12px;
    background: #fff;
    border-radius: 0 0 4px 4px;
}

.next-draw-table-result-title {
    margin: 6px 0;
    color: #333;
    @include H6();
}

.next-draw-table-result-balls {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.next-draw-table-result-plus {
    margin: 0 12px;
    font-size: 14px;
    font-weight: 700;
    transform: translateY(-5px);
}
.my-favourites-table-container {
    background-color: #fff;
    margin: 15px 0;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
}
.my-favourites-table-container .AddToSlip-container {
    border-top: 1px solid #e1e1e1;

    height: 48px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 12px;
}
.my-favourites-table-container .AddToSlip-container > button {
    border: 0;
    width: 100px;
}
.my-favourites-show {
    display: block;
}
.my-favourites-hide {
    display: none;
}
.my-favourites-table-container .my-favourites-header {
    align-items: center;
    height: 48px;
    @include H4();
}

.my-favourites-table-container .my-favourites-header div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cb7b7b;
    margin: 0 8px 0 12px;
    border-radius: 4px;
    cursor: pointer;
}

.my-favourites-table-container .my-favourites-header .msFavouIcon{
    margin: 0;
    background-color: unset;
}

.my-favourites-table-container .my-favourites-header .arrow-down {
    position: relative;
    top: -2px;
}
.my-favourites-table-container .my-favourites-header .arrow-up {
    position: relative;
    top: 2px;
    border: solid rgb(231 231 231);
    border-width: 0 3px 3px 0;
}
.my-favourites-table-container .my-favourites-table {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    display: table;
}
.my-favourites-table .table-header :nth-child(2) {
    padding-left: 30px;
}

.my-favourites-table-container .my-favourites-table .table-header {
    display: table-row;
    text-align: left;
    height: 32px;
    background-color: #f4f4f4;
}
.my-favourites-table .table-header .table-cell {
    vertical-align: middle;
    @include font11();
}
.my-favourites-table-container .my-favourites-table .table-row .table-cell {
    min-height: 66px;
    padding: 7px;
}
.my-favourites-table-container .my-favourites-table .table-checkbox-cell {
    width: 60px;
    text-align: center;
}
.my-favourites-table .table-cell .table-cell-ball-box {
    /* width: 570px; */
}
.my-favourites-table .table-cell-chance {
    width: 60px;
    padding: 7px;
}
.my-favourites-table .table-cell-multdraw {
    width: 80px;
    padding: 7px;
}

.my-favourites-table .sec {
    background-color: #f4f4f4;
}
.my-favourites-table .table-cell-delete {
    width: 40px;
    vertical-align: top;
}
.my-favourites-table .table-cell-delete > div {
    justify-content: center;
    align-items: center;
    height: 52px;
}
.my-favourites-table-container .my-favourites-table .table-cell-checkbox > div {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.my-favourites-table > .table-row > .table-cell .ball-img-box,
.single-select-table > .table-row > .table-cell .ball-img-box {
    overflow: hidden;
    width: 54px;
    height: 54px;
}
.marksix-random-middle .sec {
    background-color: #f4f4f4 !important;
}
.marksix-random-middle,
.marksix-banker-middle,
.marksix-multiple-middle {
    margin: 15px 0;
}
.marksix-random-middle .single-select-table .table-row-header .table-cell {
    border-bottom: 1px solid #e1e1e1;

    background-color: #fff;
}
.marksix-multiple-middle .single-select-table .table-row-header .table-cell {
    background-color: #fff;
}

.marksix-random-middle .table-cell-bet-box,
.marksix-multiple-middle .table-cell-bet-box {
    padding-top: 5px;
    border-left: 1px solid #e1e1e1;

}
.marksix-random-middle .bet-box-show,
.marksix-multiple-middle .bet-box-show {
    display: flex;
}
.marksix-single-middle .bet-box-hide,
.marksix-random-middle .bet-box-hide,
.marksix-multiple-middle .bet-box-hide {
    display: none;
}
.ramdom-selection-panel {
    display: flex;
    justify-content: space-between;
    height: 74px;
    gap: 24px;
}
.ramdom-selection-panel > :first-child {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    max-width: 462px;
}
.ramdom-selection-panel > :first-child > div {
    font-weight: 500;
}
.ramdom-selection-panel > :first-child > div:first-child {
    padding-left: 12px;
}
.ramdom-selection-panel > :first-child > div .dropdown {
    width: 110px;
    height: 28px;
    margin-top: 8px;
}
.ramdom-selection-panel > :last-child {
    display: flex;
    min-width: 224px;
    justify-content: flex-end;
    align-items: center;
}
.ramdom-selection-panel > :last-child button {
    width: 100px;
    height: 32px;
    margin-right: 12px;
    background-color: #cb7b7b;
    color: #fff;
    border: 0;
    border-radius: 16px;
    font-size: 13px;
}
.ramdom-selection-panel > :last-child button:hover {
    background-color: #980b0b;
}
.my-favourites-table .table-cell-ball-box,
.single-select-table .random-ball-box {
    display: block;
}
.my-favourites-table .table-cell-ball-box > div,
.single-select-table .random-ball-box > div {
    align-items: flex-start;
    font-weight: bolder;
}
.my-favourites-table .table-cell-ball-box > div .ball-list-container,
.single-select-table .random-ball-box > div .ball-list-container {
    flex-wrap: wrap;
}
.my-favourites-table .table-cell-ball-box > div .banker-label,
.single-select-table .random-ball-box > div .banker-label {
    min-width: 60px;
    height: 52px;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 1680px){
        min-width: 64px;
    }
}
.my-favourites-table-container .dropdown-toggle {
    width: 65px;
    padding: 0 0 0 6px;
}
.M6Middel .my-favourites-table-container .dropdown-menu {
    min-width: 65px;
}
.marksix-calculator-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 100vw;
    // height: 100vh;
    min-width: 500px;
    min-height: 375px;
}
.marksix-calculator-container .marksix-calculator {
    width: 500px;
    min-height: 375px;
    background-color: #fff;
}
.marksix-calculator .fMenuRowLang {
    display: none;
}
.marksix-calculator header {
    height: 36px;
    color: #fff;
    background-color: #980b0b;
    padding-left: 12px;
    line-height: 36px;
    // border-radius: 4px 4px 0 0;
    font-weight: 500;
}
.marksix-calculator .m6-cal-main {
    width: 100%;
    height: 340px;
    @include font13();
}
.marksix-calculator .m6-cal-main > div {
    width: 50%;
    position: relative;
}
.marksix-calculator .m6-cal-main > div:first-child {
    border-right: 1px solid #b4b4b4;

}
.marksix-calculator .m6-cal-title {
    background-color: #f1f1f1;
    height: 44px;
    @include font15();
    color: #980b0b;
    line-height: 44px;
    text-align: center;
    font-weight: 500;
}
.marksix-calculator .m6-cal-content {
    height: auto;
    margin: 12px 24px 0;
}
.marksix-calculator .m6-cal-content .dropdown-toggle {
    width: 100%;
    margin: 12px 0;
}
.marksix-calculator .m6-cal-content .dropdown-menu {
    width: 100%;
}
.marksix-calculator .m6-cal-amount {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 55px;
    margin: 0 12px;
    border-top: #b4b4b4 solid 1px;

    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.marksix-calculator .m6-cal-amount > div {
    color: #980b0b;
    font-weight: 500;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.marksix-calculator .m6-cal-amount > div > div:last-child {
    color: #333;
}
.marsix-chance-help-m {
    display: none;
}

.statisticsPanel {
    height: 77px;
    padding: 16px 13px 16px 12px;
    justify-content: space-between;
}
.statisticsPanelL {
    //   width: 500px;
}
.statisticsPanel .panelTitle {
    @include font13();
    color: #980b0b;
    font-weight: 500;
    margin-bottom: 12px;
}
.statisticsPanel .panelInfo {
    @include font13();
    color: #333333;
    font-weight: 400;
    display: flex;
    .panelInfoBlue{
        color: #003C84;
    }
}
.statisticsPanel .panelInfo > div {
    margin-right: 12px;
}

.statisticsPanel .chooseModelBtn {
    /* width: 160px; */
    height: 44px;
    border-radius: 4px;
    background: #b4b4b4;
    color: #fff;
    overflow: hidden;
    cursor: pointer;
    &>div{
        padding-top: 8px;
        padding-bottom: 8px;
        height: 100%;
    }
}
.statisticsPanel .chooseModelBtn div {
    width: 80px;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include H4();
    font-weight: 400;
}
.statisticsPanel .chooseModelBtn .active {
    background: #d62d2d;
}
.STATISTICS {
    cursor: default;
}
.STATISTICS .ball-panel-table .table-cell {
    vertical-align: top;
}
// .ball-panel-table .table-cell{
//     padding: 0 10px;
// }

.table-cell-sinceNumber,
.table-cell-timersNumber {
    @include font11();
    color: #003c84;
    font-weight: 500;
    height: 14px !important;
    line-height: 14px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.ball-panel-table > div > div > div:nth-child(2) {
    margin-top: -6px !important;
    margin-bottom: 0 !important;
}

.ball-panel-table > div > div > div:nth-child(3) {
    margin-top: 2px !important;
    margin-bottom: -5px !important;
}

.STATISTICS{
    .ball-panel-table > div > div > div{
        &:nth-child(2) {
            margin-top: -6px !important;
            margin-bottom: 6px !important;
        }
        &:nth-child(3) {
            margin-top: -6px !important;
            margin-bottom: 6px !important;
        }
    }
}

.table-cell-timersNumber {
    color: #333333;
}
.statFootnotes {
    @include font13();
    color: #333333;
    font-weight: 500;
    padding-left: 25px;
    padding-top: 11px;
    padding-bottom: 12px;
}

.marksixMain .RESULTS .radio-container .radio-checkmark {
    left: 0;
    right: auto;
}
.marksixMain .RESULTS .radio-container {
    padding-right: 0;
}

.RESULTS .results-remark {
    margin-top: 16px;
    padding-top: 8px;
    border-top: 1px solid #cbcbcb;
    @include Body();
}
.RESULTS .results-remark > div:first-child {
    width: 46px;
    text-align: center;
}
.RESULTS .results-remark > div:first-child img {
    width: 28px;
}
.RESULTS .results-remark > div:last-child {
    width: calc(100% - 46px);
}

.marksixMain .result-lucky-number {
    background: #fff;
    min-height: 64px;
    border-radius: 4px;
    padding: 14px 12px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}

.result-lucky-number > div:first-child {
    //   max-width: 65%;
    @include lineHeight18();
    margin-right: 36px;
}
.result-lucky-number > div:last-child {
    display: flex;
    //   width: 35%;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
}
.result-lucky-number > div:last-child input {
    width: 120px;
    height: 28px;
    margin-right: 12px;
}
.result-search-box {
    background: #fff;
    border-radius: 4px;
    height: 144px;
    padding: 16px 12px;
}
.result-search-box .draw-number-date-title {
    color: #980b0b;
    margin-bottom: 8px;
}
.result-search-box .draw-number-date-content {
    display: flex;
    margin-bottom: 15px;
}

.result-search-box .draw-number-date-content > div {
    display: flex;
    align-items: center;
}

.result-search-box .draw-number-date-content > div:first-child {
    margin-left: 18px;
}

.result-search-box .draw-number-date-content > div:last-child {
    margin-left: 15px;
}

.result-search-box .draw-number-date-content > div:first-child span {
    //   margin-right: 8px ;
}

.result-search-box .draw-number-date-content .draw-date-input-container {
    height: 28px;
    position: relative;
    width: 250px;
    @media only screen and (min-width: 1680px){
        width: 320px;
    }
}
.result-search-box .draw-number-date-content .draw-date-input {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}
.result-search-box .draw-date-input .draw-date-input-tips {
    @include font12();
    margin-bottom: 5px;
}
.result-search-box .draw-date-picker {
    height: 28px;
    width: 250px;
    position: relative;
}
.result-search-box .date-picker-from {
    position: absolute;
    left: 8px;
    top: 0;
    z-index: 1;
    height: 28px;
}
.result-search-box .date-picker-container {
    margin-right: 0;
    width: 100%;
}
.result-search-box .date-picker-container .dd-arrow {
    right: 10px;
    border: solid #333;
    border-width: 0 2px 2px 0;
}
.result-search-box .draw-date-picker .date-picker-arrow {
    position: absolute;
    right: 5px;
    top: 4px;
    z-index: 1;
}
.result-search-box .draw-date-input .date-input {
    height: 28px;
    width: 100%;
    padding: 0 30px 0 50px;
    background-color: transparent;
    border: 1px solid #666;

    color: #980b0b;
}
.result-search-box .result-draw-type > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.result-search-box .result-draw-type > div:last-child > :first-child {
    display: flex;
    margin-left: 18px;
}
.result-search-box .result-draw-type > div:last-child > :first-child > div {
    width: 110px;
}
.result-search-box .result-draw-type button {
    width: 100px;
    height: 28px;
    background: #cb7b7b;
    border: none;
    border-radius: 16px;
    color: #fff;
    font-weight: 500;
}
.result-search-box .result-draw-type button:hover {
    background: #980b0b;
}
.marksix-results-table-container {
    margin-top: 15px;
}
.RESULTS header,
.CHECKDRAWRESULT header {
    display: flex;
    justify-content: space-between;
}
.RESULTS header.result-header-m {
    display: none;
}
.CHECKDRAWRESULT .check-draw-header,
.FIXTURES .check-draw-header,
.STATISTICS .check-draw-header {
    display: none;
}
.CHECKDRAWRESULT .marksix-checkresult-middle,
.STATISTICS .marksix-checkresult-middle {
    margin-top: 0;
}
.RESULTS header .marksix-results-print,
.CHECKDRAWRESULT header .marksix-results-print {
    cursor: pointer;
    @include font11();
    img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
    }
}

.RESULTS header .marksix-results-print:hover,
.CHECKDRAWRESULT header .marksix-results-print:hover {
    text-decoration: underline;
}
.maraksx-results-table {
    background: #fff;
    width: 100%;
    display: table;
}
.maraksx-results-table .sec {
    background: #f4f4f4;
}
.maraksx-results-table .table-row {
    height: 65px;
}
.maraksx-results-table .table-cell {
    vertical-align: middle;
}
.maraksx-results-table .cell-id {
    color: #980b0b;
    cursor: pointer;
    white-space: nowrap;
}

.maraksx-results-table {
    .cell-id,
    .cell-date {
        width: 110px;
    }
}

.maraksx-results-table .cell-ball-list {
    width: 367px;
    min-width: 367px;
    padding: 0 8px 0 12px;
}
.maraksx-results-table .cell-ball-list > div {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
}
.maraksx-results-table .cell-ball-list > div span {
    padding: 0 8px 0 7px;
    font-weight: bold;
}
.maraksx-results-table .cell-ball-list > div .img-box {
    width: 45px;
    margin-right: 1px;
}
.maraksx-results-table .marksix-results-print-header {
    height: 28px;
}
.maraksx-results-table-print {
    display: none;
    width: 100%;
}
.maraksx-results-table-print .img-box {
    width: 45px !important;
    height: 50px !important;
    margin: 0 !important;
    overflow: inherit !important;
}
.maraksx-results-table-print .cell-ball-list > div {
    display: block;
}
.maraksx-results-table-print .cell-ball-list > div > div {
    display: inline-block;
}
.maraksx-results-table-print .maraksx-results-table .cell-id,
.maraksx-results-table-print .cell-date {
    padding: 25px 0 25px 15px !important;
}
.marksix-head-note-print {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.marksix-head-note-print > div:first-child {
    color: #980b0b;
    @include font15();
    font-weight: 500;
}
.marksix-foot-note-print {
    margin: 15px 0;
    padding-top: 14px;
    font-weight: 500;
}
.maraksx-results-table-print .table-row {
    height: auto;
}

.maraksx-results-table-print .cell-id .result-date-m {
    display: none !important;
}
.maraksx-results-table .marksix-results-print-header > div {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;

    padding-left: 15px;
    position: initial !important;
}

.maraksx-results-table .cell-id:hover {
    text-decoration: underline;
}
.maraksx-results-table .table-cell:not(:last-child) {
    position: relative;
    padding: 0 12px;
    //   padding-right: 7px;
}
.maraksx-results-table .table-cell:not(:last-child)::after {
    content: '';
    @include width1px();
    height: 70%;
    background: #c4c4c4;
    position: absolute;
    right: 0;
    top: 15%;
}
.marksix-result-detail {
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    .ms-result-title{
        display: flex;
        align-items: center;
    }
}
.maraksx-no-matched {
    height: 65px;
    line-height: 65px;
    padding-left: 15px;
    color: #333333;
    font-weight: 500;
}
.marksix-foot-note {
    margin-top: 15px;
    border-top: 1px solid #b4b4b4;

    padding-top: 14px;
    font-weight: 500;
}
.marksix-foot-note .foot-note-icon {
    width: 28px;
    margin: 0 8px;
}

.marksix-foot-note .foot-note-icon img {
    width: 28px;
}
.marksix-result-detail .draw-info {
    display: flex;
    height: 50px;
    align-items: center;
}
.marksix-result-detail .draw-info > div {
    margin: 0 11px 0 15px;
    font-weight: 500;
}
.marksix-result-detail .draw-result > .result-ball-list {
    margin-left: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.marksix-result-detail .draw-result > .result-ball-list span {
    margin: 0 8px;
    font-weight: 500;
    font-size: 16px;
}
.marksix-result-detail .draw-result > div:first-child {
    color: #980b0b;
    padding-left: 15px;
    padding-bottom: 5px;
}
.marksix-result-detail .draw-result-table {
    width: 100%;
    display: flex;
    border-top: 1px solid #ccc;

    margin-top: 15px;
    @include font15();
}
.marksix-result-detail .draw-result-table > div {
    width: 50%;
    display: table;
}
.marksix-result-detail .draw-result-table .table-header {
    display: table-row;
    @include font13();
}
.marksix-result-detail .draw-result-table .table-row > div {
    border-top: 1px solid #ccc;

}
.result-detail-r .table-row > div:first-child {
    border-left: 1px solid #ccc;

}
.result-detail-l .table-row > div:nth-child(2),
.result-detail-r .table-row > div:nth-child(2) {
    width: 45%;
}
.marksix-result-detail .draw-result-table .table-header > div,
.marksix-result-detail .draw-result-table .table-row > div {
    display: table-cell;
    vertical-align: middle;
    padding-left: 12px;
    color: #980b0b;
}
.marksix-result-detail .draw-result-table .table-row > div {
    font-weight: 500;
}
.marksix-result-detail .draw-result-table .table-row .result-table-value-black,
.marksix-result-detail .draw-result-table .table-row .prize-label {
    color: #333;
}
.marksix-result-detail .draw-result-table .table-row .prize-label {
    width: 56px;
}
.marksix-result-detail .draw-result-table > div > div {
    height: 28px;
}
.result-detail-r .table-row:nth-child(3),
.result-detail-l .table-row:nth-child(3) {
    background-color: #f4f4f4;
}
.result-detail-r .table-row:nth-child(4),
.result-detail-r .table-row:nth-child(5),
.result-detail-l .table-row:nth-child(4) {
    background-color: #f2ecec;
}

.draw-result-table .table-row > div:not(:last-child) {
    position: relative;
}
.draw-result-table .table-row:not(.empty-row) > div:not(:last-child)::before {
    content: '';
    position: absolute;
    @include width1px();
    height: 70%;
    right: 0;
    top: 15%;
    background-color: #ccc;
}
.marksix-result-detail header .dd-arrow {
    display: none;
}
.marksix-result-detail .draw-info-m {
    display: none;
}
.maraksx-results-table .cell-id .result-date-m {
    display: none;
}

.FIXTURES {
    border-radius: 4px;
    overflow: hidden;
    // height: 391px;
    box-shadow: var(--box-shadow);
    align-self: flex-start;
}

.FIXTURES .masonry-col > div {
    //   width: 50%;
    background-color: #ffffff;
    flex: 1;
}

.FIXTURES .masonry-col > div:first-child {
    margin: 0px;
    min-width: 414px;
    max-width: 414px;
}

.fixture-left {
    margin: 8px 0px;
    border-right: 1px solid #b4b4b4;

}

.fixture-right {
    margin: 8px 0px;
}

.fixtures-arrow {
    // padding: 8px 4px;
}

.fixtures-dd-arrow {
    border: solid #d62d2d;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4px;
    margin: 2px 2px 0px 0px;
}

.fixtures-calendar-top {
    display: flex;
    justify-content: space-evenly;
}

.fixtures-calendar {
    padding: 4px 40px;
    display: grid;
    grid-template-columns: 14% 14% 14% 14% 14% 14% 14%;
}

.fixtures-calendar-head {
    font-weight: 500;
    @include font18();
    line-height: 19px;
    padding: 12px 0px;
}

.fixtures-calendar-head:first-child {
    //   color:#980B0B;
}

.fixtures-calendar > div {
    text-align: center;
}

.fixtures-headerline {
    grid-column: 1/-1;
    width: 100%;
    //   height: 4px;
    //   background: linear-gradient(to bottom, #B4B4B4, #FFFFFF);
    height: 8px;
    /* background: linear-gradient(180deg,#b4b4b4,#fff); */
    opacity: 1;
    background: linear-gradient(
        0deg,
        rgba(238, 238, 238, 0) 0%,
        rgba(224, 224, 224, 1) 100%,
        rgba(216, 216, 216, 0.348039) 100%
    );
}

.fixtures-bottomline {
    grid-column: 1/-1;
    width: 100%;
    @include height1px();
    border-top: 1px solid #e2e2e2;

}

.fixture-body-cell {
    @include font15();
    margin: 3px 0px;
    color: #ccc;
}

.fixture-body-cell-dim {
    @include font15();
    color: #cccccc;
    margin: 3px 0px;
}

.FIXTURES .dropdown-toggle {
    border: 0;
    justify-content: center;
    @include font15();
}

.fixtures-remark-title {
    font-weight: 500;
    margin: 12px 28px;
    word-break: break-word;
    @include font15();
    line-height: 16px;
}

.fixtures-remark-subtitle {
    margin: 12px 28px;
    word-break: break-word;
}

.fixtures-remark-text {
    margin: 12px 28px;
    word-break: break-word;
    font-weight: 400;
    @include lineHeight18();
}

.fixture-legend {
    display: flex;
    margin: 8px 40px;
}

.fixture-nohl {
    display: inline-block;
    width: 32px;
    height: 32px;
    padding: 8px 4px;
}

.fixture-drawday-hl {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #f2ecec;
    border-radius: 50%;
    color: #333333;
    padding: 8px 0px;
}

.fixture-snowball-hl {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #e4960e;
    border-radius: 50%;
    color: #ffffff;
    padding: 8px 0px;
}

.fixture-presales-hl {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #f2ecec;
    border-radius: 50%;
    color: #333333;
    padding: 6px 0px;
    border: 2px solid #e4960e;
}

.fixture-drawday-legend-color {
    min-width: 20px;
    height: 20px;
    background-color: #f2ecec;
    border-radius: 50%;
}

.fixture-snowball-legend-color {
    min-width: 20px;
    height: 20px;
    background-color: #e4960e;
    border-radius: 50%;
}

.fixture-presales-legend-color {
    min-width: 20px;
    height: 20px;
    //   background-color: #F2ECEC;
    border-radius: 50%;
    color: #333333;
    border: 2px solid #e4960e;
}

.fixture-legend-txt {
    @include font11();
    padding: 2px 12px 2px 8px;
}

.LY .top-block-item {
    padding: 8px 0px;
    cursor: pointer;
    color: #333333;
    position: relative;
    &:hover{
        color: #d62d2d;
    }
}

.LY #Int_NextDraw .top-block-item ,
.LY #Int_SnowballDraw .top-block-item {
    padding: 8px 12px;
}

.m6-info-block-noinfo {
    padding: 8px 12px;
}

.result-1st-prize {
    @include font18();
    font-weight: 500;
}

.ballsize-l {
    //   width: 66px;
    //   height: 66px;
    clip-path: inset(2px);
}

.ballsize-m {
    width: 46px;
    height: 46px;
}

.ballsize-s {
    width: 32px;
    height: 32px;
}
