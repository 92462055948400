@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
.betslip-over-lay {
    position: fixed;
    left: 100vw;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    top: 0;
    border: 0;
}

#divBetSlipNormal {
    position: fixed;
    left: 105vw;
    top: 0 !important;
    width: 90vw;
    z-index: 1001;
    transition: all 0.2s ease-in-out 0s;
    height: 100% !important;
    /* min-height: 600px; */
}

#divBetSlipNormal .login {
    border-top-right-radius: 0px;
}

// #divBetSlipNormal .previewPanel {
//     // height: calc(100% - 150px - 132px);
// }
// #divBetSlipNormal .previewPanel.confirmationPanel{
//     // height: calc(100% - 150px - 132px - 90px);
// }

#divBetSlipNormal .control {
    border-bottom-right-radius: unset;
    color: #333333;
    /* height: 200px; */
}

.betslip-container  .loginCloseArea .open-btn-icon{
    display: block;
    color: #ffffff;
    position: absolute;
    right: -10px;
    top: -10px;
    height: 40px;
    width: 40px;
    border: none;
}
#divBetSlipNormal .add-slip {
    padding: 12px;
    font-size: 13px;
}

#divBetSlipNormal .add-slip>div {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    line-height: 14px;
}

#divBetSlipNormal .add-slip>div:last-child {
    margin-top: 12px;
}


#divBetSlipNormal .add-slip .delete {
    background-image: url(../../info/Include/images/delete-white.svg);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 20px;
    width: 76px;
    height: 40px;
    border: unset;
}

.GL #divBetSlipNormal .background2 {
    background-color: #6176A0;
}

.HR #divBetSlipNormal .background2 {
    background-color: #6176A0;
}

.FB #divBetSlipNormal .background2 {
    background-color: #569171;
}

.LY #divBetSlipNormal .background2 {
    background-color: #CB7B7B;
}
#divBetSlipNormal .logonState .login-account {
    padding-left: 16px !important;
    padding-bottom: 14px;
}
#divBetSlipNormal .logonState .betslip-pcard {
    width: 40px;
    height: 40px;
    margin-right: 12px;
}
#divBetSlipNormal .logonState .signIn{
    width: 84px;
}
#divBetSlipNormal .logonState .link-account {
    margin-left: 4px !important;
}
.betslip-container .pcardClose .loginCloseArea .open-btn-icon{
    &::after,&::before{
        background-color: #333333;
    }
}

#divBetSlipNormal .login-account input{
    width: 90%;
}
#divBetSlipNormal .menu-txt{
    flex-direction: row;
}
#divBetSlipNormal .login .link-account > div {
    align-items: center;
}
#divBetSlipNormal .login .link-account .menu-txt{
    width: auto;
    flex-direction: row;
}
#divBetSlipNormal .panel {
    padding-bottom: 24px;
}

#divBetSlipNormal .login-answer-content > .ekbaBtnArea .leftArea{
    // min-height: 32px;
    width: 156px;
    border-radius: 16px;
    padding: 0 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 400;
}

#divBetSlipNormal .add-slip .send {
    border-radius: 20px;
    background-color: #fecf13;
    border: unset;
    height: 40px;
    color: #122C68;
}
#divBetSlipNormal .add-slip {
    .send, button.send {
        width: 200px;
    }
}
#divBetSlipNormal .confirm {
    width: 200px;
}

#divBetSlipNormal .add-slip .alupDimm {
    background-color: #b4b4b4;
    color: #FFFFFF;
}

#divBetSlipNormal .close {
    width: 84px;
    height: 32px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: #FFFFFF;
    font-weight: 700;
    border: 1px solid;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}
div.logout-succ-msg-overlay{
    z-index: 1902 !important;
}
div.logout-succ-msg-content{
    width: 90vw;
}

#divBetSlipNormal .dialogAreaMBShow{
    min-height: calc(100% - 120px);
}

#divBetSlipNormal .dialogAreaMBShow.speedbetTandC{
    min-height: calc(100% - 160px);
}

#divBetSlipNormal .dialogAreaMBHide{
    height: 0;
}
#divBetSlipNormal .loginTandC{
    height: 100%;
    border-radius: 0 0 0 15px;
}
#divBetSlipNormal .loginTandC .loginTandCHeader{
    padding-left: 13px;
}
#divBetSlipNormal .loginTandC .loginTandCText{
    padding: 10px 13px 0px 11px;
}
#divBetSlipNormal .loginTandCText{
    height: calc(100% - 100px);
}
.loginTandC .loginTandCText .tandCTb{
    height: 100%;
}
.speedbetTandC .loginTandC .loginTandCText .tandCTb{
    height: calc(100% - 10px);
}
.logout-succ-msg-container .succ-msg-content{
    display: block;
}
.logout-succ-msg-container .succ-msg-content .message-box{
    padding-bottom:15px;
    padding-right: 0;
}
div.logout-succ-msg-content{
    height: auto;
}
div.logout-reminder-content{
width: calc(100vw - 32px );
height: 200px;
}


#divBetSlipNormal .confirmation-title .confirmation-title-r > div.confirmation-printBtn{
    display: none;
}
    }
}