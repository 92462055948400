.fbOddsTable section .team.OOE,
.fbOddsTable section.LAST_ODDS .team.OOE {
    grid-template-columns: 50% 50%;
    background-color: #fff;
    height: auto;
}
.fbOddsTable section.OOE>.header-wrapper>.header,
.fbOddsTable section.OOE>.match-row-container>.match-row {
    grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [odds] 222px [h2h] 48px [end];
}

.fbOddsTable section.OOE .match-row .odds,
.fbOddsTable section.OOE>.header-wrapper>.header>.odds>div {
    grid-template-columns: [first] 50% 50% [end];
}

.fbOddsTable section.INPLAY_ALL .oddsOOE,
.fbOddsTable section.ALLODDS .oddsOOE,
.fbOddsTable section.LAST_ODDS .oddsOOE {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    /* line-height: 32px; */
    width: 100%;
    text-align: center;
    background-color: #fff;
    justify-items: center;
}

.fbOddsTable section.INPLAY_ALL .oddsOOE,
.fbOddsTable section.ALLODDS .oddsOOE,
.fbOddsTable section.LAST_ODDS .oddsOOE {
    grid-template-columns: 50% 50%;
}

.fbOddsTable section.OOE .match-row-container .match-row-m>.odds{
    grid-template-columns: 1fr 1fr;
}