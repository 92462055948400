@import '../common-mixin.scss';
SECTION.ALLODDS .cashOutHead,
SECTION.INPLAY_ALL .cashOutHead,
.fbOddsTable .allup-cal,
.fbOddsTable .allup-control {
    height: 38px;
    width: 100%;
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
    background-color: #ffffff;
    display: inline-flex;
    align-items: center;
    align-content: center;
    flex-wrap: nowrap;

}
.fbOddsTable .allup-cal-content{
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
}

.fbOddsTable .allup-cal {
    justify-content: space-between;
}
.fbOddsTable .allup-cal.allup-cal-expend{
    border-radius: 0;
    box-shadow: none;
}

.fbOddsTable .allup-control {
    justify-content: flex-end;
}

SECTION.ALLODDS .cashOutHead .expand,
SECTION.ALLODDS .cashOutHead .collaspse,
SECTION.INPLAY_ALL .cashOutHead .expand,
SECTION.INPLAY_ALL .cashOutHead .collaspse,
.fbOddsTable .allup-cal .expand,
.fbOddsTable .allup-cal .collaspse,
.fbOddsTable .coupon .expand,
.fbOddsTable .coupon .collaspse,
.fbOddsTable .match-row .expand,
.fbOddsTable .match-row .collaspse {
    width: 20px;
    height: 20px;
    background-color: #569171;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    border-radius: 3px;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

SECTION.ALLODDS .cashOutHead .expand:after,
SECTION.ALLODDS .cashOutHead .collaspse:after,
SECTION.INPLAY_ALL .cashOutHead .expand:after,
SECTION.INPLAY_ALL .cashOutHead .collaspse:after,
.fbOddsTable .allup-cal .expand:after,
.fbOddsTable .allup-cal .collaspse:after,
.fbOddsTable .coupon .expand:after,
.fbOddsTable .coupon .collaspse:after,
.fbOddsTable .match-row .expand:after,
.fbOddsTable .match-row .collaspse:after {
    content: "";
    position: absolute;
    background-color: #fff;
    left: calc(50% - 5px);
    top: calc(50% - 1px);
    width: 10px;
    height: 1px;
}

.fbOddsTable section.OFM .coupon .expand {
    display: none;
}

SECTION.ALLODDS .cashOutHead .collaspse::before,
SECTION.INPLAY_ALL .cashOutHead .collaspse::before,
.fbOddsTable .allup-cal .collaspse::before,
.fbOddsTable .coupon .collaspse::before,
.fbOddsTable .match-row .collaspse::before {
    content: "";
    position: absolute;
    background-color: #fff;
    left: 50%;
    top: calc(50% - 5px);
    width: 1px;
    height: 10px;
}

SECTION.ALLODDS .cashOutHead>div,
SECTION.INPLAY_ALL .cashOutHead>div,
.fbOddsTable .allup-cal>a {
    display: flex;
    align-items: center;
    // margin: 10px;
    margin-left: 12px;
    cursor: pointer;
    position: relative;
    @include font13();
}


SECTION.ALLODDS .cashOutHead span,
SECTION.INPLAY_ALL .cashOutHead span{
    margin-left: 6px;
}

.oddsCheckboxGrid {
    display: grid;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    grid-template-columns: auto 34px;
    justify-items: flex-start;
    .sgaCellGrid & {
        grid-template-columns: auto 40px;
    }
}

.fbOddsTable section.ALLODDS .odds>div .fbOddsTable section.INPLAY_ALL .odds>div {
    justify-content: center;
}
.fbOddsTable section .match-row-container{
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
    @include H5();
    .goals-number {
        color: #333333;
        font-weight: 400;
        font-size: 11px;
    }
}

.fbOddsTable section {
    &.ALLODDS, &.LAST_ODDS, &.INPLAY_ALL{
        .match-row-container{
            .team {
                // @include font11();
                // height: 14px;
                // line-height: 14px;
                padding:12px 0 8px 0;
                @include H6();
                // &.HAD, &.FHA, &.HHA, &.FTS, &.OOE {
                //     padding:12px 0 0 0;
                // }
            }
            .odds {
                color: #405A55;
                // min-height: 40px;
                padding: 0 0 12px;
                &:is(.oddsTTG, .oddsETG, .oddsHFT) {
                    > div {
                        padding: 8px 0;
                    }
                    padding: 0;

                }
                .oddsValue {
                    margin-top: 0;
                    @include H4();
                    span {
                        cursor: pointer;
                        white-space: nowrap;
                        white-space: nowrap;
                    }
                }

            }
            .crsTable, .FGS, .LGS, .AGS {
                .odds {
                    min-height: 34px;
                    // height: 34px;
                    @include H5();
                    padding: unset;
                    align-content: center;
                }
                .match-row {
                    min-height: auto;
                    height: auto;
                }
            }
        }
    }
}

.fbOddsTable section.CRS .match-row-container .odds>div>span,
.fbOddsTable section.FCS .match-row-container .odds>div>span,
.fbOddsTable section.FGS .match-row-container .odds>div>span,
.fbOddsTable section.AGS .match-row-container .odds>div>span,
.fbOddsTable section.LGS .match-row-container .odds>div>span,
.fbOddsTable section.MSP .match-row-container .odds>div>span,
.fbOddsTable section.MIXALLUP_DETAIL .match-row-container .odds>div>span,
.fbOddsTable section.ALLODDS .match-row-container .odds>div>span,
.fbOddsTable section.INPLAY_ALL .match-row-container .odds>div>span {
    min-width: 33px;
}

.fbOddsTable section .odds input {
    /* margin-right: 4px; */
    height: 18px;
    width: 18px;
}

.fbOddsTable section > .header-wrapper {
    display: flex;
    align-items: center;
    min-height: 52px;
}

.fbOddsTable section > .header-wrapper > .header {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    // grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [odds] 28% [h2h] 48px [end];
    justify-items: stretch;
    width: 100%;
    align-items: flex-end;
    @include H6();
    & >:first-child{
        padding-left: 12px;
    }
}


.fbOddsTable section.MIXALLUP>.header-wrapper>.header {
    grid-template-columns: 10% 20% 20% 10% 0.8fr;
    margin-bottom: -8px;
}

.fbOddsTable section > .header-wrapper > .header>div {
    justify-content: center;
    justify-items: center;
    // padding-left: 10px;
}

.fbOddsTable section > .header-wrapper > .header>.team {
    height: 30px;
    padding: 0px 12px;
    display: grid;
    align-items: flex-end;
    align-self: flex-end;
    grid-template-columns: 100%;
    justify-items: flex-start;
}

.fbOddsTable section > .header-wrapper > .header>.inplay {
    height: 30px;
    padding: 0px 8px;
    display: grid;
    align-items: end;
    grid-template-columns: 100%;
    justify-items: end;
}

.fbOddsTable section > .header-wrapper > .header>.odds {
    padding: 0px 12px;
    display: grid;
    align-items: end;
    grid-template-columns: 100%;
}



.fbOddsTable section > .header-wrapper > .header>.h2h {
    text-align: center;
    padding:0  6px;
}

.fbOddsTable section > .header-wrapper > .header>.odds > .label {
    padding-bottom: 5px;
}

.fbOddsTable section > .header-wrapper > .header>.odds>div {
    width: 100%;
    display: grid;
    padding: 5px 0px 0px 0px;
    border-top: 1px #c4c4c4 solid;
    grid-template-columns: [first] auto auto auto [end];
    justify-items: center;
    justify-content: space-evenly;
    div {
        min-width: 58px;
        text-align: center;
    }
}

.fbOddsTable section > .header-wrapper > .header>.line {
    display: none;
}
.fbOddsTable section{
    &.HIL, &.FHL, &.FCH, &.CHL {
        >.header-wrapper>.header{
            >.line{
                display: block;
                text-align: center;
            }
            >.odds{

                padding: 0px ;
                >div{
                    grid-template-columns: [first] 1fr 1fr 20px [end];
                }
            }
        }
        .coupon{
            .right{
                margin-right: 48px;
                width: auto;
                display: flex;
                justify-content: flex-end;
            }
        }
        .match-row {
            .odds {
                grid-template-columns: [first] 1fr 1fr 20px [end];
                width: 100%;
                /* align-items: center; */
            }
        }
        >.match-row-container{
            >.match-row {
                .oddsLine{
                    padding-left: 0;
                    gap: 12px 0;
                    &.hadstopsell{
                        gap: 0 ;
                    }
                }
            }
        }
    }
    &.HHA, &.HDC, &.FHH, &.CHD, &.FHC,&.EDC, &.EHH, &.ECD{
        .header{
            .odds{
                padding: 0px ;
                >div{
                    grid-template-columns: [first] 1fr 1fr 20px [end];
                }
            }
        }
        .coupon{
            .right{
                margin-right: 48px;
                width: auto;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    &.HHA .header .odds >div{
        grid-template-columns: [first] 1fr 1fr 1fr 20px [end];
    }
}


.fbOddsTable section .match-row {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    // grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [odds] 28% [h2h] 48px [end];
    width: 100%;
    min-height: 44px;
    // justify-items: stretch;
}

.fbOddsTable section .match-row.matrix {
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
}

.fbOddsTable section .match-row.matrix .odds {
    grid-template-columns: 85px 1px 80px;
    justify-items: center;
    flex: 1;
}

.fbOddsTable section .match-row.matrix .odds .oddsCheckboxGrid{
    justify-self: flex-start;
    margin-left: 10px;
}

.fbOddsTable section .match-row.matrix .odds>span {
    // width: 1px;
    // height: 20px;
    // background-color: #979797;
    // // margin-top: 5px;
    width: 1px;
    /* height: 20px; */
    border-right: #979797 1px solid;
    margin: 2px 0;
}

.fbOddsTable section .match-row.matrix:nth-child(odd) {
    background-color: #f4f4f4;
}

.fbOddsTable section .match-row .score span {
    display: unset;
    // border-bottom: 1px #b5b5b5 solid;
    height: 18px;
    margin: 0;
    line-height: 18px;
}

.fbOddsTable section .match-row .score span:last-child {
    border-bottom: unset;
}

.fbOddsTable section .match-row .lineNum {
    line-height: 20px;
    text-align: center;
}

.fbOddsTable section .match-row-container>.match-row:nth-child(odd) {
    background-color: #ffffff;
}

.fbOddsTable section .match-row-container>.match-row:nth-child(even) {
    background-color: #f4f4f4;
}

.fbOddsTable .INPLAY_ALL .match-row-container>.match-row.matrix:nth-child(odd),
.fbOddsTable .ALLODDS .match-row-container>.match-row.matrix:nth-child(odd) {
    background-color: #f4f4f4;
}

.fbOddsTable .INPLAY_ALL .match-row-container>.match-row.matrix:nth-child(even),
.fbOddsTable .ALLODDS .match-row-container>.match-row.matrix:nth-child(even) {
    background-color: #ffffff;
}

.fbOddsTable section .match-row>div:not(.odds, .oddsLine, .team) {
    display: flex;
    align-items: center;
    &.live-info{
        justify-content: center;
    }
    &.inplay-clock-con{
        align-items: initial;
    }
}

.fbOddsTable {
    section  {
        .match-row>div {
            padding: 8px 12px;
        }
    }
}

.fbOddsTable section{
    &.ALLODDS  {
        .match-row>div {
            min-height: 40px;
        }
    }
}

.fbOddsTable {
    section {
        &.HIL, &.FHL, &.CHL, &.FCH {
            .match-row >div:not(.oddsLine) {
                min-height: 64px;
                align-self: flex-start;
            }
            .match-row .oddsLine {
            grid-template-columns: 70px 1fr;
            }
        }
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
            .match-row >div:not(.oddsLine) {
                min-height: 84px;
                align-self: flex-start;
            }
        }
        &.ALLODDS  {
            .match-row>div {
                min-height: 40px;
            }
        }
    }
}

.fbOddsTable section .match-row {
    .date, .fb-id {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.fbOddsTable .MIXALLUP .match-row>div {
    padding: 2px 12px;
}

.fbOddsTable section .match-row>div:last-child {
    border-right: unset;
}

.fbOddsTable section .match-row .team .teamIconSmall>div:nth-child(1):hover {
    text-decoration-line: underline;
}

.fbOddsTable section .match-row .team>span {
    margin-right: 4px;
    color: #405A55;
    cursor: pointer;
}

.fbOddsTable section .match-row .team  {
    display: grid;
    grid-template-columns: 1fr 8px 36px;
    justify-content: space-between;
    align-items: center;
    color: #405A55;
    /* padding: 12px; */
    height: 100%;
    word-break: break-word;
    padding: 8px 12px;
    @include fbTeamNameFont();
    &>div:first-child>div:first-child{
        margin-bottom: 2px;
    }
    & .teamIconSmall>div:first-child{
        cursor: pointer;
    }
}

.fbOddsTable .MIXALLUP {
    .match-row {
        .team {
            padding: 8px 12px;
            max-width: 254px;
        }
    }
}

.fbOddsTable section .match-row .team>div:nth-child(1) {
    display: block;
    gap: 10px;
    // line-height: 16px;
}

.fbOddsTable section .match-row .team>div:nth-child(1)>span:nth-child(2) {
    margin: 0px 4px;
}

.fbOddsTable section .match-row .team>div:nth-child(2) {
    margin: 0px 0px 0px 8px;
}

.fbOddsTable section .match-row .team>div:nth-child(3) {
    background-color: #FFD505;
    height: 36px;
    width: 18px;
    color: #333333 ;
    text-align: center;
    font-size: 13px;
    display: grid;
    margin:0 8px;
    border-radius: 2px;
    font-weight: 700;
}

.fbOddsTable section .match-row .team>div:nth-child(3)>span:first-child {
    display: block;
    position: relative;
}

.fbOddsTable section .match-row .team>div:nth-child(3)>span:first-child::after {
    display: block;
    content: '';
    width: 18px;
    height: 1px;
    background: #9C8201;
    position: absolute;
    bottom: 0;
    transform: scaleY(0.5);
}

.fbOddsTable section .match-row .team>div:nth-child(3).et {
    background-color: #00c06c;
    color: white;
}

.fbOddsTable section .match-row .team>div:nth-child(3).et>span:first-child::after {
    background: #fff;
}

.fbOddsTable section .match-row .odds {
    display: inline-grid;
    align-content: center;
    grid-template-columns: [first] auto auto auto [end];
    justify-content: space-evenly;
    color: #405A55;
    // justify-items: flex-start;
    @include H4();
}

.suspended {
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: center;
    color:#E10004;
    padding-bottom: 6px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
}

.suspended.isMultipleLineOdds {
    padding-bottom: 0px;
    margin: -6px 0px;
}

// .fbOddsTable .odds {
//     -ms-user-select: none;
//     user-select: none;
//     -moz-user-select: none;
//     -webkit-user-select: none;
// }

.fbOddsTable section .oddsValue span {
    // min-width: 34px;
    // text-align: left;
}

.fbOddsTable section:is(.TTG, .HFT, .CRS, .FCS)  .oddsValue span {
    min-width: 40px;
    text-align: center;
    cursor: pointer;
}

.fbOddsTable section .match-row-container .odds span:hover {
    cursor: pointer;
    text-decoration: underline;
}

.fbOddsTable section .match-row .tourn {
    text-align: center;
    padding: 4px 12px;
}

.fbOddsTable section .match-row .oddsLine {
    display: grid;
    justify-items: stretch;
    align-items: center;
    height: 100% !important;
    grid-template-columns: 1fr 2.5fr;
    padding: 12px 0 12px 12px;
    justify-content: space-between;
    // gap: 12px 36px;
    &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
        grid-template-columns: 1fr ;
        padding-left: 0;
        gap: 12px 0;
        &.hadstopsell{
            gap: 0 ;
        }
        .odds{
            width: 100%;
            grid-template-columns: 1fr 1fr 20px ;
            >.hdcOddsItem{
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                .cond{
                    text-align: center;
                    margin-bottom: 8px;
                    height: 14px;
                }
            }
        }
        &.HHA,  &.EHH {
            .odds{
                grid-template-columns: 1fr 1fr 1fr 20px ;
            }
        }
    }
}

.fbOddsTable section.MSP,
.fbOddsTable section.FGS,
.fbOddsTable section.AGS,
.fbOddsTable section.LGS,
.fbOddsTable section.SGA{
    .match-row .oddsLine{
        padding: 5px 0 5px 12px;
        height: auto !important;
    }
}

.fbOddsTable section .match-row .tourn img, .fbOddsTable section .tournHeader img {
    width: 24px;
    height: 21px;
}

.fbOddsTable section .coupon {
    height: 32px;
    background-color: #405A55;
    color: #ffffff;
    align-items: center;
    padding-left: 12px;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 4px 4px 0 0;
    display: grid;
    grid-template-columns: 80% 20%;
    align-items: center;
    justify-content: center;
    align-content: center;
    @include H4();
}
.fbOddsTable section .coupon.couponExpand{
    border-radius: 4px;
}
.allup-cal #allup-cal-btnExpand.couponBtn,
.fbOddsTable section .couponBtn{
    min-width: 20px;
    height: 20px;
    background: #569171;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
}
.fbOddsTable .match-row-m {
    .oddsLine .couponBtn.alloddshide{
    display: none;
}
}

.fbOddsTable section.LASTODDS,
.fbOddsTable section.INPLAY_ALL,
.fbOddsTable section.MIXALLUP_DETAIL,
.fbOddsTable section.ALLODDS {
    .oddsLine .couponBtn.alloddshide{
    display: none;
}
}

.allup-cal #allup-cal-btnExpand .dd-arrow,
.fbOddsTable section  .couponBtn .dd-arrow{
    border-color: #fff;
}

.CASHOUT .allup-cal #allup-cal-btnExpand .dd-arrow,
.fbOddsTable section .dd-arrow{
    border-color: #333333;
}


.fbOddsTable section #ddAllupFormula .dd-arrow{
    margin-top: -5px;
}

.allup-cal #allup-cal-btnExpand .dd-arrow.arrow-up,
.fbOddsTable section  .dd-arrow.arrow-up{
    margin: 4px 0 0 0;
}
.allup-cal #allup-cal-btnExpand .dd-arrow.arrow-down,
.fbOddsTable section .dd-arrow.arrow-down{
    margin:0 0 4px 0;
}

.fbOddsTable section .coupon .left,
.fbOddsTable section .coupon .right {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.fbOddsTable section .coupon .right {
    margin-right: 43px;
    width: 107px;
    display: flex;
    justify-content: flex-end;
}


.fbOddsTable section .coupon .right span {
    margin-right: 8px;
}

.fbOddsTable section .coupon .left .desc {
    margin-left: 6px;
}

.fbOddsTable section .coupon ~ .coupon {
    margin-top: 12px;
}

.fbOddsTable section.LAST_ODDS .coupon,
.fbOddsTable section.ALLODDS .coupon,
.fbOddsTable section.INPLAY_ALL .coupon {
    margin-top: 0px;
}

.fbOddsTable section .match-row>div .fbOddsTable .coupon .desc {
    margin-left: 8px;
}

.fbOddsTable .remark {
    margin: 16px 8px 24px;
    @include small-text();
}

.fbOddsTable .importNotice{
    font-weight: bold;
}

.fbOddsTable .remark a {
    text-decoration-line: underline;
    font-weight: 500;
}

.fbOddsTable section.CRS .coupon,
.fbOddsTable section.FCS .coupon,
.fbOddsTable section.MSP .coupon {
    border-radius: 4px 4px 0px 0px;
}

.fbOddsTable section .team.CRS,
.fbOddsTable section .team.FCS,
.fbOddsTable section .team.MSP {
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 30px;
    background-color: #f4f4f4;
}

.fbOddsTable section .team.CRS>div,
.fbOddsTable section .team.FCS>div,
.fbOddsTable section .team.MSP>div {
    text-align: center;
}

.fbOddsTable header.ALLODDS .match-info ,
.fbOddsTable header.INPLAY_ALL .match-info ,
.fbOddsTable section .match-info {
    color: #405A55;
    margin: 16px 12px;
}

.fbOddsTable header.ALLODDS .match-info,
.fbOddsTable header.INPLAY_ALL .match-info,
.fbOddsTable section .match-info {
    padding: 8px 0 0 0;
    .match{
        text-align: left;
    }
    .time {
        text-align: right;
    }
    .match, .time {
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #333;
        span:first-child {
                @include font11();
                font-weight: 400;
        }
        span:last-child {
            @include font13();
            font-weight: 500;
        }
    }

}


.fbOddsTable header.ALLODDS .match-info .team ,
.fbOddsTable header.INPLAY_ALL .match-info .team ,
.fbOddsTable section .match-info .team {
    @include font15();
    display: grid;
    align-items: center;
    grid-template-columns: calc(100% - 50% - 37px) 75px calc(100% - 50% - 37px);
    width: 100%;
    justify-items: center;
    justify-content: center;
    align-content: center;
}

.fbOddsTable section.CRS .match-info .team:hover>span:not(.score),
.fbOddsTable section.FCS .match-info .team:hover>span:not(.score),
.fbOddsTable section.FGS .match-info .team:hover>span:not(.score),
.fbOddsTable section.AGS .match-info .team:hover>span:not(.score),
.fbOddsTable section.LGS .match-info .team:hover>span:not(.score),
.fbOddsTable section.MSP .match-info .team:hover>span:not(.score) {
    cursor: pointer;
    text-decoration-line: underline;
}

.fbOddsTable header.ALLODDS .match-info .team>span:first-child ,
.fbOddsTable header.INPLAY_ALL .match-info .team>span:first-child ,
.fbOddsTable section .match-info .team>span:first-child {
    justify-self: end;
    text-align: right;
    font-weight: 500;
}

.fbOddsTable header.ALLODDS .match-info .team>span:last-child ,
.fbOddsTable header.INPLAY_ALL .match-info .team>span:last-child ,
.fbOddsTable section .match-info .team>span:last-child {
    justify-self: start;
    text-align: left;
    font-weight: 500;
}

.fbOddsTable section .match-info .val {
    @include font15();
    color: #333333;
    margin-top: 4px;
}

.fbOddsTable section .match-info .state {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: #333333;
    width: 100%;
    justify-content: center;
    >.matchInfoTourn{
        display: flex;
        align-items: center;
        margin-right: 6px;
        img{
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }
}

.fbOddsTable section .match-info .state>*:not(.matchInfoTourn) {
    margin: 0 6px;
    white-space: nowrap;
}

.fbOddsTable header.ALLODDS .match-info .image-H2H ,
.fbOddsTable header.INPLAY_ALL .match-info .image-H2H ,
.fbOddsTable section .match-info .image-H2H {
    margin: 0 6px;
    min-width: 24px;
}

.fbOddsTable section .match-info .team .score {
    width: 72px;
    display: flex;
    justify-content: center;
}

.fbOddsTable section .match-info .matchstatus {
    white-space: nowrap;
}

.fbOddsTable header.ALLODDS .match-info>div,
.fbOddsTable header.INPLAY_ALL .match-info>div,
.fbOddsTable section .match-info>div {
    display: grid;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
    align-content: center;
    align-items: center;
}

#dropdown-variants-scoring-info {
    padding: 0;
    border: 0;
}
.fbOddsTable .matchDropdown-ddbtn {
    width: 298px;
    border: 1px #757575 solid;
    border-radius: 2px;
    margin: 8px;
}

.fbOddsTable .match-dropdown-container {
    background-color: #F4F4F4;
    display: flex;
    justify-content: flex-end;
}

.fbOddsTable .ALLODDS .match-dropdown-container {
    display: flex;
    justify-content: flex-end;
    padding-right: 4px;
}

.fbOddsTable .matchDropdown-ddbtn button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 1px 0 1px 12px;
    font-weight: 500;
}

.fbOddsTable .match-dropdown-container .matchDropdown-ddbtn button:focus {
    color: #333333;
}

.fbOddsTable .matchDropdown-ddbtn .dropdown-menu {
    max-height: 420px;
    /* transform: translate(11px, 37px) !important; */
    overflow: hidden;
    width: auto;
    min-width: 100%;
    inset: 0px auto auto 0px !important;
    padding: 0;
    .dropdown-item {
        white-space: nowrap;
        min-height: 28px;
        padding: 6px 12px 7px;
        a{
            color: #333;
            width: 100%;
            height: 100%;
            display: block;
            overflow: hidden;
            white-space: nowrap;
        }
        &:hover a{
            color:   #00c06c;
        }
    }
}

.fbOddsTable .matchDropdown-ddbtn .dropdown-toggle:after {
    display: none;
}

.fbOddsTable .oddsCheckbox input[type=checkbox] {
    display: none;
}

.fbOddsTable .oddsCheckbox input[type=checkbox]:checked+span {
    background: #00C06C -19px top no-repeat;
    width: 16px;
    height: 16px;
}

.fbOddsTable .oddsCheckbox input[type=checkbox]+span {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 4px;
    vertical-align: middle;
    background: #fff 0 0 no-repeat;
    cursor: pointer;
    border: 1px solid #999999;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 2px;
    font-size: 1rem;
    padding-left: 4px;
}

.fbOddsTable .oddsCheckbox input[type=checkbox]:checked+span:after {
    content: " ";
    border-bottom: 2px solid #fff;
    width: 5px;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    height: 11px;
    border-right: 2px solid #fff;
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
    padding: 1px 0 0 3px;
    vertical-align: middle;
    text-align: center;
    position: absolute;
    left: 5px;
    top: 0px;
}


.fbOddsTable header.ALLODDS .match-info .team .score>div ,
.fbOddsTable header.INPLAY_ALL .match-info .team .score>div ,
.fbOddsTable section .match-info .team .score>div {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap : 4px;
}

.fbOddsTable header.ALLODDS .match-info .team .score>.vs ,
.fbOddsTable header.INPLAY_ALL .match-info .team .score>.vs ,
.fbOddsTable section .match-info .team .score>.vs {
    width: 24px;
    height: 24px;
    background-color: #FFD505;
    color: #333333;
    @include font11();
    border-radius: 2px;
}

.fbOddsTable section .match-info .team .score>.vset {
    width: 24px;
    height: 24px;
    background-color: #00C06C;
    color: #333333;
    @include font11();
    border-radius: 2px;
}

.fbOddsTable header.ALLODDS .match-info .team .score span ,
.fbOddsTable header.INPLAY_ALL .match-info .team .score span ,
.fbOddsTable section .match-info .team .score span {
    width: 24px;
    height: 24px;
    background-color: #FFD505;
    color: #333333;
    border-radius: 2px;
    @include font13();
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fbOddsTable header.ALLODDS .match-info .team .score.et span ,
.fbOddsTable header.INPLAY_ALL .match-info .team .score.et span ,
.fbOddsTable section .match-info .team .score.et span {
    background-color: #00C06C;
    color: #FFFFFF;
}

.fbOddsTable section .match-info .team .score * {
    text-align: center;
}

.fbOddsTable .match-row-m {
    display: none;
}

.fbOddsTable section.ALLODDS,
.fbOddsTable section.INPLAY_ALL {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    .updateMatchMsg{
        height: 200px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
    }
}

.fbOddsTable {
    header.ALLODDS,header.INPLAY_ALL,section.ALLODDS,section.INPLAY_ALL {
        .team:not(.allup-cal-table .team) {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            width: 100%;
            line-height: 17px;
            text-align: center;
            align-items: center;
            background-color: #ffffff;
            // &.HHA{
            //     grid-template-columns: 1fr 1fr 1fr 1fr;
            // }
            &.HDC, &.FHH, &.CHD, &.FHC,&.EDC, &.ECD {
                grid-template-columns: 1fr 1fr;
            }
        }
        .GSTabTable {
            .team:not(.allup-cal-table .team) {
                background-color: #f4f4f4
            }
        }
    }
}

.fbOddsTable :is(section.ALLODDS,section.INPLAY_ALL) .match-info .team{
    grid-template-columns: 1fr 72px 1fr !important;
}

.fbOddsTable  :is(section.ALLODDS,section.INPLAY_ALL) :is(.team.OOE,.team.FGS,.team.LGS,.team.AGS) {
    grid-template-columns: 1fr 1fr !important;
}

.fbOddsTable section.ALLODDS .coupon{
    border-radius: 0;
}

.fbOddsTable section.ALLODDS, .fbOddsTable section.INPLAY_ALL, .fbOddsTable section.LASTODDS, .fbOddsTable section.MIXALLUP_DETAIL_SINGLE {
    .coupon:not(:first-child) {
        margin-top: 0;
    }
    .match-row-container>.match-row-m.matrix:nth-child(odd) {
        background-color: #f4f4f4;
    }
    .match-row-container>.match-row-m.matrix:nth-child(even) {
        background-color: #fff;
    }
    .oddsHAD, .oddsEHA, .oddsFHA, .oddsHHA, .oddsFTS, .oddsNTS, .oddsENT {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        text-align: center;
        /* background-color: #f4f4f4; */
        justify-items: center;
        background-color: #fff;
    }
    .oddsFTS {
        background-color: #fff;
    }
    .oddsLine {
        display: grid;
        grid-template-columns: 1fr 2fr;
        justify-items: center;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
            grid-template-columns: 1fr;
            &.hadstopsell{
                gap: 0 ;
            }
            .odds{
                width: 100%;
                grid-template-columns: 1fr 1fr  ;
                padding: 6px 0;
                >.hdcOddsItem{
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    row-gap: 8px;
                    .cond{
                        text-align: center;
                        height: 14px;
                    }
                }
            }
        }
        &.HHA, &.EHH {
             .odds{
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
        .lineNum {
            font-weight: 500;
            color: #405A55
        }
        .arrow-small {
            display: none;
        }
        &:nth-child(even) {
            // background-color: #f4f4f4;
            background: linear-gradient(to bottom, #f4f4f4 50%, #fff 50%);
            background-size: 100% 76px;
        }
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC,&.EDC, &.EHH, &.ECD{
            background-size: 100% 108px;
        }
        .odds {
            display: grid;
            grid-template-columns: 50% 50%;
            width: 100%;
            justify-items: center;
            padding: 0;
            min-height: 38px;
        }
    }

}
.fbOddsTable section.LASTODDS{
    .oddsLine{
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC,&.EDC, &.EHH, &.ECD{
            background-size: 100% 96px;
        }
    }
}

.fbOddsTable .corner-result {
    width: 100%;
    height: auto;
    display: grid;
    position: relative;
    grid-template-columns: auto auto;
    align-items: center;
}

.fbOddsTable .match-row .exepcted-stop-time {
    /* padding-top: 2px; */
    white-space: nowrap;
    margin-right: 2px;
    margin-left: 6px;
    width: 26px;
    text-align: right;
    font-size: 13px;
}

.fbOddsTable .match-row .status-and-clock {
    display: flex;
    align-items: center;
    &.aet {
        // flex-direction: column;
        // align-items: flex-end;
        .exepcted-stop-time{
            width: auto;
            margin-right: 2;
        }
    }
}

.fbOddsTable .state .corner-result {
    width: fit-content;
}

.fbOddsTable .corner-result span {
    line-height: 8px;
    // margin-left: 4px;
    padding-left: 4px;
    // text-align: end;
    white-space: nowrap;
}


.fbOddsTable section .match-info.image-H2H {
    height: 18px;
    width: 15px;
    margin-left: 0px;
}

.fbOddsTable section .supplement {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    padding-right: 4px;
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
}

.fbOddsTable section.ALLODDS .singleMsg {
    width: 65%;
}


.fbOddsTable header.ALLODDS .match-info .team,
.fbOddsTable header.INPLAY_ALL .match-info .team,
.fbOddsTable section.ALLODDS .match-info .team,
.fbOddsTable section.INPLAY_ALL .match-info .team {
    grid-template-columns: 33% 76px 33% !important;
    color: #405A55;
}

.fbOddsTable section.OFM .match-row:nth-child(odd) {
    background-color: #fff;
}

.fbOddsTable section.OFM .match-row:nth-child(even) {
    background-color: #f4f4f4;
}


.fbOddsTable section .match-row .image-H2H {
    /* margin-top: 3px; */
    margin: 0 12px;
    padding: 0;
}


.fbOddsTable section .match-row .odds.hadstopsell,
.fbOddsTable section .match-row-m .odds.hadstopsell {
    display: inline-grid !important;
    grid-template-columns: 100% !important;
    text-align: center;
    @include font12();
    justify-items: center;
    align-self: center;
    gap: 0 !important;
    &>div{
        min-height: 18px;
    }
}

.fbOddsTable section .match-row-m .odds.hadstopsell>div:first-child:hover,
.fbOddsTable section .match-row .odds.hadstopsell>div:first-child:hover {
    cursor: pointer;
    text-decoration-line: underline;
}

.fbOddsTable section .match-row-m .odds.hadstopsell.announced>div:first-child:hover,
.fbOddsTable section .match-row .odds.hadstopsell.announced>div:first-child:hover {
    cursor: auto;
    text-decoration-line: none;
}

.fbOddsTable .coupon .goal-number {
    margin-left: 2px;
    display: flex;
}

.en .fbOddsTable .coupon .goal-number span.suffix {
    vertical-align: super;
    font-size: smaller;
    margin-right: 2px;
}

.fbOddsTable .matchInfo .exepcted-stop-time {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-right: 0;
}

.fbOddsTable .matchInfo .status-and-clock,
.fbOddsTable .match-info .status-and-clock {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
}

.fbOddsTable .coupon .corner-result {
    align-items: center;
    grid-template-columns: 34% 50%;
    width: 42px;
}


.fbOddsTable section .match-row .inplay-clock-con {
    justify-content: flex-end;
    padding: 0 !important;
    align-items: initial;
}
.fbOddsTable section .match-row .inplay-clock {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 0px 12px;
    // text-align: center;
    align-items: center;
}

.fbOddsTable .match-info .inplay-clock-con.right-border {
    &::after{
        content: none;
    }
}

.fbOddsTable .match-info .inplay-clock {
    display: flex;
    align-items: center;
}

.fbOddsTable header.ALLODDS .inplay-clock-con.right-border::after  ,
.fbOddsTable section.ALLODDS .inplay-clock-con.right-border::after  {
    content: unset;
}

.fbOddsTable section.INPLAY>.header-wrapper>.header {
    // grid-template-columns: [first] 140px [frontEndId] 80px [tourn] 40px 1fr 40px [clock] 128px [odds] 30% [h2h] 48px [end];
}

.fbOddsTable section.INPLAY .match-row-container>.match-row {
    // grid-template-columns: [first] 140px [frontEndId] 80px [tourn] 40px 1fr 40px [clock] 128px [odds] 30% [h2h] 48px [end];
    line-height: 15px;
    // align-items: center;
}

.fbOddsTable section.INPLAY .match-row .icon-clock,
.fbOddsTable section.INPLAY .match-row .icon-venue>div {
    margin-top: 0px;
}



.fbOddsTable section .match-row .status {
    text-align: center;
}

.fbOddsTable section .match-row .odds.hadstopsell>div:last-child {
    color: #cc0000;
    padding-left: 0;
}

.fbOddsTable section.INPLAY>.header-wrapper>.header .inplay-clock,
.fbOddsTable section.INPLAY>.header-wrapper>.header .match-status {
    text-align: center;
    padding: 0px 5px;
}

.fbOddsTable section.INPLAY_ALL .match-info .status {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.fbOddsTable header.ALLODDS .match-info .scoring-info ,
.fbOddsTable header.INPLAY_ALL .match-info .scoring-info ,
.fbOddsTable section .match-info .scoring-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.fbOddsTable section.INPLAY_ALL .match-info .status>div:last-child {
    cursor: pointer;
}

.fbOddsTable section.INPLAY_ALL .match-info .status>div:last-child .dropdown-toggle::after,
.fbOddsTable section.INPLAY_ALL .match-info .scoring-info>div:last-child .dropdown-toggle::after {
    display: none;
}

.fbOddsTable section.INPLAY_ALL .match-info .status>div:last-child:hover,
.fbOddsTable section.INPLAY_ALL .match-info .scoring-info>div:last-child:hover {
    text-decoration-line: underline;
}

.fbOddsTable header.ALLODDS .match-info .dropdown.btn-group button,
.fbOddsTable header.INPLAY_ALL .match-info .dropdown.btn-group button,
.fbOddsTable section.INPLAY_ALL .match-info .dropdown.btn-group button{
    padding: 0px;
    color: #405A55;
}

.fbOddsTable section.INPLAY_ALL .match-info .scoring-info>div:last-child button .fbOddsTable section.INPLAY_ALL .match-info .status>div:last-child button {
    line-height: 0px;
    padding: 0px;
    height: 12px;
}

.fbOddsTable section.INPLAY_ALL .match-info .status .dropdown-menu:before {
    content: "";
    position: absolute;
    /* right: 11px; */
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #569171 transparent;
    z-index: 9999;
    right: 30px;
}

.fbOddsTable header .match-info .status .dropdown-menu.show,
.fbOddsTable section .match-info .status .dropdown-menu.show {
    padding: 0px;
    width: 600px;
    transform: translate(32px, 28px) !important;
    border-radius: 10px;
}

.fbOddsTable header .match-info .scoring-info .dropdown-menu.show,
.fbOddsTable section .match-info .scoring-info .dropdown-menu.show {
    padding: 0px;
    width: 400px;
    transform: translate(8px, 32px) !important;
    border-radius: 10px;
}

.fbOddsTable section .match-info .match-status-table .header,
.fbOddsTable header .match-info .scoring-info-table .header,
.fbOddsTable section .match-info .scoring-info .header {
    background-color: #569171;
    color: white;
    padding: 10px 15px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include font18();
}

.fbOddsTable section .match-info .match-status-table .content{

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    cursor: initial;
}

.fbOddsTable section .match-info .match-status-table .content>div{
    line-height: 30px;
    padding-left: 10px;
    background-color: #F4F4F4;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(3n +1){
    border-right: 0.5px #B4B4B4 solid;
    border-bottom: 0.5px #B4B4B4 solid;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(3n +2){
    border-bottom: 0.5px #B4B4B4 solid;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(3n +3){
    border-left: 0.5px #B4B4B4 solid;
    border-bottom: 0.5px #B4B4B4 solid;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(22),
.fbOddsTable section .match-info .match-status-table .content>div:nth-child(23),
.fbOddsTable section .match-info .match-status-table .content>div:nth-child(24){
    border-bottom: unset;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(22){
    border-bottom-left-radius: 10px;
}

.fbOddsTable section .match-info .match-status-table .content>div:nth-child(24){
    border-bottom-right-radius: 10px;
}

.fbOddsTable header .match-info .scoring-info .content,
.fbOddsTable section .match-info .scoring-info .content {
    display: grid;
    grid-template-columns: 50% 50%;
    @include font13();
    justify-items: flex-start;
    align-items: center;
    color: #333333;
}

.fbOddsTable header .match-info .scoring-info .content>div,
.fbOddsTable section .match-info .scoring-info .content>div {
    padding: 5px 0 5px 10px;
    height: 100%;
    width: 100%;
    // padding-left: 10px;
    border-top: 0.5px #B4B4B4 solid;
    display: flex;
    align-items: center;
}

.fbOddsTable header .match-info .scoring-info .content>div:nth-child(2n +1),
.fbOddsTable section .match-info .scoring-info .content>div:nth-child(2n +1) {
    border-right: 0.5px #B4B4B4 solid;
}


.fbOddsTable header .match-info .scoring-info .sub-header ,
.fbOddsTable section .match-info .scoring-info .sub-header {
    display: grid;
    height: 28px;
    @include font13();
    color: #569171;
    grid-template-columns: 50% 50%;
    justify-items: flex-start;
    align-items: center;
}

.fbOddsTable header .match-info .scoring-info .sub-header>div,
.fbOddsTable section .match-info .scoring-info .sub-header>div {
    padding-left: 10px;
}

.fbOddsTable header.ALLODDS .match-info .scoring-info .dropdown-menu:before,
.fbOddsTable  header.MIXALLUP_DETAIL .match-info .scoring-info .dropdown-menu:before,
.fbOddsTable  section.MIXALLUP_DETAIL .match-info .scoring-info .dropdown-menu:before,
.fbOddsTable header.INPLAY_ALL .match-info .scoring-info .dropdown-menu:before,
.fbOddsTable section.INPLAY_ALL .match-info .scoring-info .dropdown-menu:before {
    content: "";
    position: absolute;
    /* right: 11px; */
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #569171 transparent;
    z-index: 9999;
    right: 25px;
}

.btnNext,
.btnReset {
    width: 100px;
    height: 28px;
    border-radius: 16.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 13px;
    margin-right: 14px;
    cursor: pointer;
}

.fbOddsTable section .odds.hadstopsell{
    @include font13();
}

.fbOddsTable section .odds.hadstopsell>div:last-child{
    color: #cc0000;
}

.fbOddsTable .allupCal-result{
    display: flex;
    align-items: center;
    justify-content: center;
}

.fb-top-betslip {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 0px;
    background-color: #FFFFFF;
    border-radius: 0px 0px 4px 4px;
    box-shadow: var(--box-shadow);
    gap: 8px;
}

.fb-bottom-betslip {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 8px 0px;
    background-color: #FFFFFF;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    .is-floating &{
        background-color: rgba(255, 255, 255, 0.95);
    }
}

.fbOddsTable section.CRS>.header-wrapper>.header,
.fbOddsTable section.FCS>.header-wrapper>.header,
.fbOddsTable section.CRS .match-row,
.fbOddsTable section.FCS .match-row {
    grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px 1fr [clock] 104px [odds] 28% [h2h] 48px [end];
}

// .fbOddsTable section.FGS>.header,
.fbOddsTable section.FGS .match-row,
.fbOddsTable section.AGS .match-row,
.fbOddsTable section.LGS .match-row,
// .fbOddsTable section.MSP>.header,
.fbOddsTable section.MSP .match-row,
// .fbOddsTable section.SGA>.header,
.fbOddsTable section.SGA .match-row {
    grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 48px [teamName] 247px [odds] 1fr [h2h] 48px [end];

    &>div{
        display: flex;
        align-items: center;
    }
}
.fbOddsTable section.FGS>.header-wrapper>.header,
.fbOddsTable section.AGS>.header-wrapper>.header,
.fbOddsTable section.LGS>.header-wrapper>.header,
.fbOddsTable section.MSP>.header-wrapper>.header,
.fbOddsTable section.SGA>.header-wrapper>.header{
    &>:first-child, .team{
        >br{
            display: none;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding-left: 12px;
    }
}

.fbOddsTable section .match-row .oddsLine.CRS,
.fbOddsTable section .match-row .oddsLine.FCS {
    align-items: end;
    grid-template-columns: 1fr auto;
    margin-right: 12px;
    .crs-odds-box{
        display: grid;
        grid-template-columns: repeat(4, 25%);
    }
    .crs-more-button{
        padding-left: 12px;
        >div {
            margin-top: 0;
        }
    }
}

.fbOddsTable section.MSP .match-row .image-H2H,
.fbOddsTable section.FGS .match-row .image-H2H,
.fbOddsTable section.AGS .match-row .image-H2H,
.fbOddsTable section.LGS .match-row .image-H2H,
.fbOddsTable section.SGA .match-row .image-H2H,
.fbOddsTable section.CRS .match-row .image-H2H,
.fbOddsTable section.FCS .match-row .image-H2H {
    margin-left: 0px;
}

.fbOddsTable section .match-row,  .fbOddsTable section .header {
    .oddsLine{
        &.SGA, &.MSP, &.FGS, &.LGS, &.AGS{

            display: flex;
            justify-content: flex-end;
            padding-right: 12px;
            .crs-more-button{
                align-items: center;
                @include H4();
            }
        }
    }
    .h2h{
        width: 36px;
        padding-right: 6px;
    }
}

.oddsLine.CRS .oddsCheckboxGrid,
.oddsLine.FCS .oddsCheckboxGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 14px;
    justify-content: flex-start;
    width: 40px;
    // &.oddsChanged{
    //     margin-bottom: -8px;
    // }
}

.fbOddsTable .CRS .oddsCheckbox input[type=checkbox]+span,
.fbOddsTable .FCS .oddsCheckbox input[type=checkbox]+span {
    margin: 0px;
}

.crs-label {
    @include font11();
    text-align: center;
    line-height: 11px;
    margin-bottom: 4px;
}

.hasO .crs-label {
    min-height: 22px;
    display: inline-flex;
    align-items: flex-end;
}

.crs-more-button {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-self: center;
    text-align: center;
    align-items: center;
}

.crs-more-button>div {
    background-color: #ebebeb;
    color: #00c06c;
    cursor: pointer;
    width: 50px;
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
}

.crs-more-button>div:hover {
    background-color: #00c06c;
    color: #fff;
}

.sgaCellGrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: 8px 0;
    align-items: center;
    min-height: 32px;
    color: #405A55;
    .sgaAns {
        width: 115%;
        padding-left: 24px;
        color: #333333;
        font-size: 13px;
        font-weight: 400;
    }
}

.sgaTable>div:nth-child(even) {
    background-color: #f4f4f4;
}

.sgaMoreButton {
    display: flex;
    width: 100%;
    height: 30px;
    justify-content: center;
    align-items: center;
    color: #333333;
    background-color: #ffffff;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    &.showLine{
        border-top: 1px solid #ccc;
    }
}

// .sgaMoreButton .expand,
// .sgaMoreButton .collaspse {
//     background-color: unset;
//     border-color: #00C06C;
//     margin-right: 10px;
//     margin-top: -3px;
//     margin-left: 10px;
//     width: 10px;
//     height: 10px;
// }

// .sgaMoreButton .expand {
//     margin-top: 6px;
// }

.sgaRemarks, .crsRemarks {
    // display: flex; PRJ1027-7243
    width: 100%;
    background-color: #FCF2D7;
    padding: 8px 12px;
    height: auto;
    @include font13();
    font-weight: 400;
    a{
        font-weight: 500;
    }
}

header .sgaRemarks {
    display: none;
}

header.SGA .sgaRemarks, header .crsRemarks {
    display: block;
    padding: 8px 12px;
}

.FB .top-block-item {
    position: relative;
    padding: 8px 0px;
    color: #333333;
    cursor: pointer;
    &:hover{
        color: #00c06c;
    }
}

.fb-info-block-noinfo {
    padding: 8px 12px;
}

.live-football-icon {
    width: 24px;
    height: 24px;
    background-image: url(../../info/Include/images/icon-live-football.svg);
    background-repeat: no-repeat;
    background-position: 1px 4px;
}

.oddsValue {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.oddsArrow.oddsUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #00C06C;
    margin: -10px 0px 2px 0px;
}

.oddsArrow.oddsDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #E10004;
    margin: -10px 0px 2px 0px;
}

.cashOutButton .oddsArrow {
    margin: 0px 0px 0px 4px;
}

.CRS .oddsValue,
.FCS .oddsValue,
.TTG .oddsValue {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 4px;
}

.ALLODDS .oddsValue,
.INPLAY_ALL .oddsValue,
.MIXALLUP_DETAIL .oddsValue {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    height: 14px;
    line-height: 14px;
    margin-top: 4px;
    column-gap: 6px;
    word-break: keep-all; //SQ0248-2631
    .oddsArrow {
        margin: 0 0px 2px 0px;
        width: 10px;
    }
}
@media not screen and (max-width: 1023px) and (pointer: coarse){
    .ALLODDS .tournSelOdds,
    .INPLAY_ALL .tournSelOdds,
    .MIXALLUP_DETAIL .tournSelOdds {
        flex-flow: row;

    }

    .ALLODDS .tqlOdds,
    .INPLAY_ALL .tqlOdds,
    .MIXALLUP_DETAIL .tqlOdds {
        flex-flow: row;
        gap: 6px;
        .oddsArrow:is(.oddsUp, .oddsDown) {
            margin: 0 0 2px 0;
        }
    }

}
.HFT .oddsArrow,
.TTG .oddsArrow {
     margin: 4px 0px 0px 4px;
}

section:is(.FCS, .CRS) .oddsArrow {
     margin: 4px 0px 2px 0px;
}


:is(.HFT, .TTG) .oddsArrow:is(.oddsUp, .oddsDown) {
    margin: 4px 0px 2px 0px;
}

.oddsValueM {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.oddsArrowM.oddsUp {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #00C06C;
    margin-top: -9px;
    margin-bottom: 1px;
}

.oddsArrowM.oddsDown {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid #E10004;
    margin-top: -9px;
    margin-bottom: 1px;
}

:is(.TTG, .HFT) .oddsValueM {
    .oddsArrowM:is(.oddsUp, .oddsDown) {
        margin-top: -4px;
        margin-bottom: 1px;
    }
    .oddsColor:is(.oddsUp, .oddsDown) {
        margin-bottom: -6px;
    }
}


section:is(.HIL, .CHL, .FHL, .FCH){
    .oddsValueM:is(.oddsDown, .oddsUp) {
        transform: translateY(4px);
    }
}

.ALLODDS, .INPLAY_ALL, .MIXALLUP_DETAIL {
    .MSP, .SGA {
        .oddsArrow {
            margin: 4px 0px 0px 4px;
        }
    }
    .oddsTTG .oddsETG .oddsHFT {
        .oddsArrowM {
            margin: 0px 8px 0px 4px;
        }
    }
    .HIL, .CHL, .FHL, .FCH {
        .oddsArrowM.oddsUp, .oddsArrowM.oddsDown {
            margin: 0px 0px 0px 4px;
        }
    }
}

.oddsColor.oddsUp {
    color: #00C06C;
}

.oddsColor.oddsDown {
    color: #E10004;
}

.sgaSelOdds {
    display: flex;
    padding: 0px 0px 0px 4px;
    font-weight: 500;
}

.crsSel {
    text-align: center;
    width: 112px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @include H4();
    color:#333333;
}

.fbOddsTable .unCickable{
    @include font13();
    font-weight: 400;
    color: #333333;
}
.searchNoMatchResult{
    background-color: #fff;
    height: 100px;
    padding: 18px 0;
    margin: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    @include H4();
    .searchNoMatchResult-content{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .searchNoMatchResult-button{
        display: flex;
        width: 200px;
        height: 32px;
        border-radius: 16px;
        margin: 0 auto;
        background: #fed00b;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }
}
.fbMain .pagination-box > div{
    color: #405a55;
}
.FBMatchListLoadMoreContainer{
    margin-bottom: constant(safe-area-inset-bottom, 34px);
    margin-bottom: env(safe-area-inset-bottom, 34px);
    .FBMatchListLoadMoreButton{
        width: 100%;
        background-color: #fff;
        border-radius: 0 0 4px 4px;
        height: 32px;
        border-top: 1px solid #B4B4B4;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        box-shadow: var(--box-shadow);

    }
    .fbMatchloadMoreLoading{
        padding: 12px 0 0;
    }
}