.modal-90w {
    width: calc(100% - 12px) !important;
    max-width: none !important;
    // max-height: calc(100vh - 1rem) !important;
}

@media (min-width: 320px) {
    .modal-dialog.mobile-menu-modal {
        margin: 0.5rem auto;
    }
}

.mobile-menu-modal {
    .modal-content {
        overflow: hidden;
        border-radius: 4px;
    }
    .modal-body {
        padding: 0;
        max-height: calc(100vh - 70px) !important;
        overflow-y: auto;
    }
}


.navigation-menu .dropdown-item:before {
    display: none;
}