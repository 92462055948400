/* mobile */
@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion, #popup-root, [data-floating-ui-portal]) {
        .masonry-col-fo>div:first-child, .masonry-col-fo>div:last-child,
        .masonry-col-jkc>div:first-child, .masonry-col-jkc>div:last-child,
        .masonry-col-tnc>div:first-child, .masonry-col-tnc>div:last-child {
            width: 100% !important;
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 0px;
            margin-bottom: 0px;
        }
        .invCollapse-m {
            display: block;
            width: 100%;
        }

        .invCollapse-m>a {
            display: flex;
            justify-content: space-between;
            align-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        #collapseIconList .card-body .card-cell:not(:last-child):after {
            display: none;
        }

        #collapseIconList .card-body {
            padding: 6px 0px;
        }

        #collapseIconList .card-body div {
            display: flex;
            padding: 2px 8px;
            justify-content: space-evenly;
            align-items: flex-start;
            justify-items: start;
            flex-wrap: nowrap;
            width: 100%;
            flex-direction: column;
            align-content: center;
        }

        #collapseIconList .card-body>div:not(:last-child) {
            border-right: 1px solid #d8d8d8;
        }

        #collapseIconList .card-cell {
            height: 40px;
        }

        .card-cell {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-content: space-around;
            justify-content: center;
            align-items: flex-start;
        }

        .icontitle{
            display: none !important;
        }
        .icontitle-m {
            color: #333333;
            border-radius: 4px 4px 0 0;
            display: flex !important;
        }
        .icontitle-m a button {
            background: unset;
            border: unset;
            color: white;
            width: 20px;
            height: 20px;
            font-size: 14px;
        }
        .icontitle-m .arrow-small {
            border-color: #FFFFFF;
        }

        .icontitle-m a .arrow-up {
            transform: rotateZ(-135deg);
            transition: all 0.2s ease-in-out 0s;
            display: flex;
            margin-top: 6px;
        }

        .icontitle-m a .arrow-down {
            transform: rotateZ(45deg);
            transition: all 0.2s ease-in-out 0s;
            display: flex;
            margin-top: 2px;
        }

        .icontitle-m a label {
            cursor: pointer;
            height: 22px;
            margin-top: 4px;
            margin-bottom: 4px;
            margin-right: 10px;
            font-size: 15px;
            color: #333333;
            line-height: 13px;
            font-weight: 500;
        }

        #ICON_collapseIconList.collapse:not(.show) {
            display: none;
        }
        #ICON_collapseIconList.collapse {
            width: calc(100% - 12px);
            margin: 6px;
        }



        .JKC .meeting-info-fo-topinfo-m-container , .TNC .meeting-info-fo-topinfo-m-container {
            border-left: 0px;
            border-bottom: 1px;
            border-top: 0px;
            border-right: 0px;
            border-style: solid;
            border-color: #d8d8d8;
        }
        .JKC .meeting-info-fo-topinfo-m-container .meeting-info-menu, .TNC .meeting-info-fo-topinfo-m-container .meeting-info-menu{
            border-left: 1px;
            border-bottom: 0px;
            border-top: 0px;
            border-right: 0px;
            border-style: solid;
            border-color: #d8d8d8;
        }
        .JKC .table.folistaddtoslip, .TNC .table.folistaddtoslip {
            display:none !important;
        }

        .otherdetailpopup-content .otherdtlsec {
            display: none !important;
        }
        .otherdetailpopup-content .otherdtlsecM {
            display: inline !important;
        }
        .otherdetailpopup-content .otherdtlsec .row .cell
        {
            display: none !important;
        }

        .fomenuM {
            display: flex !important;
            align-items: flex-start;
            height: 100%;
        }

        .fomenuM a{
            white-space: nowrap;
        }


        .iconsection{
            min-height: 60px !important;
        }

        [role='tooltip'].otherdetailpopup-content,[role='dialog'].otherdetailpopup-content {
            width: calc(100vw - 16px ) !important;
            height: auto;
            max-width: 400px;
            height: min-content;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
            position: fixed !important;
            top: 20% !important;
            left: 8px !important;
            transform: translate(0, 0) !important;
            z-index: 9999 !important;
            right: 8px !important;
        }
        [data-popup='tooltip'].otherdetailpopup-overlay{
            background: #d8d8d8 !important;
            opacity: 0;
        }
        .otherdetailpopup-content .table{
            display: table;
        }
        .otherdetailpopup-content .table.other, .otherdetailpopup-content .otherdtlsec{
            display: none !important;
        }
        .otherdetailpopup-content .OtherDetail{
            // display: none;
        }
        .otherdetailpopup-content .table.otherm{
            display: table !important ;
        }
        .otherdetailpopup-content .OtherDetailm, .otherdetailpopup-content .otherdtlsecM{
            display: block !important;
            width: 100%;
        }
        .otherdetailpopup-content .otherdtlsecM{
            box-shadow:0 3px 5px rgba($color: #000000, $alpha: .2);
            border-radius: 10px;
            overflow: hidden;
        }
        .otherdtlsecM_table{
            display: table;
            width: 100%;
            &>:first-child {
                > *{
                    font-size: 11px !important;
                    font-weight: 400 !important;
                    color: #333 !important;
                    white-space: nowrap !important;
                }
                > :first-child{
                    text-align: left !important;
                }
            }
        }
        .rcOddsTable{
            .JKC .row .cell, .TNC .row .cell,
            .otherdetailpopup-content .row .cell{
                min-width: 40px;
                &.oddschk{
                    min-width: 70px;
                }
            }

        }
    }
}