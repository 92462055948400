@import '../common-mixin.scss';
.fbOddsTable .date-competition-selection{
    @include H4();
}
.fbOddsTable .date-competition-selection header {
    height: 52px;
    background-color: #333333;
    border-radius: 4px 4px 0px 0px;
    margin-top: 8px;
    display: flex;
    /* grid-template-columns: 1fr 1fr; */
    align-items: center;
    justify-content: space-between;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

}
.fbOddsTable .date-competition-selection header > div:first-child{
    display: flex;
}

.fbOddsTable .date-competition-selection section {
    /* min-height: 66px; */
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 12px;
    border-radius: 0px 0px 4px 4px;
    /* margin-bottom: 12px; */
    box-shadow: rgb(0 0 0 / 9%) 0px 2px 5px;
    border-radius: 4px;
    flex-direction: column;
}
.fbOddsTable .date-competition-selection section.section-ALL{
    padding: 0;
    box-shadow: none;
}
.fbOddsTable .date-competition-selection  .fbSearchButton{
    height: 36px;
    min-width: 107px;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 8px;
}
.fbOddsTable .date-competition-selection  .fbSearchButton > .searchBtn{
    display: flex;
    height: 100%;
    width: 40px;
    align-items: center;
    justify-content: center;
    background-color: #569171;
    border-radius: 2px;
    flex:  1;
}

.fbOddsTable .date-competition-selection  .fbSearchButton.active > .searchBtn{
    background-color: #00c06c;
}

.fbOddsTable .date-competition-selection  .fbSearchButton > input{
    display: none;
}

.fbOddsTable .date-competition-selection .icon-search{
    margin: 0;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
    margin-right: 8px;
}
.fbOddsTable .RESULTS  .section-SEARCH > div,
.fbOddsTable .date-competition-selection  .section-SEARCH > div{
    width: 100%;
    height: 100%;
    box-shadow: var(--box-shadow);
    position: relative;
    .msgInboxClose.clearSearchResultBtn{
        position: absolute;
        right: 12px;
        top: 32%;
    }
}
.fbOddsTable .RESULTS  .section-SEARCH > div .msgInboxClose.clearSearchResultBtn{
    right: 34px;
}
.fbOddsTable .RESULTS  .searchResultsList,
.fbOddsTable .date-competition-selection  .searchResultsList{
    position: absolute;
    width: 100%;
    left: 0;
    top: 48px;
    background-color: #FFF;
    box-shadow: var(--box-shadow);
    border-radius: 2px;
    max-height: 194px;
    overflow: auto;
    z-index: 20;
}
.fbOddsTable .RESULTS  .searchResultsList{
    width: calc(100% - 22px);
}
.fbOddsTable .RESULTS  .searchResultsList > div,
.fbOddsTable .date-competition-selection  .searchResultsList > div{
    min-height: 38px;
    border-bottom: 1px solid #eee;
    display: grid;
    align-items: center;
    padding:6px 12px;
    grid-template-columns: 24px 1fr 20px;
    cursor: pointer;
    &:hover{
        background-color: #eaeaea;
    }
    &.noSearchResultsListItem{
        display: flex;
        &:hover{
            background-color: unset;
            cursor: unset;
        }
    }
}
.fbOddsTable .RESULTS  .msgInboxClose::after,
.fbOddsTable .RESULTS  .msgInboxClose::before,
.fbOddsTable .date-competition-selection  .msgInboxClose::after,
.fbOddsTable .date-competition-selection  .msgInboxClose::before{
    background-color: #333333;
    width: 16px;
    top: 7px;
    left: 0;
    right: 0;
}
.fbOddsTable .date-competition-selection  .searchResultsList .fbSearchHL{
    color: #00C06C;
}
.fbOddsTable .date-competition-selection  .section-SEARCH input{
    height: 44px;
    width: 100%;
    border-radius: 5px;
    border: 0;
    padding: 0 12px;

}
.fbOddsTable .date-competition-selection .date-tab,
.fbOddsTable .date-competition-selection .tourn-tab,
.fbOddsTable .date-competition-selection .featured-tab {
    background-color: #569171;
    height: 36px;
    border-radius: 2px;
    cursor: pointer;
    margin-left: 8px;
    color: #ffffff;
    display: flex;
    justify-items: center;
    align-items: center;
    padding: 7px 16px;
    .ic-leagues, .ic-calender, .ic-featuredMatch{
        margin-right: 8px;
    }
}

.fbOddsTable .date-competition-selection .date-tab.disabled {
    background-color: #757575;
    cursor: unset;
}

.fbOddsTable .date-competition-selection .active,
.fbOddsTable .date-competition-selection .section-DATE .date.active,
.fbOddsTable .date-competition-selection .section-DATE .other.active {
    background-color: #00c06c;
    color: #ffffff;
}

.fbOddsTable .date-competition-selection .section-DATE > div {
    width: 100%;
    /* display: inline-flex; */
    /* flex-wrap: wrap; */
    justify-content: flex-start;
    align-items: center;
    /* height: 66px; */
    /* gap: 10px; */
}

.fbOddsTable .date-competition-selection .section-DATE > div .gradient {
    position: absolute;
    width: 10px;
    top: 0;
    bottom: 0;
    right: 20px;
    background: linear-gradient(
        90deg,
        rgba(234, 234, 234, 0.135615) 0%,
        rgba(234, 234, 234, 1) 100%,
        rgba(234, 234, 234, 1) 100%
    );
}

#dateListUi .slick-arrow.slick-prev, #dateListUi .slick-arrow.slick-next {
    margin-left: 0;
    box-shadow: none;
}

#dateListUi .slick-list {
    margin-left: 0;
}

#dateListUi .slick-next.slick-disabled:before, #dateListUi .slick-prev.slick-disabled:before {
    right: -4px;
}

#dateListUi .slick-arrow.slick-prev.slick-disabled, #dateListUi .slick-arrow.slick-next.slick-disabled {
    background: #B4B4B4;
}

#dateListUi .slick-arrow.slick-prev, #dateListUi .slick-arrow.slick-next {
    background: #00C06C;
}

/* #dateListUi .slick-next:before {
    top: -2px;
}

#dateListUi .slick-prev:before {
    top: -3px;
} */


#dateListUi .slick-next:before, #dateListUi .slick-prev:before {
    color: #eaeaea;
}


.fbOddsTable .date-competition-selection .section-DATE .date {
    background-color: #ffffff;
    /* padding: 8px 0px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    /* margin-left: 5px; */
    height: 45px;
    width: 50px;
    text-align: center;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.fbOddsTable .date-competition-selection .section-DATE .date:first-child {
    margin-left: 0px;
}

.fbOddsTable .date-competition-selection .section-DATE .other {
    background-color: #ffffff;
    border-radius: 4px;
    /* margin-left: 5px; */
    height: 45px;
    width: 90px;
    padding-top: 14px;
    text-align: center;
    cursor: pointer;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.fbOddsTable .date-competition-selection .section-DATE .date > * {
    display: block;
    @include H6();
}
.fbOddsTable .date-competition-selection .section-DATE .date > :first-child{
    margin-bottom: 3px;
    @include H4();
}

/* .fbOddsTable .date-competition-selection .section-TOURN input[type='checkbox']:checked + span:before {
    margin-top: -12px;
} */

.fbOddsTable .date-competition-selection .section-TOURN > .slider-container-tournament {
    /* grid-template-columns: 100%; */
    width: 100%;
}

.fbOddsTable .section-TOURN > .more span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 1px 18px 0px;
    @include font13();
    color: #405a55;
}

.fbOddsTable .section-TOURN > .more .arrow-small {
    border-color: #00c06c;
    border-width: 0 2px 2px 0;
    width: 6px;
    height: 6px;
    margin-left: 14px;
    margin-bottom: 3px;
}

.fbOddsTable .section-TOURN > .more .arrow-small.arrow-up {
    margin-bottom: 0px;
    margin-top: 3px;
}

.fbOddsTable .section-TOURN > div:nth-child(2):not(.slider-container-tournament) {
    display: grid;
    grid-template-columns: [first] 25% 25% 25% 25% [end];
    width: 100%;
    // line-height: 20px;
    padding: 4px;
    margin: 8px 0px;
    cursor: pointer;
}

.fbOddsTable .section-TOURN > .more {
    position: relative;
    cursor: pointer;
    width: 100%;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.fbOddsTable .section-TOURN > .control {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;
    color: #405a55;
    .left:hover,.right:hover{
        color: #00c06c;
    }
}

.fbOddsTable .section-TOURN > .control > .left {
    display: flex;
    gap: 12px;
    cursor: pointer;
}

.fbOddsTable .section-TOURN > .control .right {
    justify-content: flex-end;
    cursor: pointer;
}

.fbOddsTable .section-TOURN > .control button {
    background-color: #569171;
    color: #ffffff;
    width: 100px;
    height: 32px;
    border: 0px;
    border-radius: 15px;
    @include font13();
    /* margin-right: 8px; */
}

.fbOddsTable .section-TOURN > .control .right button {
    width: 110px;
}

.fbOddsTable .section-TOURN > .control button:hover {
    opacity: 0.8;
}

.fbOddsTable .section-TOURN > div:nth-child(2):not(.slider-container-tournament) > div {
    border-radius: 4px;
    width: 90%;
    margin: 8px 4px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;
}

/* .fbOddsTable .section-TOURN .tourn-input {
    margin-top: 3px;
} */

.fbOddsTable .section-TOURN .tourn label {
    word-break: break-word;
    width: 80%;
    margin-left: 4px;
    cursor: pointer;
    padding-top: 2px;
}

/* .fbOddsTable .section-TOURN input[type='checkbox'] {
    display: none;
}

.fbOddsTable .section-TOURN input[type='checkbox']:checked + span {
    background: #00c06c -19px top no-repeat;
    width: 16px;
    height: 16px;
}

.fbOddsTable .section-TOURN input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-right: 4px;
    vertical-align: middle;
    background: #fff 0 0 no-repeat;
    cursor: pointer;
    border: 1px solid #999999;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    border-radius: 2px;
    font-size: 1rem;
    padding-left: 4px;
    margin-top: 2px;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.fbOddsTable .section-TOURN input[type='checkbox'] + span {
    border: none;
    padding-left: 5px;
    padding-top: 1px;
}

.fbOddsTable .section-TOURN input[type='checkbox']:checked + span:before {
    content: ' ';
    border-bottom: 2px solid #fff;
    width: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 11px;
    border-right: 2px solid #fff;
    display: inline-block;
    color: #fff;
    font-weight: 900;
    font-size: 14px;
    padding: 1px 0 0 3px;
    vertical-align: middle;
    text-align: center;
    margin-top: -28px;
} */

.fbOddsTable .slider-container-tournament .slick-slide > div > div {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    justify-items: stretch;
    align-items: flex-start;
    grid-column-gap: min(19px, 10%);
    grid-row-gap: 12px;
    line-height: 14px;
}

.fbOddsTable .slider-container-tournament .slick-slide .tourn-m {
    display: flex;
}

/* .fbOddsTable .slider-container-tournament .slick-slide .tourn-m span {
    border: none;
    padding-left: 5px;
} */

.fbOddsTable .slider-container-tournament .slick-slide .tourn-m label {
    margin-left: 10px;
    word-break: break-word;
}

.fbOddsTable .slider-container-tournament .slick-list {
    width: 100%;
    margin-left: 0;
}

.fbOddsTable .slider-container-tournament .slick-dots {
    margin-top: 11px;
    position: static;
    width: 100%;
}

.fbOddsTable .slider-container-tournament .slick-dots li {
    margin: 0;
    width: 14px;
}
.venue-list {
    height: 60px;
    align-items: center;
}
.venue-list > div:not(:last-child){
    padding-right: 8px;

}
.venue-list > div{
    position: relative;
    .dhWeekDay{
        position: absolute;
        left: 0;
        bottom: -7px;
        z-index: 1;
        background: #fff;
        width: 36px;
        height: 14px;
        line-height: 14PX;
        text-align: center;
        border-radius: 3px;
        font-size: 11px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6176a0;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2)
    }
    &.active .dhWeekDay{
        color: #122c68;
    }
}