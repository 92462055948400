@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
    #betslip-panel.panel {
        &.betslipPanelalupModel{
            padding-bottom: 80px !important;
        }
        .bet-line {
            .dividend10px{
                font-size: 12px !important;
            }
           .content.COContent{
                .betLineContentTop{
                    gap: 12px;
                    .right{
                        min-width: 100px;
                        max-width: 100px;
                    }
                }
           }
            .bet-type-col {
                >:first-child{
                    margin-right: 12px;
                }
                .collapse-content-l {
                    margin-right: 12px;
                    width: auto;
                    .betline-checkbox {
                        margin-right: 8px;
                        padding-right: 8px;
                        gap: 8px;
                    }
                    .collapse-betline{
                        width: 100%;
                    }
                }
            }
            .bet-type-col {
                .unitbet-input input {
                    caret-color: transparent;
                }
            }
            .amount-col {
                .left{
                    >:first-child{
                        margin-right: 14px;
                        >:first-child{
                            margin-right: 4px;
                            &.noOfBets35px{
                                width: auto;
                                margin-right: 4px;
                            }
                        }
                        span{
                            margin: 0;
                        }
                    }
                    > .betline-checkbox{
                        padding-left: 8px;
                        height: 24px;
                        > :first-child{
                            margin-right: 4px;
                        }
                    }
                }
                .right{
                    margin-left: 4px;
                }
            }
        }
    }
        #divBetSlipNormal{
            .en & .menu-txt{
                flex-direction: row;
            }
            .login .link-account{
                >div.menu-txt{
                    align-items: center !important;
                }
            } 
        }
    }
}