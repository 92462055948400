@import './football.scss';
@import './racing.scss';

@media only screen and (max-width: 1300px) {
    .ewinMain{
        .FBBlock{
            .mobile-tab-set{
                grid-template-columns: 50% 50%;
                .line{
                    width: 50%;
                }

            }
        }
    }
}


@media only screen and (max-width: 1440px) {
    .en .topInfoMenu .topNav ul {
        padding-right: 0;
        max-width: 400px;
    }
}

