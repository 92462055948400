/******************* pagination *******************/
@import '../common-mixin.scss';
.fbOddsTable .pagination {
    color: #333333;
    border-bottom: 1px #bdbdbd solid;
    padding: 8px 4px;
    padding-left: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: -12px; */
    @include H4();
}

.fbOddsTable .pagination.right {
    justify-content: flex-end;
}

.fbOddsTable .pagination.bottom {
    border-bottom: unset;
}

.fbOddsTable .pagination .pagination-box {
    margin: 0px;
    @include H2();
}

.fbOddsTable .pagination .pagination-box>div:first-child, .fbOddsTable .pagination .pagination-box>div:last-child {
    border: 1px solid transparent;
    background-color: transparent;
}

.fbOddsTable .pagination .pagination-box .disable {
    background-color: transparent !important;
    color: #b5b5b5;
    opacity: 0.3;
}

.fbOddsTable .pagination .pagination-box .arrow-small {
    border-color: #00C06C;
}

.fbOddsTable .pagination .pagination-box .disable .arrow-small {
    border-color: #b5b5b5;
}