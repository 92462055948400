.MoreButtonText.showRed{
    position: relative;
    margin-right: 3px;
    &:after{
        content: "";
        height: 6px;
        width: 6px;
        border-radius: 6px;
        position: absolute;
        background: #E10004;
        top: -3px;
    }
}