.single-line-up-marquee-container{
  height:var(--line-height, '26px');
  padding: 0;
  overflow: hidden;
  .animate{
    transition: transform .8s;
  }
  .item {
    line-height: var(--line-height, '26px');
  }
}
