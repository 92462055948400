.jcbwTable{
    width: 100%;
    background-color: #fff;
    table-layout: fixed;
    thead{
        height: 24px;
        line-height: 24px;
        background: #F4F4F4;
        th{
            font-size: 11px;
            font-weight: normal;
            color: #333;
            padding: 0 16px;
            text-align: center;
            &:first-child{
                text-align: left;
            }
        }
    }
    td{
        padding: 8px 16px;
        word-wrap: break-word;
    }
    tr:nth-child(even) {
        background-color: #F4F4F4; 
    }
}

@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {

    }
}
