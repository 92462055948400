@media screen and (max-width: 1023px) and (pointer: coarse){
    .MIXALLUP_DETAIL .mixallup-detail-calculator {
        .matches {
            background-color: white;
            height: 44px;
            display: flex;
            overflow: auto;
            // box-shadow: var(--box-shadow);

            .match {
                display: flex;
                justify-content: space-between;
                min-width: 120px;
                flex: 1;
                padding: 5px 4px 0px 8px;

                .match-name {
                    display: flex;
                    flex-direction: column;
                    color: #333;
                    line-height: 16px;
                    font-size: 13px;
                    font-weight: 500;
                    min-width: 84px;
                }

                .match-tick {
                    display: flex;
                    margin-top: 4px;
                    height: 20px;
                    min-width: 20px;
                    background-image: url(../../info/Include/images/icon_tick_grey.svg);
                    &.selected {
                        background-image: url(../../info/Include/images/icon_tick_football.svg);
                    }
                }
                &.selected-match {
                    border-bottom: solid 4px #00c06c;
                }
                &:not(:last-child) {
                    border-right: 1px solid #e1e1e1;
                }
            }
        }
        .bettype-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;
            padding-right: 6px;
            height: 40px;
            background-color: rgba(244, 244, 244, 1);
            .selected-betType-text {
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: #333;
            }
            .toggle-icon {
                height: 30px;
                width: 30px;
            }
        }
    }

    .MIXALLUP_DETAIL .match-row-container {
        .team.HDC {
            grid-template-columns: 1fr 1fr;
        }
        .odds.oddsHDC {
            grid-template-columns: 1fr 1fr;
        }
    }
}
