@import '../../common-mixin.scss';
.tournTqlTable{
    box-shadow: var(--box-shadow);
}
.tournTqlTable .tournTqlTableHeader{
    display: grid;
    min-height: 36px;
    padding: 5px 0;
    align-items: end;
    background-color: #F4F4F4;
    height: 40px;
    @include H6();
}
.tournTqlTable .tournTqlTableBody{
    display: grid;
    background: #fff;
    min-height: 44px;
    word-wrap: break-word;
    word-break: break-word;
    @include H5();
}

.tournTqlTable .tournTqlTableHeader, .tournTqlTable .tournTqlTableBody {
    grid-template-columns: 142px 106px 1fr 1fr 80px 1fr 80px;
}

.tournTqlTable .tournTqlTableBody:last-child{
    border-radius: 0 0 4px 4px;
    box-shadow:  var(--box-shadow);
}
.tqlAllOdds {
    background-color: #FFFFFF;
}

.tqlAllOddsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
}

.tqlAllOddsTable {
    display: grid;
    grid-template-columns: 50% 50%;
}
.tqlAllOddsTitle{
    display: flex;
    align-items: center;
    padding-left: 4px;
    img{
        height: 21px;
        width: 24px;
    }
    span{
        margin-left: 12px
    }
}

.tqlAllOddsSel {
    display: grid;
    grid-template-columns: 60% 10% 30%;
    padding: 8px 0px;
    align-items: center;
}

.LAST_ODDS .tqlAllOddsSel {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px 0px;
    div {
        padding: 8px 0px;
    }
}

.tqlSel {
    text-align: center;
    @include H5();
}

.tqlCheckbox {
    text-align: right;
    padding-right: 8px;
    position: relative;
}

.tqlOdds {
    @include H5();
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: min-content;
    white-space: nowrap;
}

.TQL .padl4{
    padding-left: 8px;
    padding-right: 4px;
    flex-flow: column-reverse;
    justify-content: center;
}

.TQL .padl12{
    padding-left: 12px;
}
.TQL .padl16{
    padding: 0 16px;
}

.TQL .padtb6{
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.fbOddsTable .ALLODDS .TQL,.fbOddsTable .INPLAY_ALL .TQL{
    margin-bottom: 0;
    border-radius: 0;
    overflow: auto;
}

.TQL .dfCol{
    display: flex;
    flex-direction: column;
}
.TQL .dfcc{
    display: flex;
    align-items: center;
    justify-content: center;
}
.TQL .dfc{
    display: flex;
    align-items: center;
}
.TQL .dfjc{
    display: flex;
    justify-content: center;
}

.TQL .dffsb{
    display: flex;
    justify-content: space-between;
    height: 42px;
    align-items: center;
    border-bottom: 1px solid #F4F4F4;
    padding-left: 8px;
    padding-right: 8px;
}
.TQL .even {
    background-color: #F4F4F4;
}
.borderRLine{
    border-right: 1px #ebebeb solid;
    margin: 4px 0;
}