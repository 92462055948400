div.PopupAlert-content{
    border-radius: 8px;
    height: auto;
    max-height: 300px;
    width: 90%;
    max-width: 500px;
    .msgContent{
        margin: 12px 0;
        font-size: 13px;
        color: #333;
        font-weight: 400;
    }
    .close-btn-box {
        display: flex;
        justify-content: flex-end;
    }
}