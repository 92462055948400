@import './racing.scss';
@import './football.scss';

.main-content {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    .left-content {
        max-width: 1464px;
        // overflow-x: auto;
    }
}

@media only screen and (min-width: 1921px) {
    .fMenuTR {
        box-sizing: content-box;
        max-width: 1856px;
        padding-left: calc((100vw - 1856px) / 2);
        padding-right: calc((100vw - 1856px) / 2);
    }
    .topInfoMenu,.footer {
        box-sizing: content-box;
        max-width: 1808px;
        padding-left: calc((100vw - 1808px) / 2);
        padding-right: calc((100vw - 1808px) / 2);
    }
    .important-notice {
        width: unset !important;
        position: unset !important;
    }
    .rcMain, .fbMain, .marksixMain, .ewinMain {
        padding-left: 0px;
    }
    #divBetSlipNormal {
         border-radius: 15px;
    }
    .main-content {
        justify-content: center;
        #divBetSlipNormal .login {
            border-radius: 15px 15px 0px 0px;
        }

        #divBetSlipNormal .control {
            border-radius: 0px 0px 15px 15px;
        }

        #divBetSlipNormal .preview-bot-area{
            border-radius: 0 0 15px 15px;
        }
    }

}

@media only screen and (min-width: 1441px) {
    .main-content {
        .left-content {
            width: calc(100% - 342px);
            .leftMenuMain {
                min-width: calc((100% - 132px) / 12 * 2.5 + 24px);
                width: calc((100% - 132px) / 12 * 2.5 + 24px);
            }

            #info_top_block {
                min-width: 222px;
                width: calc((100% - 132px) / 12 * 2.5 + 24px);
            }

            .rcOddsTable, .fbOddsTable {
                width: calc(100% - ((100% - 132px) / 12 * 2.5 + 24px) - 12px);
            }
        }
        .betslip-container {
            max-width: 342px;
            width: 342px;
        }

    }
    .betslip-width.limit-width {
        min-width: 342px;
        max-width: 342px;
    }
}
