@import '../common-mixin.scss';
.rebateCalculator{
    width: 920px;
    min-width: 920px;
}
.rebateCalculator_m{
    min-width: unset;
    width: 100%;
    padding: 0px;
    background: #EAEAEA;
}
.rbcTitle{
    height: 40px;
    padding-left: 14px;
    background: #122C68;
    @include font13();
    color: #FFFFFF;
    line-height: 40px;
    font-weight: 500;
}
.rbcTitle_m{
    height: 60px;
    line-height: 60px;
    text-align: center;
    @include font13();
    font-weight: 700;
}
.rbcMeetingSession{
    height: 60px;
    background: #EAEAEA;
    display: grid;
    grid-template-columns: auto auto minmax(0,1fr) ;
    align-items: center;
    /* grid-column-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 15px;
    row-gap: 15px; */
}

.rbcMeetingSession .meetingBtnSplitLine{
    width: 1px;
    height: 44px;
    background: #979797;
    margin: 0 8px;
}

.rbcMeetingSession .slick-list .active::after{
    border:unset;
}

.rbcMeetingSession .meetingDate,.rbcMobileMeetingSession .meetingDate{
    width: 140px;
    text-align: left;
    padding-left: 12px;
    margin-right: 24px;
}

.rbcMobileMeetingSession .meetingDate{
    margin-top: 12px;
}

.rbcMeetingSession .meetingDate .rcbDateDrop,.rbcMobileMeetingSession .meetingDate .rcbDateDrop{
    padding-left: 0px;
    height: 28px;
    width: 140px;
}

.rbcMeetingSession .meetingDate .rcbDateDrop>div,.rbcMobileMeetingSession .meetingDate .rcbDateDrop>div{
    width: 140px;
}

.rbcMeetingSession .meetingDate #slAllupDate,.rbcMobileMeetingSession .meetingDate #slAllupDate{
    width: 140px;
    height: 28px;
    border: 1px solid #757575;
    color: #122C68;
    background: #ffffff;
    border-radius: 2px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset;
}


.rbcMeetingSession .separator,.rbcMobileMeetingSession .separator {
    background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0px;
    width: 1px;
    height: 35px;
    margin: 13px 28px 0 17px;
}

.rbcMobileMeetingSession{
    height: 90px;
    background: #EAEAEA;
}

.rbcMobileMeetingSession .venueInfo{
    grid-template-columns: auto minmax(0, 1fr) ;
    /* grid-column-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 15px;
    row-gap: 15px; */
    display: grid;
}

.rbcMobileMeetingSession .venueInfo .venue_m{
    padding-left: 12px;
}

.rbcMeetingSession .meeting-button {
    grid-template-columns: unset;
    padding-left: 0;
    display: flex;
    padding-right: 8px;
    .raceNoSliderCon{
        width: 100%;
    }
}
.rbcMeetingSession .meeting-button .slick-slider {
    width: 100%;
}

.rbcMeetingSession .slider-container-venue {
    /* width: 140px; */
}

.meeting-button .miss h3{
    cursor: auto;
}
.meeting-button .miss h3 span{
    opacity: 0.4;
}

.rbcBetFormula{
    height: 44px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.rbcBetFormula  .form-check-label{
    margin-left: 13px;
    margin-right: 12px;
    @include font13();
    color: #333333;

    @include lineHeight18();
    font-weight: 500;
}

.rbcBetFormula .poolSelection{
    margin-left: 11px;
}

.rbcBetFormula .poolSelection #slAlluppool{
    width: 160px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #757575;
    border-radius: 2px;
    line-height: 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset;
}

.rbcBetFormula .dropdown-menu.show {
    inset: -2px 0px auto auto !important;
}

.rbcBetFormula .alupFormulaSelection .dropdown-menu {
    min-width: 120px;
}

.rbcBetFormula .alupFormulaSelection #dropdown-currentoddsSec{
    width: 120px;
    height: 28px;
    background: #FFFFFF;
    border: 1px solid #757575;
    border-radius: 2px;
    line-height: 15px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: unset;
}


.rbcMeetingSession .meetingDate #slAllupDate .dd-arrow, .rbcBetFormula .poolSelection #slAlluppool .dd-arrow, .rbcBetFormula .alupFormulaSelection #dropdown-currentoddsSec .dd-arrow{
    padding: 3px;
}

.rbcMobileMeetingSession .dd-arrow{
    padding: 3px;
}

.rebateCalculator .dropdown-toggle:after,.rebateCalculator_m .dropdown-toggle:after {
    display: none;
}

.rbcBetFormula .poolSelection,.rbcBetFormula .alupFormulaSelection,.rbcBetFormula .unit-bet{
    margin: auto 0;
}

.rbcBetFormula .unit-bet{
    display: flex;
}
.rbcBetFormula .unit-bet .unit-bet-input{
    @include font13();
}
.rbcBetFormula .unit-bet .bet-btn-icon-area{
    display: flex;
    flex-direction: column;
    padding-right: 12px;
}

.rbcBetFormula .unit-bet .bet-btn-icon{
    width: 10px;
    height: 10px;
    cursor: pointer;
}

.rbcCardArea{
    min-height: 796px;
    background: #EAEAEA;
    padding: 10px 13px;
    margin-bottom: 60px;
}


.rbcCardArea .rcbTableDrop{
    width: 142px;
    padding: 6px 0 37px 6px;
}

.rbcCardArea .rcbTableSubDrop{
    padding: 0px 0 37px 6px;
}

.rbcCardArea .rcbTableDrop>div{
    width: 130px;
}

.rbcCardArea .rcbTableDrop .slAllup-dd > button{
    padding: 0 0 0 8px;
}

.rebateCalculator .radio-row{
    border-radius: 0;
    border-top: 1px solid #EAEAEA;
}

.rbcBottomBtn{
    height: 60px;
    padding-right: 13px;
    background: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    max-width: 920px;
    position: fixed;
    bottom: constant(safe-area-inset-bottom, 34px);
    bottom: env(safe-area-inset-bottom, 34px);
    left: 0px;
}

.rbcBottomBtn .disBtn{
    background: #CCCCCC;
}
.rbcBottomBtn .disBtn:hover{
    background: #CCCCCC;
    cursor: default;
}

.rbcBetArea {
    display: flex;
}
.rbcBetArea .rbcTableMain{
    width: 142px;
    border-radius: 2px;
    margin: 0px 4px;
    text-align: center;
}

.rbcBetArea .rbcTableMain .rbcTableHeader{
    width: 142px;
    @include font13();
    color: #FFFFFF;
    @include lineHeight18();
    font-weight: 500;
    background-color: #6176A0;
}

.rbcTableMain .rbcTableRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    background: white;
    padding: 0 0  0 12px;
}

.rbcTableMain .rbcTableRow2 {
    display: grid;
    grid-template-columns: 55% 45%;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    background: white;
    padding: 0 0  0 12px;
}

.rbcTableMain .rbcTableRow3 {
    display: grid;
    grid-template-columns: 40% 30% 30%;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    background: white;
    padding: 0 0  0 12px;
}

.rbcTableMain .rbcTableRow2>div:first-child, .rbcTableMain .rbcTableRow3>div:first-child {
    justify-self: start;
}

.rbcTableMain .rbcTableRow2.fctheader, .rbcTableMain .rbcTableRow3.fctheader {
    height: auto;
}

.rbcTableMain .verticalWord {
    line-height: 36px;
}

.rbcTableRowCompact{
    width: 142px;
}

.rbcTableRowCompact>div:nth-child(even) {
    background-color: #FFFFFFFF;
}

.rbcTableRowCompact>div:nth-child(odd) {
    background-color: #f4f4f4;
}

.rbcTableMain .rbcTableRowRight{
    display: flex;
    justify-content: flex-end;
}

.rbcTableMain .rbcTableRowRight>div{
    width: 44px;
}
.rbcTableMain .rbcTableRowRight .banker{
    width: 40px;
}

.rbcTableMain .rbcTableRow .no{
    @include font13();
    color: #333333;
    @include lineHeight18();
    font-weight: 500;
}

.rbcBetArea .multi-leg-table{
    display: flex;
    flex-direction: row;
}

.rbcResultArea{
    /* height: 600px; */
    @include font13();
    color: #333333;
    @include lineHeight18();
    font-weight: 500;
    background: #E1E1E1;
}

.rbcResultArea .rbcRHeader{
    background: #ffffff;
}
.rbcResultArea  .odd{
    background: #F4F4F4;
}
.rbcResultArea .rbcRHeaderItem,.rbcResultArea .rbcRBetBot{
    position: relative;
    min-height: 32px;
    display: flex;
    padding: 4px 0;
    line-height: 24px;
}

.rbcResultArea .rbcRHeaderItem .rbcRHeaderLabel{
    min-width: 138px;
    position: relative;
    padding-left: 14px;
}

.rbcResultArea .rbcRHeaderItem .rbcRemarklabel{
    padding-left: 234px;
}
.rbcResultArea .rbcRHeaderItem .rbcRHeaderLabel::after {
    position: absolute;
    display: inline-block;
    content: '';
    right: 0;
    top: 0;
    bottom: 0;
    width: 1px;
    background: #B4B4B4;
    transform: scaleX(0.5);
}
.rbcResultArea .rbcRHeaderItem .rbcRHeaderName{
    padding:0 23px;
}
.rbcResultArea .rbcRHeaderItem .rbcRHeaderName .flexJCB{
    display: flex;
    justify-content:space-between;
    // width: 130px;
    gap: 8px;
}
.rbcResultArea .rbcRHeaderItem  .rbcBetLine{
    word-break:break-all;
    word-wrap:break-word;
}
.rbcAmount{
    height: 32px;
    position: relative;
}
.rbcAmount span{
    position: absolute;
    right: 26px;
    bottom: 7px;
}
.rbcResultArea .rbcRBet{
    border-radius: 4px;
    background: #FFFFFF;
    margin: 0 12px 12px;
    overflow: hidden;
}
.rbcResultArea .rbcRBetTitle{
    background: #6176A0;
    height: 36px;
    padding: 9px 0 7px 11px;
    display: flex;
    flex-direction: row;
}
.rbcResultArea .rbcRBetTitle label{
    color: #FFFFFF;
    padding-left: 8px;
}
.rbcResultArea .rbcRBetTitle  .arrow-small{
    border: solid #122C68;
    border-width: 0 3px 3px 0;
}
.rbcResultArea .rbcRBetTitle .expend div {
    transform: rotateZ(-135deg);
    transition: all 0.2s ease-in-out 0s;
    display: flex;
    top: 2px;
    left: 1px;
    position: relative;
}

.rbcResultArea .rbcRBetTitle .collaspse div {
    transform: rotateZ(
45deg);
    transition: all 0.2s ease-in-out 0s;
    display: flex;
    top: -1px;
    left: 0px;
}

.rbcResultArea .rbcRBetTitle .iconArea{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin: 0 8px 0 0;
    border-radius: 4px;
    cursor: pointer;
}

.rbcResultArea .rbcRBetTitle span{
    color: white
}

.rbcResultArea .rbcRBetTitle .iconArea .arrow-up{
    position: relative;
    top: 2px;
}
.rbcResultArea .rbcRBetTitle .iconArea .arrow-down{
    position: relative;
    top: -2px;
}
.rbcResultArea .rbcRBetInfo{
    transition: all 0.2s ease-in-out 0s;
    margin-bottom: 0px;
}
.rbcResultArea .rbcRBetInfo .rbcRBetInfoItem{
    padding: 8px 13px 8px 234px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
}

.rbcResultArea .rbcRBetBot{
    height: 32px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #F4F4F4;
}
.rbcResultArea .rbcRBetBot .rbcRBetBotLabel{
    width: 218px;
}
.rbcResultArea .rbcRBetBot .rbcRBetBotName{
    text-align: right;
    margin-right: 13px;
}
.rbcResultArea .rbcRAmount {
    height: 58px;
    background: #FDF3D8 ;
    border-radius: 4px;
    padding: 7px 0 5px;
    margin: 0 12px 12px;
    line-height: 23px;
}
.rbcResultArea .rbcRAmount .rbcRBetBotName{
    line-height: 46px;
}

.rbcResultArea .rbcNoDivMsg{
    margin: 0 26px 12px 12px;
    text-align: right;
    @include font13();
    color: #d62d2d;
}
.rbcResultArea .rbcRemark{
    margin: 0px 0px 178px 24px;
    @include font11();
    color: #333333;
    @include lineHeight18();
    font-weight: 700;
}

.rbcResultArea .rbcRemark .rbcRemarklabel{
    font-weight: 400;
}

.rbcRHeaderItem .ball-panel-help-link {
    color: #122C68;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}


.rbcRemark .ball-panel-help-link:hover {
    text-decoration: underline;
}

.rbcBetArea .slick-list  {
    width: 103%;
    margin-left: 0px;
}

.rbcBetFormula_m{
    flex-direction:column;
    justify-content: flex-start;
    padding-left: 12px;
    height: 140px;
}

.rbcBetFormula_m>div{
    margin: auto 0;
}

.rbcBetFormula_m .poolSelection{
    margin: auto 0;
}
.rbcBetFormula_m .alupFormulaSelection{
    margin: auto 24px;

}

.rbcBetFormula_m .poolSelection>div,.rbcBetFormula_m .alupFormulaSelection>div,.rbcBetFormula_m .unit-bet>div:nth-child(1){
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

.rbcBetFormula_m .unit-bet>div:nth-child(1){
    width: 132px;
}


.rbcBetFormula_m  .form-check-label{
    margin-left: 0;
    margin-bottom: 4px;
}

.rbcBetArea .slick-slider  {
    width: calc(100vw - 0px);
}


.rebateCalculator_m .rbcCardArea{
    padding: 10px 0px;
    min-height: 496px;
    /* height: calc(100vh- 342px); */
    overflow-x: hidden;
    margin-bottom: 63px;
}

.rebateCalculator_m .slick-list .rc-odds-table-compact-race-allup {
    width: 142px;
}

.rebateCalculator_m .multi-leg-slide-btn-prev,.rebateCalculator_m .multi-leg-slide-btn-next {
    bottom: 45%;
    display: block;
    position: fixed;
    bottom: 50%;
    z-index: 500;
    width: 21px;
    height: 40px;
    background: #173e96;
}
.rebateCalculator_m .multi-leg-slide-btn-prev{
    left: 0;
    border-radius: 0 20px 20px 0;
    & .arrow-icon {
        margin: 11px 4px 0px -1px;
    }
}
.rebateCalculator_m .multi-leg-slide-btn-next{
    right: 0;
    border-radius: 20px 0 0 20px;
    & .arrow-icon {
        margin: 11px 0px 0px 4px;
    }
}
.rebateCalculator_m .multi-leg-slide-btn-next li,.rebateCalculator_m .multi-leg-slide-btn-prev li {
    margin: 14px 0 0 4px;
}

.rbc-button-set {
    display: grid;
    grid-template-columns: repeat(6, 16%);
    background-color: #FFFFFF;
    padding: 12px 12px;
    border-top: 1px solid #b7b7b7;
}


@media only screen and (max-width: 1023px) and (pointer: coarse){

    .rbcBetArea .rbcTableMain{
        margin: 0px 0px;
        padding: 0px 0px;
    }
    .rbcBottomBtn{
        width: 100%;
        position: fixed;
        bottom: constant(safe-area-inset-bottom, 34px);
        bottom: env(safe-area-inset-bottom, 34px);
        right: 0px;
        max-width: unset;
    }
    .rbcResultArea .rbcRBetInfo .rbcRBetInfoItem{
        padding-left: 39px;
    }

    .rbcResultArea .rbcRBetInfo .rbcRBetInfoItem span:nth-child(1){
        word-break: break-all;
        word-wrap: break-word;
        max-width: 180px;
    }
    .rbcResultArea .rbcRBetInfo .rbcRBetInfoItem span:nth-child(2){
        display: flex;
        align-items: center;
    }
    .rbcResultArea .rbcRHeaderItem .rbcRemarklabel{
        padding-left: 39px;
    }
    .rbcResultArea .rbcRBetBot .rbcRBetBotLabel{
        width: 125px;
    }
    .rbcResultArea  .rbcRBetBotWinLabel{
        height: 70px;
        line-height: 61px;
    }
    .rbcResultArea  .rbcRBetBotLoseLabel{
        height: 50px;
        line-height: 41px;
    }
    .rbcResultArea  .rbcRBetBotTotalLabel{
        height: 75px;
        line-height: 62px;
    }
    .rbcResultArea  .rbcRBetBotLabel{
        @include lineHeight18();
    }
    .rbcResultArea .rbcRemark{
        margin: 0px 45px 70px 16px;
    }

    .rbcBetFormula .unit-bet .bet-btn-icon-area{
        padding-top: 12px;
    }

    .rbcBetArea .slick-list  {
        width: 95%;
        margin-left: 5%;
    }

    .rbc-button-set {
        display: grid;
        grid-template-columns: repeat(5, 20%);
        background-color: #F6F6F6;
        color: #122c68;
        @include font13();
        padding: 0px;
        border: unset;
        height: 48px;
    }

    .rbc-button-set>div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
    }

    .rbc-button-set>div.active {
        border-bottom: 4px solid #fec107;
        font-weight: bold;
    }
    .rbcMobileMeetingSession .meeting-button {
        grid-template-columns: auto auto minmax(0, 1fr) auto auto;
        padding-left: 0;
        display: grid;
        .raceNoSliderCon{
            padding-right: 24px;
        }
    }
    // .rbcMobileMeetingSession .race-no-item.active::after{
    //     border:unset !important;
    // }
}
