.parabolaBall{
    display: none;
    width: 16px;
    height: 16px;
    background: #f8f9fa;
    border-radius: 50%;
    position: fixed;
    z-index: 1001;
    text-align: center;
    line-height: 16px;
    color: #333;
    font-size: 12px;
}
.MSCBall{
    background: #D62D2D;
    color: #fff;
}
.FTCBall{
    background: #00C06C;
    color: #fff;
}
.HRCBall{
    background: #0851bb;
    color: #fff;
}