.ARInbox {
  width: 800px;
  min-height: 630px;
  font-size: 13px;
  background-color: #e7e7e7;
  margin: 0px;
  cursor: default;
  .commom-header {
      position: sticky;
      z-index: 111;
      top:0
  }
  .ARInboxTitle {
    padding: 0 24px;
    height: 40px !important;
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    background-color: #003c84;
    align-items: center;
    .dropdown-menu{
      min-width: 80px;
    }
    #dropdown-variants-arexport{
      .btn:focus,
      &:focus,
      &:hover{
        border: 1px solid transparent;
        box-shadow: none;
      }

    }
    a.dropdown-item:hover{
      color: #003c84;
    }
    &:first-child {
        font-weight: 500;
    }
  }


  .ARExportBtn,
  .ARPrintBtn,
  .ARExportBtn{
    cursor: pointer;
    color: #fff;
    align-items: center;
    font-size: 11px;
  }

  .ARPrintBtn{
    margin: 0 16px;
  }
  .ARInboxClose {
    width:20px;
    height:20px;
    position: relative;
    cursor:pointer;
    &:before,&:after{
      content:'';
      position:absolute;
      width:18px;
      height:2px;
      background-color:#FFFFFF;
      border-radius:2px;
      top:8px;
      box-shadow:0 0 2px 0 #ccc;
    }
    &:before{
        transform:rotate(45deg);
        left:1px;
    }
    &:after{
        transform:rotate(-45deg);
        right:1px;
    }
  }
  .ARInboxHeader {
    width: 100%;
    background-color: #eaeaea;
    display: flex;
  }

  .ARInboxHeaderCell {
    flex: 1;
    display: inline-block;
    height: 40px;
  }

  .ARInboxHeaderCellInner {
    width: 100%;
    height: 36px;
    align-items: center;
    // height: 100%;
    // text-align: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    // align-items: center;
    // line-height: 38px;
  }
  .firstCell{
    padding-left: 15px;
  }
  .ARInboxHeaderCell.active {
    color:   #003C84;
    border-bottom: 4px solid #fecf13;
  }
  .acInfo {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #FFF7D5;
    height: 40px;
    line-height: 20px;
    font-weight: 500;
    .acInfoCell{
      display: inline-block;
      width: 33.33%;
      .labelText{
        padding-left: 15px;
        padding-right: 12px;
      }
      .colorBule{
        color:   #003C84;
      }
    }
    .borderLLine{
      border-left: 1px solid #333333;
    }
  }
  .ARContent{
    position: relative;
    .ARSearchBoxOverlay{
      position: fixed;
      left: 0;
      top: 120px;
      height: 100%;
      max-width: 800px;
      width: 100%;
      z-index: 122;
    //   background-color: rgba($color: #333, $alpha: 0.4);
      background-color: rgba(0,0,0, 0.5);;
    }
    .ARSearchBox{
      display: inline-block;
      max-width: 800px;
      width: 100%;
      background-color: #FFFFFF;
      padding: 15px 0;
      position: fixed;
      top: 120px;
      .date-picker-container{
        display:inline-flex;
        width: 100%;
        margin-right: 0;
        .date-input{
          width: 100%;
          height: 40px;
          padding: 0 32px 0 50px;
          border-radius: 2px;
          color: #003c84;
          border-color: #757575;
        }
        &>div:nth-child(2) {
          width: 100%;
        }
        .date-dd-arrow {
          top: 22%;
      }
      }
      .react-datepicker__triangle {
          display: none;
      }
      .react-datepicker-popper[data-placement^=bottom] {
        padding-top: 0 !important;
      }
    }
    .search-result-container{
      padding: 0 14px 0px;
      width: 800px;
      position: relative;
      .acctstmt-header{
        padding-left: 10px;
        position: sticky;
        top: 120px;
        background-color: #e7e7e7;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;
        font-weight: bold;
        border-bottom: 1px solid #B6B6B6;
        .header-r{
          display: flex;
          .btn-arexport{
            display: none;
            height: 28px;
            padding: 0;
          }
          .ARExportBtn{
            margin-right: 8px;
            color: #333;
            .icon-download-white{
              filter:invert(100%) sepia(0) saturate(0) hue-rotate(350deg) brightness(137%) contrast(59%);
            }
          }
          .new-search-btn{
            width: 100px;
            height: 28px;
            border-radius: 14px;
            color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .table-header-shadow {
          height: 36px;
      }
      .ARResultTable{
        display: table;
        width: 100%;
        &:not(.print-table) {
            margin-top: -40px;
        }
        .table-row{
          .table-cell{
            height: 36px;
            padding: 8px;
            vertical-align: top;
            background: #fff;
          }
          .ar-raceday,
          .ar-detail{
            white-space:pre-wrap;
          }
          .ar-bettype{
            min-width: 74px;
          }
          .ar-credit-amount {
            color: #3FA33A;
            font-weight: 500;
          }
        }
        .table-row.sec{
          .table-cell{
            background: #f4f4f4;
          }
        }
        .table-header{
            position: sticky;
            top: 170px;
            background-color: #e7e7e7;
          .table-cell{
            height: 36px;
            background-color: unset;
            vertical-align: middle;
            white-space: nowrap;
          }
          .ar-header-datetime{
            width: 90px;
          }
        }
      }
      .print-table{
        display: none;
        .ar-receipt{
          display: none !important;
        }
      }
      .ARResultTableM{
        display: none;
      }
      .wait-mobile{
        display: none;
      }
      .ARPagination{
        display: flex;
        justify-content: space-between;
        height: 48px;
        width: calc(800px - 36px);
        border-top: solid 1px #B6B6B6;
        position: fixed;
        bottom: 0;
        left: 0;
        margin:0 18px;
        align-items: center;
        background-color: #E7E7E7;
        .paginationBtn{
          font-weight: bold;
          padding: 0 8px;
          cursor: pointer;
          .dd-arrow{
            border-color: #333;
          }
        }
        .disabled{
          opacity: 0.5;
        }
      }
    }
  }
  .ARSearchBox-l{
    width:50%;
    float:left;
    .statementSelection-lastday{
      display: flex;
      align-items: center;
      .speedbetStatementRange {
        width: 60px;
        margin: 0px 10px;
        .dropdown-toggle{
          width: 100%;
          // padding-left: 0;
          margin-left: 0;
        }
        .dropdown-menu{
          min-width: 100%;
          width: 100%;
          .dropdown-item::before{
            position: inherit;
            display: none;
          }
          &.show{
            inset: 0px 0px auto auto !important;
          }
        }
      }
    }
  }
  .right-border::after{
    height: 116px;
  }
  .ARSearchBox-r {
    float:left;
    text-align: right;
    width: 50%;
    min-width: 290px;
  }

  .statementSelection_1st {
    margin: 0px;
  }
  .statementSelection{
    margin:12px 0px 0px 0px;
    display:flex;
    margin-left: 28px;
    width: calc(100% - 56px);
    position: relative;
  }
  .statementSelection > .statementDropdown {
    margin-left:auto;
    width: 100%;
  }
    .statementSelection>.alignButton {
      margin-left: auto;
    }
  .statementRadio {
    display: inline-flex;
    margin-right: 8px;

  }
  .recordRange{
    font-size: 11px;
    padding:8px 28px 0px 49px;
  }
    .statementDropdown{
      float:right;
    }
  .statementDropdown > .dropdown-toggle  {
    width: 100%;
    height: 40px;
    margin-left: 0px;
    background-color: #FFFF;
    color: #003C84;
    border: #757575 1px solid;
    box-shadow: none;
    text-align: right;
    border-radius: 2px;
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .arrow-icon{
      display: inline-flex;
    }
  }

  .alignButton {
    float:right;
    margin-top:10px;
  }
  .hide{
    display: none;
  }
  .selectionLabel {
    position: absolute;
    left: 8px;
    top: 12px;
    z-index: 2;
    font-size: 13px;
  }
  .dd-arrow{
    margin-left: 8px;
    margin-right: 4px;
  }
  .date-picker-container {
    .date-picker-from{
      top: 4px;
    }
    .dd-arrow{
      top: 11px;
      right: 10px;
      color: #003c84;
    }
  }
  .wait, .no-records {
    width:100%;
    text-align: center;
    padding-top:8px;
    padding-bottom:8px;
  }
  .wait-mobile{
    display: none;
  }



  .table-header-shadow {
        height: 40px;
        position: sticky;
        left: 0;
        margin-left: -15px;
        margin-right: -15px;
        max-width: 800px;
        border-left: 15px solid #e7e7e7;
        border-right: 15px solid #e7e7e7;
   }

    &.hasScroll  {
        .table-header-shadow {
            box-shadow: 0px 3px 3px rgba(0,0,0, 0.2);
        }
    }

    .ARContent .search-result-container {
        .table-header-shadow {
            top: 170px;
        }
    }

    .current-session {
        .table-header-shadow {
            top: 160px;
        }
        .detail-table {
            thead {
                top: 160px;
            }
        }
    }

    .transaction-records {
        .table-header-shadow {
            top: 120px;
        }
        .detail-table {
            thead {
                top: 120px;
            }
        }
    }

}
div.ewalletDialog-content {
  width: 360px;
  height: auto;
  border-radius: 4px;
  overflow: hidden;
  background-color: transparent;
  .ewalletDialog-container{
    height: 100%;
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    .downloadBtn{
      align-self: center;
      width: 100px;
      height: 28px;
      border-radius: 14px;
      color: #FFF;
      background-color: #003c84 ;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 6px;
    }
    .dimm{
      background: #ccc!important;
      pointer-events: none;
      opacity: .5;
      cursor: auto;
    }
    .imgArea{
      width: 100%;
      height: auto;
      img{
        width: 100%;
      }
    }
  }
}

.ARInbox-scrollbars.disableScroll >div:first-child {
    overflow: hidden !important;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.icon-print-white {
  width: 20px;
  height: 20px;
  background-image: url(../../../info/Include/images/icon-print-white.svg);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  margin-right: 5px;
  background-repeat: no-repeat;
}

// print style
.ARPrintContent{
  .acctstmt-header{
    .new-search-btn{
      display: none !important;
    }
    .header-l{
    }
  }
  .ARResultTable{
    display: none !important;
  }
  .ARResultTable.print-table{
    display: table !important;

  }
  .ARPagination{
    display: none !important;
  }
  .ARInbox .ARContent .search-result-container .acctstmt-header{
    position: static !important;
  }
  .ARInbox ,
  .ARInbox .ARContent .search-result-container{
    width: 100% !important;
  }
  .ARInbox .table-header-shadow{
    position: static !important;
    height: 0 !important;
  }
  .ARInbox .ARContent .search-result-container .ARResultTable .table-header{
    position: static !important;
  }
  .ARInbox .current-session .explain, .ARInbox .transaction-records .explain{
    display: none !important;
  }
  .ARInbox .current-session .detail-table thead, .ARInbox .transaction-records .detail-table thead{
    position: static !important;
  }
  .FBMatchListLoadMoreContainer{
    display: none !important;
  }
  .ARInbox .current-session .detail-table, .ARInbox .transaction-records .detail-table{
    margin-top: unset !important;
  }
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus{
  box-shadow: none;
}