@import '../../common-mixin.scss';

.fbOddsTable section.TTG > .header-wrapper > .header,
.fbOddsTable section.TTG .match-row {
    grid-template-columns: [first] 140px [frontEndId] 80px [tourn] 40px 1fr [clock] 128px [odds] 40% [h2h] 48px [end];
}

.fbOddsTable section.TTG > .header-wrapper > .header{
    align-items: flex-end;
}

.fbOddsTable section.TTG .header .odds > div > div {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    width: 100%;
    justify-items: center;
    align-items: center;
}

.fbOddsTable section.TTG .match-row .odds {
    grid-template-columns: repeat(8, 1fr);
    // gap: 12px;
}
.fbOddsTable section.TTG .match-row .odds>div{
    width: auto;
}

.fbOddsTable .INPLAY_ALL .oddsTTG,
.fbOddsTable .INPLAY_ALL .oddsETG,
.fbOddsTable .ALLODDS .oddsTTG,
.fbOddsTable .ALLODDS .oddsETG,
.fbOddsTable .LAST_ODDS .oddsTTG,
.fbOddsTable .LAST_ODDS .oddsETG {
    display: grid;
    grid-template-columns: repeat(8, auto);
    justify-content: space-evenly;
    gap: 0px;
    width: 100%;
}

.fbOddsTable .INPLAY_ALL .odds.oddsTTG > div,
.fbOddsTable .INPLAY_ALL .odds.oddsETG > div,
.fbOddsTable .ALLODDS .odds.oddsTTG > div,
.fbOddsTable .ALLODDS .odds.oddsETG > div,
.fbOddsTable .LAST_ODDS .odds.oddsTTG > div,
.fbOddsTable .LAST_ODDS .odds.oddsETG > div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 12px 0px;
    align-items: center;
}

.fbOddsTable section.INPLAY_ALL .odds.oddsTTG .oddsCheckbox,
.fbOddsTable section.INPLAY_ALL .odds.oddsETG .oddsCheckbox,
.fbOddsTable section.ALLODDS .odds.oddsTTG .oddsCheckbox,
.fbOddsTable section.ALLODDS .odds.oddsETG .oddsCheckbox {
    text-align: center;
    line-height: 29px;
    display: inline-block;
}

.fbOddsTable section.ALLODDS .team.TTG,
.fbOddsTable section.ALLODDS .team.ETG,
.fbOddsTable section.INPLAY_ALL .team.TTG,
.fbOddsTable section.INPLAY_ALL .team.ETG,
.fbOddsTable section.LAST_ODDS .team.TTG,
.fbOddsTable section.LAST_ODDS .team.ETG {
    display: none !important;
}

.fbOddsTable section.TTG > .header-wrapper > .header > .odds > div:last-child {
    display: none;
}

.fbOddsTable section.TTG .odds > div {
    display: block;
    min-width: auto;
    flex: auto;
}

.fbOddsTable section.TTG .odds > div > div:first-child {
    min-width: 34px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    @include font11();
}

.fbOddsTable section.TTG .odds > div > div:last-child {
    margin-top: 6px;
    text-align: center;
    flex-direction: column;
    display: flex;
}

.fbOddsTable section.TTG .match-row .icon-venue > div {
    margin-top: 0px;
}

.fbOddsTable .section.TTG .match-row .icon-clock {
    text-align: center;
    width: 100%;
    justify-content: flex-end;
}

.fbOddsTable section.TTG .match-row > div:nth-child(6) {
    display: grid;
    justify-items: center;
    padding: 14px 0px 14px 12px;
    flex-wrap: wrap;
    height: auto;
}

.fbOddsTable section.TTG .match-row .icon-clock {
    margin-top: 0px;
}

.fbOddsTable section.TTG .match-row-container .match-row-m .more {
    grid-column: 1 / span 3;
    border-top: 1px #e7e7e7 solid;
    border-bottom: 4px #dedede solid;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.fbOddsTable section.TTG .match-row-container .match-row-m .more .arrow-small {
    margin: -6px 0px 0px 12px;
    border-color: #00c06c;
    width: 9px;
    height: 9px;
    border-width: 0 2px 2px 0;
}

.fbOddsTable section.TTG .match-row-container .match-row-m .more .arrow-up {
    margin: 0px 0px -6px 12px;
}

.fbOddsTable section{
    &.TTG, &.HFT {
        .match-row-container .match-row-m {
            grid-template-columns: 1fr 119px 44px;
            align-items: flex-start;
        }
    }
}

.fbOddsTable section.TTG .match-row-container .match-row-m > .odds {
    margin-top: 6px;
    margin-bottom: 20px;
}

.fbOddsTable section.TTG .match-row-m .image-H2H {
    margin-top: 8px;
}

.fbOddsTable section{
    &.TTG, &.HFT {
        .match-row-m .odds > div > div:last-child {
            margin-top: 0px;
            flex-direction: column-reverse;
            row-gap: 8px;
        }
    }
}

.oddsTTG .oddsCheckboxGrid,
.oddsETG .oddsCheckboxGrid,
.oddsHFT .oddsCheckboxGrid {
    display: flex;
    /* grid-template-columns: 50% 50%; */
    align-items: center;
}

section.LASTODDS .oddsTTG .oddsCheckboxGrid,
section.LASTODDS .oddsETG .oddsCheckboxGrid,
section.LASTODDS .oddsHFT .oddsCheckboxGrid {
    grid-template-columns: 100%;
}

.oddsTTG .oddsCheckboxGrid > span,
.oddsETG .oddsCheckboxGrid > span,
.oddsHFT .oddsCheckboxGrid > span {
    justify-self: flex-end;
}

section.LASTODDS .oddsTTG .oddsCheckboxGrid > span,
section.LASTODDS .oddsETG .oddsCheckboxGrid > span,
section.LASTODDS .oddsHFT .oddsCheckboxGrid > span {
    justify-self: center;
}

.fbOddsTable section.TTG .oddsCheckbox input[type='checkbox'] + span {
    margin-right: 0px;
}
