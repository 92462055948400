@import '../../common-mixin.scss';

.AllupCombination {
    max-width: 828px;
    min-width: 828px;
    @include font13();
}

.AllupCombination .header {
    max-width: 828px;
    background-color: #405A55;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 12px;
    width: 100%;
    color: white;
    @include font15();
    font-weight: 500;
    .no-of-match {
        font-size: 14px;
    }
}





.AllupCombination .header .right {
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 12px;
    font-size: 11px;
}

.AllupCombination .header .right:hover {
    text-decoration: underline;
}

.AllupCombination .subHeader {
    height: 68px;
    background-color: white;
    display: grid;
    grid-template-columns: 8% 14.5% 12% 13.5% 52%;
    justify-items: center;
    justify-content: center;
    max-width: 828px;
    width: 100%;
    color: #333333;
    align-items: center;
    font-weight: 500;
    position: relative;
}

.AllupCombination:not(.isMobile) .subHeader::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -12px;
    background-image: linear-gradient(0, rgba(0, 0, 0, 0) 0%, rgba(124, 124, 124, 0.36) 100%);
    height: 12px;
    width: 100%;
    z-index: 1;
}

.AllupCombination .subHeader>div {
    text-align: center;
}

.AllupCombination .subHeader>div:last-child {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-right: 18px;
    padding-left: 2px;
    font-weight: 400;
}


.AllupCombination .subHeader>div:nth-child(4) {
    padding: 0px 10px;
}

.AllupCombination .subHeader>div:last-child>div:last-child {
    display: grid;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    width: 100%;
    // border-top: 1px solid #E7E7E7;
    padding-top: 5px;
    justify-items: center;
    // font-size: 10px;
}


.AllupCombination .content {
    // height: 440px;
    background-color: white;
    display: grid;
    // grid-template-columns: 8% 15% 12% 13.5% 51.5%;
    grid-template-columns: 8% 14.5% 12% 13.5% 52%;
    justify-items: center;
    justify-content: center;
    max-width: 828px;
    width: 100%;
    color: #333333;
    align-items: center;
    overflow: auto;
    // -webkit-box-shadow: inset -2px 17px 17px -9px rgba(0, 0, 0, 0.4);
    // box-shadow: inset -2px 17px 26px -15px rgb(133 133 133 / 40%);
    // border: 1px solid #B6B6B6;
    clip-path: inset(0 0 1px 0);
}

.AllupCombination .content>div {
    height: 24px;
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    width: 100%;
    text-align: center;
    line-height: 23px;
}

.AllupCombination .content>div.leg {
    border-right: none;
}

.AllupCombination .content .spanRow {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AllupCombination .content .leg {
    display: grid;
    padding-right: 18px;
    padding-left: 2px;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    justify-items: center;
    align-items: center;
}

.AllupCombination .even {
    background-color: #f4f4f4;
}

.icon-allupCal {
    background-image: url(../../../info/Football/info/images/calculate-new.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    margin-left: 9px;
    .investmentCaculatorBtn & {
        margin-left: 0;
    }
    // margin-top: 3px;
}

.AllupCombination.isMobile .header {
    padding: 0px;
    flex-direction: column;
    display: flex;
    height: auto
}

.AllupCombination.isMobile .header>div:nth-child(1) {
    display: flex;
    height: 44px;
    background-color: #405A55;
    color: white;
    @include font15();
    width: 100%;
    align-items: center;
    justify-content: center;
}

.AllupCombination.isMobile .header>div:nth-child(2) {
    background-color: white;
    color: #333333;
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
}

.AllupCombination.isMobile .content {
    height: 100%;
    grid-template-columns: 40% 60%;
    border: unset;
    box-shadow: unset;
}
.AllupCombination.isMobile .content>div{
    border: unset;
}

.AllupCombination.isMobile .content>div:nth-child(2){
    width: 100%;
    height: 100%;
    box-shadow: inset 13px 3px 14px -10px #dadacf;
    grid-template-columns: 100%;
}

.AllupCombination.isMobile .content>div:nth-child(2)>div{
    display: grid;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    justify-items: center;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e0e0e0;
    height: 32px;
}

.AllupCombination .investmentCaculatorBtn {
    background-color: #569171;
    color: #ffffff;
    width: 48px;
    height: 28px;
    border: 0px;
    border-radius: 15px;
    @include font15();
    margin-right: 12px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.AllupCombination .legSelction {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 66px;
    padding: 8px 12px;
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
}

.AllupCombination .legSelction>div {
    width: auto;
    display: flex;
}

.AllupCombination .legSelction .btn {
    height: 50px;
    width: 54px;
    background-color: white;
    color: #333333;
    margin-right: 12px;
    @include font15();
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.AllupCombination .legSelction .btn.active {

    background-color: #00C06C;
    color: white;
}

.AllupCombination.isMobile .subHeader {
    display: grid;
    grid-template-columns: 40% 60%;
}

.AllupCombination.isMobile .subHeader>div {
    border: 0px;
    background-color: #405A55;
    color: white;
    height: 100%;
    align-items: center;
    align-content: center;
    display: flex;
    width: 100%;
}

.AllupCombination.isMobile .subHeader>div>div {
    padding: 0px 3px;
}

.AllupCombination.isMobile .subHeader>div:nth-child(2) {
    box-shadow: inset 17px 3px 18px -6px #374c48cf;
    padding: 0px;
}

.AllupCombination.isMobile .subHeader>div:nth-child(2)>div:nth-child(1) {
    height: 30px;
    display: flex;
    align-items: center;
    flex: 1;
}

.AllupCombination.isMobile .subHeader>div:last-child>div {
    padding: 0px;
    background-color: #405A55;
}

.AllupCombination.isMobile .subHeader>div:last-child>div:last-child {
    background-color: #4d5554;
    align-items: center;
}


.AllupCombination.isMobile .content>div {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    justify-content: flex-start;
    height: auto;
}

.AllupCombination.isMobile .content>div:nth-child(1)>div {
    height: 32px;
    border-bottom: 1px solid #e0e0e0;
    line-height: 32px;
}

.ch .AllupCombination.isMobile .subHeader>div>div{
    padding: 0px 7px;
}

.AllupCombination-Scrollbars > div:last-child {
    right: 6px !important;
}

@media only screen and (max-width: 1023px) and (pointer: coarse){

    .AllupCombination {
        max-width: 828px;
        min-width: unset;
    }

    .AllupCombination .subHeader {
        height: 52px;
    }

    .AllupCombination .subHeader {
        grid-template-columns: 8% 15% 12% 13.5% 52%;
    }

    .AllupCombination .subHeader>div:last-child>div:last-child {
        padding: 0px;
        background-color: #575757;
        font-size: 10px;
    }

}