@media only screen and (max-width: 1279px) {
    section.CASHOUT, section.ALLODDS, section.INPLAY_ALL {
        .cashOutMain {
            .cashOutItem{
                .dividendInfoContainer{
                    .betType{
                        width: 139px;
                    }
                    .betDivInfo{
                        width: 180px;
                    }
                }
                .betslipInfo{
                    flex: 0 0 auto;
                    width: 343px;
                }
            }
            .cashOutButton{
                width: 319px;
            }
            .cashOutPreview{
                .cashOutPreviewBtn{
                    width: 319px;
                    &>:first-child{
                        margin: 0px 11px 0px 0;
                    }
                }
            }
        }
    }
    section.ALLODDS, section.INPLAY_ALL {
        .cashOutMain{
            .cashOutItem{
                .matchInfoContainer {
                    .dividendInfoContainer{
                        .betType{
                            width: 139px;
                        }
                        .unitBet{
                            width: 82px;
                            margin-left: 8px;
                        }
                    }
                }
                .betslipInfo{
                    flex: 0 0 auto;
                    display: flex;
                    align-items: center;
                    width: 298px;
                }
                .betType{
                    align-self: center;
                }
                .betDivInfo{
                    flex-direction: column;
                    width: 100px;
                    align-items: end;
                    &>:first-child{
                        margin-bottom: 8px;
                    }
                }
            }
            .cashOutButton{
                width: 250px;
            }
            .cashOutPreview{
                .cashOutPreviewBtn{
                    width: 250px;
                    &>:first-child{
                        margin: 0px 24px 0px 0;
                    }
                }
            }
        }
    }
}