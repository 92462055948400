@media only screen and (max-width: 1279px) {
    #divBetSlipNormal {
        .add-slip{
            padding-right: 10px;
        }
    }
    #betslip-panel.panel {
        &.betslipPanelalupModel{
            padding-bottom: 105px !important;
        }
        .bet-line, .login-answer-container {
            margin: 8px 8px 0;
            .dividend10px{
                font-size: 10px !important;
            }
           .content.COContent{
                .betLineContentTop{
                    gap: 4px;
                    .right{
                        min-width: 80px;
                        max-width: 80px;
                    }
                }
           }
            .bet-type-col {
                >:first-child{
                    margin-right: 8px;
                }
                .collapse-content-l {
                    margin-right: 8px;
                    width: calc(100% - 84px);
                    .betline-checkbox {
                        margin-right: 4px;
                        padding-right: 4px;
                        gap: 4px;
                    }
                    .collapse-betline{
                        width: 100%;
                    }
                }
            }
            .amount-col {
                .left{
                    >:first-child{
                        margin-right: 8px;
                        >:first-child{
                            margin-right: 4px;
                            &.noOfBets35px{
                                width: 35px;
                                margin-right: 0;
                            }
                        }
                        span{
                            margin: 0;
                        }
                    }
                    > .betline-checkbox{
                        padding-left: 8px;
                        height: 24px;
                        > :first-child{
                            margin-right: 4px;
                        }
                    }
                }
                .right{
                    margin-left: 4px;
                }
            }
        }
    }

}

@media only screen and (max-width: 1060px) {
    #betslip-panel{
        .fb-bet-line{
            .collapse-content-l{
                .collapse-betline {
                    font-size: 12px !important;
                }
            }
        }
    }
}