@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {

    .FB header.CASHOUT {
        display: none;
    }
    #fbOddsTable>section.CASHOUT{
        margin: 0;
        height: 100%;
    }
    .fb-cashout-header {
        display: block;
        background-color: #333333;
        width: 100%;
        color: #FFFFFF;
        border-radius: 0;
        height: 52px;
        position: relative;
        font-size: 15px;
        text-align: left;
        padding-left: 36px;
        line-height: 52px;
        font-weight: 500;
        .cashout-arrow-left{
            position: absolute;
            left: 6px;
            top: calc(50% - 15px);
        }
    }
    section.CASHOUT, section.ALLODDS, section.INPLAY_ALL {
        width: 100%;
        .cashOutMsgMain{
            width: 90%;
            max-width: 500px;
            position: fixed;
            top:  0px;
            left: 0;
            right: 0;
            height: auto;
            transition: all 0.5s ease-out;
            margin: 0 auto;
            z-index: 10;
        }
        .cashOutResultMeassage{
            margin-top: 12px;
            padding-top: 3px;
            padding-bottom: 3px;
            min-height: 35px;
            height: auto;
            animation: msgAction 3s ease-out ;
        }
        @keyframes msgAction {
            0%,80%{
                opacity: 1;
                min-height: 35px;
                height: auto;
            }
            100%{opacity: 0.4;height: 0px;}
        }
        .upDateTime{
            height: 26px;
            line-height: 26px;
            font-size: 11px;
            color: #444444;
            text-align: right;
            padding-right: 15px;
        }
        .cashOutMain{
            margin-top: 0px;
            .cashOutItem{
                border-radius: 4px;
                overflow: hidden;
                width: calc(100% - 16px );
                min-height: 242px;
                margin: 12px 8px 0;
                box-shadow: var(--box-shadow);
                flex-direction: column;
                &>div{
                    width: 100% !important;
                }
                .dividendInfoContainer{
                    padding: 0 8px 8px;
                }

                .matchInfoContainer{
                    justify-content: space-between;
                    padding: 0 8px;
                }


                .betslipInfo{
                    padding: 8px 0 0;
                }

                .cashOutItemHeader{
                    background-color: #757575;
                    height: 28px;
                    line-height: 28px;
                    color: #FFF;
                    padding: 0 8px;
                    display: block;

                }
                .matchInfoMid{
                    justify-content: space-between;
                    align-items: center;

                }
                .matchInfoBottom{
                    padding-bottom: 6px;
                    border-bottom: 1px solid #dcdcdc;

                    &>div{
                        font-weight: 400;
                    }
                }
                .dividendInfoContainer{
                    .dividendInfo-top{
                        display: flex;
                        justify-content: space-between;
                        padding-top: 8px;
                        &> div >:first-child{
                            margin-bottom: 4px;
                        }
                    }
                    .dividendInfo-bottom{
                        display: flex;
                        justify-content: flex-end;
                        text-align: right;
                        margin-top: 8px;
                    }
                    .unitBet{
                        text-align: right;
                        &>:nth-child(odd){
                            font-size: 11px;
                            margin-bottom: 4px;
                        }
                        &>:nth-child(even){
                            font-size: 15px;
                            font-weight: 500;
                            margin-bottom: 8px;
                        }
                    }
                    .unitBet>:last-child{
                        text-align: right;
                        font-size: 15px;
                        margin-bottom: 0;
                    }
                }
                .cashOutPreview{
                    display: flex;
                    flex-direction: column;
                }
                &:nth-child(even) {
                    background-color: #fff;
                }
                &:first-child{
                    margin-top: 0;
                }
            }

            .cashOutPreview{
                background: #EBF5F0;
                padding: 0 8px 0px;
                &> :first-child{
                    justify-content: space-between;
                    align-items: center;
                    padding: 12px 0;
                    &>:last-child{
                        font-weight: 500;
                        font-size: 15px;
                    }
                }
                &>:last-child{
                    &>:first-child{
                        margin: 0px 12px 12px 0;
                        font-weight: 400;
                        font-size: 15px;
                    }
                    &>:last-child{
                        margin: 0px 0 12px;
                        font-weight: 400;
                        font-size: 15px;
                    }
                }
                .loadingBtn{
                    .loadingBtnMask{
                        margin: 0;
                        background-color: rgba($color: #999, $alpha: .5);
                    }
                }
            }
            .suspended{
                background-color: #fff;
                display: flex;
                flex-direction: column;
                margin-bottom: unset;
                .cashOutSuspended{
                    justify-content: center;
                }
            }
            .cashOutButton{
                margin: 8px auto 12px;
            }
            .requestInfomation{
                margin: 8px 0 6px;
            }
            .cashOutValue{
                border-top: 0px;
            }
        }
        .CashOutNoData{
            height: 80%;
            background-color:  #E7E7E7;
            justify-content: center;
            .CashOutNoDataBtn{
                margin-top: 18px;
            }
        }
        .CashOutLogoutState{
            height: 160px;
            padding: 0;
            width: 260px;
            margin: 230px auto 0;
            justify-content: space-between;
            align-items: center;
            text-align: center;
            .CashOutNoDataMsg{
                line-height: 18px;
            }
            .CashOutNoDataBtn{
                width: 100px;
            }

        }
        .CashoutDisabled{
            width: 100%;
            white-space: pre;
        }
        .cashOutHeadIcon{
            margin-left: 0;
            margin-right: 4px;
        }
    }
    .cashOutSettledTable-m{
        .table-m{
            width: calc(100% - 16px);
            margin-left: 8px;
            margin-top: 12px;
            border-radius: 4px;
            overflow: hidden;
            font-weight: 500;
            .table-header-m{
                background-color: #757575;
                height: 28px;
                line-height: 28px;
                color: #fff;
                padding: 0 8px;
            }
            .table-body-m{
                padding-top: 6px;
                padding-bottom: 7px;
                background-color: #FFF;
            }
            .table-body-item{
                display: flex;
                &>div{
                    padding: 8px;
                }
                .item-title{
                    width: 28%;
                    border-right: 1px solid #D8D8D8;
                }
                .item-content{
                    width: 71.5%;
                    padding-left: 5%;
                }
                .item-details{
                    display: flex;
                    flex-direction: column;
                }
                .bet-details{
                    display: flex;
                    flex-direction: column;
                    padding: 3px 0;
                    word-break: break-word;
                }
                .ESLoadMoreButton{
                    color: #00C06C;
                    font-size: 13px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        }
    }

    section.ALLODDS, section.INPLAY_ALL {
        .cashOutAllOdds{
            padding-bottom: 0;
            background-color: transparent;
        }
        .resultsTitlemobile  {
            margin: 0;
        }
        .cashOutMain{
            margin-top: 0px;
            .cashOutItem{
                min-height: unset;
                margin: 0;
                width: 100%;
                border-radius: 0;
                .betslipInfo{
                    flex-direction: column;
                }
                .dividendInfoContainer{
                    width: 100%;
                }
                .cashOutButtonContainer{
                    width: 100%;
                }
            }
            .cashOutButton{
                width: 320px;
            }
            .cashOutPreview{
                &> :last-child {
                    &> :first-child{
                        width: 320px;
                    }
                }
                .cashOutPreviewBtn{
                    &> :first-child{
                        width: 100px;
                    }
                    &> :last-child{
                        width: 200px;
                    }
                }
            }
        }
    }
    section.ALLODDS .cashOutMain .cashOutItem .matchInfoContainer,
    section.CASHOUT .cashOutMain .cashOutItem .matchInfoContainer,
    section.INPLAY_ALL .cashOutMain .cashOutItem .matchInfoContainer{
        line-height: 15px;
    }
}

}