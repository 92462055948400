@import './common-mixin.scss';

#info_top_block {
    height: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: var(--box-shadow);
    opacity: 1;
    display: flex;
    flex-direction: column;
}

#info_top_block .tab-pane > div {
    // height: 214px !important;
}
#info_top_block .nav-tabs {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
    border: none;
}

#info_top_block .nav-tabs .nav-item {
    flex: 1;
}

#info_top_block .nav-tabs .nav-link {
    color: #333333;
    background: #f4f4f4;
    height: 49px;
    width: 100%;
    border: none;
    border-radius: 0px;
    padding: 8px;
    position: relative;
    @include H4;
    .HR &:hover{
        background-color: #173e96;
        color: #ffffff;
    }
    .FB &:hover{
        background-color: #00c06c;
        color: #ffffff;
    }
    .LY &:hover{
        background-color: #d62d2d;
        color: #ffffff;
    }
}

.HR #info_top_block .nav-link.active {
    background-color: #173e96;
    color: #ffffff;
    /* outline: solid 1px #173E96; */
    /* z-index: 1; */
}

.FB #info_top_block .nav-link.active {
    background-color: #00c06c;
    color: #ffffff;
    /* outline: solid 1px #00C06C; */
    /* z-index: 1; */
}

.LY #info_top_block .nav-link.active {
    background-color: #d62d2d;
    color: #ffffff;
    /* outline: solid 1px #D62D2D; */
    /* z-index: 1; */
}

.tab-content {
    padding: 8px 0;
    flex: 1;
}

.tab-content > .tab-pane {
    height: 100%;
    color: #333333;
    @include Body();
}

.tab-content > .tab-pane > div > div:first-child > * {
    padding: 0px 9px 0px 12px;
}

.race-btn {
    width: 100px;
    height: 28px;
    // background: #6176a0;
    border-radius: 16.5px;
    border: unset;
    color: white;
    font-size: 13px;
}

// .race-btn:hover {
//     background-color: #173e96;
// }

.ml-6 {
    margin-left: 6px;
}

.ml-12 {
    margin-left: 12px;
}

.mr-12 {
    margin-right: 12px;
}

.with-100 {
    width: 100%;
}

.hide {
    display: none !important;
}

.inline-block {
    display: inline-block;
}

.dropdown.btn-group button {
    @include font13();;
}

.topNav .dropdown.btn-group button {
    white-space: pre-wrap;
    width: auto;
    margin: 0px;
    //padding-top: 3px;
    @include H4();
    text-align: left;
}

// .en .topNav .dropdown.btn-group button {
//     max-width: 210px;
//     // white-space: nowrap;
// }

.topNav .dropdown.btn-group {
    button:hover, .RATdropdown-item:hover {
        color: #122c68;
    }
}
.FB .topNav .dropdown.btn-group {
    button:hover, .RATdropdown-item:hover{
        color: #00c06c;
    }
}
.LY .topNav .dropdown.btn-group {
    button:hover, .RATdropdown-item:hover{
        color: #d62d2d;
    }
}

.dropdown-menu {
    z-index: 9001; //SQ0248-3151
}

.dropdown-menu .dropdown-item {
    color: #333333;
    padding: 10px 12px 11px;
    min-height: 36px;
    white-space: pre-wrap;
    word-wrap: break-word;
    @include H5();
}

.dropdown-item:hover {
    color: #6176a0;
}

.cursor-point {
    cursor: pointer;
}

.dropdown-item:before {
    content: '';
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 10px 10px;
    z-index: 9999;
    right: 10px;
}

.dropup .dropdown-item:before {
    top: unset;
    bottom: -10px;
    border-color: #fff transparent transparent;
    border-width: 10px 10px 0;
}

.rc-jtc-dropdown-menu:before,
.rc-tt-dropdown-menu:before {
    content: '';
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    border-color: transparent transparent #122c68;
    border-style: solid;
    border-width: 0 10px 10px;
    z-index: 9999;
    right: 20px;
}

.rc-jtc-search-item ul li .rc-jtc-dropdown-menu>div:nth-child(2) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.dropdown-item:not(:first-child) {
    border-top: 1px #d2d2d2 solid;
    padding-bottom: 6px;
}

/* .slick-slider.slick-initialized {
    width: 57%;
    height: 36px;
} */

.text-bold {
    font-weight: bold;
}

.modal {
    font-size: 12px;
    display: contents;
}

.modal > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
    height: 55px;
    background-color: #003c84;
    padding-top: 21px;
    color: white;
}

.modal > .content {
    width: 100%;
    padding: 14px 10px;
    background: #dee2e6;
    height: 90vh;
    overflow-y: scroll;
}

.modal > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.modal > .close {
    cursor: pointer;
    position: absolute;
    display: block;
    // padding: 2px 5px;
    line-height: 20px;
    top: 7px;
    left: 0px;
    font-size: 31px;
    background: #003c84;
    border-radius: 0;
    border: none;
    color: #ffffff;
    height: 40px;
    width: 40px;
    padding: 0;
}

.modal > .close > .arrow {
    height: 15px;
    width: 15px;
}

.popup-overlay {
    z-index: 9999 !important;
}

.popup-content {
    margin: auto;
    background: #dee2e6;
    width: 100%;
    height: 100vh;
    padding: 5px;
    border: unset;
    padding: 0px;
}
.popup-content .font-color-color {
    color: #173e96;
    font-weight: bold;
}

.bm-burger-button {
    z-index: 1 !important;
}

.font-color-red {
    color: #cc0000;
}

.font-color-blue {
    color: #173e96;
}

.font-color-black {
    color: #333333;
}

.font-size-15 {
    @include font15;
}

.font-size-18 {
    font-size: 18px;
}

.bold {
    font-weight: bold;
}

.shadow {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 5;
    width: 100%;
    height: 100%;
    display: none;
    text-align: center;
}

.arrow-small {
    border: none !important;
    display: inline-block;
    padding: 3px;
    width: 9px;
    height: 9px;
}

.arrow {
    border: solid rgb(231 231 231);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    width: 12px;
    height: 12px;
}

.arrow-right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.arrow-left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}

.arrow-up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.arrow-down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.border-blue {
    border-color: #003c84;
}

.border-white {
    border-color: #ffffff;
}

.table {
    display: table-row;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.accordion-body {
    margin-left: 25px;
    padding-top: 10px;
}

.marquee-container {
    height: 30px;
}

.menu-show {
    animation: menu-show 0.2s 0.3s ease-in both;
    z-index: 998;
    .en .GL &,
    .ch &{
        animation: menu-show-ch 0.2s 0.3s ease-in both;
    }
}

.menu-hidden {
    animation: menu-hidden 0.2s ease-in both;
    z-index: 998;
    .en .GL &,
    .ch &{
        animation: menu-hidden-ch 0.2s ease-in both;
    }
}

.menu-down {
    animation: menu-down 0.2s 0.3s ease-in both;
    z-index: 998;
}

.menu-up {
    animation: menu-up 0.2s 0.3s ease-in both;
    z-index: 998;
}

@keyframes menu-show {
    from {
        // opacity: 0;
        transform: translate3d(0, 90px, 0);
    }

    to {
        // opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes menu-hidden {
    from {
        // opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        // opacity: 0;
        transform: translate3d(0, 90px, 0);
    }
}

@keyframes menu-show-ch {
    from {
        // opacity: 0;
        transform: translate3d(0, 75px, 0);
    }

    to {
        // opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes menu-hidden-ch {
    from {
        // opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    to {
        // opacity: 0;
        transform: translate3d(0, 75px, 0);
    }
}

@keyframes menu-up {
    from {
        // opacity: 0;
        transform: translate3d(0,calc(100% - 42px),0);
    }

    to {
        // opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes menu-down {
    // from {
    //     // opacity: 1;
    //     transform: translate3d(0, 0, 0);
    // }

    to {
        // opacity: 0;
        transform: translate3d(0,calc(100% - 42px),0);
    }
}

.marquee > div {
    margin-left: 50px;
}

.cb-allup-container input[type='checkbox'] {
    display: none;
}

.cb-allup-container input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    cursor: pointer;
    border: 1px solid #173e96;
    min-width: 25px;
    height: 25px;
    border-radius: 15px;
    font-size: 1rem;
}

.cb-allup-container input[type='checkbox']:checked + span {
    background: #173e96 -19px top no-repeat;
    width: 25px;
    height: 25px;
}

.cb-allup-container input[type='checkbox']:checked + span:before {
    content: ' ';
    border-bottom: 3px solid #ffffff;
    width: 8px;
    transform: rotate(45deg);
    height: 13px;
    border-right: 3px solid #ffffff;
    display: inline-block;
    color: white;
    font-weight: 900;
    font-size: 14px;
    padding: 1px 0px 0px 3px;
    vertical-align: middle;
    text-align: center;
}
.cb-allup-container input[type='checkbox'] + span {
    margin-right: 4px;
    &.spanRadioDim{
        background-color: #e7e7e7;
        border: 1px solid #b4b4b4;
    }
}
.cb-allup-container label {
    @include font13();;
}

.dim {
    opacity: 0.5;
}

.switch-btn-icon {
    width: 21px;
    height: 21px;
    cursor: pointer;
    background-color: #6176a0;
    color: #fff;
    font-size: 20px;
    border-radius: 3px;
    text-align: center;
    position: relative;
    z-index: 3;
}
.close-btn-icon::after {
    content: '';
    position: absolute;
    background-color: #fff;
    left: calc(50% - 6px);
    top: calc(50% - 1px);
    width: 12px;
    height: 2px;
}
.open-btn-icon::after {
    content: '';
    position: absolute;
    background-color: #fff;
    left: calc(50% - 6px);
    top: calc(50% - 1px);
    width: 12px;
    height: 2px;
}
.open-btn-icon::before {
    content: '';
    position: absolute;
    background-color: #fff;
    left: calc(50% - 1px);
    top: calc(50% - 6px);
    width: 2px;
    height: 12px;
}

.form-select:focus {
    border-color: #ced4da;
    box-shadow: unset;
}

.rcOddsTable section .slick-list {
    padding: 0px 8px;
}

.btn-check:focus+.btn, .btn:focus{
    box-shadow: none;
}

button.btn{
    color: #333333;
}

.fontBold {
    font-weight: 500;
}
