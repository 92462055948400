@import '../common-mixin.scss';
.fbOddsTable .allup-cal>a>* {
    margin-right: 17px;
    color: #333333;
    @include H4();
}

.allup-cal-panel-set {
    background-color: #f4f4f4;
    padding: 0px 40px;
    border-radius: 0 0 4px 4px;
    box-shadow: var(--box-shadow);
}

.allup-cal-panel-set:is(.sticky-bottom) {
    border-radius: 10px 10px 0 0 ;
    // position: fixed;
    // bottom: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: unset;
}

.fbOddsTable .allup-cal-content>div:nth-child(2)>div:nth-child(3) {
    color: #405A55;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.fbOddsTable .allup-cal-table .header {
    background-color: #EAEAEA;
    display: grid;
    align-items: end;
    justify-items: center;
    grid-template-columns: 12% 10% 26% 16% 10% 20% 6%;
    padding-bottom: 8px;
    padding-top: 6px;
}

.fbOddsTable .allup-cal-table .header.hasLine {
    grid-template-columns: 16% 8% 18% 6% 16% 10% 20% 6%;
}

.fbOddsTable .allup-cal-table .team,
.fbOddsTable .allup-cal-table .type {
    text-align: left;
    width: 100%;
    padding: 6px;
    justify-content: left !important;
}

.fbOddsTable .allup-cal-table .selection {
    padding: 6px;
    word-break: break-all;
}

.fbOddsTable .allup-cal-table .type {
    word-break: break-word;
}

.fbOddsTable .allup-cal-table .content>div.cal-row {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 12% 10% 26% 16% 10% 20% 6%;
    min-height: 44px;
}

.fbOddsTable .allup-cal-table .content>div.cal-row.hasLine {
    grid-template-columns: 16% 8% 18% 6% 16% 10% 20% 6%;
}

.fbOddsTable .allup-cal-table .content>div.cal-row:nth-child(even) {
    background-color: #F4F4F4;
}

.fbOddsTable .allup-cal-table .content.pc>div.cal-row > div:not(:last-child) {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.fbOddsTable .allup-cal-table .content.pc>div.cal-row {
    & > div.right-border:after,  & > div.right-border-before:before{
        background-image: linear-gradient(to bottom, #F7F7F7 0%, #EBEBEB 55%, #F6F6F6 100%)
    }
}

.fbOddsTable .allup-cal-table .content .odds {
    color: #333333;
    @include H5();
    &.oddsDown{
        color: #E10004;
    }
    &.oddsUp {
        color: #43A53E;
    }
    .oddsArrow{
        margin: 0 0 0 4px;
    }
}

.fbOddsTable .allup-cal-table .content .team {
    color: #405A55;
    word-break: break-word;
    @include H5();
}

.fbOddsTable .allup-cal-table .content .selection{
    text-align: center;
}


.allup-cal-panel-input input[type=text] {
    // padding-left: 8px;
    box-sizing: border-box;
    border: 1px solid #757575;
    outline: none;
    border-radius: 2px;
    width: 120px;
    height: 28px;
    margin: 0px 12px;
    text-align: right;
    padding-right: 8px;
}

.fbOddsTable .allup-cal-panel-input .dropdown-toggle::after {
    display: none;
}

#footbal-allup-detail-cal-panel .dropup .dropdown-toggle::after {
    display: none;
}

.allup-cal-panel-input #ddAllupFormula,
.fbOddsTable .allup-cal-table .dropdown-toggle {
    width: 120px;
    border: 1px solid #757575;
    height: 28px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
    background-color: #FFFFFF;
    margin: 0px 20px;
    padding: 0 8px;
}

.fbOddsTable .allup-cal-table .dropdown-toggle{
    width: 110px;
}

.fbOddsTable .allup-cal-table .dropdown-menu.show{
    inset: 5px 0px auto auto !important;
}

.fbOddsTable .cal-row .dropdown-toggle::after{
    display: none;
}

.fbOddsTable .cal-row .dd-arrow{
    margin-top: -5px;
}

.fbOddsTable .allup-cal-content .dd-arrow {
    margin-top: -5px;
    color: #333333;
}

.fbOddsTable .allup-cal-content .dropdown-menu.show,
.fbOddsTable .mixallup-detail-calculator .dropdown-menu.show {
    inset: 5px 0px auto auto !important;
    min-width: 100%;
}


.fbOddsTable .allup-cal-content .right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.fbOddsTable .allup-cal-content .right .AddToSlip {
    margin-right: 0px;
}

.fbOddsTable .cal-row .oddsCheckbox input[type=checkbox]:checked+span:before {
    margin-top: -8px;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}


.fbOddsTable .allup-cal-content .red {
    color: #E10004;
}

.net-return {
    color: #3FA33A;
}

.fbOddsTable.mobile .allup-cal {
    width: calc(100% - 16px);
    margin: 10px 8px 0px 8px;
    border-radius: 4px 4px 0px 0px;
    // border-bottom: 1px solid #E4E4E4;
    padding-right: 12px;
}
.fbOddsTable.mobile header.INPLAY_ALL .allup-cal {
    margin-bottom: 8px;
}

.fbOddsTable.mobile .allup-cal-content {
    margin: 0px 8px;
}

.fbOddsTable.mobile .allup-cal .expand,
.fbOddsTable.mobile .allup-cal .collaspse {
    background-color: unset;
    border-color: #00C06C;
    margin-right: 12px;
    margin-top: -3px;
    width: 12px;
    height: 12px;
    border-radius: 0;
}

.fbOddsTable.mobile .allup-cal .expand {
    margin-top: 10px;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile {
    margin: 0px 8px;
    background-color: white;
    line-height: 20px;
    border-top: 1px solid rgba(180, 180, 180, .3);
}

.fbOddsTable.mobile .allup-cal-table .dropdown-toggle{
    margin: 0;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div {
    display: grid;
    grid-template-columns: 20% 73% 7%;
    justify-items: flex-start;
    align-content: center;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div .header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    background-color: white;
    line-height: 24px;
    box-shadow: 6px 2px 12px -1px #d7d1d1;
    clip-path: inset(0px -15px 0px 0px);
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div .header>div {
    padding: 0px;
    padding-left: 8px;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div .cal-row {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: calc(100% - 18px);
    background-color: white;
    line-height: 24px;
    margin-left: 18px;
    padding-bottom: 8px;
    padding-top: 6px;
}


.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div .cal-row>div {
    padding: 0px;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div:nth-child(even) {
    background-color: #F4F4F4;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div:nth-child(even)>div {
    background-color: #F4F4F4;
    width: 100%;
}

.fbOddsTable.mobile .allup-cal-table .data-table-mobile .content>div>div:nth-child(3) {
    padding-top: 4px;
}


.fbOddsTable.mobile .allup-cal-content .right {
    justify-content: center;
}

.fbOddsTable.mobile .allup-cal-content>div:first-child {
    grid-template-columns: 100%;
}

.fbOddsTable.mobile .allup-cal-footer {
    border-top: 1px solid rgba(180, 180, 180, .3);
    background-color: white;
    padding: 10px 10px 0px 10px;
    color: #333333;
    margin: 0px 8px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 8px;
}

.fbOddsTable.mobile .cal-row .oddsCheckbox input[type=checkbox]:checked+span:before{
    margin-top: -11px;
}

.fbOddsTable.mobile .allup-cal-table .allupCal-result{
    display: flex;
    align-items: center;
}

.fbOddsTable.mobile .allup-cal-table .allupCal-result>div{
    width: 20px;
    height: 20px;
}

.fbOddsTable.mobile .allup-cal-content>div:first-child>div:nth-child(3)>div>div{
    padding: 12px 10px 0px 0px;
    line-height: 16px;
}