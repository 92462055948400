@media only screen and (max-width: 1279px) {
    .result-lucky-number > div:first-child {
        margin-right: 12px;
    }
    .result-search-box {
        padding: 14px 12px;
    }
    .result-search-box .draw-number-date-content > div:first-child {
        margin-left: 15px;
    }

    .maraksx-results-table {
        .cell-id,
        .cell-date {
            width: auto;
        }
    }

    .maraksx-results-table .table-cell:not(:last-child) {
        position: relative;
        padding: 0 8px;
    }

    .maraksx-results-table .table-cell:first-child {
        position: relative;
        padding: 0 8px 0 12px;
    }

    .amount-display-table {
        gap: 16px;
    }

    .ball-panel-table>div>div>div {
        margin: 6px;
    }

    .ball-panel-table .table-cell .table-cell-ball {
        width: 61px;
        height: 61px;
    }
    .ball-panel-table .table-cell {
        vertical-align: middle;
        text-align: center;
        width: 61px;
        height: 61px;
    }


    .statisticsPanel .chooseModelBtn div {
        width: 72px;
    }
}
