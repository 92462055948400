@mixin v-center {
    display: flex;
    align-items: center;
}

@mixin h-center {
    display: flex;
    justify-content: center;
}

@mixin H2 {
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    @media only screen and (min-width: 1680px) {
        font-size: 17px;
        line-height: 17px;
    }
}

@mixin H4 {
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 15px;
    }
}

@mixin fbTeamNameFont{
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 16px;
    }
}

@mixin H5 {
    font-size: 13px;
    line-height: 14px;
    font-weight: 400;

    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 15px;
    }
}

@mixin H6 {
    font-size: 11px;
    line-height: 12px;
    font-weight: 400;
    @media only screen and (min-width: 1680px) {
        font-size: 13px;
        line-height: 13px;
    }
}

@mixin Body-Bold {
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 19px;
    }
}

@mixin Body {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 19px;
    }
}

@mixin small-text {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
        line-height: 19px;
    }
}

@mixin font9 {
    font-size: 9px;
    @media only screen and (min-width: 1680px) {
        font-size: 11px;
    }
}
@mixin font10 {
    font-size: 10px;
    @media only screen and (min-width: 1680px) {
        font-size: 12px;
    }
}
@mixin font11 {
    font-size: 11px;
    @media only screen and (min-width: 1680px) {
        font-size: 13px;
    }
}
@mixin font11Body {
    font-size: 11px;
    line-height: 15px;
    @media only screen and (min-width: 1680px) {
        font-size: 13px;
        line-height: 16px;
    }
}

@mixin font12 {
    font-size: 12px;
    @media only screen and (min-width: 1680px) {
        font-size: 14px;
    }
}
@mixin font13 {
    font-size: 13px;
    @media only screen and (min-width: 1680px) {
        font-size: 15px;
    }
}
@mixin font15 {
    font-size: 15px;
    @media only screen and (min-width: 1680px) {
        font-size: 17px;
    }
}
@mixin font16 {
    font-size: 16px;
    @media only screen and (min-width: 1680px) {
        font-size: 18px;
    }
}
@mixin font18 {
    font-size: 18px;
    @media only screen and (min-width: 1680px) {
        font-size: 20px;
    }
}
@mixin font20 {
    font-size: 20px;
    @media only screen and (min-width: 1680px) {
        font-size: 20px;
    }
}

@mixin lineHeight14 {
    line-height: 14px;
    @media only screen and (min-width: 1680px) {
        line-height: 15px;
    }
}
@mixin lineHeight15 {
    line-height: 15px;
    @media only screen and (min-width: 1680px) {
        line-height: 16px;
    }
}
@mixin lineHeight16 {
    line-height: 16px;
    @media only screen and (min-width: 1680px) {
        line-height: 17px;
    }
}
@mixin lineHeight18 {
    line-height: 18px;
    @media only screen and (min-width: 1680px) {
        line-height: 19px;
    }
}

@mixin width1px {
    width: 1px;
}
@mixin height1px {
    height: 1px;
}
@mixin border1px {
    border-width: 1px;
}

@mixin textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin setIconSize($size) {
    img {
        width: #{$size}px;
        height: #{$size}px;
    }
}

@mixin fontWeight500 {
    font-weight: 500;
}
