@media only screen and (max-width: 768px) and (pointer: coarse) {
  .FTInbox {
    width: 100%;
    height: 100vh;
    height: 100dvh;
    min-width: auto;
    .FTInboxTitle{
      justify-content: center;
      .controlContainer{
        display: none;
      }
    }
    .FTInboxHelp{
      display: none;
    }

    .FTInboxHeader{
      display: flex;
    }
    .FTInboxHeaderCell{
      height: 54px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      text-align: center;
    }
    .FTInboxHeaderCellInner{
      white-space: normal;
    }
    .firstCell{
      padding-left: 0;
    }
    .FTMain{
      height: calc(100vh - 152px);
      height: calc(100dvh - 152px);
      overflow-y: auto;
      &>div{
        padding:12px 0 24px 0;
        min-height: calc(100vh - 152px);
        min-height: calc(100dvh - 152px);
      }
      .FT-form-content{
        margin:0 8px 12px 8px;
        .formItem{
          display: block;
          .formItemLabel{
            margin-bottom: 8px;
            width: auto;
          }
          .formItemContent{
            width: 100%;
            .inputContainer{
              .HKJCAmountInputContainer{
                width: 98%;
              }
            }
          }
        }
      }
      .bannerBox{
        width: calc(100% - 16px) !important;
        margin: 0 8px;
        margin-bottom: 24px;
        img {
            max-width: 100%;
        }
      }
      .withdrawalBegins-container{
        padding-bottom: 12px;
        .footerBox{
          justify-content: center;
          .footerButton{
            width: 200px;
          }
        }
      }
      .deposit-prev {
        .deposit-prev-title{
          margin-left: 20px ;
        }
      }

    }
    .FTMain-s{
      height: calc(100vh - 152px - 74px);
      height: calc(100dvh - 152px - 74px);
    }
    .deposit-btnArea{
      justify-content: center;

    }
    .deposit-btn{
      min-width: 200px;
      margin-right: 0px;
    }
    .prev-btnArea{
      justify-content: flex-end;
      .deposit-btn{
        min-width: 100px;
        margin-right: 12px;
      }
    }
    .msgBtn{
      min-width: 100px;
      margin: 0 12px;
    }
    .bankInfo,.formItemContent{
      width: auto;
      .itemMult .bankInfo{
        padding: 0 !important;
        .contentBottom{
          padding: 0;
        }
      }
      .epscoPinContainer{
        width: 260px;
        margin: 0 auto;
        padding: 15px 0;
        font-weight: normal;
        .epscoPinTop{
          display: block;
          // justify-content: space-between;
          // align-items: center;
          margin-bottom: 15px;
          .epscoPinInput{
            display: block;
            width: 260px;
            &>div {
              white-space: normal;
              margin-bottom: 10px;
            }
            input {
              width: 100%;
              height: 32px;
              margin-bottom: 10px;
            }
          }
        }
        .epscoPinBottom{
          display: block;
          .pinList{
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
            .epscoPinNumberContainer{
              width: 56px;
              margin-bottom: 12px;
              .epscoPinNumber{
                width: 36px;
                height: 36px;

              }
              &:nth-child(5n){
                width: 36px;
              }
            }
          }
        }

        .deposit-btn{
          margin: 0;
          width: 84px;
        }
      }
    }


    .acInfo{
      height: auto;
      display: flex;
      .acInfoCell{
        margin-top: 8px;
        .labelText,span{
          padding-left: 7px;
          padding-right: 0;
        }
      }
      .borderLLine{
        height: auto;
      }
    }

    .link-row {
      display: unset;
    }
    .link-l {
      width:100%;
    }

    .link-r {
      width:100%;
      margin-right: 0;
      &>div{
        .link-type-fps:hover {
            box-shadow: unset;
          }
        .link-type-fps, .link-type-eps{
          width: 48%;
          max-width: 182px;
        }
      }
    }
    .link-reject {
      width: 100%;
    }
    .link-otp-title {
      padding: 0px 10% 0px 10%;
    }
    .link-otp-cc {
      padding: 0px 10% 0px 10%;
    }

    .link-otp-code {
      padding: 0px 10% 0px 10%;
    }
    .link-otp-resend-msg {
      top:100px;
    }
    .link-summary{
      margin-top:5px;
    }
     .link-bank-row-l {
      width: 30%;
    }

    .link-bank-row-r {
      width:70%;
    }
    .fps-charge-tips{
      margin: 12px 8px;
      padding: 12px;
      border-radius: 4px;
    }
    .gray-area-message{
      margin:0 8px;
      padding: 0 4px;
    }
    .link-type-text-status {
      white-space: nowrap;
      font-size: 0.9em;
    }
  }
}