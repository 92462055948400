@import '../../common-mixin.scss';

.fbOddsTable section.HFT>.header-wrapper>.header,
.fbOddsTable section.HFT .match-row {
    grid-template-columns: [first] 128px [frontEndId] 70px [tourn] 40px 1fr 57% [h2h] 48px [end];
    // align-items: center;
}
.fbOddsTable section.HFT>.header-wrapper>.header{
    align-items: flex-end;
}

.fbOddsTable section.HFT .header .odds>div>div {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    width: 100%;
    justify-items: center;
}

.fbOddsTable section.HFT .match-row .odds{
    grid-template-columns: repeat(9, 1fr);
    display: grid;
    justify-items: center;
    padding: 14px 12px;
    flex-wrap: wrap;
    height: auto;
}

.fbOddsTable section.INPLAY_ALL .team.HFT,
.fbOddsTable section.ALLODDS .team.HFT,
.fbOddsTable section.LAST_ODDS .team.HFT {
    display: none !important;
}

.fbOddsTable .INPLAY_ALL .oddsHFT,
.fbOddsTable .ALLODDS .oddsHFT,
.fbOddsTable .LAST_ODDS .oddsHFT {
    display: grid;
    grid-template-columns: repeat(9, 11%);
    margin-left: 6px;
    gap: 0px;
    width: 100%;
    justify-content: space-evenly;
}

.fbOddsTable .INPLAY_ALL .odds.oddsHFT>div,
.fbOddsTable .ALLODDS .odds.oddsHFT>div,
.fbOddsTable .LAST_ODDS .odds.oddsHFT>div {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding: 8px 0px;
    align-items: center;
}


.fbOddsTable .INPLAY_ALL .oddsHFT .oddsCheckbox,
.fbOddsTable .ALLODDS .oddsHFT .oddsCheckbox,
.fbOddsTable .LAST_ODDS .oddsHFT .oddsCheckbox {
    text-align: center;
}

.fbOddsTable section.HFT>.header-wrapper>.header>.odds>div:last-child {
    display: none;
}

.fbOddsTable section.HFT .odds>div {
    display: block;
    width: auto;
    /* flex: auto; */
    min-width: 36px;
}

.fbOddsTable section.HFT .odds>div>div:first-child{
    min-width: 40px;
    height: 14px;
    line-height: 14px;
    text-align: center;
    @include font11();
    color: #333;
    font-weight: 300;
}

.fbOddsTable section.HFT .odds>div>div:last-child{
    margin-top: 6px;
    text-align: center;
    flex-direction: column;
    display: flex;
}

.fbOddsTable section.HFT .match-row-m .odds>div>div:first-child{
    border-bottom: unset;
}



.fbOddsTable section.HFT .match-row-container .match-row-m .more{
    grid-column: 1 / span 3;
    border: 1px #eee solid;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    border-right: none;
    border-left: none;
}

.fbOddsTable section.HFT .match-row-container .match-row-m .more .arrow-small{
    margin: -6px 0px 0px 12px;
    border-color: #00C06C;
    width: 9px;
    height: 9px;
    border-width: 0 2px 2px 0;
}

.fbOddsTable section.HFT .match-row-container .match-row-m .more .arrow-up{
    margin: 0px 0px -6px 12px;
}

// .fbOddsTable section.HFT .match-row-container .match-row-m{
//     grid-template-columns: 43% 47% auto;
//     align-items: flex-start;
// }



.fbOddsTable section.HFT .match-row-m .image-H2H{
    margin-top: 10px;
}

.fbOddsTable section.HFT .match-row-m .odds>div>div:last-child{
    margin-top: 0px;
}


.fbOddsTable section.HFT .oddsCheckbox input[type=checkbox] + span {
    margin-right: 0px;
}