@import '../common-mixin.scss';
@media screen and (max-width: 1023px) and (pointer: coarse){
.FTCalculator{
    align-items: baseline;
    min-width: unset;
    min-height: unset;
    width: 100vw;
    height: 100vh;
}

.FTCalculator .ftCalcTitle{
    border-radius: 0;
    height: 52px;
    position: relative;
    font-size: 18px;
    text-align: center;
    line-height: 52px;
    font-weight: 700;
    // width: 100vw;
}
.FTCalculator .ftCalcTitle>div:first-child{
    width: 100%;
}

.FTCalculator .ftCalcTitle .ftcalc-arrow-left{
    display: block;
    position: absolute;
    left: 6px;
    top: calc(50% - 15px);
}
.FTCalculator .ftCalcTitle .allUpComb{
    display: none;
}
.FTCalculator .dfFe{
    padding: 6px 12px 16px;
}
.FTCalculator .ftCalcTitle .dfFe{
    display: none;
}
.FTCalculator .ftCalcBtn{
    width: 48px;
    background-color: #569171;
}
.FTCalculator .ftCalcBetComb{
    flex-direction: column;
    height: auto;
    align-items: baseline;
    padding-bottom: 22px;
    gap: 12px;
    margin: 0 11%;
}
.FTCalculator .ftCalcBetComb .dfr{
    margin-top: unset;
    &:first-child{
        .dropdown{
            width: 100%;
        }
    }
}
.FTCalculator .ftCalcBetComb .dfc{
    word-wrap: break-word;
    word-break: break-word;
}
.ftCalcBetComb .dropdown{
    width: 100%;
}
.FTCalculator #ftBetTypeText,
.FTCalculator #formulaText,
.FTCalculator #unitbetrange{
    padding: 0 0 0 8px;
}
.FTCalculator .ftCalcSelection .ftCalTable{
    display: grid;
    grid-template-columns:  repeat(3, 88px);
    /* width: calc(100%); */
    overflow-x: auto;
    overflow-y: hidden;
}
.ftCalcSelection .ftCalColFirst:before {
    content : "";
    position: absolute;
    top    : 0;
    right  : 0;
    height  : 100%;
    width   : 1px;
    border-right:1px solid #B4B4B4;
    box-shadow: 2px 0px 7px;
}
.FTCalculator .ftCalcSelection .lastDDSelect .dropdown-menu{
    max-height: 116px;
    overflow-y: unset;
    inset: auto auto 0px 0px !important;
    box-shadow: 0 0 18px 3px rgba(0, 0, 0, 0.3607843137);
    .dropdown-item:before{
        content: unset;
    }
}

.FTCalculator .ftCalcSelection .ftCalMTable{
    width: 100%;
    .ftCalMTableHeader,
    .ftCalMTableRow{
        display: grid;
        grid-template-columns:  24% 28% 20% 28%;
        /* width: calc(100%); */
        // overflow-x: auto;
        // overflow-y: hidden;
        &>div{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 0;
        }
        .ftCalNum{
            @include H4();
        }
        .ftCalBetNo,
        .ftCalFormula{
            @include H5();
        }
    }
    .ftCalMTableHeader{
        height: 28px;
        .ftCalBetNo{
            font-size: 11px;
        }
    }
}
.FTCalculator .ftCalcBotArea .dfc{
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    line-height: 20px;
}
.FTCalculator .ftCalcBotArea .dfc .text{
    padding-left: 0px;
}
}