@media screen and (max-width: 1279px) {
    .main-content {
        .rcOddsTable {
            .horse-racing-race-card {
                .rc-odds-table {
                    .jocky {
                        min-width: 70px;
                        max-width: 70px;
                    }
                    .trainer {
                        min-width: 70px;
                        max-width: 70px;
                    }
                    .horseName {
                        min-width: 70px !important;
                        max-width: 70px !important;
                        padding: 0 5px;
                        width: auto;
                        word-wrap: break-word;
                    }
                    .last6, .rc-last6 {
                        min-width: 70px !important;
                        max-width: 115px !important;
                        width: 80px;
                    }
                    .rc-odds-table-header{
                        div{
                            padding: 0 !important;
                        }
                        .horseName{
                            padding: 0 5px !important;
                        }
                    }
                }
            }
            .meeting-button .slider-container {
                // width: 100%;
            }
        }

        .OddsDropLegend-Content-ge1280 {
            display: none;
        }

        .OddsDropLegend-Content-lt1280 {
            display: block;
        }


        .rc-odds-enquiry .content {
            grid-template-columns: 50% 50%;
            row-gap: 12px;
            // justify-content: center;
            align-items: center;
        }

        .rc-odds-enquiry .content>div:not(:last-child) {
            border-right: none;
        }

        .rc-odds-enquiry .content>div:first-child {
            border-right: 1px solid #ededed;
        }

        .rc-odds-enquiry .content>div:last-child {
            grid-column: 1 / 3 ;
            display: flex;
            justify-content: center;
            .race-btn {
                width: 50%;
            }
        }

        .multi-leg-3x-table {
            grid-template-columns: minmax(50px, 1fr) minmax(50px, 1fr);
        }

        .rc-odds-row >td {
            padding: 0 2px;
        }
        .TT .radio-row .ttqp-btn{
            margin-left: 0;
        }
    }
}
