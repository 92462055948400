@import '../common-mixin.scss';
.FTCalculator {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 828px;
    min-height: 296px;
    cursor: default;
}
.FTCalculator .ftCalcContainer {
    width: 828px;
    min-height: 296px;
    background-color: #FFF;
}
.FTCalculator .ftCalcTitle{
    color: #ffffff;
    background: #405A55;
    height: 40px;
    line-height: 40px;
    @include font15();
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
}
.FTCalculator .ftCalcTitle .ftcalc-arrow-left{
    display: none;
}
.FTCalculator .allUpComb{
    @include font11();
    font-weight: normal;
    cursor: pointer;
}
.FTCalculator .dfFe{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
}
.FTCalculator .ftCalcBtn{
    height: 28px;
    border-radius: 20px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ftCalcIcon{
        width: 24px;
        height: 24px;
        background-image: url(../../info/Football/info/images/ftCombIcon.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
}
.ftCalcBetComb{
    height: 68px;
    display: flex;
    align-items: center;
    gap: 18px;
    margin: 0 20px;
    .dfr{
        width: 100%;
        display: flex;
        flex: 1 1;
        justify-content: space-between;
        align-items: center;
    }
    .dfc{
        @include font13();
        margin: 0 0 0 auto;
        color: #405A55;
    }
    .dropdown{
        width: 190px;
    }
    .dfr:first-child{
        .dropdown{
            width: 370px;
        }
    }
}
.FTCalculator .dropdown-toggle{
    width: 150px;
}
.FTCalculator .dropdown-toggle .dd-arrow{
    align-self: center;
    margin-top: -5px;
    border-color: #333;
}
.FTCalculator .dropdown-menu {
    min-width: 60px;
    max-height: 160px;
    overflow-y: auto;
    width: 100%;
}
.FTCalculator .dropdown-menu.show {
    inset: -2px auto auto auto !important;
}
.FTCalculator .dropdown-toggle::after{
  content: none;
}
.FTCalculator #ftBetTypeText,
.FTCalculator #formulaText,
.FTCalculator #legSelection,
.FTCalculator #unitbetrange{
    width: 60px;
    height: 28px;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    box-shadow: none;
    align-items: center;
    padding: 0 12px;
    background-color: #fff;
    border: 1px solid #666;
    color: #333;
    @include H4();
    @include font13();
    padding: 0 0 0 8px;
}
.FTCalculator .dropdown-item.active {
    background-color: #00C06C;
    color: #ffffff;
}
.FTCalculator #unitbetrange,
.FTCalculator #ftBetTypeText,
.FTCalculator #formulaText{
    width: 100%;
    justify-content: flex-end;
}
.ftCalcSelection{
    // height: 137px;
    position: relative;
    display: flex;
}
.ftCalcSelection .ftCalColBG{
    flex-grow: 1;
    // height: 137px;
}
.ftCalcSelection  .bgColor{
    background-color: #F4F4F4;
}
.ftCalcSelection .dfcc{
    display: flex;
    align-items: center;
    justify-content: center;
}


.ftCalcSelection .ftCalCol{
    width: 88px;
}
.ftCalcSelection .formulaRow{
    height: 32px;
    // background-color: #ffffff;
}
.ftCalcSelection .betSelectRow{
    height: 60px;
}
.ftCalcSelection .betSelectRow1{
    height: 44px;
}
.ftCalcSelection .ftCalTable{
    display: grid;
    grid-template-columns: 112px repeat(2, 88px);
    /* width: calc(100%); */
    background-color: transparent;
}

.ftCalcSelection .ftCalColFirst{
    position: sticky;
    left: 0;
    z-index: 10;

}
.ftCalcSelection .ftCalColEnd{
    flex-grow: 1;
}
.ftCalcSelection .w88{
    width: 88px;
}
.ftCalcSelection  .dfc{
    display: flex;
    align-items: center;
    padding-left: 24px;
    @include font13();
    color: #405A55;
}
.ftCalcBotArea{
    display: flex;
    font-weight: bold;
    height: 74px;
    @include font13();
    border-top: 4px solid #E7E7E7;
}
.ftCalcBotArea .dfc{
    width: 50%;
    padding-left: 24px;
    display: flex;
    align-items: center;
    position: relative;
    &:first-child{
        &::after{
            content: "";
            width: 1px;
            height: 70%;
            background: #c4c4c4;
            position: absolute;
            right: 0;
            top: 15%;
        }
    }
}
.ftCalcBotArea .text{
    padding-left: 10px;
    @include font15();
}