@import '../../common-mixin.scss';

.FB header.CASHOUT {
    display: none;
}
.fbOddsTable>section.CASHOUT{
    margin: 0;
}
.fb-cashout-header {
    display: none;
}
section.CASHOUT, .FB .fbOddsTable , section.INPLAY_ALL {
    position: relative;
}
section.CASHOUT, section.ALLODDS, section.INPLAY_ALL {
    width: 100%;
    cursor: default;
    .fbTitle {
        padding-right: 10px;
    }
    .fbTitle #mtgInfoDV .form-check{
        font-size: 12px;
    }
    .fbTitle #mtgInfoDV .form-check span{
        padding-right: 4px;
    }
    .cashOutMsgMain{
        width: 100%;
        position: fixed;
        top:  3%;
        left: 0;
        right: 0;
        height: auto;
        margin: 0 auto;
        z-index: 10;
        pointer-events: none;
    }
    .cashOutResultMeassage{
        display: flex;
        color: #FFF;
        align-items: center;
        padding: 0 15px;
        height: 35px;
        border-radius: 2px;
        margin-top: 20px;
        animation: msgAction 3s ease-out ;
        &.successful{
            background-color: #3FA33A;
        }
        &.rejected, &.value_rejected, &.jsEarlySettlementRejected1, &.jsEarlySettlementRejected2, &.jsEarlySettlementRejected3, &.jsEarlySettlementRejected4, &.jsEarlySettlementRejected6 {
            background-color: #E10004;
        }
        span{
            margin-left:  15px;
        }
    }
    @keyframes msgAction {
        0%,80%{opacity: 1;height: 35px;}
        100%{opacity: 0.4;height: 0px;}
    }
    .icon-status-accepted{
        background-image: url(../../../info/Include/images/status-success.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
    }
    .icon-status-rejected{
        background-image: url(../../../info/Include/images/status-failed.svg);
        background-repeat: no-repeat;
        background-size: cover;
        width: 20px;
        height: 20px;
    }
    .cashOutMsgIcon{
        animation: msgIconAction 3s ease-out ;
        flex-shrink: 0;
    }
    @keyframes msgIconAction {
        0%,80%{opacity: 1;height: 20px;}
        100%{opacity: 0.4;height: 0px;}
    }
    .cashoutTabs{
        position: relative;
        overflow-x: auto;
        .cashoutTabsContainer{
            display: flex;
            .cashoutTab{
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                cursor: pointer;
                font-size: 13px;
                font-weight: 500;
                min-width: 86px;
            }
            .cashoutTab.cashoutTabActive{
                color: #405A55;
            }
        }
        .tabSlider{
            width: 25%;
            position: absolute;
            bottom: 0;
            left: 0;
            height: 4px;
            background: #FECF13;
            transition: left 0.2s ease-out ;
            min-width: 86px;
        }
    }
    .cashOutImportantNotice{
        display: flex;
        height: 52px;
        justify-content: space-between;
        align-items: center;
        padding:  0 12px;
        background: #FFF7D5;
        box-shadow: var(--box-shadow);
        .importantNoticeLeft{
            font-size: 11px;
            font-weight: 400;
        }
        .importantNoticeTitle{
            font-weight: 700;
        }
        .importantNoticeLink{
            color: #405a55;
            cursor: pointer;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
            }
        }
        .cashOutAllButton{
            width: 120px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            background: #FECF13;
            font-weight: 500;
            cursor: pointer;
            &:hover{
                background: #E4B910;
            }
        }
    }
    .cashOutMain{
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 12px;
        .cashOutItem{
            background: #fff;
            width: 100%;
            display: flex;
            .ESLoadMoreButton{
                color: #00C06C;
                font-size: 13px;
                font-weight: 500;
                cursor: pointer;
            }
            .dicashOutItemvidendInfoContainer{
                padding-bottom: 8px;
            }
            .cashOutButtonContainer{
                padding: 0;
            }
            .cashOutItemHeader{
                line-height: 18px;
                color: #333333;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                flex: 0 0 auto;
                width: 55px;
                padding: 0 12px;
                word-break: break-word;
                @media only screen and (min-width: 1680px){
                    width: 60px;
                }
            }
            .matchInfoContainer{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex: 1 1 auto;
                width: 40%;
                padding: 8px 12px;
                line-height: 22px;
                .requestInfomation,.matchName{
                    font-size: 11px;
                    // height: 20px;
                    display: flex;
                    align-items: center;
                    &>span{
                        margin-left: 8px;
                    }
                }
                .matchInfo{
                    border-top: 1px solid #e7e7e7;
                }
                .matchInfo-m{
                    border-bottom: 1px solid #e7e7e7;
                    width: 100%;
                    height: 124px;
                    .matchInfoBottom{
                        border: 0;
                    }
                }
                .matchInfo:first-child{
                    border-top: 0;
                }
                .es-swiper-container{
                    width: 100%;

                    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
                        bottom: 4px;
                        left: 0;
                        width: 100%;
                    }
                }
            }
            .betslipInfo{
                width: 50%;
                padding: 8px 12px;
                flex: 1 1 auto;
            }

            .matchInfoMid{
                justify-content: space-between;
                align-items: center;
                // height: 44px;

                .matchInfoMid-team{
                    font-size: 15px;
                    font-weight: 500;
                    color: #405A55;
                    line-height: 16px;
                    min-width: 130px;
                    max-width: 260px;
                    text-align: left;
                    &>:first-child{
                        margin-bottom: 4px;
                    }
                }
                .matchInfoMid-d{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .matchInfoMidScore{
                    display: flex;
                    position: relative;
                    margin-right: 10px;
                    gap: 6px;
                    .scoreInfo{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .exepcted-stop-time {
                        @include font11();
                        display: flex;
                        justify-content: center;
                        text-align: center;
                    }
                }
                .matchInfoMid-score{
                    width: 18px;
                    background: #FFD505;
                    border-radius: 2px;
                    &>div{
                        font-size: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    &>div:first-child{
                        height: 18px;
                        position: relative;
                        &::after {
                            display: block;
                            content: "";
                            width: 18px;
                            height: 1px;
                            background: #9CB820;
                            position: absolute;
                            bottom: 0;
                            transform: scaleY(0.5);
                        }
                    }
                    &>div:last-child{
                        height: 18px;
                    }
                    &.et {
                        background-color: #00c06c;
                        color: #ffffff;
                        &>div:first-child{
                            &::after {
                                background: #ffffff;
                            }
                        }
                    }
                }
                .matchInfoMid-more{
                    margin-right: 2px;
                    cursor: pointer;
                }
            }
            .matchInfoBottom{
                font-size: 11px;
                margin-top: 8px;
                &>div{
                    display: flex;
                    align-items: center;
                    & .icon_clock_image {
                        height: 12px;
                        width: 12px;
                        background-size: 12px;
                        display: block;
                    }
                    &>*{
                    margin-left: 11px;
                    }
                }
                .matchInfoBottom-corner{
                    display: flex;
                    &>span{
                        // margin-top: -9px; SQ0248-1998
                        font-weight: 500;
                    }
                }
                .red {
                    color: #D62D2D;
                    font-family: "NotoSansCJKtc-Bold";
                    font-size: 11px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: 0px;
                    text-align: left;
                    line-height: 13px;
                }

                .matchCompletedContainer{
                    display: flex;
                    justify-content: space-between;
                    &>*{
                        margin-left: 0px;
                    }
                    &>.et{
                        display: flex;
                        justify-content: space-between;
                        &>*{
                            margin-left: 0px;
                            margin-right: 7px;
                        }
                        &>div .corner-ET{
                            color: #00c06c;
                        }
                    }
                    .match-completed {
                        opacity: 1;
                        color: rgba(51,51,51,1);
                        font-family: "NotoSansCJKtc-Bold";
                        font-size: 11px;
                        font-weight: 700;
                        font-style: normal;
                        letter-spacing: 0px;
                        text-align: left;
                        line-height: 14px;
                    }
                }

            }
            .dividendInfoContainer{
                .betTypeInfo{
                    word-break: break-word;
                }
                .betType{
                    width: 156px;
                    flex: 1 1 auto;
                    padding-bottom: 7px;
                    .matchName, .matchVS{
                        font-size: 11px;
                        font-weight: 500;
                    }
                    .matchInfoMid-team{
                        font-size: 13px;
                        font-weight: 700;
                        padding: 2px 0;
                    }
                }
                .esSingleMatchBet{
                    color: #333333;
                    font-size: 11px;
                    font-weight: 400;
                }
                .dividendInfo-betType{
                    // color: #405A55;
                    font-size: 11px;
                    margin-bottom: 4px;
                    font-weight: 500;

                }
                .dividendInfo-betComb{
                    font-size: 13px;
                    font-weight: 700;
                }
                .unitBet{
                    width: 156px;
                    flex: 1 1 auto;
                    margin-left: 12px;
                    &>:nth-child(odd){
                        font-size: 11px;
                        margin-bottom: 4px;
                    }
                    &>:nth-child(even){
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 8px;
                    }
                }
                .unitBet>:last-child{
                    margin-bottom: 0;
                }
                .esDimmed{
                    color: #cccccc;
                }
            }
            .dividendInfoContainer-d{
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
            }
            .betDivInfo{
                display: flex;
                justify-content: space-between;
                flex: 1 1 auto;
                width: 200px;
            }
            &:nth-child(even) {
                background-color: #f4f4f4;
            }
        }
        .cashOutbegins {
            .resultValue-d{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px 0 0;
                border-top: 1px solid #e7e7e7;
                &>:last-child{
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
        .right-border{
            &::after{
                height: calc(100% - 16px);
                top: 8px;
            }
        }
        .cashOutItem-bigH{
            // height: 154px;
            .betslipInfo{
                padding-top: 19px;
                padding-bottom: 18px;
            }
            .right-border{
                &::after{
                    height: calc(100% - 26px);
                    top: 13px;
                }
            }
        }

        .matchInfoContainer-d{
            position: relative;
            .requestInfomation{
                // position: absolute;
                // top: 10px;
                // left: 10px;
                width: 85%;
                line-height: 12px;
                .icon-s{
                    flex-shrink: 0;
                }
                span{
                    flex-shrink: 1;
                }
            }
        }

        .cashOutPreview{
            padding: 0 0px;
            &.suspended{
                flex-direction: column;
            }
            &> div{
                display: flex;
            }
            .cashOutPreviewBtnArea{

                justify-content: center;
            }
            .cashOutPreviewBtn{
                display: flex;
                width: 332px;
                &>:first-child{
                    width: 108px;
                    margin: 0px 24px 0px 0;
                    color: #FFF;
                    background: #569171 !important;
                    cursor: pointer;
                }
                &>:last-child{
                    width: 200px;
                    margin: 0px 0;
                }
            }

            .loadingBtn{
                position: relative;
                overflow: hidden;
                font-size: 11px;
                font-weight: 400;
                text-align: center;
                .loadingBtnMask{
                    position: absolute;
                    width: 0;
                    left: 0;
                    top: 0;
                    height: 32px;
                    background-color: rgba($color: #999, $alpha: .5) !important;
                    margin: 0 ;
                }
            }
            .cashOutPreviewBtnArea .cashOutButton{
                font-size: 13px ;
                font-weight: 400 ;
            }
        }
        .cashOutValue{
            line-height: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0;
            border-top: 1px solid #e7e7e7;
            &>:first-child{
                font-size: 11px;
            }
            &>:last-child{
                font-weight: 500;
                font-size: 18px;
                color: #405A55;
            }
        }
        .cashOutSuspended{
            line-height: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 0;
            color:  #E10004;
            font-size: 13px;
        }
        .cashOutButton{
            width: 332px;
            margin: 0px auto 0px;
            height: 32px;
            background: #FECF13;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
            &:hover{
                opacity: 0.8;
            }
        }
        .cashOutButtonResult-d{
            margin: 0 auto;
        }
        .requestInfomation{
            display: flex;
            align-items: center;
            margin: 0px 0 8px;
            .successfulText{
                color: #3FA33A;
                font-size: 11px;
                margin-left: 5px;
            }
            .rejectColor{
                color: #E10004;
            }
        }
    }
    .cashOutSettledTable{
        width: 100%;
        display: table;
        &>:nth-child(2) {
            &>:first-child{
                border-top-left-radius: 4px;
            }
            &>:last-child{
                border-top-right-radius: 4px;
            }
        }
        &>:last-child {
            &>:first-child{
                border-bottom-left-radius: 4px;
            }
            &>:last-child{
                border-bottom-right-radius: 4px;
            }
        }
        .table-header{
            min-height: 36px;
            height: 36px;
            &>div{
                padding:0 12px;
            }
            .table-cell{
                background-color:  #e1e1e1;
                vertical-align: middle;
            }
        }
        .table-body{
            background: #FFF;
            height: 72px;
            &>div{
                padding:8px 12px;
            }
            &:nth-child(odd) {
                background-color: #f4f4f4;
            }
        }

        .text-center{
            text-align: center;
        }
        .table-cell-time{
            width: 92px;
        }
        .table-cell-details{
            display: flex;
            flex-direction: column;
            .bet-details{
                display: flex;
                flex-direction: column;
                padding: 3px 0;
                word-break: break-word;
            }
        }
        .ESLoadMoreButton{
            color: #00C06C;
            font-size: 13px;
            font-weight: 500;
            cursor: pointer;
        }
    }
    .CashOutNoData{
        width: 100%;
        // height: 100px;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 12px;
        padding: 18px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        color: #333333;
        .CashOutNoDataBtn{
            width: 200px;
            height: 32px;
            background-color:  #FECF13;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 16px;
            cursor: pointer;
            font-weight: 500;
            &:hover{
                opacity: 0.8;
            }
        }
        .CashOutNoDataMsg{
            padding-bottom: 18px;
            text-align: center;
            .CashOutNoDataText{
                padding-top: 8px;
            }
        }
    }
    .CashOutLogoutState{
        height: 230px;
        margin-top: 0px;
        padding: 60px 0 74px;
        .icon-status-logout{
            background-image: url(../../../info/Include/images/cash_out.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 70px;
            height: 48px;
        }

    }
    .CashoutDisabled{
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: pre;
        text-align: center;
    }
    .dimBg{
        background-color: #E7E7E7 !important;
        color: #333333 !important;
        &:hover{
            opacity: 1 !important;
            cursor: default;
        }
    }
    .fontS13{
        font-size: 13px !important;
    }
    .opacity30{
        opacity: .3;
    }

}
div.cashout-reminder-content {
    height: 160px !important;
    border-radius: 10px;
}
.cashout-reminder-container{
    padding: 24px;
    font-size: 13px;
    .cashout-reminder-message{
        height: 80px;
        display: flex;
        align-items: center;
    }
    .button-box{
        height: 32px;
        display: flex;
        justify-content: center;
        .cancelBtn{
            width: 84px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #569171;
            color: #fff;
            margin-right: 24px;
            border-radius: 16px;
            cursor: pointer;
        }
        .confirmBtn{
            background: #FECF13;
            color: #122C68;
            margin-right: 0;
        }
    }
}

.cashOutAllOdds {
    background-color: #E7E7E7;
    padding:12px 0px;
}

.cashOutHead>a>* {
    margin-right: 17px;
    color: #333333;
}

.textAlignR{
    text-align: right;
}


section.ALLODDS, section.INPLAY_ALL {
    .cashOutMain{
        margin-top: 0px;
        border-radius: 0 0 4px 4px;
        .cashOutItem{
            .cashOutItemHeader{
                width: 55px;
                @media only screen and (min-width: 1680px){
                    width: 60px;
                }
            }
            .matchInfoContainer{
                line-height: unset;
                width: calc(100% - 353px);
                @media only screen and (min-width: 1680px){
                    width: calc(100% - 358px);
                }
                .dividendInfoContainer {
                    height: auto;
                    display: flex;
                    .betType{
                        width: 187px;
                    }
                    .unitBet{
                        width: 100px;
                    }
                }
                .requestInfomation {
                    display: flex;
                }
            }
            .betslipInfo{
                width: 298px;
                padding: 11px 24px 11px 24px;
            }
            .dividendInfoContainer-d {
                margin-bottom: 0;
            }
            &:nth-child(odd) {
                background-color: #f4f4f4;
            }
            &:nth-child(even) {
                background-color: #ffffff;
            }
            .matchInfoContainer-d{
                .requestInfomation{
                    top: 8px;
                }
            }
        }
        .cashOutItem-bigH {
            height: auto;
        }
        .cashOutValue{
            border-top: unset;
            padding: 0px 0px 14px 0px;
        }
        .cashOutPreview .cashOutPreviewBtn{
            &> :first-child{
                width: 84px;
            }
            &> :last-child{
                width: 142px;
            }
        }
        .cashOutButton{
            width: 250px;
        }
    }
}

SECTION.ALLODDS .cashOutHead, SECTION.INPLAY_ALL .cashOutHead {
    border-radius: 4px 4px 0 0;
}
SECTION.ALLODDS .cashOutHeadIcon,SECTION.INPLAY_ALL .cashOutHeadIcon{
    background-image: url(../../../info/Include/images/cash_out.svg);
    background-repeat: no-repeat;
    background-size: cover;
    width: 24px;
    height: 16px;
    position: relative;
    margin-left: 6px;
    .cashOutHeadNum{
        position: absolute;
        top: -8px;
        right: 1px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        background-color:  #FECF13;
        border-radius: 50%;
        font-size: 12px;
        transform: scale(0.75);
    }
}

