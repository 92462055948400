.onlineWithdrawalContainer{
    width: 100%;
    .withdrawalBegins-container{
        width: 100%;
        .bannerBox{
            width: 480px;
            height: 80px;
            background-color: #ccc;
            margin: 0 auto;
            margin-bottom: 12px;
        }
        .footerBox{
            display: flex;
            width: 100%;
            padding: 0 24px;
            justify-content: flex-end;
            .footerButton{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 32px;
                background-color: #003C84;
                color: #FFF;
                border-radius: 16px;
                font-weight: bold;
                cursor: pointer;
            }
        }
    }

    .red {
        color:red;
    }
}