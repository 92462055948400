@import './common-mixin.scss';

.msgInbox {
    width: 640px;
    @include font13();
	background-color: #e7e7e7;
	margin: 0px;
}

.msgInboxTitle {
    padding: 12px 24px;
    @include font15();
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.GL .msgInboxTitle {
    background-color: #003c84;
}

.HR .msgInboxTitle {
    background-color: #122c68;
}

.FB .msgInboxTitle {
    background-color: #405a55;
}

.LY .msgInboxTitle {
    background-color: #980b0b;
}

.invisiableBlock {
    display: none;
}

.msgInboxClose {
    width:20px;
    height:20px;
    position: relative;
    cursor:pointer;
}

.msgInboxClose:before,.msgInboxClose:after{
    content:'';
    position:absolute;
    width:18px;
    height:2px;
    background-color:#FFFFFF;
    border-radius:2px;
    top:8px;
    box-shadow:0 0 2px 0 #ccc;
}

.msgInboxClose:before{
    transform:rotate(45deg);
    left:1px;
}

.msgInboxClose:after{
    transform:rotate(-45deg);
    right:1px;
}

.msgInboxHeader {
	width: 100%;
	background-color: #eaeaea;
	box-shadow: 0px 0px 10px #b7b7b7;
}

.msgInboxHeaderCell {
	width: 50%;
	display: inline-block;
	padding: 8px 0px 4px 0px;
}

.msgInboxHeaderCellInner {
	width: 100%;
	text-align: center;
	cursor: pointer;
	font-weight: bold;
}

.GL .msgInboxHeaderCellInner {
	color: #003c84;
}

.HR .msgInboxHeaderCellInner {
	color: #122c68;
}

.FB .msgInboxHeaderCellInner {
	color: #405a55;
}

.LY .msgInboxHeaderCellInner {
	color: #980b0b;
}

.msgInboxHeaderCell.active {
	border-bottom: 4px solid #fecf13;
}

.msgInboxTables {
	height: 430px;
	overflow-y: auto;
	overflow-x: hidden;
}

.msgInboxTable {
	margin: 15px 16px;
	background-color: #FFFFFF;
	border-radius: 4px;
}

.msgInboxTableHeader {
	padding: 6px 15px;
	border-radius: 4px 4px 0px 0px;
	color: #FFFFFF;
}

.GL .msgInboxTableHeader {
	background-color: #757575;
}

.HR .msgInboxTableHeader {
	background-color: #6176a0;
}

.FB .msgInboxTableHeader {
	background-color: #569171;
}

.LY .msgInboxTableHeader {
	background-color: #cb7b7b;
}

.msgInboxTableRow {
	padding: 8px 16px;
	line-height: 20px;
	white-space: nowrap;
}

.msgInboxTableChangeRow {
	white-space: normal;
}

.msgInboxTableRow {
	border-top: 1px solid #b7b7b7;
}

.msgInboxTableRow:first-child {
	border-top: 0;
}

.msgInboxChangeRace {
	color: #122C68;
}

.msgInboxPt {
	display: inline-block;
	vertical-align: top;
	width: 26px;
}

.msgInboxMsg {
	display: inline-block;
	width: calc(100% - 26px);
	white-space: normal;
}

.msgInboxMsg a {
	word-break: break-all;
}

@media only screen and (max-width: 768px) {
	.msgInbox {
		width: 100%;
	}

	.msgInboxTables {
		height: calc(100vh - 75px);
	}

	.invisiableBlock {
		display: block;
	}
}