body {
    background-color: #e1e1e1;
}

.sellingHours {
    width: 100%;
    font-size: 13px;
	margin: 0px;
}

.shTitle {
    background-color: #405a55;
    padding: 12px 24px;
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
    justify-content: space-between;
}

.shTitle a {
    color: #FFFFFF;
    font-size: 11px;
}

.shHeader a, .shNotes a, .shTableMobile a  {
    color: #405a55;
    text-decoration: underline;
}

.shHeader {
    background-color: #ffffff;
    padding: 12px;
}

.shRow {
    display: grid;
    grid-template-columns: 15% 10% 15% 20% 20% 20%;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    justify-items: center;
    align-items: center;
}

.shTable .shRow.hl, .shHL {
    border-top: 1px solid #76dbb0;
    border-bottom: 1px solid #76dbb0;
    background-color: #ebf5f0;
}

.shTable .shRow>div, .shTableMobile>div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.en .shTable .shRow>div>div {
    margin: 12px 0px;
}

.ch .shTable .shRow>div>div:first-child {
    margin-top: 12px;
}

.ch .shTable .shRow>div>div:last-child {
    margin-bottom: 12px;
}

.ch .shTable .shRow>div {
    flex-direction: column;
}

.shNote {
    display: inline-block;
    background-color: #569171;
    color: #ffffff;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 4px;
}

.shTable {
    margin: 12px;
}

.shTable .shRow:first-child {
    border-radius: 4px 4px 0 0;
}

.shTable .shRow:last-child {
    border-radius: 0 0 4px 4px;
}

.shTable .shRow {
    font-size: 13px;
}

.shTable .shRow:not(.hl):nth-child(odd), .shOdd {
    background-color: #ffffff;
}

.shTable .shRow:not(.hl):nth-child(even), .shEven {
    background-color: #f4f4f4;
}

.en .shWeekDay {
    padding-left: 4px;
}

.matchdayIcon {
    height: 20px;
    width: 22px;
    background-image: url(../../info/Include/images/matchday.svg);
    background-size: cover;
    background-position: center;
}

.shNotes {
    display: grid;
    grid-template-columns: 30px calc(100% - 30px);
    padding: 12px 16px 12px 12px;
}

.shNotes .shNote  {
    margin-right: 8px;
    padding-left: 6px;
    font-size: 11px;
}

.shNotes>div:nth-child(2)>div:not(:first-child) {
    padding-top: 16px;
}

@media only screen and (max-width: 768px) {
    .shTitle {
        justify-content: center;
    }

    .printBtn-container {
        /* display: none;  */
    }

    .shTableMobile {
        display: grid;
        grid-template-columns: 124px 75px 124px 156px 156px 155px;
        background-color: #ffffff;
        line-height: 18px;
        text-align: center;
        justify-items: center;
        align-items: center;
        width: calc(100%);
        overflow-x: auto;
        overflow-y: hidden;
    }

    .shTableMobile>div {
        padding: 12px 0px;
    }

    .shTableMobile>div:nth-child(-n+6) {
        font-size: 11px;
        background-color: #ffffff;
        border-bottom: 1px solid #e7e7e7;
    }

    .ch .shRowMobile, .shTitleOnline, .shTitleRaceCourse {
        flex-direction: column;
    }

	.dtStickyCol {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 10;
        box-shadow: 0px 0px 4px 4px #d7d1d1;
        clip-path: inset(0px -15px 0px 0px);
    }
}