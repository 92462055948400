@import "../mobile/fo.scss";
@import '../common-mixin.scss';
.masonry-col-jkc>div:first-child {
    width: calc(45% - 12px);
    margin-right: 12px;
}

.masonry-col-jkc>div:last-child {
    width: 55%;
}

.masonry-col-tnc>div:first-child {
    width: calc(45% - 12px);
    margin-right: 12px;
}

.masonry-col-tnc>div:last-child {
    width: 55%;
}
.masonry-col-fo>div:first-child{
    /* width: calc(45% - 12px); */
    width: min-content !important;
    margin-right: 12px;
    flex: 0 !important;
}
.masonry-col-fo>div:last-child{
    /* width: 55%; */
    flex: 1 !important;
}



.JKC .JKCIcon, .TNC .TNCIcon {
    height: 40px;
    background-color: #122C68;
    color: white;
    border-radius: 4px 4px 0 0;
    padding: 6px;
    padding-left: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.icontitle-m{
    display: none;
}
.icon-list .header .icontitle {
    height: 40px;
    background-color: #122C68;
    color: white;
    border-radius: 4px 4px 0 0;
    /* padding: 6px;
    padding-left: 20px; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    @include font15();
    line-height: 13px;
    font-weight: 500;
    justify-content: center;
    align-items : center;
    white-space: nowrap;
    padding: 0 6px;
}

.icontitle>div{
    margin-top: 5px;
}


.JKC .meeting-info-fo-topinfo-container .meeting-info-allraces.ch, .TNC .meeting-info-fo-topinfo-container .meeting-info-allraces.ch {
    font-weight: 500;
    margin-right: 20px;
    display: inline;
    min-width: 41px;
    display: inline-flex;
    margin: 0px 0px 0px 0px;
    color: #000000;
}

.JKC .meeting-info-fo-topinfo-container .meeting-info-allraces.en, .TNC .meeting-info-fo-topinfo-container .meeting-info-allraces.en {
    font-weight: 500;
    margin-right: 20px;
    display: inline;
    min-width: 41px;
    display: inline-flex;
    margin: 0px 15px 0px 0px;
    color: #000000;
}

.JKC .meeting-info-fo-topinfo-container .expectedstopsell, .JKC .meeting-info-fo-topinfo-container .inplaystopsell,
.TNC .meeting-info-fo-topinfo-container .expectedstopsell, .TNC .meeting-info-fo-topinfo-container .inplaystopsell {
    display: inline;
}

.JKC .meeting-info-fo-topinfo-m-container, .TNC .meeting-info-fo-topinfo-m-container {
    display: none;
}

.meeting-info-fo-topinfo-container .separator {
    background-image: url(../../info/Include/images/popuo_stroke_gray.gif);
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
    padding: 0px;
    width: 2px;
    height: 24px;
    margin: 0px 20px 0px 20px;
}

.JKC .rc-odds-row, .TNC .rc-odds-row {
    color: #122C68;
    font-weight: 500;
}

.JKC .rc-odds-row .sel, .TNC .rc-odds-row .sel {
    color: #333333;
    font-weight: 400;
}

.JKC .rc-odds-row .rc-checkbox, .TNC .rc-odds-row .rc-checkbox {
    max-width: 100px;
    min-width: 100px;
}

.JKC .rc-odds-table-header .odds, .TNC .rc-odds-table-header .odds {
    max-width: 100px;
}

.JKC .nextline, .TNC .nextline {
    display: none;
}

.JKC .rc-odds-table-compact .sel, .TNC .rc-odds-table-compact .sel {
    min-width: 60px;
    padding: 0 5px;
    width: auto;
}

.JKC .rc-odds-table-compact .jockey, .TNC .rc-odds-table-compact .jockey {
    min-width: 115px;
    max-width: 115px;
    padding: 0 5px;
    width: auto;
    text-align: left;
}

.JKC .iconcontainer .jockey {
    display: flex;
    vertical-align: middle;
    width: 100%;
}

.JKC .iconcontainer .jockey .jockeya {
    width: 100%;
}

.JKC .JockeyOtherDetail, .TNC .JockeyOtherDetail {
    position: absolute;
    left: 10px;
    top: 50px;
    background-color: white;
    display: none;
    color: #122C68;
    @include font13();
    font-weight: 500;
    cursor: pointer;
}

.JKC .rc-odds-table-header, .TNC .rc-odds-table-header {
    color: #333333;
    font-weight: 400;
}

.jkc-image-cropper, .tnc-image-cropper {
    width: 100px;
    height: 100px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}
.icon-list .cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    &:hover{
        .name{
            text-decoration: underline;
            color: #173e96;
        }
    }
}
.icon-list .name{
    max-width: 76px;
    display: inline-block;
    // word-break: keep-all;
}

.icon-list .profile{
    height: 60px;
    width: 60px;
}

.icon-list .profile-pic {
    display: inline;
    margin: 0 auto;
    height: 60px;
    width: 60px;
    border-radius: 50%;
}

.icon-list .profile-icon {
    margin-left: 40px;
    margin-top: -40px;
}

.icon-list .remarks{
    text-align: left;
    color: #333333;
    padding: 8px;
    @include H5();
}

.foIJCtxt {
    padding: 8px;
}

.foIJCLink {
    display: flex;
    padding: 0px 8px;
    justify-content: flex-end;
}

.JKC .rc-odds-row .iconcontainer, .TNC .rc-odds-row .iconcontainer {
    max-width: 90px;
}

.fotopmenu {
    border-top: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.fotopmenu div {
    text-decoration: none;
    padding: 0px 10px;
    @include font13();
    font-weight: 500;
    position: relative;
    line-height: 36px;
}

.fotopmenu a {
    vertical-align: middle;
    text-decoration: none;
    @include font13();
    font-weight: 500;
    color: #122C68 !important;
}

.fotopmenu a:hover {
    cursor: pointer;
    color: #122C68;
}

.fotopmenu a.active {
    color: #122C68;
}

.otherdetailpopup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    // padding: 5px;
    width: auto;
    height: min-content;
}

.otherdetailpopup-content a {
    color: #122C68;
    @include font13();
    font-weight: 500;
    cursor: pointer;
}

.otherdetailpopup-content .rcTitle{
    font-size: 20px;
    display: inline;
    vertical-align: middle;
    height: 35px;
    background-color: #122C68;
    color: white;
    border-radius: 4px 4px 0 0;
    padding-left: 20px;
    padding-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.otherdetailpopup-arrow, .tncotherdetailpopup-arrow {
    display: none;
}

[role='tooltip'].otherdetailpopup-content,[role='dialog'].otherdetailpopup-content{
    width: fit-content;
    height: min-content;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
    z-index: 9999 !important;
    // top: 20% !important;
    /* left: 10% !important; */
    // transform: translate(-50px, 0);
}

[data-popup='tooltip'].otherdetailpopup-overlay{
    background: transparent;
    left: 0px;
}



.JKC .IconListDownArrow, .TNC .IconListDownArrow {
    display: none;
}

.JKC .IconListUpArrow, .TNC .IconListUpArrow {
    display: none;
}

.JKC .rc-odds-row, .TNC .rc-odds-row {
    color: #122C68;
    font-weight: 500;
}

.JKC .rc-odds-row .sel, .TNC .rc-odds-row .sel {
    color: #333333;
    font-weight: 400;

}

.JKC .rc-odds-row .rc-checkbox, .TNC .rc-odds-row .rc-checkbox {
    max-width: 100px;
    min-width: 100px;
}

.JKC .rc-odds-table-header .odds, .TNC .rc-odds-table-header .odds {
    max-width: 100px;
}

.JKC .nextline, .TNC .nextline {
    display: none;
}

.JKC .rc-odds-table-compact .sel, .TNC .rc-odds-table-compact .sel {
    min-width: 60px;
    padding: 0 5px;
    width: auto;
}

.JKC .rc-odds-table-header, .TNC .rc-odds-table-header {
    color: #333333;
    font-weight: 400;
    background-color: white;
}

.fotopmenu.first.en a {
    float: left;
    text-decoration: none;
    @include font13();
    font-weight: 500;
    padding: 10px 10px 10px 10px;
}

.fotopmenu.second.en a {
    float: left;
    padding: 10px 10px 10px 10px;
    text-decoration: none;
    @include font13();
}

.fotopmenu.first.ch a {
    float: left;
    text-decoration: none;
    @include font13();
    font-weight: 500;
    padding: 10px 10px 10px 10px;
}

.fotopmenu.second.ch a {
    float: left;
    padding: 10px 23px 10px 10px;
    text-decoration: none;
    @include font13();
    font-weight: 500;
}
.fotomenu.table{
   display:table;
}
.fotomenu .row{
    display:table-row;
}
.fotomenu .cell{
    display: table-cell
}
.otherdetailpopup-content{
    width: fit-content;
    height: fit-content;
}
.JKC .table, .TNC .table,
.otherdetailpopup-content .table{
    display: table;
}
.otherdetailpopup-content .otherdtlsec{
    display: inline;
}
.otherdetailpopup-content .otherdtlsecM{
    display: none;
}
.otherdetailpopup-content .OtherDetailm{
    display: none;
}
.otherdetailpopup-content .table.otherm{
    display: none ;
}
.JKC .row, .TNC .row,
.otherdetailpopup-content .row{
    display: table-row;
    height: 44px;
    background-color: #FFFFFF;
}
.JKC .row.header, .TNC .row.header,
.otherdetailpopup-content .row.header{
    display: table-row;
    height: 36px;
    @include font13();
    font-weight: 400;
    line-height: 14px;
    color: #333333;
    background-color: #FFFFFF;
}
.JKC .row.sec, .TNC .row.sec,
.otherdetailpopup-content .row.sec {
    display: table-row;
    background: #F4F4F4;
}
.JKC .row .cell, .TNC .row .cell,
.otherdetailpopup-content .row .cell{
    display: table-cell;
    width: auto;
    text-align: center;
    vertical-align: middle;
    color: #122C68;
    @include font13();
    font-weight: 500;
    @include lineHeight18();
    &.srides {
        padding: 0 2px;
        a{
            cursor: pointer;
            &:hover{
                text-decoration: underline;
            }
        }
    }
    @media screen and (max-width:480px){
        font-size:11px ;
    }
}
.otherdetailpopup-content .row .cell.rides1{
    border-top: 0px;
    border-bottom: 0px;
    border-left: 0px;
    border-right: 1px;
    border-style: solid;
    border-color: #dee2e6;
}
.JKC .row .cell.sel, .TNC .row .cell.sel,
.otherdetailpopup-content .row .cell.sel{
    min-width: 50px;
}
.JKC .row .cell.name, .TNC .row .cell.name,
.otherdetailpopup-content .row .cell.name{
    min-width: 80px;
}
.JKC .row .cell.odds, .TNC .row .cell.odds,
.otherdetailpopup-content .row .cell.odds{
    width: fit-content;
}
.JKC .row .cell.oddschk, .TNC .row .cell.oddschk,
.otherdetailpopup-content .row .cell.oddschk{
    min-width: 90px;
    text-align: center;
    .odds-number {
        width: 36px;
    }
}
.JKC .row .cell.oddschk >div, .TNC .row .cell.oddschk > div,
.otherdetailpopup-content .row .cell.oddschk > div{
    display: inline-block;
}
.iconsection{
    margin-bottom: 10px;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
    min-height: 500px;
    min-width: 176px;
}

 .noinfo{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 176px;
}
.JKC .iconsection .row, .TNC .iconsection .row {
    color: #122C68;
    font-weight: 500;
}

.JKC .iconsection .row .sel, .TNC .iconsection .row .sel {
    color: #333333;
    font-weight: 400;

}

.JKC .iconsection .row .rc-checkbox, .TNC .iconsection .row .rc-checkbox {
    max-width: 100px;
    min-width: 100px;
}

.icon-list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    align-items: flex-start;
    border-top: 1px #dee2e6 solid;
    background: white;
    border-radius: 4px;
}

.icon-list .icon-list-content {
    /*min-width: 530px;*/
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

.icon-list .icon-list-content .radio-container {
    margin-right: 12px;
}

.icon-list .hide-m.icon-list-content .radio-container span {
    margin-left: 3px;
}
#ICON_collapseIconList.collapse{
    display: block;
}

.icon-list .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-content: center;
    align-items: flex-start;
    border-bottom:#dee2e6;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    background-color: #122c68;
    color: #FFFFFF;
    padding: 8px;
}

.lstCollapse-m{
    display: none;
}


.collapseIconList {
    transition: all 0.2s ease-in-out 0s;
    margin-bottom: 0px;
    margin-top: 12px;
    border-radius: 0px 0px 4px 4px;
}

.collapseIconList .card-body {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    background: #F4F4F4;
    border-radius: 0px 0px 4px 4px;
    border: unset;
    margin: 0;
    padding: 0;
}

.collapseIconList .card-body div {
    flex-basis: 30%;
}

.collapseIconList .card-body div:last-child {
    flex-basis: 38%;
}

.collapseIconList .card-body div div {
    display: inline;
    text-align: left;
    @include font13();
}
.JKC .table.folist, .TNC .table.folist{
    margin-bottom: 0px;
}

.lstsection {
    box-shadow: var(--box-shadow);
    border-radius: 4px;
    overflow: hidden;
    min-height: 500px;
}

.JKC .table.folistaddtoslip , .TNC .table.folistaddtoslip{
    display:inline-table;
    margin-bottom: 0px;
}
.JKC .table.folistaddtoslip .slipcontainer, .TNC .table.folistaddtoslip .slipcontainer{
    display:flex;
    justify-content: space-between;
    padding: 0px;
}
.JKC .table.folistaddtoslip .row .cell, .TNC .table.folistaddtoslip .row .cell{
    padding: 0px;
}
.JKC .row .cell, .TNC .row .cell{
    padding: 0px;
}
.JKC .row .jockey, .TNC .row .jockey{
    /* width: 120px; */
    // white-space: nowrap;
}
:is(.JKC, .TNC) .table>:not(caption)>*>*{
    padding: 0;
    background-color: inherit;
}


.JKC .row .oddschk, .TNC .row .oddschk {
    // padding-left: 20px;
}
.foNotes {
    margin: 0px 10px;
    max-width: 326px;
}
.forescontainer .refund {
    height: 36px;
    color: #FFF;
    @include font15();
    font-weight: 500;
    line-height: 36px;
    padding-left: 10px;
    background-color: #122c68;

    border-radius: 4px;
}
.forescontainer>header {
    height: 36px;
    color: #FFF;
    @include font15();
    font-weight: 500;
    line-height: 36px;
    padding-left: 10px;
    background-color: #122c68;
    border-radius: 4px 4px 0 0;
}

.RESULTS .forestable {
    display: table;
    width: 100%;
}

.RESULTS .forestable .rc-odds-table-header {
    text-align: left;
    background-color: #FFFFFF;
    @include font13();
}

.RESULTS .forestable .rc-odds-row {
    @include font13();
    height: 32px;
    width: 100%;
    text-align: left;
}

.RESULTS .forecfootnote {
    text-align: end;
    color: #122C68;
    @include font13();
    font-weight: 500;
    clear: both;
    height: 32px;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 15px 8px;
    background-color:#FFFFFF;
    margin-bottom: 5px;
}

.RESULTS .forecfootnote>a {
    cursor: pointer;
    vertical-align: middle;
}


.RESULTS .fosubtitleoth.sec, .RESULTS .forecfootnote.sec {
    background: #F4F4F4;
}

.RESULTS .spancol.sec {
    background-color: #beb4b4;
}

.RESULTS .forestable .rc-odds-table-header .sel, .RESULTS .forestable .rc-odds-row .sel {
    width: 50px;
    white-space: nowrap;
}

.RESULTS .forestable .rc-odds-row>div:first-child {
    max-width: 70%;
    line-height: 18px;
}
.RESULTS .forestable .rc-odds-row .jockey, .RESULTS .forestable .rc-odds-table-header .jockey {
    text-align: left;
    white-space: nowrap;
}

.RESULTS .forestable .rc-odds-table-header .pt, .RESULTS .forestable .rc-odds-row .pt {
    width: 100px;
    white-space: nowrap;
}

.RESULTS .resulttitle {
    @include font15();
    margin-top: 5px;
}
.RESULTS .forescontainer {
    background-color: #FFFFFF;
    width: 100%;
    display: table;
}
.RESULTS .forescontainer .sel {
    width: 10%;
    min-width: 60px;
}
.RESULTS .forescontainer .name {
    text-align: left;
    width: 80%;
}
.RESULTS .forescontainer .pt {
    width: 10%;
}
.RESULTS .forescontainer .remarks {
    width: 10%;
    min-width: 56px;
}
.RESULTS .forescontainer.refund {
    margin-top: 5px;
    margin-bottom: 5px;
}
.RESULTS .forescontainer.other {
    margin-bottom: 0px;
}
.RESULTS .forescontainer.top {
    margin-top: 5px;
}
.RESULTS .forescontainer.bottom {
    margin-bottom: 0px;
}
.RESULTS .subtitleother{
    white-space: nowrap;
    text-align: left;
}

.JKC .row .jockey, .TNC .row .jockey,
.otherdetailpopup-content .jockey {
    text-align: left;
    padding-left: 2px;
    a {
        cursor: pointer;
    }
}

.foProfile2 {
    display: grid;
    grid-template-columns: 50% 50%;
}

.foProfile3 {
    display: grid;
    grid-template-columns: 33% 33% 34%;
}

.foProfile4 {
    display: grid;
    grid-template-columns: repeat( 4, 25% );
}