@media only screen and (max-width: 1279px) {

    // .topInfoMenu .secMenu {
    //     min-width: 372px;
    // }

    .secMenu .secMenuHR, .secMenu .secMenuFB, .secMenu .secMenuM6 {
        width: 124px;
    }

    .rcMain, .fbMain, .marksixMain {
        padding: 12px 8px 0px 8px;
    }

    .topNav ul {
        margin: 0px;
        padding-left: 0;
    }

    // .topInfoMenu .topNav ul li {
    //     max-width: 127px;
    // }

    // .en .topNav .dropdown.btn-group button{
    //     min-width: 60px;
    // }

    .HR .topMenu,
    .FB .topMenu,
    .LY .topMenu {
        width: 100%;
    }

    .tContainer .ewin {
        flex: 0 0 10%;
    }

    // #divBetSlipNormal .add-slip .send{
    //     width: 100px;
    // }

    .leftMenuMain {
        min-width: 202px;
        max-width: 202px;
        margin-right: 8px;
    }

    .rcOddsTable {
        width: 100%;
    }


    .rc-odds-row .betSlip {
        width: 627px;
    }

    .topBanner {
        width: 100%;
    }

    #info_top_promo {
        width: calc(100% - 250px);
    }

    .slick-arrow {
        display: none;
    }


    .rcMain,
    .fbMain,
    .marksixMain {
        width: auto;
    }
    .horse-racing-race-card .rc-odds-table-header .rtg{
        max-width: 40px;
    }
    .horse-racing-race-card .rc-odds-table-header .last6{
        min-width: 80px;
        max-width: 80px;
    }
    .horse-racing-race-card .rc-odds-table-header .tp {
        min-width: 30px;
        max-width: 30px;
    }
    .rc-odds-table .rc-odds-table-tp{
        min-width: 30px;
        max-width: 30px;
    }
    .rc-odds-table .rc-tp{
        padding: 0 5px;
        max-width: 36px;
    }
    .horse-racing-race-card .rc-odds-table-header .draw{
        max-width: 40px;
        min-width: 40px;
    }
    .horse-racing-race-card .rc-odds-table-header .bodyWT,
    .horse-racing-race-card .rc-rtg {
        max-width: 40px;
        min-width: 40px;
    }
    .horse-racing-race-card .rc-odds-row>.rc-last6 {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        word-break: break-all;
    }
    .rc-odds-row.betSlip>div{
        width: 100%;
    }

    .fbOddsTable {
        width: 100%;
        border-radius: 5px;
    }



    .fbOddsTable section .match-row>div {
        padding: 8px 8px;
    }

    .fbOddsTable section.CRS .match-row,
    .fbOddsTable section.FCS .match-row {
        align-items: flex-start;
    }

    .fbOddsTable section{
        &.FGS, &.MSP, &.SGA, &.AGS, &.LGS{
            >.header-wrapper>.header, .match-row{
                grid-template-columns: [first] 90px [frontEndId] 60px [tourn] 40px repeat(1, [teamName] 250px) [odds] 1fr [h2h] 48px [end];
            }
        }
    }

    .fbOddsTable section.OOE>.header-wrapper>.header,
    .fbOddsTable section.OOE>.match-row-container>.match-row {
        grid-template-columns: [first] 90px [frontEndId] 60px [tourn] 40px 1fr [odds] 156px [h2h] 48px [end];
    }

    .M6Middel section,
    .marksix-single-middle {
        width: 100%;
        // height: 391px;
    }

    .fbOddsTable section.INPLAY>.header-wrapper>.header .icon_flag{
        margin-left: 0px;
    }

    .fbOddsTable section.HFT .match-row .odds{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        // min-width: 228px;
        gap: 12px 0;
    }


    #divBetSlipNormal .ftInplayDialog {
        /* width: calc( 100vw - 980px); */
        right: 0;
        left: unset;
    }


    .investment-cal {
        /* grid-template-columns: 24% 20% 28% 28%; */
        grid-template-columns: 33% 33% 34%;
        height: 82px;
        grid-template-rows: 30px 50px;
    }

    .investment-cal-lbl {
        grid-column: 1 / 5;
        align-self: flex-end;
        height: initial;
    }

    .ewinMain{

        .ewinInfoGrid {
            grid-column-gap: 8px;
        }

        .HRBlock{
            .blockGenaralInfoBody>div, .blockPoolInfoTitle>div, .blockPoolInfoBody>div {
                padding: 0px 0px 5px 12px;
            }
        }
        :is(.m6tab, .hrTab)>div {
            padding: 0 6px;
            line-height: 15px;
            text-align: center;
            display: flex;
            align-items: center;
            padding-bottom: 4px;
        }
        // .FBBlock{
        //     .mobile-tab-set{
        //         grid-template-columns: 50% 50%;
        //         .line{
        //             width: 50%;
        //         }

        //     }
        // }
        .blockFeaturedMatches .team  div{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .blockDrawResultHead>div {
            padding: 6px 8px;
        }
        .blockDrawResultHead  .df-date>div {
            padding: 0px 8px;
        }
        .blockDrawResultHead  .df-date>div:nth-child(1) {
            padding-left: 0px;
        }
        .blockDrawResultHead  .df-date>div:nth-child(2) {
            padding-right: 0px;
        }
        .blockDrawResultBody{
            margin-top: 8px;
            justify-content: flex-start;
        }
        .blockDrawBall {
            margin: -6px 0px 0px 0px;
        }
        .blockDrawPlus{
            margin-top: -10px;
        }
        .blockDrawResultBottom {
            padding: 2px 3px 6px 8px;
            min-height: 38px;
            margin-top: -6px;
            display: flex;
            align-items: center;
        }
        .blockQuickPickBody{
            flex-basis: auto;
        }

        .blockItemBody>div:nth-child(1) {
            min-width: 58px;
            display: flex;
            align-items: center;
            padding: 0 8px 0 0;
            // font-size: 11px;
        }

        .blockButton .btn{
            width: 96px;
        }
    }
}