@import './cashout.scss';
@import './football.scss';
@import './betSlip.scss';

@media only screen and (max-width: 1023px) and (pointer: coarse){
    body{
        padding-bottom: 120px;
    }
    .navigation-menu{
        .dropdown-menu-end{
            overflow: unset;
            &>div{
                &::before {
                    content: "";
                    position: absolute;
                    /* right: 11px; */
                    top: -24px;
                    width: 24px;
                    height: 24px;
                    border-style: solid;
                    border-width: 0 12px 12px 12px;
                    border-color: transparent transparent #980b0b transparent;
                    z-index: 9999;
                    right: 12px;
                }
            }
        }
    }
    .fbMiddle {
        .navigation-menu{
            .dropdown-menu-end{
                &>div::before {
                    border-color: transparent transparent #569171 transparent;
                }

            }
        }
    }
    .rcMiddle {
        .navigation-menu{
            .dropdown-menu-end{
                &>div::before {
                    border-color: transparent transparent #003C84 transparent;
                }

            }
        }
    }
}