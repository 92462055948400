@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
.fbOddsTable .allup-cal-table .content .team,
.fbOddsTable .allup-cal-table .content .odds {
    line-height: 24px;
}
.fbOddsTable section .match-row.matrix .odds .oddsCheckboxGrid{
    justify-self: unset;
    margin-left: unset;
}

.crs-more-button {
    align-items: center;
}
.crs-more-button>div {
    background-color: #E2E2E2;
    color: #00c06c;
    cursor: pointer;
    width: 32px;
    height: 60px;
    margin-top: 0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 14px;
    font-weight: 500;
}
.fbMain {
    padding: 0;
    width: 100%;
}

.fbOddsTable {
    width: 100%;
    max-width: 100%;
}

.fbOddsTable section:is(.LAST_ODDS, .ALLODDS, .INPLAY_ALL) .match-row-container .oddsColor {
    font-size: 15px;
    font-weight: 500;
}

.fbOddsTable>section:not(.MIXALLUP_DETAIL) {
    margin: 0px 8px;
}

.fbOddsTable>section.LAST_ODDS,
.fbOddsTable>section.ALLODDS,
.fbOddsTable>section.INPLAY_ALL {
    margin: 0px 0px;
    background-color: #dee2e6;
}

.fbOddsTable>section.LAST_ODDS .match-info,
.fbOddsTable>section.ALLODDS .match-info,
.fbOddsTable>header.ALLODDS .match-info,
.fbOddsTable>header.INPLAY_ALL .match-info,
.fbOddsTable>section.INPLAY_ALL .match-info {
    margin: 0;
    margin-bottom: 12px;
    // padding: 6px 0px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 6%) 0px 20px 25px -5px, rgb(0 0 0 / 6%) 0px 10px 10px 0px;
}

.fbOddsTable header>.fbTitle {
    display: none;
}

.fbOddsTable .date-competition-selection section {
    /* padding-left: 0px; */
    border-radius: 0px;
    overflow: hidden;
    overflow-x: scroll;
    align-items: flex-start;
}

.fbOddsTable .date-competition-selection .date-tab,
.fbOddsTable .date-competition-selection .tourn-tab,
.fbOddsTable .date-competition-selection .featured-tab {
    width: 40px;
    background-color: #757575;
    padding: 0px;
    padding-left: 8px;
    grid-template-columns: 100% 0%;
    justify-items: start;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0;
}

.fbOddsTable .date-competition-selection .date-tab>span,
.fbOddsTable .date-competition-selection .tourn-tab>span,
.fbOddsTable .date-competition-selection .featured-tab>span {
    display: none;
}

.FB .bottom-menu .bottom-menu-link.active {
    color: #00C06C;
    img {
        // filter: drop-shadow(24px 0 0 #00C06C);
        // position: relative;
        // left: -24px;
        // border-right: solid transparent  1px;
        // box-sizing: content-box;
        filter: brightness(0) saturate(100%) invert(54%) sepia(77%) saturate(4778%) hue-rotate(129deg) brightness(106%) contrast(101%);
    }
    &#bottom-menu-Int_Early_Settlement img{
        // filter: drop-shadow(27px 0 0 #00C06C);
        // left: -27px;
        filter: brightness(0) saturate(100%) invert(54%) sepia(77%) saturate(4778%) hue-rotate(129deg) brightness(106%) contrast(101%);
    }
}

.fbOddsTable .date-competition-selection .active, .fbOddsTable .date-competition-selection .section-DATE .date.active, .fbOddsTable .date-competition-selection .section-DATE .other.active {
    background-color: #00C06C;
    color: #ffffff;
}

.fbOddsTable section > .header-wrapper {
    display: none;
}

.fbOddsTable section .coupon {
    /* display: none; */
}

.fbOddsTable section .coupon-m {
    height: auto;
    background-color: #405A55;
    color: #ffffff;
    // font-size: 11px;
    // line-height: 12px;
    // display: flex;
    align-items: center;
    padding: 8px 8px 8px 8px;
    border-radius: 4px 4px 0px 0px;
    // PRJ1027-7242
    display: grid;
    grid-template-columns: 1fr 119px  36px;
    justify-content: center;
    align-content: center;
}

.fbOddsTable section.TQL .coupon-m {
    display: flex;
}

.fbOddsTable section .coupon-m:not(:nth-child(3), :first-child) {
    margin-top: 12px;
}


.fbOddsTable section.TOURNRES section:not(:first-child) .coupon-m {
    margin-top: 0px;
    border-radius: 0;
}

.fbOddsTable section.LAST_ODDS .coupon-m,
.fbOddsTable section.ALLODDS .coupon-m,
.fbOddsTable section.INPLAY_ALL .coupon-m {
    margin-top: 0px;
    border-radius: 0px;
    margin: 0px 8px;
    display: flex;
    justify-content: space-between;
}

.fbOddsTable section.LAST_ODDS>div:nth-child(3),
.fbOddsTable section.ALLODDS .cashOutAllOdds,
.fbOddsTable section.INPLAY_ALL>div:nth-child(3) {
    border-radius: 4px 4px 0px 0px;
    margin: 0px 8px;
    width: calc(100% - 16px);
}

.fbOddsTable section .match-row {
    display: none;
}

.fbOddsTable section .crsTable .match-row {
    display: grid;
}

.fbOddsTable .date-competition-selection header {
    // display: grid;
    // grid-template-columns: 1fr auto;
    border-radius: 0;
    margin-top: 0px;
    // padding-left: 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}

.fbOddsTable .date-competition-selection header > div:nth-child(1){
    display: flex;
    gap: 8px;
    /* margin: 0 12px; */
    // margin-right: 12px;
    padding:0 8px;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: 60%;
    &.flexbsAuto{
        flex-basis: 100%;
    }
}
.fbOddsTable .date-competition-selection  .activeFBSearch > *{
    position: relative;
    z-index: 1020 ;
}
.fbOddsTable .date-competition-selection .fbSearchButton{
    margin: 0;
    min-width: 40px;
    background-color: transparent;
}
.fbOddsTable .date-competition-selection .fbSearchButton.active {
    flex: 1;
    transition: all 0.2s linear;
    overflow: hidden;
    max-width: 40px;
}
.fbOddsTable .date-competition-selection .fbSearchButton.active.showInput{
    max-width: 100%;
    overflow: initial;
}
.fbOddsTable .date-competition-selection .fbSearchButton .searchBtn{
    background-color: #757575;
}
.fbOddsTable .date-competition-selection .fbSearchButton .icon-search{
    margin: 0;
}
.fbOddsTable .date-competition-selection .fbSearchButton.active .searchBtn{
    position: relative;
    z-index: 1;
    border-radius: 2px 0 0 2px;
}
.fbOddsTable .date-competition-selection  .fbSearchButton .section-SEARCH-m{
    width: calc(100% - 40px);
    height: 100%;
    position: relative;
    .msgInboxClose.clearSearchResultBtn{
        position: absolute;
        right: 8px;
        top: 32%;
        width: 16px;
        height: 16px;
    }
}
.fbOddsTable .date-competition-selection  .fbSearchButton .section-SEARCH-m .searchResultsList{
    position: absolute;
    top: 42px;
    left: 0;
    color: #333;
    z-index: 1200;
    font-size: 13px;
    font-weight: 500;
}
.fbOddsTable .searchResultsListMask{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(0 0 0 / 60%);
    z-index: 1019;
}
.fbOddsTable .date-competition-selection  .fbSearchButton  input{
    height: 100%;
    width: 100%;
    border: 0;
    border-radius: 0 2px 2px 0;
    padding-left: 12px;
    padding-right: 30px;
    font-weight: 500;
}


.fbOddsTable .date-competition-selection header>div:nth-child(2) {
    font-size: 15px;
    display: flex;
    justify-content: flex-end;
    border-left: 1px #B4B4B4 solid;
    padding-left: 8px;
    // width: 187px;
    height: 46px;
    align-items: center;
    flex-basis: 40%;
    .fontSize13{
        font-size: 13px;
    }
}
.fbOddsTable .date-competition-selection header >div:nth-child(2).hideMenu{
    display: none;
}

.fbOddsTable .date-competition-selection header>div:nth-child(2)>nav {
    width: 100%;
}

.fbOddsTable .match-row-container,
.fbOddsTable section .coupon-m {
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 5px;
}

.fbOddsTable section.LAST_ODDS .match-row-container,
.fbOddsTable section.ALLODDS .match-row-container,
.fbOddsTable section.INPLAY_ALL .match-row-container {
    margin: 0px 8px;
}

.fbOddsTable section {
    &.ALLODDS, &.LAST_ODDS, &.INPLAY_ALL{
        .match-row-container{
            .team {
                padding:8px 0 8px 0;
            }
            .odds {
                padding: 0 0 8px;
            }
        }
        section.CHP{
            margin-bottom: 0 !important;
        }
    }
}
.fbOddsTable .match-row-container .match-row-m {
    display: grid;
    grid-template-columns: 1fr 119px 44px;
    align-items: center;
    padding: 0px;
}

.fbOddsTable .match-row-container .match-row-m>.odds {
    display: grid;
    column-gap: 4px;
    row-gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    line-height: 16px;
    font-size: 15px;
    font-weight: 500;
    color: #405A55;
    width: 100%;
}
.fbOddsTable .HAD .match-row-container .match-row-m>.odds {
    margin: 12px 0px 20px 0px;
}
.fbOddsTable section.MIXALLUP_DETAIL_SINGLE .match-row-container.FGS .match-row-m>.odds {
    >:first-child{
        font-size: 13px;
        font-weight: 400;
        width: auto;
        text-align: center;
    }
}

.fbOddsTable section .match-row-m .odds.hadstopsell {
    row-gap: 0;
}

.fbOddsTable :is(section.MSP, section.FGS, section.AGS, section.LGS) .match-row-m .odds.hadstopsell {
    margin-left: 12px;
}

.fbOddsTable .match-row-container.FGS .match-row-m {
    align-items: flex-end;
    >.odds {
        padding: 8px 0;
        >span {
            display: none;
        }
    }
}

.fbOddsTable .match-row-container .match-row-m>.odds .oddsValueM {
    min-width: 36px;
    white-space: nowrap;
}

.fbOddsTable .match-row-container .match-row-m>.odds  .oddsCheckboxGrid {
    display: flex;
    flex-direction: column;
    /* margin-bottom: 4px; */
}

.fbOddsTable .HFT .match-row-container .match-row-m>.odds {
    padding: 6px 0 20px 0;
    column-gap: 5px;

}

.fbOddsTable section.INPLAY_ALL .team.HDC,
.fbOddsTable section.INPLAY_ALL .team.EDC,
.fbOddsTable section.INPLAY_ALL .oddsEDC,
.fbOddsTable section.INPLAY_ALL .oddsHDC,
.fbOddsTable section.ALLODDS .team.HDC,
.fbOddsTable section.ALLODDS .team.EDC,
.fbOddsTable section.ALLODDS .oddsHDC,
.fbOddsTable section.ALLODDS .oddsEDC,
.fbOddsTable section.LAST_ODDS .team.HDC,
.fbOddsTable section.LAST_ODDS .team.EDC,
.fbOddsTable section.LAST_ODDS .oddsHDC,
.fbOddsTable section.LAST_ODDS .oddsEDC {
    grid-template-columns: 50% 50% !important;
}

.fbOddsTable section.INPLAY_ALL .oddsHDC>div:first-child,
.fbOddsTable section.ALLODDS .oddsHDC>div:first-child,
.fbOddsTable section.LAST_ODDS .oddsHDC>div:first-child,
.fbOddsTable section.INPLAY_ALL .oddsEDC>div:first-child,
.fbOddsTable section.ALLODDS .oddsEDC>div:first-child,
.fbOddsTable section.LAST_ODDS .oddsEDC>div:first-child {
    flex-direction: row;
}

.fbOddsTable .match-row-container .match-row-m>.odds>div {
    display: grid;
    gap: 8px;
    justify-items: center;
    align-items: center;
    align-content: flex-start;
    justify-content: center;
    width: 100%;
    grid-template-columns: 100%;
    word-break: break-word;
}

.suspended {
    padding-bottom: 0 !important;
    margin-bottom: -12px;
}

.col-gap-6px {
    line-height: 0;
}
.fbOddsTable .match-row-container .match-row-m.matrix>.odds>div:first-child {
    font-size: 11px;
}

.fbOddsTable section .match-row-container>.match-row-m-container:nth-child(odd) {
    background-color: #ffffff;
}

.fbOddsTable section .match-row-container>.match-row-m-container:nth-child(even) {
    background-color: #f4f4f4;
}
.fbOddsTable section {
    &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
        .match-row-container .match-row-m:last-child .more {
            align-items: center;
            border-bottom: 4px solid #dedede;
            border-top: 1px solid #e7e7e7;
            display: flex;
            flex-wrap: nowrap;
            grid-column: 1 / span 3;
            height: 32px;
            justify-content: center;

        }
    }
}
.fbOddsTable section .match-row-container .match-row-m:last-child .more {
    border-bottom: none;

}

// .fbOddsTable section.CRS .match-row-container>.match-row-m:nth-child(odd) {
//     background-color: #f4f4f4;
// }

// .fbOddsTable section.CRS .match-row-container>.match-row-m:nth-child(even) {
//     background-color: #ffffff;
// }

.fbOddsTable .coupon-m {
    display: none;
}

.fbOddsTable section .coupon-m .desc {
    flex: 1;
}

.fbOddsTable section.HIL .coupon-m .desc {
    /* flex-basis: 50%; */
}

.fbOddsTable section.HIL .match-row-container .match-row-m {
    grid-template-columns: 1fr 119px 44px;
}

.fbOddsTable section .match-row-container .match-row-m .matchInfo {
    display: grid;
    height: 100%;
    align-content: space-between;
}

.fbOddsTable :is(section.MSP, section.FGS) .match-row-container .match-row-m .matchInfo {
    position: relative;
    &::after{
        display: block;
        content: '';
        position:absolute;
        right: -12px;
        top: 3px;
        bottom: 3px;
        width: 1px;
        background-color: #E7E7E7;
    }
}

.fbOddsTable section.ALLODDS .coupon-m .desc,
.fbOddsTable section.LAST_ODDS .coupon-m .desc,
.fbOddsTable section.INPLAY_ALL .coupon-m .desc {
    flex-basis: 70%;
    max-width: 100%;
}
.fbOddsTable section.INPLAY_ALL,.fbOddsTable section.LAST_ODDS{
    .couponECH .desc{
        display: flex;
        align-items: center;
        .corner-result{
            height: 20px;
        }
    }
}

.fbOddsTable section.ALLODDS .coupon-m .desc{
    .desc-title {
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
    }
    .corner-result{
        height: 14px;
        margin-right: 0;
        .icon-corner{
            background-size: 20px 20px;
            background-position: -2px -2px;
            height: 14px;
            width: 18px;
        }
    }
}
.fbOddsTable section.INPLAY_ALL .coupon-m .desc{
    .desc-title {
        font-size: 13px;
        font-weight: 500;
    }
}
.fbOddsTable section.INPLAY_ALL .coupon-m ,
.fbOddsTable section.LAST_ODDS .coupon-m ,
.fbOddsTable section.MIXALLUP_DETAIL .coupon-m ,
.fbOddsTable section.ALLODDS .coupon-m {
    display: flex;
    justify-content: flex-start;
    position: relative;
    padding-right: 28px;
    .desc{
        flex: 0 1 auto;
    }
    .corner-result{
        margin-left: unset;
    }
    .couponBtn{
        width: 20px;
        margin-left: 4px;
        background-color: unset;
        position: absolute;
        right: 4px;
    }
}

.fbOddsTable section.Tournament{
    section.CHP{
        margin-bottom: 0 !important;
        .tournSelections{
            margin-bottom: 12px;
        }
    }
    section.TPS{
        .tournSelections{
            margin-bottom: 12px;
        }
    }
    .coupon-m{
        position: relative;
        border-radius: 0;
        .couponBtn {
            width: 20px;
            margin-left: 4px;
            background-color: unset;
            position: absolute;
            right: 4px;
        }
        .legstext{
            max-height: 29px;
        }
    } 
    section.TQL{
        .couponBtn{
            display: block;
        }
    }
} 

.fbOddsTable section.LAST_ODDS .coupon-m .legstext,
.fbOddsTable section.ALLODDS .coupon-m .legstext,
.fbOddsTable section.INPLAY_ALL .coupon-m .legstext {
    text-align: right;
    white-space: nowrap;
}

.fbOddsTable section .coupon-m .odds {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    width: 119px;
    // gap: 4px;
}

.fbOddsTable section .coupon-m .h2h {
    width: 44px;
}


.fbOddsTable section .coupon-m .odds>div {
    align-items: center;
}

.fbOddsTable section.HIL .coupon-m .odds,
.fbOddsTable section.CHL .coupon-m .odds,
.fbOddsTable section.FCH .coupon-m .odds,
.fbOddsTable section.FHL .coupon-m .odds {
    grid-template-columns: 39% 1fr 1fr;
    gap: 0;
}

.fbOddsTable .coupon-m .goal-number{
    margin-left: 2px;
}

.fbOddsTable section.HIL .match-row-m,
.fbOddsTable section.CHL .match-row-m,
.fbOddsTable section.FCH .match-row-m,
.fbOddsTable section.FHL .match-row-m {
    align-items: unset;
}

.fbOddsTable section.HIL .match-row-m .image-H2H,
.fbOddsTable section.CHL .match-row-m .image-H2H,
.fbOddsTable section.FCH .match-row-m .image-H2H,
.fbOddsTable section.FHL .match-row-m .image-H2H {
    margin-top: 8px;
}

.fbOddsTable section{
    &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.ECD {
        .coupon-m .odds {
            grid-template-columns: 1fr 1fr;
        }
        .match-row-container .match-row-m > .odds {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.fbOddsTable section.HIL .coupon-m .odds {
    /* width: 37%; */
}

.fbOddsTable section .match-row-m {
    display: none;
    color: #333333;
    font-size: 11px;
}

.fbOddsTable section .match-row-m .lineNume {
    text-align: center;
}

.fbOddsTable .section-TOURN>.more span {
    left: calc(50% - 24px);
}

.fbOddsTable section .odds>div {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    row-gap: 8px;
}

.fbOddsTable section .oddsLine .odds>div {
    align-items: center;
}

.fbOddsTable section.MIXALLUP_DETAIL .odds>div,
.fbOddsTable section.LAST_ODDS .odds>div,
.fbOddsTable section.ALLODDS .odds>div,
.fbOddsTable section.INPLAY_ALL .odds>div {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
}

.fbOddsTable section.LAST_ODDS .FGS .odds>div:first-child,
.fbOddsTable section.ALLODDS .FGS .odds>div:first-child,
.fbOddsTable section.INPLAY_ALL .FGS .odds>div:first-child {
    width: 100%;
    text-align: center;
    padding-bottom: 8px;
}

.fbOddsTable section.FGS .odds>div:last-child {
    padding-left: 0px;
}

.fbOddsTable section .match-row-m .oddsLine {
    display: grid;
    grid-template-columns: 39% 1fr;
    font-size: 15px;
    text-align: center;
    row-gap: 12px;
    column-gap: 0px;
    margin: 18px 0px 18px 0px;
    // align-content: center;
    color: #405A55;
    .oddsValue {
        margin-top: 4px;
        line-height: 16px;
        font-weight: 500;
        span {
            /* padding-right: 4px; */
        }
    }
    &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
        grid-template-columns: 1fr;
        .odds{
            grid-template-columns: 1fr 1fr;
            .hdcOddsItem {
                .oddsCheckboxGrid  {
                    display: flex;
                    flex-direction: column-reverse;
                    .oddsValueM .oddsArrowM{
                        &.oddsUp, &.oddsDown{
                            margin-top: -4px;
                            margin-bottom: 1px;
                        }
                    }
                }
            }
        }
        .cond{
            text-align: center;
            height: 14px;
        }
    }
    &.HHA,&.EHH{
        .odds {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.fbOddsTable section .match-row-m .oddsLine .arrow-small {
    border-color: #00C06C;
    width: 10px;
    height: 10px;
    margin-top: 4px;
    margin-left: 0px;
    border-width: 0 2px 2px 0;
}

.fbOddsTable section .match-row-m .oddsLine .odds {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: flex-start;
}

.fbOddsTable section .match-row-m .oddsLine .oddsLine {
    text-align: center;
}

.fbOddsTable section .match-row-m .oddsLine .lineNum {
    word-break: break-word;
    margin-top: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fbOddsTable section .match-row-m .matchInfo .tourn {
    display: flex;
    line-height: 12px;
    margin-left: 8px;
    margin-top: 4px;
    padding: 0;
    // padding-right: 32px;
    word-break: break-word;
    width: 100%;
}

.fbOddsTable .tourn_es_icon_m{
    min-width: 20px;
    min-height: 20px;
    background-image: url(../../info/Include/images/early_settlement_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: 2px;
}

.fbOddsTable section {
    .tournName-m{
        position: relative;
        padding-right: 24px;
    }
    .tourn_es_icon_m{
        position: absolute;
        right: -24px;
        top: -4px;
        min-height: 16px;
    }
}

.fbOddsTable section.MIXALLUP  {
    .tourn_es_icon_m{
        top: -2px;
    }
}
.fbOddsTable header {
    .tourn_es_icon_m{
        margin: 0 6px;
    }
}

.fbOddsTable section .match-row-m .matchInfo .tourn >div:first-child {
    min-width: 38px;
}

.fbOddsTable section .match-row-m .matchInfo .tourn>span {
    width: 10px;
}

.fbOddsTable section .match-row-m .team {
    display: flex;
    justify-content: space-between;
    padding: 4px 12px 5px 8px;
    align-items: center;
}

.fbOddsTable section .team.CRS,
.fbOddsTable section .team.ECS,
.fbOddsTable section .team.FCS,
.fbOddsTable section .team.FGS {
    margin: 0px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #fff;
    width: 100%;
}


.fbOddsTable section .team.FGS {
    grid-template-columns: 50% 50%;
    border-radius: 0px;
    width: calc(100% - 16px);
    margin: 0px 8px;
}

.fbOddsTable section.ALLODDS .team.FGS {
    width: 100%;
    margin:0px;
}

.fbOddsTable .match-row-container.FGS {
    margin: 0px 8px;
}

.fbOddsTable section.ALLODDS .match-row-container.FGS {
    margin: 0px;
}


.fbOddsTable section.LAST_ODDS .team.HFT,
.fbOddsTable section.ALLODDS .team.HFT {
    display: none;
}

.fbOddsTable section .match-row-m .team>div:first-child>span {
    font-size: 15px;
    display: block;
    color: #405A55;
    font-size: 15px;
    line-height: 16px;
    font-weight: 500;
    word-break: break-word;
}

.fbOddsTable section .match-row-m .team>div:first-child>span:first-child {
    margin-bottom: 2px;
}

.fbOddsTable .match-info .clock-row>div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;
    width: auto;
    row-gap: 8px;
}

.fbOddsTable .match-row-m .clock-row {
    display: inline-flex;
    justify-content: space-between;
    width: 80px;
    padding-left: 8px;
    background-color: #e2e2e2;
    height: 20px;
    border-color: #ffffff #fff transparent transparent;
    border-style: solid solid solid solid;
    border-width: 0px 8px 20px 0px;
    .live-football-icon {
        margin-left: 4px;
        transform: translate(-1px, -1px);
    }
    .icon-liveInfo {
        display: none;
    }
}

.fbOddsTable .match-row-m .clock-row > div > div {
    display: flex;
    align-items: center;
    // grid-template-columns: 50px 36px 32px 32px 32px;
    justify-items: start;
    height: 20px;
    width: 218px;
    .exepcted-stop-time{
        width: 50px;
    }
    .status-and-clock{
        width: 30px;
    }
    .corner-result{
        width: 62px;
    }
    .tvChannelsContent{
        margin-left: 4px;
    }
}

.fbOddsTable .match-row-m .icon-clock>div {
    background-size: contain;
    background-repeat: no-repeat;
    width: 12px;
    height: 12px;
}

.fbOddsTable .match-row-m .corner-result {
    padding-top: 0px;
    width: 40px;
    height: auto;
    display: inline-grid;
    margin-left: 0px;
    grid-template-columns: 12px 28px;
    justify-items: start;
    align-items: center;
}

.fbOddsTable .match-row-m .corner-result span {
    height: 12px;
    text-align: center;
}

.fbOddsTable .tvChannelsContent{
    position: unset;
}
.fbOddsTable .FBTVChannelDialog{
    right: unset;
    left: calc(100% - 50px);
    top: unset;
    z-index: 997;
}
.fbOddsTable .tvChannelList .FBTVChannelDialog{
    left: 50px;
}
.fbOddsTable .MIXALLUP_DETAIL .tvChannelList .FBTVChannelDialog{
    left: 30px;
}

.fbOddsTable .match-row-m:nth-child(even) .icon-clock {
    border-color: #f4f4f4 #f4f4f4 transparent transparent;
}


.fbOddsTable section .match-row-m .team>div:nth-child(2) {
    background-color: #FFD505;
    height: 36px;
    min-width: 18px;
    color: #333333 ;
    text-align: center;
    font-size: 13px;
    border-radius: 2px;
    // margin-top: -1px;
    display: grid;
    margin-left: 8px;
    font-weight: 700;
    line-height: 18px;
}


.fbOddsTable section .match-row-m .team>div:nth-child(2)>span:first-child {
    display: block;
    position: relative;
}

.fbOddsTable section .match-row-m .team>div:nth-child(2)>span:first-child::after {
    display: block;
    content: '';
    width: 18px;
    height: 1px;
    background: #9c8201;
    position: absolute;
    bottom: 0;
    transform: scaleY(0.5);
}

.fbOddsTable section .match-row-m .team>div:nth-child(2).et {
    background-color: #00c06c;
    color: white;
}

.fbOddsTable section .match-row-m .team>div:nth-child(2).et>span:first-child::after {
    background: #fff;
}

.fbOddsTable .date-competition-selection .section-DATE>div {
    /* margin: 0px 8px; */
    width: auto;
}


.fbOddsTable section.ALLODDS .match-info,
.fbOddsTable header.ALLODDS .match-info,
.fbOddsTable header.INPLAY_ALL .match-info,
.fbOddsTable section.INPLAY_ALL .match-info,
.fbOddsTable section.MIXALLUP_DETAIL .match-info {
    padding-top: 8px;
}

.fbOddsTable section.LAST_ODDS .match-info>div,
.fbOddsTable section.ALLODDS .match-info>div,
.fbOddsTable header.ALLODDS .match-info>div,
.fbOddsTable header.INPLAY_ALL .match-info>div,
.fbOddsTable section.MIXALLUP_DETAIL .match-info>div,
.fbOddsTable section.INPLAY_ALL .match-info>div,
.fbOddsTable section.CRS .match-info>div,
.fbOddsTable section.FCS .match-info>div,
.fbOddsTable section.FGS .match-info>div,
.fbOddsTable section.MSP .match-info>div {
    grid-template-columns: none;
    // height: 24px;
}
.fbOddsTable :is(header.ALLODDS,header.INPLAY_ALL,section.MIXALLUP_DETAIL) .match-info{
    .clock-row{
        .tvChannels{
            display: none;
        }
    }
    .tvChannelList {
        display: flex;
        justify-content: center;
        margin-left: 6px;
        .tvChannels{
            font-weight: 500;
        }
    }
}

.fbOddsTable {
    .tvChannels{
        border: none;
        position: relative;
        &::after {
            position: absolute;
            bottom: 0;
            width: 200%;
            height: 200%;
            content: "";
            display: block;
            border: 1px solid #333;
            border-radius: 4px;
            transform: scale(0.5);
            transform-origin: center bottom;
        }
    }
}

.fbOddsTable section.LAST_ODDS .match-info>div:nth-child(2),
.fbOddsTable header.ALLODDS .match-info>div:nth-child(2),
.fbOddsTable header.INPLAY_ALL .match-info>div:nth-child(2),
.fbOddsTable section.ALLODDS .match-info>div:nth-child(2),
.fbOddsTable section.MIXALLUP_DETAIL .match-info>div:nth-child(2),
.fbOddsTable section.INPLAY_ALL .match-info>div:nth-child(2),
.fbOddsTable section.CRS .match-info>div:nth-child(2),
.fbOddsTable section.FCS .match-info>div:nth-child(2),
.fbOddsTable section.FGS .match-info>div:nth-child(2),
.fbOddsTable section.MSP .match-info>div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 4px;
    flex-wrap: wrap;
}

.fbOddsTable section.LAST_ODDS .match-info>div:nth-child(2)>span,
.fbOddsTable header.ALLODDS .match-info>div:nth-child(2)>span,
.fbOddsTable header.INPLAY_ALL .match-info>div:nth-child(2)>span,
.fbOddsTable section.ALLODDS .match-info>div:nth-child(2)>span,
.fbOddsTable section.MIXALLUP_DETAIL .match-info>div:nth-child(2)>span,
.fbOddsTable section.INPLAY_ALL .match-info>div:nth-child(2)>span,
.fbOddsTable section.CRS .match-info>div:nth-child(2)>span,
.fbOddsTable section.FCS .match-info>div:nth-child(2)>span,
.fbOddsTable section.FGS .match-info>div:nth-child(2)>span,
.fbOddsTable section.MSP .match-info>div:nth-child(2)>span {
    padding: 6px;
}

.fbOddsTable header.INPLAY_ALL .match-info>div:nth-child(2)>.image-H2H{
    margin: 0;
    margin: 0 4px;
}


.fbOddsTable .oddsCheckbox input[type=checkbox]+span,
.fbOddsTable .oddsCheckbox input[type=checkbox]:checked+span {
    width: 20px;
    height: 20px;
}

.fbOddsTable .oddsCheckbox input[type=checkbox]:checked+span:after {
    width: 7px;
    height: 13px;
}

.fbOddsTable .match-info .image-H2H {
    height: 24px;
    width: 24px;
}

.fbOddsTable .match-info .inplay-clock {
    padding: 6px;
}

.fbOddsTable section .pagination {
    border-bottom: none;
    border-bottom: unset;
}

/* .fbOddsTable .oddsCheckbox input[type=checkbox]+span,
.fbOddsTable .section-TOURN input[type=checkbox]+span {
    margin: 2px;
} */

.fbOddsTable .section-TOURN>.more {
    margin-top: 7px;
}

.fbOddsTable .slick-dots {
    width: 98vw;
}

.fbOddsTable .slick-dots li.slick-active button:before {
    color: #00C06C;
    font-size: 11px;
}

.fbOddsTable .slick-dots li button:before {
    font-size: 11px;
}

section.CRS .coupon-m,
section.FCS .coupon-m,
section.ECS .coupon-m,
section.CRS .match-row-container,
section.FCS .match-row-container,
section.ECS .match-row-container {
    margin: 0px 8px;
}

.crsTable {
    margin: 0px;
}

.crsTable .match-row.matrix:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.crsTable>.match-row.matrix:nth-child(odd) {
    background-color: #F4F4F4;
}


.crsTable>.match-row.matrix:nth-child(even) {
    background-color: #FFFFFF;
}

.fbOddsTable section .crsTable .match-row.matrix .odds {
    row-gap: 4px;
    grid-template-columns: 100%;
    padding: 8px 0;
    height: auto;
    width: auto;
}

.fbOddsTable section .crsTable .match-row.matrix .odds>span {
    display:none;
}

.fbOddsTable section .crsTable .match-row.matrix .odds>div {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.crsTable .oddsCheckbox {
    margin-left: 4px;
}

.crsTable .match-row.matrix>.odds>div:first-child {
    color: #333333;
}

.crsSel {
    font-size: 11px;
    justify-self: unset;
    width: auto;
}

.crsTable .oddsCheckboxGrid {
    font-size: 15px;
    font-weight: 500;
}

.crsTable .oddsCheckboxGrid .oddsValueM {
    width: 40px;
}

.fbOddsTable section.LAST_ODDS .corner-result,
.fbOddsTable header.ALLODDS .corner-result,
.fbOddsTable header.INPLAY_ALL .corner-result,
.fbOddsTable section.ALLODDS .corner-result,
.fbOddsTable section.MIXALLUP_DETAIL .corner-result,
.fbOddsTable section.INPLAY_ALL .corner-result,
.fbOddsTable section.CRS .corner-result,
.fbOddsTable section.FCS .corner-result {
    width: fit-content;
    height: 22px;
    display: inline-grid;
    position: relative;
    margin-left: 6px;
    margin-right: 6px;
    align-items: center;
    justify-items: start;
}
.fbOddsTable section.FCS .corner-result,
.fbOddsTable section.CRS .corner-result,
.fbOddsTable header.INPLAY_ALL .corner-result{
    margin-left: 0;
}

.fbOddsTable section.LAST_ODDS .oddsLine .odds,
.fbOddsTable section.ALLODDS .oddsLine .odds,
.fbOddsTable section.MIXALLUP_DETAIL .oddsLine .odds,
.fbOddsTable section.INPLAY_ALL .oddsLine .odds {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    justify-items: center;
    padding: 0;
    min-height: 38px;
    .oddsCheckboxGrid{
        grid-template-columns: auto 20px;
    }
}

.fbOddsTable section.CRS .coupon,
.fbOddsTable section.FCS .coupon,
.fbOddsTable section.FGS .coupon {
    display: none;
}

.fbOddsTable .matchDropdown-ddbtn {
    width: calc(100% - 20px);
    margin: 11px;
}

.fbOddsTable .matchDropdown-ddbtn button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 28px;
    align-content: center;
}

.fbOddsTable .matchDropdown-ddbtn button .dd-arrow {
    margin-top: -4px;
}

.fbOddsTable section.LAST_ODDS :is(.oddsTTG, .oddsETG)>div:nth-child(4), .fbOddsTable section.INPLAY_ALL :is(.oddsTTG, .oddsETG)>div:nth-child(4),
.fbOddsTable section.LAST_ODDS :is(.oddsTTG, .oddsETG)>div:nth-child(5), .fbOddsTable section.INPLAY_ALL :is(.oddsTTG, .oddsETG)>div:nth-child(5),
.fbOddsTable section.LAST_ODDS :is(.oddsTTG, .oddsETG)>div:nth-child(6), .fbOddsTable section.INPLAY_ALL :is(.oddsTTG, .oddsETG)>div:nth-child(6),
.fbOddsTable section.ALLODDS :is(.oddsTTG, .oddsETG)>div:nth-child(4),
.fbOddsTable section.ALLODDS :is(.oddsTTG, .oddsETG)>div:nth-child(5),
.fbOddsTable section.ALLODDS :is(.oddsTTG, .oddsETG)>div:nth-child(6),
.fbOddsTable section.MIXALLUP_DETAIL :is(.oddsTTG, .oddsETG)>div:nth-child(4),
.fbOddsTable section.MIXALLUP_DETAIL :is(.oddsTTG, .oddsETG)>div:nth-child(5),
.fbOddsTable section.MIXALLUP_DETAIL :is(.oddsTTG, .oddsETG)>div:nth-child(6){
    background-color: #f4f4f4;
}

.fbOddsTable .match-info [data-title]:after {
    left: -160px;
    width: 238px;
    top: 34px;
}

.fbOddsTable .match-info .icon-clock>div {
    margin-right: 0px;
}


.fbOddsTable [data-title]:after {
    width: 238px;
    top: 0px;
    bottom: 0px;
}

.fbOddsTable .icon-clock [data-title]:after,
.fbOddsTable .icon-venue [data-title]:after {
    top: 30px;
}


.fbOddsTable .match-row-m .icon-venue {
    display: inline-block;
    vertical-align: middle;
}

.fbOddsTable section .match-row-container .match-row-m-container:nth-child(even) .match-row-m .clock-row{
    border-color: #ffffff #f4f4f4 transparent transparent;
}

.fbOddsTable section.TTG .match-row-container .odds>div>div:first-child{
    border-bottom: none;
    // height: 20px;
    display: flex;
    // align-items: flex-end;
    justify-content: center;
    font-size: 13px;
    line-height: 14px;
    color: #333;
    font-weight: 400;
}

/*
.fbOddsTable section.TTG .match-row-container .odds>div>div:last-child{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
} */

.fbOddsTable section.OOE .coupon-m .desc{
    // flex-basis: 63%;
}

.fbOddsTable section.OOE .coupon-m .odds{
    /* flex-basis: 37%; */
}

.fbOddsTable section.OOE .coupon-m .odds{
    // grid-template-columns: 34% 36% 24%;
    // margin-left: 2px;
    grid-template-columns: 1fr 1fr;
}

.fbOddsTable section.OOE .match-row-container .match-row-m{
    grid-template-columns: 1fr 119px 44px
}

.ALLODDS .CHP .oddsCheckbox input[type=checkbox]:checked+span,
.INPLAY_ALL .CHP .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 4px;
    padding-left: 10px;
}

.CHP .oddsCheckbox input[type=checkbox]:checked+span,
.GPW .oddsCheckbox input[type=checkbox]:checked+span,
.TPS .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 0px;
}

.GPF .oddsCheckbox input[type=checkbox]:checked+span,
.TSP .oddsCheckbox input[type=checkbox]:checked+span {
    padding-top: 0px;
    padding-right: 8px;
}

.GPW .masonry-col>div:nth-child(1), .GPW .masonry-col>div:nth-child(2), .GPW .masonry-col>div:last-child {
    width: 100%;
    margin: 0px;
}

.gpwSelections {
    line-height: 20px;
}

.gpfHeader {
    display: block;
    border-bottom: 1px #c4c4c4 solid;
}

.gpwEsst, .gpfEsst {
    display: flex;
    justify-content: space-between;
    border-top: 1px #c4c4c4 solid;
}

.gpfSelections2 {
    display: grid;
    grid-template-columns: 140px repeat(2, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections3 {
    display: grid;
    grid-template-columns: 140px repeat(3, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections4 {
    display: grid;
    grid-template-columns: 140px repeat(4, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections5 {
    display: grid;
    grid-template-columns: 140px repeat(5, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections6 {
    display: grid;
    grid-template-columns: 140px repeat(6, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections7 {
    display: grid;
    grid-template-columns: 140px repeat(7, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpfSelections8 {
    display: grid;
    grid-template-columns: 140px repeat(8, 120px);
    width: calc(100%);
    overflow-x: auto;
    overflow-y: hidden;
    line-height: 20px;
}

.gpwSelOdds .oddsArrow, .gpfSelOdds .oddsArrow, .TSP .tspSelOdds .oddsArrow {
    margin: 6px 0px 0px 4px;
}

.fbOddsTable section.CHP .tournHeader .coupon {
    display: none;
}

.tournHeader {
    display: grid;
    grid-template-columns: 45% 55%;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 8px 0px;
}

.tournSelHeaderOdds {
    text-align: right;
    justify-content: end;
}

.tournName {
    grid-column: 1;
    padding: 0px 0px 8px 8px;
    flex-direction: column;
    align-items: flex-start;
}

.tournEsst {
    grid-row: 2;
    grid-column: 1 / 3;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 1px #e7e7e7 solid;
    padding: 8px 8px 0px 8px;
}

.tournButton {
    grid-column: 2;
    margin: 0px 8px 8px 0px;
}

.tournSelections {
    display: grid;
    grid-template-columns: 60% 25% 15%;
    line-height: 20px;
}

.tournSelOdds {
    display: flex;
    padding: 8px 4px 8px 12px;
    text-align: right;
}

.gpwSelOdds .oddsArrow.oddsUp,
.gpwSelOdds .oddsArrow.oddsDown,
.gpfSelOdds .oddsArrow.oddsUp,
.gpfSelOdds .oddsArrow.oddsDown,
.TSP .tspSelOdds .oddsArrow.oddsUp,
.TSP .tspSelOdds .oddsArrow.oddsDown {
    margin: 6px 0px 0px 4px;
}

.tournSelOdds .oddsArrow.oddsUp,
.tournSelOdds .oddsArrow.oddsDown,
.TQL .oddsArrow.oddsUp,
.TQL .oddsArrow.oddsDown {
    margin: 0px 0px 2px 0px ;
}

.tournMoreRow {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    border-top: 1px #c4c4c4 solid;
    border-radius: 0px 0px 4px 4px;
}


.fbMain .arrow-small {
    border-color: #00C06C;
    border-width: 0 2px 2px 0;
    width: 6px;
    height: 6px;
    margin-left: 14px;
    margin-bottom: 3px;
}

.arrow-up {
    /* margin-top: 4px; */
}

.ALLODDS .CHP .tournHeader, .ALLODDS .CHP .tournMoreRow, .ALLODDS .CHP .tournSelections, .ALLODDS .CHP .showAllRow,
.INPLAY_ALL .CHP .tournHeader, .INPLAY_ALL .CHP .tournMoreRow, .INPLAY_ALL .CHP .tournSelections, .INPLAY_ALL .CHP .showAllRow {
    width: calc(100vw - 16px);
    margin: 0 8px;
}

.GPF .supplement, .GPW .supplement {
    font-size: 11px;
}

.gpwSelOdds {
    display: flex;
    padding: 8px 0px 8px 16px;
}

.ALLODDS .TQL,.INPLAY_ALL .TQL {
    margin: 0 8px;
}

.TQL .font11{
    font-size: 11px;
}

.tournTqlTableM {
    margin-bottom: 12px;
    background-color: #ffffff;
}

.tournTqlTableM .tournHeader{
    display: flex;
    justify-content: space-between;
    padding: 8px;
    min-height: 46px;
    font-size: 13px;
    align-items: baseline;
    line-height: 16px;
}

.tournStage {
    text-align: right;
}

.tournTqlTableM  .tournEsst {
    padding: 8px;
}

.tournTqlTableM .tournHeader .essTime{
    text-align: right;
}

.tournTqlTableM .tournAllOddsHeader{
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    min-height: 46px;
    line-height: 16px;
    font-size: 13px;
}

.tournTqlTableM .tournAllOddsHeader .essTime{
    text-align: right;
}

.tournTqlTableM .tournSeltableTh{
    display: grid;
    grid-template-columns: 81% 12%;
    align-items: center;
    height: 24px;
    background: #e2e2e2;
    padding-right: 20px;
    padding-left: 12px;
}

.tournTqlTableM .tournSeltableTb{
    display: grid;
    grid-template-columns: 75% 22% ;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    min-height: 36px;
}

.tournTqlTableM .tournAllOddsSeltableTb {
    display: flex;
}

.tournTqlTableM .borBot{
    border-bottom: 1px solid #F4F4F4;
}

.tournTqlTableM  .tqlTnameM{
    flex-direction: row;
    width: 100%;
    min-height: 30px;
}

.tournTqlTableM .even{
    background: #f4f4f4;
}

section.ALLODDS .TQL .coupon-m, section.INPLAY_ALL .TQL .coupon-m {
    margin: 0px;
}

section.ALLODDS,section.INPLAY_ALL{
    .TQL,.CHP{
        .supplement{
            display: none;
        }
    }
    section.TQL{
        .couponBtn{
            display: block;
        }
    }
}
section.TQL{
    .couponBtn{
        display: none;
    }
}

.tqlAllOddsHeader {
    display: block;
    padding: 8px;
}

.tqlAllOddsTitle {
    padding-bottom: 8px;
}

.tqlAllOdds .tournEsst {
    padding-bottom: 8px;
    border-bottom: 1px #e7e7e7 solid;
}

.tqlAllOddsTable {
    display: grid;
    grid-template-columns: 50% 50%;
}

.tqlAllOddsSel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 8px 0px;
    gap: 0 8px;
}

.tqlSel {
    grid-column: 1 / 3;
    text-align: center;
    padding-bottom: 8px;
}

.tqlCheckbox {
    grid-column: 2;
    text-align: left;
}

.tqlOdds {
    grid-row: 2;
    grid-column: 1;
    text-align: right;
    justify-self: flex-end;
}

.tspHeader {
    display: block;
    background-color: #FFFFFF;
    padding: 0px;
}

.tspTable {
    margin-bottom: 12px;
}

.tspTitle {
    padding: 8px;
}

.tspEsst {
    display: flex;
    justify-content: space-between;
    border-top: 1px #c4c4c4 solid;
    padding: 8px ;
}

.even,
.fbOddsTable .ALLODDS .odds.oddsHFT>div:nth-child(n + 4):nth-child(-n +6) {
    background-color: #F4F4F4;
}

.tspSelections1 {
    display: block;
}

.tspSelections2 {
    display: block;
}

.tspSelections3 {
    display: block;
}

.tspSelections4 {
    display: block;
}

.tspSelections5 {
    display: block;
}

.tspSelections6 {
    display: block;
}

.tspCell {
    border-right: 0;
    display: grid;
    grid-template-columns: 80% 20%;
}

.tspAns {
    grid-column: 1;
    text-align: left;
    padding-left:8px;
}

.tspCellGrid {
    display: grid;
    grid-template-columns: 65% 25% 10%;
    padding-top: 8px;
    line-height: 20px;
    align-items: center;
}

.tspSelChkbox {
    grid-column: 3;
    text-align: left;
    display: flex;
}

.tspSelOdds {
    text-align: right;
    grid-row: 1;
    grid-column: 2;
    padding: 0px 4px 0px 16px;
}

.fbOddsTable section.LAST_ODDS .oddsLine .arrow-down {
    display: none;
}

.nts-row-m>div {
    display: flex;
    justify-content: space-between;
    padding: 6px 8px;
    height: 32px;
    border-top: 1px #B6B6B6 solid;
    margin: 0px;
    // height: 27px;
    & button{
        line-height: 18px;
    }
}
.fbOddsTable header.ALLODDS .match-info .scoring-info,
.fbOddsTable header.INPLAY_ALL .match-info .scoring-info,
.fbOddsTable section .match-info .scoring-info {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
}

.fbOddsTable section.INPLAY_ALL .match-info .status {
    align-items: flex-start;
    flex-direction: row;
    align-items: center;
}

.fbOddsTable section.INPLAY_ALL .match-info .status>div {
    margin-right: 10px;
}

.fbOddsTable section.INPLAY_ALL .match-info .status .dropdown-menu:before {
    left: 65px;
    right: unset;
}

.en .fbOddsTable section.INPLAY_ALL .match-info .status .dropdown-menu:before {
    left: 88px;
    right: unset;
}


.fbOddsTable section .match-info .match-status-table .content>div {
    line-height: 21px;
    padding: 0px 10px;
    background-color: #F4F4F4;
}

.fbOddsTable  header.MIXALLUP_DETAIL .match-info .scoring-info .dropdown-menu.show,
.fbOddsTable  section.MIXALLUP_DETAIL .match-info .scoring-info .dropdown-menu.show{
    transform: translate(31px, 32px) !important;
    width: calc(100vw - 16px);
}

.fbOddsTable  header .match-info .scoring-info .dropdown-menu.show,
.fbOddsTable  section .match-info .scoring-info .dropdown-menu.show{
    transform: translate(11px, 32px) !important;
    width: calc(100vw - 16px);
}

.fbOddsTable section .match-info .status .dropdown-menu.show{
    padding: 0px;
    width: calc(100vw - 16px);
    transform: translate3d(-66px, 32px, 0px) !important;
    border-radius: 10px;
    inset: 0 0 0 0!important;
}

.en .fbOddsTable section .match-info .status .dropdown-menu.show{
    padding: 0px;
    width: calc(100vw - 16px);
    transform: translate3d(-80px, 32px, 0px) !important;
    border-radius: 10px;
    inset: 0 0 0 0!important;
}

.fbOddsTable .LAST_ODDS .oddsTTG,
.fbOddsTable .INPLAY_ALL .oddsTTG,
.fbOddsTable .ALLODDS .oddsTTG,
.fbOddsTable .MIXALLUP_DETAIL .oddsTTG,
.fbOddsTable .LAST_ODDS .oddsETG,
.fbOddsTable .INPLAY_ALL .oddsETG,
.fbOddsTable .ALLODDS .oddsETG,
.fbOddsTable .MIXALLUP_DETAIL .oddsETG,
.fbOddsTable .INPLAY_ALL .oddsHFT,
.fbOddsTable .ALLODDS .oddsHFT,
.fbOddsTable .MIXALLUP_DETAIL .oddsHFT,
.fbOddsTable .LAST_ODDS .oddsHFT {
    display: grid;
    grid-template-columns: repeat(3, 33%);
    background-color: #FFFFFF;
    gap: 0px;
}

.fbOddsTable .LAST_ODDS .odds.oddsTTG>div,
.fbOddsTable .INPLAY_ALL .odds.oddsTTG>div,
.fbOddsTable .ALLODDS .odds.oddsTTG>div,
.fbOddsTable .MIXALLUP_DETAIL .odds.oddsTTG>div,
.fbOddsTable .LAST_ODDS .odds.oddsETG>div,
.fbOddsTable .INPLAY_ALL .odds.oddsETG>div,
.fbOddsTable .MIXALLUP_DETAIL .odds.oddsETG>div,
.fbOddsTable .ALLODDS .odds.oddsETG>div,
.fbOddsTable .LAST_ODDS .odds.oddsHFT>div,
.fbOddsTable .INPLAY_ALL .odds.oddsHFT>div,
.fbOddsTable .ALLODDS .odds.oddsHFT>div,
.fbOddsTable .ALLODDS .odds.oddsHFT>div,
.fbOddsTable .MIXALLUP_DETAIL .odds.oddsHFT>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fbOddsTable section.CRS .match-dropdown-container,
.fbOddsTable section.FCS .match-dropdown-container,
.fbOddsTable section.FGS .match-dropdown-container,
.fbOddsTable section.ALLODDS .match-dropdown-container,
.fbOddsTable header.ALLODDS .match-dropdown-container,
.fbOddsTable header.INPLAY_ALL .match-dropdown-container,
.fbOddsTable section.INPLAY_ALL .match-dropdown-container,
.fbOddsTable section.CRS .match-info,
.fbOddsTable section.FCS .match-info,
.fbOddsTable section.FGS .match-info,
.fbOddsTable section.ALLODDS .match-info,
.fbOddsTable header.ALLODDS .match-info,
.fbOddsTable header.INPLAY_ALL .match-info,
.fbOddsTable section.INPLAY_ALL .match-info{
    /* margin: 0 8px; */
    /* border-radius:  0 0 4px 4px; */
}

.fbOddsTable header.INPLAY_ALL .match-dropdown-container,
.fbOddsTable header.ALLODDS .match-dropdown-container{
    margin-top: 0;
    padding-right: 0px;
}

.fbOddsTable section.LAST_ODDS .team.FGS {
    width: 100%;
    margin: 0px;
}

.fbOddsTable section.LAST_ODDS .match-row-container.FGS {
    margin: 0px;
}

.fbOddsTable .date-competition-selection section {
    margin-bottom: 0px;
}

.fbOddsTable .date-competition-selection .mobileHideTourn {
    display: none;
}

.fbOddsTable .section-TOURN > .control{
    margin-bottom: 12px;
}

.fbOddsTable .section-TOURN > .control .right button {
    width: 100px;
}

.fb-top-betslip, .fb-bottom-betslip {
    display: none;
}

.chpres-table {
    display: grid;
    grid-template-columns: 20% 40% 40%;
    align-items: center;
}

.flag-cell {
    display: none;
}

.chpres-table .tourn-cell {
    padding: 14px 0px 16px 4px;
}

.tourn-result-header {
    display: block;
    background: #405A55;
    color: #FFF;
    padding: 9px 0px 9px 12px;
    margin-top: 12px;
    border-radius: 4px 4px 0px 0px;
}

.MIXALLUP_DETAIL .headLbl, .MIXALLUP_DETAIL .hideM {
    display: none;
}

.fbOddsTable .allup-control {
    justify-content: center;
}

.allup-control .btnReset {
    margin: 0px;
}
#footbal-allup-detail-cal-panel {
    width: 100%;
    // margin: -5px 0;
    // margin-bottom: -10px;
}
.allup-cal-panel-set {
    background-color: #ffffff;
    padding: 0px;
}

.allup-cal-panel {
    display: block;
}

.allup-cal-panel-result {
    position: relative;
    padding-bottom: 12px;
    margin-right: 0;
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel-result {
    padding-bottom: 3px;
}

.allup-cal-panel-set:not(.sticky-bottom)  .allup-cal-panel-result::after {
    content: '';
    // width: calc(100% - 24px);
    width: 100%;
    height: 1px;
    background-color: #e7e7e7;
    position: absolute;
    // left: 12px;
    bottom: 0;
}

.allup-cal-panel-btn {
    margin-top: 12px;
    justify-content: center;
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel-btn {
    margin-top: 0;
    padding: 0 12px;
    justify-content: space-between;
    height: 44px;
    align-items: center;
    .text-button {
        text-decoration: underline;
        cursor: pointer;
        color: #333;
        font-size: 13px;
        font-weight: 500;
    }
}

.allup-cal-panel-set:not(.sticky-bottom) .allup-cal-panel-btn>div {
    margin: 0px 6px;
}

.allup-cal-panel-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel-input {
    border-top: 1px solid #e7e7e7;
    padding: 8px 12px 0px 12px;
    width: unset;
    grid-template-columns: auto auto;
    justify-content: space-between;
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-formula-dd .dropdown-menu.show{
    min-width: 136px;
    inset: auto auto 8px 0px !important;
    box-shadow: 0 0 18px 3px #0000005c;
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-formula-input {
    div {
        position: relative;
        span {
            position: absolute;
            top: 8px;
            left: 8px;
            color: #333;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
        }
        input {
            width: 188px;
            color: #333;
        }
    }
}

.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel {
    width: unset;
    padding: 12px 12px 0 12px;
    padding-bottom: 0;
}
.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel-result > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.allup-cal-panel-set:is(.sticky-bottom) .allup-cal-panel-result > div > :first-child {
    height: unset;
}

.allup-formula-dd, .allup-formula-input {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.allup-cal-quick-btn-set>div {
    width: 136px;
    grid-template-columns: auto auto;
    margin-top: 12px;
    justify-content: space-between;
    row-gap: 12px;
}

.fbOddsTable .allup-cal-content {
    padding: 0px;
}

.allup-cal-panel-input #ddAllupFormula, .allup-cal-panel-input input[type=text] {
    margin: 0px;
    width: 136px;
    height: 28px;
}

.allup-cal-quick-btn {
    border: 1px solid #333333;
    text-align: center;
    margin: 0;
    padding: 2px;
    width: 60px;
    cursor: pointer;
}
.fbOddsTable section.MIXALLUP_DETAIL_SINGLE{
    margin:12px 8px 0;
}

.fbOddsTable section.MIXALLUP_DETAIL_SINGLE .match-info {
    margin-bottom: 0px;
}

.oddsTTG .oddsCheckboxGrid, .oddsETG .oddsCheckboxGrid, .oddsHFT .oddsCheckboxGrid {
    grid-template-columns: 60% 40%;
    justify-items: flex-start;
    gap: 4px;
}

.match-info-2nd-row-m {
    font-size: 11px;
    color: #333333;
    font-weight: 400;
    > :nth-child(2){
        // width: min-content;
    }
    .match-info-2nd-row-ftScore-m{
        white-space: nowrap;
    }
}

.fbOddsTable section.HIL .oddsLine,
.fbOddsTable section.FHL .oddsLine,
.fbOddsTable section.FCH .oddsLine,
.fbOddsTable section.CHL .oddsLine {
    padding: 0;
}

.fbOddsTable section.CRS .match-row-m .oddsLine,
.fbOddsTable section.FCS .match-row-m .oddsLine {
    grid-template-columns: 1fr auto;
    column-gap: 12px;
    margin: 0px;
    padding: 20px 0;
}


.fbOddsTable section.CRS .match-row-m .crs-odds-box,
.fbOddsTable section.FCS .match-row-m .crs-odds-box{
    display: grid;
    grid-template-columns: auto auto;
    gap: 12px 0;
    .oddsCheckboxGrid {
        width: 40px;
    }
    .suspended {
        margin-bottom: -8px;
    }
}
.fbOddsTable section  .match-row-m .image-H2H {
    margin-right: 8px;
    margin-left: auto;
    background-color: #E2E2E2;
    height: 60px;
    border-radius: 2px;
    margin-top: auto;
    margin-bottom: auto;
    align-self: flex-start;
}

.fbOddsTable section  .match-row-m .image-H2H:hover {
    background-color: rgb(0, 192, 108);
}

.fbOddsTable section{
    &.FGS, &.MSP ,&.SGA, &.LGS, &.AGS{
        .match-row-m .oddsLine{
            display: flex;
            justify-content: flex-end;
            margin: 0px;
        }
    }
}

.oddsLine.CRS .oddsCheckboxGrid .oddsCheckbox,
.oddsLine.FCS .oddsCheckboxGrid .oddsCheckbox {
    padding: 8px 0px;
}

.crs-label {
    font-size: 13px;
    text-align: center;
    line-height: 14px;
    font-weight: 400;
    margin-bottom: 8px;
}

.sgaTable {
    background-color: #ffffff;
}

.sgaCellGrid {
    grid-template-columns: 1fr auto;
    padding-left: 12px;
    padding-right: 12px;
    .sgaAns {
        padding-left: 0px;
        margin-right: 8px;
        width: 100%;
    }
}

.sgaSelChkbox {
    grid-column: 3;
    grid-row: 1;
}

.sgaSelOdds {
    grid-column: 2;
    grid-row: 1;
    text-align: right;
    padding-right: 2px;
    .sgaAns {
        color: #333333
    }
}

.CHP .acceptInplayOnly {
    border-top: 1px solid #e7e7e7;
}

.TQL .acceptInplayOnly {
    grid-column: 1 / 4;
    height: 44px;
}

.crsRemarks {
    margin: 0 8px;
    width: calc(100% - 16px);
    > p {
        margin-bottom: 0;
    }
    header:is(.CRS, .FCS) &{
        margin: 0;
        width: 100%;
        padding: 8px 12px;
    }
}
.GSTabTable{
    .crsRemarks {
        margin: 0;
        width:100%;
    }
}
section.MIXALLUP_DETAIL .crsRemarks{
    margin: 0;
    width: 100%;
}
.sgaRemarks {
    height: auto;
}
header.SGA .sgaRemarks {
    display: none;
}

.fbOddsTable .allup-cal .expand:after,
.fbOddsTable .allup-cal .collaspse:after,
.fbOddsTable .allup-cal .collaspse::before {
    display: none;
}

.ALLODDS .MSP .oddsArrow {
    margin: 6px 0px 0px 4px;
}

.ALLODDS .SGA .oddsArrow {
    margin: 4px 0px 0px 4px;
    position: relative;
    top: -13px;
    left: -24px;
}
.refreshContainer {
    height: 32px;
    padding: 9px 4px;
    display: flex;
    justify-content: flex-end;
    width: calc(100vw - 16px);
    margin: 0 8px;
}

.fbOddsTable .remark {
    font-size: 11px;
    line-height: 14px;
    margin:16px 0 9px;
}
.fbOddsTable .MIXALLUP .remark {
    margin-bottom: 24px;
}
.fbOddsTable .mobileMarginLR .remark {
    margin-left: 8px;
    margin-right: 8px;
}

.fbOddsTable .MIXALLUP_DETAIL .remark {
    margin-left: 8px;
    margin-right: 8px;
}

.searchNoMatchResult{
    background-color: unset;
    margin-top: 120px;
    height: auto;
    box-shadow: unset;
    .searchNoMatchResult-content{
        display: block;
        line-height: 18px;
        margin-bottom: 24px;
        font-weight: 400;
    }
    .searchNoMatchResult-button{
        width: 140px;
    }
}
.hasO .crs-label {
    min-height: 28px;
}
section:is(.FCS, .CRS) .oddsArrow {
    margin: 0;
}
section:is(.FCS, .CRS) .oddsArrow:is(.oddsUp, .oddsDown) {
    margin: 0 0 2px 0;
}
.fbOddsTable .date-competition-selection section.section-SEARCH{
    padding: 0;
    box-shadow: none;
}
.fbOddsTable .matchDropdown-ddbtn .dropdown-menu{
    max-height: 250px;
    max-width: 100%;
    .dropdown-item{
        white-space: pre-line;
    }
}
// .FBMatchListLoadMoreButton{
//     border: none;
// }

.fbOddsTable section .match-row-container .odds span:hover{
    text-decoration: none;
}
}
.fbOddsTable section .match-row-container .odds span:hover{
    text-decoration: none;
}
}