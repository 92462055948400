@import './common-mixin.scss';

.ewinMain {
    width: 100%;
}

.ewinInfoGrid {
    display: grid;
    grid-template-columns: minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr);
    grid-template-areas:
        'a b c'
        'd e f'
        'g h i';
    grid-column-gap: 12px;
    grid-row-gap: 2px;
    grid-auto-flow: column;
    margin-top: 12px;
}

.ewinInfoGrid > div {
    box-shadow: var(--box-shadow);
}

.ewinInfoGrid>div:nth-child(3n) {
    border-radius: 0 0 4px 4px;
    overflow: hidden;
}

.blockHeader {
    color: #ffffff;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 12px;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
    font-weight: 500;
}

.blockHeader .dd-arrow {
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
}

.ewinMain .mobile-tab-set {
    display: grid;
    grid-template-columns: 34% 33% 33%;
    justify-items: center;
    position: relative;
    width: 100%;
    height: 36px;
    line-height: 32px;
    background-color: #eaeaea;
    cursor: pointer;
}

.ewinMain .HRBlock .mobile-tab-set, .blockRacingLink {
    color: #122c68;
}

.ewinMain .FBBlock .mobile-tab-set, .blockFootballLink {
    color: #405a55;
}

.ewinMain .LYBlock .mobile-tab-set, .blockM6Jackpot, .blockM6Est, .blockMarksixLink {
    color: #980b0b;
}

.mobile-tab-set .tab-highlight {
    border-bottom: 4px solid #FEC107;
}

.ewinMain .mobile-tab-set .line {
    position: absolute;
    bottom: 0;
    transition: left 0.5s ease-in-out, width 0.5s 0.10s;
    height: 4px;
    background-color: #FEC107;
    left: 0;
    width: 33%;
    pointer-events: none;
}

.HRBlock .blockBody, .FBBlock .blockBody, .LYBlock .blockBody {
    background-color: #ffffff;
    height: 144px;
}

.LYBlock .blockBodyContent{
    display: flex;
    align-items: center;
}

.HRQuickPick .blockBody, .HRUsefulLinks .blockBody,
.FBSGABlock .blockBody, .FBUsefulLinks .blockBody,
.LYQuickPick .blockBody, .LYUsefulLinks .blockBody {
    background-color: #ffffff;
    height: 180px;
}

.HRQuickPick .blockBody,
.FBSGABlock .blockBody,
.LYBlock .blockBody,
.LYQuickPick .blockBody{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include font13();
}

.M6NoInfo{
    padding-top: 12px;
    padding-left: 12px;
}

.blockGenaralInfoBody {
    display: grid;
    grid-template-columns: auto auto;
    padding : 12px 0px;
}

.blockPoolInfoTitle {
    display: grid;
    grid-template-columns: auto auto;
    padding : 12px 0px 8px 0px;
}

.blockPoolInfoBody {
    display: grid;
    grid-template-columns: 50% 50%;
    padding : 8px 0px;
}

.blockGenaralInfoBody>div, .blockPoolInfoTitle>div, .blockPoolInfoBody>div {
    padding: 0px 12px 5px 12px;
}

.blockGenaralInfoBody .span2, .blockPoolInfoBody .span2 {
    grid-column: 1 / 3;
}

.blockChanges {
    padding: 8px 12px 12px 12px;
}

.blockChanges>div {
    padding: 4px 0px;
}

.blockChangesRow {
    width: calc(100% - 40px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: #122C68;
}

.blockTTQuickPickHead {
    display: grid;
    grid-template-columns: 42% 58%;
    padding : 12px;
}

.blockTTQuickPickHead>div:nth-child(2) {
    text-align: right;
}

.blockTTTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 80px;
    .right-border::after {
        right: 8px;
        width: 1.5px;
        background-color: #b4b4b4;
    }
}

.blockTTTable>div {
    border-bottom: getWidth(1) solid #e7e7e7;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.blockTTTable>div:nth-child(-n+3) {
    color:#122c68;
    @include font11();
}

.blockTTTable>div:nth-child(n+4):nth-child(-n+6) {
    background-color: #f4f4f4;
}

.blockFeaturedMatches {
    display: grid;
    grid-template-columns: 60px 56px calc(100% - 176px) 30px 30px;
    align-items: center;
    justify-items: center;
}

.blockFeaturedMatches>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.blockFeaturedMatches .center {
    align-items: center;
    width: 100%;
    height: 100%;
}

.blockFeaturedMatches .team {
    padding: 8px;
    color: #405A55;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-weight: 500;
}

.blockFeaturedMatches .icon_clock_image {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 19px;
    margin-left: 8px;
}

.blockFeaturedMatches .image-H2H {
    margin-left: 0px;
}

.blockMatchDate {
    grid-column: 1 / 6;
    @include font11();
    background-color: #F4F4F4;
    width: 100%;
    line-height: 24px;
    padding-left: 12px;
}

.blockSGAHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding : 6px 0px;
}

.blockSGAHead>div {
    padding: 0 12px 0 12px;
}

.blockSGAHead .dd-arrow {
    border: solid #00c06c;
    border-width: 0 2px 2px 0;
    cursor: pointer;
}

.blockSGATable {
    padding: 12px;
    height: 80px;
}

.blockSGATable .blockMsg {
    padding-top: 6px;
}

.blockSGATable .matchInfo {
    @include font11();
}

.blockSGATable .sgaDtls {
    padding-top: 8px;
    @include Body();
}

.blockDrawResultHead {
    /* display: grid;
    grid-template-columns: 41% 59%; */
    display: flex;
    justify-content: space-between;
    padding : 6px 0px;
}

.blockDrawResultHead>div {
    padding: 6px 12px;
    &:nth-child(1) {
        font-weight: 500;
    }
}

.blockDrawResultHead .df-date {
    display: flex;
    align-items: center;
}

.blockDrawResultHead  .df-date>div {
    padding: 0px 12px;
}

.blockDrawResultHead  .df-date .right-border::after {
    height: 15px;
    top: 0;
}

.blockDrawResultHead>div:nth-child(2) {
    text-align: right;
}

.blockDrawResultBody, .blockQuickPickBody {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 14px;
    padding-right: 14px;
}

.blockQuickPickBody {
    /* min-height: 80px;
    max-height: 80px; */
    justify-content: center;
}

.blockDrawPlus {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    padding: 0px 15px 0px 9px;
}

.blockDrawBall {
    margin: 8px 0px 0px 0px;
}

.blockDrawResultBottom {
    padding: 12px;
}

.blockNextDrawBody {
    display: grid;
    grid-template-columns: auto auto;
    padding: 12px;
}

.blockNextDrawBody>div {
    padding: 0px 0px 5px 0px;
}

.blockNextDrawBody>div:nth-child(even) {
    text-align: right;
}

.blockM6Est {
    @include font15();
}

.blockItemBody {
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
}

.blockItemBody>div:nth-child(1) {
    min-width: 150px;
    display: flex;
    align-items: center;
}

.blockItemBody>div:nth-child(2) {
    display: flex;
    align-items: center;
    padding-left: 8px;
}

.blockStatBall {
    margin: 4px 0px 0px -4px;
}

.blockButton {
    display: flex;
    align-items: center;
    // justify-content: space-evenly;
    padding: 12px;
    gap: 24px;
    justify-content: center;
}

.blockButton .btn {
    width: 100px;
    height: 28px;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
    padding: 0;
    line-height: 14px;
    font-weight: 500;
}

.blockChangesMore, .blockRacingReset {
    background: #6176a0;
    color: #ffffff;
}

.blockChangesMore:hover
{
    color: #ffffff;
}

.blockFootballRandom {
    background: #569171;
    color: #ffffff;
}

.blockMarksixReset {
    background: #cb7b7b;
    color: #ffffff;
}


.blockAddToSlip {
    background: #fecf13;
    color: #122c68;
}

.blockMsg {
    text-align: center;
    background-color: #ffffff;
}

.blockMsg2 {
    padding: 12px;
    background-color: #ffffff;
    align-self: center;
}

.width80per {
    width: 80%;
}

.blockBodyCenter {
    justify-content: center;
    align-items: center;
    padding: 8%;
}

.HRBlock .blockMsg2,
.FBBlock .blockMsg2,
.LYBlock .blockMsg2 {
    background-color: #ffffff;
    height: 144px;
}

.FBBlock .blockMsg2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &>div:nth-child(2){
        text-align: center;
    }
}
.FBSGABlock .blockMsg2{
    text-align: center;
}

.blockMarksixLink, .blockRacingLink, .blockFootballLink {
    cursor: pointer;
}

.blockUsefulLinks {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 140px;
    padding: 2%;
}

.blockUsefulLinks>div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.blockUsefulLinks>div>div {
    @include font11();
    cursor: pointer;
    text-align: center;
}

.blockUsefulLinks>div>div:nth-child(2) {
    height: 40px;
    width: 52px;
}

@media only screen and (max-width: 1279px) {
    .blockStatBody {
        padding: 8px 0 0 8px;
    }

    .blockStatBall {
        margin: 4px 0px 0px -8px;
    }

    .blockStatBall img{
        width: 28px;
    }
}

.ewinMain .HRQuickPick .right-border:nth-child(3n)::after {
    background: none
}

/* @media only screen and (max-width: 1279px) {
    .ewinMain {
        width: 948px;
    }

    .blockStatBody {
        grid-template-columns: 45% 55%;
    }

    .en .ewinMain .FBBlock .mobile-tab-set {
        grid-template-columns: 40% 30% 30%;
    }

    .en .ewinMain .FBBlock .mobile-tab-set .line {
        width: 40%;
    }
}

@media only screen and (max-device-width: 1024px) {
    .ewinMain {
        width: 754px;
    }

    .blockDrawResultHead {
        grid-template-columns: 45% 20% 35%;
    }

    .blockDrawBall {
        margin: 8px 0px 0px -10px;
    }

    .blockQuickPickBody {
        min-height: 70px;
        max-height: 80px;
    }

    .en .ewinMain .FBBlock .mobile-tab-set {
        grid-template-columns: 45% 30% 25%;
    }

    .en .ewinMain .FBBlock .mobile-tab-set .line {
        width: 45%;
    }
} */