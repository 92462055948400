body {
    background-color: #e1e1e1;
}

.payoutTime {
    width: 100%;
    font-size: 13px;
	margin: 0px;
}

.ptTitle {
    background-color: #405a55;
    padding: 12px 24px;
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
}

.invisibleBlock {
    display: none;
}

.ptClose {
    width:20px;
    height:20px;
    position: relative;
    cursor:pointer;
}

.ptClose:before,.ptClose:after{
    content:'';
    position:absolute;
    width:18px;
    height:2px;
    background-color:#FFFFFF;
    border-radius:2px;
    top:8px;
    box-shadow:0 0 2px 0 #ccc;
}

.ptClose:before{
    transform:rotate(45deg);
    left:1px;
}

.ptClose:after{
    transform:rotate(-45deg);
    right:1px;
}

.ptHeader {
    padding: 16px 20px;
    background-color:#FFFFFF;
    box-shadow: var(--box-shadow);
}

.ptHeader u {
    color: #405a55;
}

.ptFooter {
    padding: 8px 32px;
}

.ptRemarks {
    padding: 8px 32px;
}


.ptTournTable {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    background-color: #ffffff;
    margin: 12px;
    border-radius: 4px;
    box-shadow:  var(--box-shadow);
    /* overflow: hidden; */
}

.ptTournItem {
    display: flex;
    padding: 10px;
}

.ptTournFlag img {
    width: 24px;
    height: 21px;
}

.ptTournName {
    padding-left: 8px;
    word-break: break-word;
}

.ptRGP .footer-banner-media {
    display:none;
}

@media only screen and (max-width: 768px) {
    .ptTitle {
        justify-content: center;
    }

	.invisibleBlock {
		display: block;
	}

    .ptTournTable {
        grid-template-columns: 50% 50%;
    }


    .ptRGP .footer-banner-media {
        display:flex;
    }

    .ptRGP .footer-banner-media {
        visibility: hidden;
    }
}