@import './racing.scss';
@import './football.scss';
@import './screen_1280.scss';
@import './betslip_login.scss';
@import './cashout.scss';
@import './betSlip.scss';

@media only screen and (max-width: 1279px) {
    .secMenu {
        margin-right: 1%;
    }
    // .topNav ul {
    //     width: 310px;
    // }
    .en .topNav ul {
        gap: 0 0.8%;
        justify-content: space-between;
    }
    .secMenuItem{
        width: 100px;
        &:nth-child(3) {
            width: 106px;
        }
    }
    .secMenuItem:first-child{
        width: 130px;
    }
    .secMenuItem:last-child{
        width: 90px;
    }
    .main-content {
        .left-content {
            width: calc(100% - ((100% - 72px) / 4 + 16px));

            // flex: 1;
            .leftMenuMain {
                min-width: calc((100% - 40px) / 6);
                width: calc((100% - 40px) / 6);
            }

            #info_top_block {
                min-width: 198px;
                width: calc((100% - 40px) / 6);
            }

            .rcOddsTable, .fbOddsTable {
                width: calc(100% - (100% - 40px) / 6 - 8px);
            }
        }
        .betslip-container {
            width: calc((100% - 72px) / 4 + 16px);
        }
    }
    .betslip-width.limit-width {
        min-width: calc((100% - 72px) / 4 + 16px);
        max-width: calc((100% - 72px) / 4 + 16px);
    }
    #divBetSlipNormal {


        .add-slip {
            .send, button.send {
                width: 148px;
            }
        }
        .control{
            height: auto;
            .allup-container{
                flex-wrap: wrap;
            }
            .allup-container>div:first-child{
                width: 100%;
                height: 24px;
        }

        }
    }
    #info_top_block{
        // height: 210px !important;
        // .tab-pane >div{
        //     height:  150px !important;

        //     padding-bottom: 12px;
        //     min-height: unset !important;
        //     max-height: unset !important;
        //     & > div{
        //         // height: 100%;
        //         overflow-x: hidden !important;
        //         min-height: unset !important;
        //         max-height: unset !important;
        //         padding-bottom: 12px;
        //     }
        // }
    }
    #divTopBanner {
        // height:  210px !important;
    }
    #betslip-panel .bet-line .content.COContent .betLineContentTop .right {
        .fb-preview-amount > span, .coAmountChangeText{
            white-space:normal;
        }
    }
    .rcMain, .fbMain, .marksixMain, .ewinMain {
        padding: 8px 8px 0;
      }
}
