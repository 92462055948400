.FTInbox {
  width: min(800px, 100%);
  min-width: 783px; // 预留 17px滚动条位置
  min-height: 630px;
  font-size: 13px;
  background-color: #e7e7e7;
  margin: 0px;
  position: relative;
  cursor: default;
  .FTInboxTitle {
    padding: 12px 24px;
    font-size: 15px;
    color: #FFFFFF;
    display: flex;
    background-color: #003c84;
    align-items: center;
  }
  .loadTimgOutImg{
    width: 0;
    height: 0;
  }
  .FTInboxHelp{
    height: 16px;
    width: 16px;
    margin-right: 10px;
    margin-left: 10px;
    background-image: url(../../../info/Include/images/ic_help_white.svg);
    background-size: cover;
    cursor: pointer;
  }


  .FTInboxHeader {
    width: 100%;
    background-color: #eaeaea;
    display: flex;
  }
  .result-status{
    height: 74px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    font-size: 15px;
    font-weight: bold;
    div:nth-child(2){
      margin-top: 2px;
    }
  }
  .redWarningIcon{
      width: 24px;
      min-width: 24px;
      height: 24px;
      color: #fff;
      background-color: #E10004;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 8px;
      font-size: 16px;
  }

  .FTInboxHeaderCell {
    // width: 33.33%;
    flex: 1;
    display: flex;
    height: 40px;
    justify-content: space-between;
  }

  .FTInboxHeaderCellInner {
    width: 100%;
    height: 36px;
    // text-align: center;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .firstCell{
    padding-left: 15px;
  }
  .FTInboxHeaderCell.active {
    color:   #003C84;
    border-bottom: 4px solid #fecf13;
  }
  .acInfo {
    width: 100%;
    background-color: #FFF7D5;
    height: 40px;
    line-height: 20px;
    font-weight: bold;
    box-shadow: 0px 5px 9px -2px rgba(0, 0, 0, 0.13);
    .acInfoCell{
      display: inline-block;
      width: 100%;
      .labelText{
        padding-left: 24px;
        padding-right: 12px;
      }
      .colorBule{
        color:   #003C84;
      }
    }
    .borderLLine{
      margin: 10px 0;
      height: 20px;
    }
  }
  .epscoPinContainer{
    width: 455px;
    padding: 15px 0;
    font-weight: normal;
    .epscoPinTop{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      .epscoPinInput{
        align-items: center;
        justify-content: space-between;
        width: 352px;
        &>div {
          white-space: pre-wrap;
        }
        input {
          width: 182px;
          height: 32px;
        }
      }
    }
    .epscoPinBottom{
      display: flex;
      justify-content: space-between;
      .pinList{
        width: 352px;
        justify-content: space-between;
        .epscoPinNumberContainer{
          .epscoPinNumber{
            width: 28px;
            font-size: 15px;
            height: 28px;
            border-radius: 2px;
            border: 2px solid #003C84;
            display: flex;
            color: #003C84;
            justify-content: center;
            align-items: center;
            user-select: none;
            &:active{
              background-color: #003C84;
              color: #FFF;
            }
          }
        }
      }
    }
    .deposit-btn{
      min-width: 84px;
      margin: 0;
      margin-left: 10px;
      user-select:unset;
      cursor: pointer;
    }
  }
  .deposit-main{
    width: 100%;
    font-size: 13px;
    .deposit-form{
      width: 100%;
      margin-top: 12px;
    }
  }
    .bannerBox {
      width: 480px;
      height: 80px;
      margin: 0 auto;
      margin-top: 12px;
      margin-bottom: 12px;
    }
  .FT-form-content{
    margin: 12px;
    background-color: #FFF;
    overflow: hidden;
    border-radius: 4px;
    padding: 6px 16px;
    box-shadow: var(--box-shadow);
    .formItem{
        display: flex;
        border-bottom:1px solid #dfdfdf;
        padding: 10px 0;
        .contentBlue{
          color: #003C84;
        }
        &:last-child{
            border: none;
        }
        .formItemLabel{
            width: 30%;
        }
        .formItemContent{
            width: 70%;
            .contentTop{
                margin-bottom: 8px;
            }

            .contentBottom{
                padding-right: 12px ;
                white-space: pre-wrap;
                color: #333333;
                line-height: 14px;
                font-weight: 400;
            }
            .contentBlue{
              color: #003C84;
            }
            .eftAccountBtnArea{
              display: flex;
              width: 100%;
              gap: 8px;
              margin-bottom: 12px;
            }
            .eftAccountBtn{
              width: 286px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 4px;
              background-color: #e5e5e5;
              color: #333333;
              cursor: pointer;
              padding: 0 8px;
            }
            .eftAccountBtn.active{
              background-color: #003C84;
              color: #FFFFFF;
            }
            .eftAccountBtn.disabled{
              color: #b4b4b4;
              cursor: default;
            }
            .itemMult{
                display: flex;
                margin-bottom: 5px;

                .reactRadio{
                  min-width: 24px;
                }
                .bankInfo{
                  padding:0 12px 12px 0;
                }
                .singleLegMsg{
                  margin: 8px 0;
                  color: #333;
                  &>span{
                    color: #003c84;
                    cursor: pointer;
                    &:hover{
                      text-decoration: underline;
                    }
                  }
                }
            }
            .itemMult.disabled {
              color: #b4b4b4;
            }
            .method-dim{
              color: #b4b4b4;
            }
            .otherMeansBtn{
                height: 24px;
                font-size: 11px;
                display: flex;
                align-items: center;
                color: #003C84;
                margin-left: 24px;
                cursor: pointer;
                .dd-arrow{
                    width: 8px;
                    height: 8px;
                    border-color: #003C84;
                    border-width: 0 1px 1px 0;
                    margin-left: 8px;
                }
                .dd-arrow-up{
                    margin-top: 8px;
                }
                .dd-arrow-down{
                    margin-bottom: 3px;
                }
            }
            .pps-msg a{
              text-decoration: underline;
            }
        }

    }
    .contentTopBottom{
      margin-bottom:8px ;
    }
  }
  .deposit-registerFPS-msg{
    margin: 0 12px 10px;
    padding: 0 4px;
    font-size: 11px;
  }
  .deposit-prev{
    width: 100%;
    line-height: 18px;
    .deposit-prev-title{
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin: 8px 0 8px 28px;
    }
  }
  .fps-charge-tips{
    margin: 12px;
    background-color: #FFF;
    overflow: hidden;
    border-radius: 8px;
    padding: 16px;
    font-size: 13px;
    color: #333333;
  }
  .FT-valid-content{
    .deposit-btnArea{
      // position: absolute;
      // bottom: 0;
      margin-top: 24px;
    }
  }

  .deposit-btnArea{
    // margin-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 24px;

  }
  .goToLinkBank{
    color: #003C84;
    cursor: pointer;
    &:hover{
      text-decoration: underline;
    }
  }
  .deposit-btn{
    min-width: 100px;
    padding: 0 12px;
    height: 32px;
    border-radius: 16px;
    color:  #FFFFFF;
    background-color:  #6176A0;
    margin-right: 24px;
    line-height: 32px;
    text-align: center;
    user-select: none;
    cursor: pointer;
  }
  .confirmBtn{
    background-color:  #FECF13;
    color:  #122C68;
  }
  .fpsDim{
    cursor: no-drop;
    opacity: .5;
  }
  .backBtn:hover,.nextBtn:hover,.doneBtn:hover{
    background-color:   #003C84;
  }
  .confirmBtn:hover{
    background-color:  #FECF13;
    opacity: 0.8;
  }
  .ft-transaction{
    height: 470px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 12px;
    background-color: #FFF;
    overflow: hidden;
    border-radius: 8px;
    padding: 16px;
    font-size: 15px;
    font-weight: bold;
    color: #333333;
  }
  .load-icon{
    width: 48px;
    height: 48px;
    background-image: url(../../../info/Include/images/eft_progress.gif);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .deposit-nopin{
    padding: 13px 0 10px;
  }
  .result-tips{
    font-size: 11px;
    color: #333333;
    margin: 8px 0 6px;
    span{
      color:  #003C84;
      font-weight: bold;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
  }
  .mrL24{
    margin-left: 24px;
  }
  .mrB24{
    margin-bottom: 24px;
  }
  .marT-16{
    margin-top: -16px;
  }
  .underLineText{
    color: #173E96;
    text-decoration: underline;
    cursor: pointer;
  }
  .caseCl{
    color: #173E96;
    cursor: pointer;
  }
  .fontW-b{
    font-weight: bold !important;
  }

  .link-title {
    margin: 12px 26px 12px 26px;
  }

  .link-row {
    display: flex;
    align-items: center;
    border-bottom: #b4b4b4 1px solid;
    width:100%;
    float: left;
    margin: 7px 0px 7px 0px;
    padding: 0px 0px 5px 0px;
  }

  .link-footer {
    width: 100%;
    float: left;
    margin: 7px 0px 7px 0px;
  }

  .link-l {
    width:25%;
    float:left;
    margin:3px 30px 5px 0px;
  }

  .link-r {
    width:75%;
    float:left;
    margin:3px 5px 5px 0px;
  }

  .link-r >div{
    display: flex;
  }

  .link-no-nba {
    display: block !important;
  }

  .link-type-fps, .link-type-eps {
    background-color: #F4F4F4;
    display: flex;
    border-radius: 2px;
    width:182px;
    height: 46px;
    padding: 8px;
    float:left;
    margin: 13px 12px 13px 0px;
  }

  .link-type-fps {
    cursor: pointer;
  }
  .link-type-fps:hover {
    box-shadow: 0 0 0pt 2px #07B3FE;
  }
  .link-type-fps-selected {
    box-shadow: 0 0 0pt 2px #07B3FE !important;
  }

  .link-type-text {
    float: left;
    width: 140px;
  }

  .link-type-text-status {
    white-space: nowrap;
  }

  .link-type-icon {
    float:left;
  }

  .link-type-icon > div {
    margin-top: 3px;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .link-type-icon > .AP {
    background-image: url(../../../info/Include/images/icon_success.svg) ;
  }

  .link-type-icon > .NO {
    background-image: url(../../../info/Include/images/link_avaliable.png);
  }

  .link-type-icon > .RJ {
    background-image: url(../../../info/Include/images/link_failed.png);
  }

  .link-type-icon > .IP {
    background-image: url(../../../info/Include/images/icon_pending.svg);
  }

  .link-btnArea {
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 24px;

  }

  .link-reject {
    color: red;
    border: red 1px solid;
    padding: 8px;
    width: 80%;
    display: block;
    cursor: pointer;
    border-radius: 4px;
    .singleLegErrorMessage{
      margin-top: 8px;
    }
  }

  .link-reject > div {
    width:100%;
    display:block;
  }

  .link-reject-close{
    float:right;
    font-weight: bold;
  }

  .link-btn-active {
    background-color: #003C84;
  }

  .link-btn-nonActive {
    cursor:default;
  }

  .link-TandC {
    width:100%;
    background-color: #F4F4F4;
    border-radius: 6px 6px 6px 6px;
    overflow:hidden;
    float:none;
    height: 156px;
  }

    .loginTandCText {
      padding: 10px 10px 20px;
      height: 100%;
      > div {
        height: 100%;
      }
    }

  .tandCTextRow {
    display: grid;
    grid-template-columns: 25px auto;
  }

    .tandCTextRowR {
      padding: 0 0 20px;
      word-break: break-word;
      white-space: pre-wrap;
    }

    .noline {
      border-width: 0px;
    }

    .link-otp-title {
      font-size: 18px;
      margin: 54px 0px 0px 0px;
      width:100%;
      padding: 0px 33% 0px 33%;
      text-align: center;
      line-height: 1.2em;
    }

    .link-otp-mobile{
      font-size: 18px;
      width: 100%;
      text-align: center;
      margin: 5px 0px 15px 0px;
    }

    .link-otp-cc{
      width: 100%;
      padding: 0px 15% 0px 15%;
      margin: 0px 0px 24px 0px;
      text-align: center;
    }

    .link-otp-code{
      width: 100%;
        padding: 0px 33% 0px 33%;
        margin: 0px 0px 10px 0px;
        text-align: center;
    }

    .link-otp-cod-ph {
      text-align: center;
      margin:0px 0px 24px 0px;
    }

    .link-otp-otpCode-container {
        display: inline-flex;
        margin: 0px 5px 0px 5px;
        border-bottom: black solid 1px;
        height: 38px;
    }

    .link-otp-otpCode {
      border: none;
      height: 36px;
      font-size: 30px;
      width: 30px;
      text-align: center;
    //   margin: 0px 5px 0px 5px;
      padding: 5px 0px 3px 0px;
      font: 30px consolas, monospace;
      -moz-appearance: textfield;
    }

        .link-otp-otpCode::-webkit-outer-spin-button,
        .link-otp-otpCode::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

    .link-otp-rec {
      text-align: center;
      width: 100%;
      margin: 0px 0px 12px 0px;
    }

    .link-otp-resend {
      text-align: center;
      width: 100%;
      margin:0px 0px 54px 0px;
    }

    .link-otp-resend-ok {
      color: #003C84;
      cursor: pointer;
      margin-right:5px;
    }

    .link-otp-resend-no {
      color: #B2B2B2;
      cursor:default;
      margin-right: 5px;
    }

    .link-otp-timer {
      display: contents;
      color: #B2B2B2;
    }

    .link-otp-resend-msg {
      font-weight: bold;
      line-height: 40px;
      padding-left: 25px;
      background-color: #FFF7D5 ;
      position: absolute;
      width:100%;
      top:82px;
    }

    .link-otp-summary {
      margin: 12px 0px 12px 0px;
      font-size: 13px;
    }

    .link-hide {
      display:none;
    }

    .link-banklist-url {
      margin-top: 10px;
      cursor: pointer;
      color: #003C84;
    }

    .link-summary {
      margin: 12px 28px 12px 28px;
    }

    .link-message {
      margin: 12px 0px 12px 0px;
    }

    .link-bank {
      padding-left:0px;
      padding-right:0px;
    }

    .link-bank-list {
      float:left;
      width: 100%;
      height:45vh;
      overflow-y: scroll;
    }

    .link-bank-row:nth-child(odd) div {
      background-color: #F4F4F4;
    }

    .link-bank-row {
      padding: auto;
    }

    .link-bank-row-l {
      float:left;
      width: 20%;
      padding:8px 0px 8px 16px;
    }

    .link-bank-row-r {
      float: left;
      width:80%;
      padding: 8px 16px 8px 0px;
    }

    .prewrap{
      white-space: pre-wrap;
    }

    .link-bottom {
      position:absolute;
      bottom: 0;
    }

    .errorColor{
      color: #E10004;
      font-size: 11px;
    }
    .dimColor{
      color: #b4b4b4;
    }
    .gray-area-message{
      margin:0 12px;
      padding: 0 8px;
    }
    .ppsCheckOtherMeans{
      color: #003c84;
      text-decoration: underline;
      padding: 12px;
      span{
        cursor: pointer;
      }
    }
}

