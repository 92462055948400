.esShortcutNavWrap {
    margin-top: 4px;
    .esShortcutNav {
        display: flex;
        border-radius: 2px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.123976);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        min-height: 76px; 
        padding: 12px 8px;
        width: 100%;
        justify-content: space-between;
        // gap: 12px;
        padding-bottom: 12px;
        @media only screen and (max-width: 1279px){
            margin: 0 8px;
            width: calc(100% - 16px);
        }
        .icon {
            display: flex;
            opacity: 1;
            background-color: #EBF5F0;
            min-width: 60px;
            min-height: 60px;
            border-radius: 50%;
            background-image: url('../../../../info/Include/images/early_settlement_icon_deep.svg');
            background-repeat: no-repeat;
            background-position: center;
            align-self: center;
            
            @media only screen and (max-width: 1279px){
                min-width: 50px;
                min-height: 50px;
            }
        }

        .text {
            // display: flex;
            // align-items: center;
            padding: 0px 2px 0px 4px;
            font-size: 13px;
            line-height: 16px;
            color: #333333;
            font-weight: 500;
            word-break: break-word;
        }
        .btnArea{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
        }
        .switch-btn-icon{
            width: 16px;
            height: 16px;
        }

        .button {
            width: 65px;
            height: 28px;
            background-color: #569171;
            border-radius: 16px;
            opacity: 1;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 4px;
        }
    }
}

