.SurveyInBetSlipWrap {
    display: flex;
    position: sticky;
    bottom: 0;
    padding: 12px 0;
    background-color: #e7e7e7;
    margin-top: auto;
    margin-bottom: -32px;
    .SurveyInBetSlip {
        display: flex;
        margin: 0 12px;
        padding: 0 8px;
        border: 2px;
        border-radius: 2px;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.123976);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        align-items: center;
        /* height: 76px; */
        padding: 12px 8px;
        width: 100%;
        justify-content: space-between;
        // gap: 12px;
        padding-bottom: 12px;
        .icon {
            display: flex;
            opacity: 1;
            background-color: #dbe6ef;
            min-width: 60px;
            min-height: 60px;
            border-radius: 50%;
            background-image: url('../../../info/Include/images/Survey_big.svg');
            background-repeat: no-repeat;
            background-position: center;
        }

        .text {
            display: flex;
            flex-direction: column;
            padding: 0 2px 4px 4px;
            .Leave-Feedback {
                opacity: 1;
                color: rgba(51, 51, 51, 1);
                font-size: 13px;
                font-weight: 700;
            }
            .click-share-opinion {
                opacity: 1;
                color: rgba(51, 51, 51, 1);
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
            }
        }

        .button {
            min-width: 80px;
            height: 32px;
            background-color: #003c84;
            border-radius: 16px;
            opacity: 1;
            background-color: rgba(0, 60, 132, 1);
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media only screen and (max-width: 1279px) {
    .SurveyInBetSlipWrap {
        .SurveyInBetSlip {
            display: flex;
            margin: 0 8px;

            .icon {
                min-width: 50px !important;
                min-height: 50px !important;
            }

            .button {
                min-width: 70px !important;
            }
        }
    }
}
