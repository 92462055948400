.HKJCAmountInputContainer {
    width: 254px;
    height: 32px;
    position: relative;
    font-size: 15px;
    & span {
        position: absolute;
        left: 10px;
        top: 7px;
        bottom: 0;
        font-weight: bold;
    }
    & input {
        height: 100%;
        width: 100%;
        padding: 0 15px;
        text-align: right;
    }
}
