$h: 40px;
$bc: #405a55;

.help {
    header {
        background-color: $bc;
        height: $h;
        // border-radius: 4px 4px 0 0
        font-size: 15px;
        color: #ffffff;
        display: flex;
        padding-left: 43px;
        align-items: center;
    }
    .content {
         padding: 12px;
    }
}