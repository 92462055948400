@import "./footer.scss";
@import "./betslip.scss";
@import "./racing.scss";
@import "./marksix_m.scss";
@import "./football.scss";
@import "./football_results.scss";
@import "./football_calculator.scss";
@import "./football_fgsres.scss";
@import "./mixallup_detail.scss";

@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion, #popup-root) {

    .StandardPage-scrollbars, .ARInbox-scrollbars  {
        position: unset !important;
        overflow: unset !important;
        // width: unset !important;
        height: unset !important;

    }
    .StandardPage-scrollbars>div:first-child, .ARInbox-scrollbars>div:first-child  {
        position: unset !important;
        inset: unset !important;
        overflow: unset !important;
        margin-right: unset !important;
        margin-bottom: unset !important;
        overflow-x: hidden !important;
    }

    .main-content .left-content{
        width: 100vw;
    }
    .main-content {
        display: block;
        justify-content: normal;
    }

    #root>div>.footer {
        width: auto;
    }

    .rcMiddle, .fbMiddle {
        width: auto;
    }

    #root {
        width: 100%;
        overflow-x: hidden;
    }

    .fMenuTR {
        display: none;
    }

    .fMenuTR-mobile-con {
        height: 48px;
    }

    .fMenuTR-mobile {
        // position: fixed;
        // top: 0;
        // z-index: 1022;
        // width: 100%;
        height: 48px;
    }

    .GL .fMenuTR-mobile, .bm-cross {
        display: block;
        height: 48px;
        background-color: #003C84;
    }

    .HR .fMenuTR-mobile, .bm-cross {
        display: block;
        height: 48px;
        background-color: #122c68;
        width: 100vw;
    }

    .FB .fMenuTR-mobile, .bm-cross {
        display: block;
        height: 48px;
        background-color: #405a55;
    }

    .LY .fMenuTR-mobile, .bm-cross {
        display: block;
        height: 48px;
        background-color: #980b0b;
    }

    .fMenuTR-mobile>div {
        width: 53px;
        height: 34px;
        display: inline-block;
    }

    .fMenuTR-mobile img {
        margin-top: 4px;
    }

    .fMenuTR-mobile .leftMenuIcon {
        padding-right: 18px;
        transform: translateY(-6px);
    }

    .fMenuTR-mobile a {
        color:#333333;
        font-weight: 500;
    }

    .fMenuTR-mobile a.Int_Racing .active {
        color: #173E96;
      }

    .fMenuTR-mobile a.Int_Football .active {
        color: #00C06C;
    }

    .fMenuTR-mobile a.Int_MarkSix .active {
        color: #D62D2D;
    }

    .fMenuTR-mobile .product-tab>div {
        margin: 8px 0px;
    }

    .fMenuRowLang:hover {
        opacity: 1;
    }

    .topBanner{
        width: 100%;
        margin-bottom: 8px;
      }
      .info_top_promo {
        width: 100%;
        margin-top: 8px;
        padding: 0;
      }

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 25px;
        height: 25px;
        left: 16px;
        top: 13px;
    }
    .bm-burger-button>span{
        width: 24px;
        height: 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: white;
        height: 2px !important;
        /* top: 10% !important; */
        position: static !important;
    }


    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
        right: auto !important;
        left: 12px;
        top: 8px !important;
    }
    .fMenuRowLang{
        margin-right: 0;
        height: 28px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #333;
        height: 20px !important;
    }

    /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
    .bm-menu-wrap {
        position: fixed;
        height: 100%;
        top: 0;
    }

    /* General sidebar styles */
    .bm-menu {
        background: white;
        font-size: 15px;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: white;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 12px 12px 0 12px;
        max-height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .bm-top-header {
            height: 20px;
            height: 43px;
            padding-bottom: 14px;
            border-bottom: 1px #d8d8d8 solid;
        }

        .bm-content {
            // padding: 20px 0;
            display: block !important;
            overflow: auto;
            flex: 1;
            align-content: flex-start;

        }

        .bm-menu-bottom{
            display: flex;
            justify-content: space-between;
            bottom: 0;
            left: 0;
            min-height: 55px;
            border-top: 1px #d8d8d8 solid;
            color: #333 !important;
            font-size: 13px;
            font-weight: 400;
            a{
                margin: 0;
                color: #333 !important;
                font-weight: 400;
                img{
                    margin: 0;
                }
            }
        }
    }

    /* Individual item */
    .bm-item {
        display: flex !important;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        align-content: center;
        background: #FFF;
    }

    .under-separator {
        margin-bottom: 20px;
        border-bottom: 1px solid #d8d8d8;
        padding-bottom: 14px;
    }



    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        top: 0;
    }


    .topMenuLang, .topMenuSurvey {
        color: #333333;
        // width: 32px;
        font-weight: bold;
    }

    .bm-item.menu-item {
        padding: 5px;
        color: #333333;
    }

    // .bm-item.menu-item-small {
    //     padding: 5px;
    //     color: #333333;
    //     font-size: 13px;
    // }

    .accordion-item {
        width: 100%;
        border: none;
    }

    .accordion-button {
        padding: 5px;
        &::after {
            background-image: url(../../info/Include/images/arrow-left.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 24px;
            height: 24px;
            transition: unset;
        }
        &:not(.collapsed)::after {
            background-image: url(../../info/Include/images/arrow-up.svg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 24px;
            height: 24px;
        }
    }

    .accordion-body {
        color: #333333;
    }

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        background-color: white;
        border: none;
        box-shadow: none;
    }

    .HR .accordion-button:not(.collapsed){
        color: #173E96;
    }
    .FB .accordion-button:not(.collapsed){
        color: #00C06C;
    }
    .LY .accordion-button:not(.collapsed){
        color:#D62D2D;
    }

    .topInfoMenu {
        display: none;
    }


    .rgpLinks {
        margin-bottom: 14px;
    }

    .rgpLinks a {
        color: #6176A0;
    }

    .copyRight {
        display: block;
    }

    .fMenuTR-mobile .login-icon {
        background-image: url(../../info/Include/images/icon-login.svg);
        background-repeat: no-repeat;
        display: inline;
        float: right;
        height: 24px;
        width: 24px;
        background-size: contain;
        margin: 12px 18px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(120%) contrast(170%);
    }

    .fMenuTR-mobile .login-icon.LoaginPage {
        background-image: url('../../info/Include/images/main-logo-white.svg');
        background-repeat: no-repeat;
        display: inline;
        float: right;
        height: 31px;
        width: 120px;
        background-size: contain;
        margin: 9px 12px 12px 12px;
        color: white;
        /* font-size: 9px; */
        transform: scale(0.8);
        vertical-align: bottom;
        padding-top: 26px;
    }

    .fMenuTR-mobile .mobileEwinHome {
        padding-top:15px;
        color: #ffffff;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(120%) contrast(170%);
    }

    .popup-content .login-icon {
        background-image: url(../../info/Include/images/icon-login.svg);
        background-repeat: no-repeat;
        background-size: contain;
        float: right;
        height: 32px;
        width: 32px;
        position: absolute;
        right: 14px;
        top: 11px;
        filter:invert(100%) sepia(0%) saturate(0%) hue-rotate(350deg) brightness(104%) contrast(101%);
    }

    .topMenu, #info_top_block, .leftMenuMain, .rcOddsTable header>.rcTitle, .rc-odds-table, .meeting-info-no, .meeting-info .addSlip {
        display: none;
    }

    .rcMain {
        width: 100%;
        padding: 0px;
    }

    #info_top_promo {
        padding: 0px;
        margin: 0px;
        width: 100%;
    }

    .botMenuNavigBox{
        position: relative;
    }

    .botMenuNavigContain{
        display: flex;
    }

    .botMenuNavigContain a:first-child:nth-last-child(3),
    .botMenuNavigContain a:first-child:nth-last-child(3) ~ a{
        width: 33.3%;
    }

    .botMenuNavigContain a:first-child:nth-last-child(4),
    .botMenuNavigContain a:first-child:nth-last-child(4) ~ a{
        width: 25%;
    }

    .botMenuNavigContain a:first-child:nth-last-child(5),
    .botMenuNavigContain a:first-child:nth-last-child(5) ~ a{
        width: 20%;
    }

    .botMenuNavigContain a:first-child:nth-last-child(6),
    .botMenuNavigContain a:first-child:nth-last-child(6) ~ a{
        width: 20%;
    }


    .bottom-menu {
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: auto;
        background-color: #fbfbfb;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0px -9px 14px 0px rgb(0 0 0 / 15%);
    }

    .bottom-menu {
        .container-top {
            width: 100%;
            position: relative;
            .rectangle {
                width: 72px;
                height: 12px;
                background-color: #FFFFFF;
                position: absolute;
                left: calc(50% - 36px);
                top: -11px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                transform: perspective(9px) rotateX(0deg);
                transform-origin: 50% 100%;
                box-shadow: 0px -9px 14px 0px rgb(0 0 0 / 15%);
                &::before {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    left: -9px;
                    bottom: 0;
                    background: #fff;
                    background: radial-gradient(circle at 0 0, transparent 12px, #fff 13px);
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    right: -9px;
                    bottom: 0;
                    background: #fff;
                    background: radial-gradient(circle at 100% 0, transparent 12px, #fff 13px);
                }
            }
            .inner-triangle {
                position: absolute;
                left: calc(50% - 5px);
                top: 6px;
                background-image: url(../../info/Include/images/navigate_top.svg);
                height: 6px !important;
                &.arrow-icon-default-down {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .bottom-menu>header {
        padding:5px 8px;
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .AddToSlip {
            margin-right: 0;
        }
    }

    .bottom-menu .bottomMenuTab {
        display: inline-flex;
        //width: 20%;
        // height: 64px;
        // align-content: center;
        padding-top: 12px;
        justify-content: center;
        // align-items: center;
        color: #333333;
        font-size: 15px;
        box-sizing: content-box;
        vertical-align: bottom;
        margin-top: 4px;
    }


    .safe-area-bottom {
        // bottom: 0px;
        bottom: constant(safe-area-inset-bottom, 34px);//兼容 IOS<11.2
        bottom: env(safe-area-inset-bottom, 34px);//兼容 IOS>11.2
        // bottom: 34px;// 测试代码
    }


    .safe-area-height {
        background-color: #fbfbfb;
        // height: 0px;
        height: constant(safe-area-inset-bottom, 34px);
        height: env(safe-area-inset-bottom, 34px);
        // height: 34px; // 测试代码
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 1111;
    }

    a.bottomMenuTab:hover {
        color: #333;
    }

    .bottom-menu .bottomMenuTab.active {
        border-top: 4px solid;
        color: #173E96;
        margin-top: 0;
        img {
            // filter: drop-shadow(24px 0 0 #173E96);
            // position: relative;
            // left: -24px;
            // border-right: solid transparent  1px;
            // box-sizing: content-box;
            filter: brightness(0) saturate(100%) invert(16%) sepia(84%) saturate(2227%) hue-rotate(215deg) brightness(96%) contrast(95%);
        }
    }

    .bottom-menu .bottom-menu-icon {
        margin-bottom: 8px;
        text-align: center;
        // margin-left: 11px;
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        position: relative;
        height: 24px;
    }

    .bottom-menu .bottomMenuTab.active .bottom-menu-icon{
        /* width: 24px; */
    }

    .menu-icon {
        background-repeat: no-repeat;
        background-size: contain;
        width: 27px;
        height: 30px;
        display: inline-block;
        margin-right: 14px;
        vertical-align: middle;
    }

    .icon-start {
        background-image: url(../../info/Include/images/icon-start.svg);
    }

    .icon-channel {
        background-image: url(../../info/Include/images/icon-Betting_Channels.svg);
    }

    .icon-info {
        background-image: url(../../info/Include/images/info.svg);
    }

    .icon-cs {
        background-image: url(../../info/Include/images/icon-CCHKJC.svg);
    }

    .icon-betslip {
        background-image: url(../../info/Include/images/icon-betslip.svg);
    }

    .icon-more {
        background-image: url(../../info/Include/images/icon-menu.svg);
    }

    .menu-item>.active {
        color: #0d6efd;
    }

    .bottom-menu-icon-container {
        text-align: center;
        &>div:nth-child(2){
            margin-bottom: 16px;
        }
    }

    .bottom-menu-icon {
        background-repeat: no-repeat;
        background-size: contain;
        // width: 24px;
        // height: 24px;
        display: inline-block;
        margin-right: 14px;
        vertical-align: middle;
    }



    .slider-container-venue .slick-initialized .slick-slide {
        /* width: 40px !important; */
    }

    .slider-container-venue .slick-arrow.slick-next {
        right: -23px;
    }

    .important-notice {
        width: 100vw !important;
        margin-left: 0;
    }

    .important-icon {
        display: inline-block;
        margin-right: 6px;
        vertical-align: middle;
        height: 30px;
    }

    .hide-m {
        display: none !important;
    }

    .show-m {
        display: block !important;
    }

    .bottom-menu .left {
        display: inline-flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        margin-left: 0px;
        // width: 80%;
    }

    .bottom-menu .left>div, .bottom-menu .left>span {
        min-width: 24%;
        text-align: center;
        margin-left: 5px;
    }

    .live-racing-icon-m {
        background-image: url(../../info/Include/images/icon-live-racing-m.svg);
        background-repeat: no-repeat;
        background-size: 35px auto;
        background-position: 10px 2px;
        height: 35px;
        width: 50px;
        filter: invert(45%) sepia(13%) saturate(1221%) hue-rotate(182deg) brightness(97%) contrast(88%);
    }

    .meeting-info-menu .navigation-menu .show.dropdown button {
        transform: rotate(180deg);
    }

    .JKC .meeting-info-no {
        display: inline;
    }

    .JKC .nextline {
        display: inline;
    }

    .JKC .separator {
        display: none;
    }
    .betslip-container{
        display: none;
        z-index: 9000;
    }

    .ewinInfoGrid {
        display: block;
        padding: 0 8px;
    }

    .ewinInfoGrid>div {
        margin-top: 2px;
    }

    .ewinMain {
        width: 100%;
        padding: 8px 0 0;
    }


    .blockHeader {
        margin-top: 12px;
    }

    .navigation-menu {
        display: flex;
        // margin-right: 12px;
        align-items: center;
        align-content: center;
        flex-wrap: nowrap;
        flex-direction: row;
        // justify-content: flex-end;
        justify-content: space-between;
        width: calc(100% - 12px);
        .dropdown-item.hlMenuText {
            color: #ff0000;
        }
    }

    .navigation-menu #menu-icon {
        min-width: 24px;
        height: 24px;
        background-image: url(../../info/Include/images/Dropdown_menu_btn.svg);
        background-repeat: no-repeat;
        background-color: #FFFFFF;
        border: unset;
        margin: 0px;
        padding: 0px;
        background-size: cover;
        border-radius: 7px;
    }

    .FB .navigation-menu #menu-icon {
        min-width: 28px;
        height: 28px;
        border-radius: 4px;
    }

    .navigation-menu .dropdown-item:before {
        border-color: transparent transparent #569171;
    }

    .navigation-menu .dropdown-menu {
        width: calc(100vw - 16px);
        padding-top: 0px;
        padding-bottom: 0px;
        inset: 12px -4px auto auto !important;
        border-radius: 4px;
    }

    .navigation-menu .dropdown-menu .DropdownButtonScrollContainer{
        overflow-y: auto;
        border-radius: 0 0 4px 4px;
        max-height: calc(100vh - 314px);
        background-color: #fff;

    }

    .marksixMain .navigation-menu .dropdown-menu .DropdownButtonScrollContainer{
        // height: 564px;
        border-radius: 4px;
    }
    /* .fbMiddle .navigation-menu .dropdown-menu{
        inset: 12px -4px auto auto !important;
    } */
    .LY .navigation-menu .dropdown-menu {
        /* height: auto; */
    }

    .navigation-menu .dropdown-section {
        display: flex;
        align-items:center;
        padding: 0 1rem;
        color: #ffffff;
        border-radius: 4px 4px 0 0;
        min-height: 36px;
        font-size: 15px;
        justify-content: space-between;
        text-align: left;
    }

    .navigation-menu .dropdown-child {
        // width: 50%;
        display: inline-flex;
        align-items: baseline;
        flex-wrap: nowrap;
        flex-direction: row;
        font-size: 15px;
        line-height: 17px;
        padding: 10px 12px;
        text-align: center;
        word-break: break-all;
        border-top: unset;
        /* border-right: 1px solid #dee2e6; */
        border-bottom: 1px solid #dee2e6;
        justify-items: center;
        justify-content: center;
        white-space: normal;
        word-break: break-word;
        color: #333;
        min-height: 47px;
        height: 100%;
        align-content: space-between;
        vertical-align: middle;
        align-items: center;
        position: relative;
        &.shrinkFont{
            font-size: 13px;
            line-height: 15px;
        }
    }

    .navigation-menu .dropdown-child:is(.son-menu-m):not(.son-menu-m-last) {
        border-bottom: none
    }


    .navigation-menu .DropdownButtonScrollContainer .dropdown-child:nth-child(odd)::after{
        position: absolute;
        content: '';
        width: 1px;
        height: calc(100% - 8px);
        top: 4px;
        right: 0;
        background-color: #dee2e6;
    }

    .navigation-menu .DropdownButtonScrollContainer .dropdown-child:is(.son-menu-m)::after{
        height: 100%;
        top: 0;
    }

    .navigation-menu .DropdownButtonScrollContainer .dropdown-child:is(.son-menu-m-0) .after{
        position: absolute;
        content: "";
        width: 12px;
        top: -1px;
        right: 0;
        background-color: white;
        height: 1px;
    }

    .navigation-menu .DropdownButtonScrollContainer .dropdown-child:is(.son-menu-m-0) .before{
        position: absolute;
        content: '';
        width: 12px;
        top: -1px;
        height: 1px;
        left: 0;
        background-color: white;
    }
    .navigation-menu .DropdownButtonScrollContainer .DropdownButtonScrollContainerWapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }


    .navigation-menu .pageName {
        min-width: calc(100% - 36px);
        max-width: calc(100% - 36px);
        word-break: break-word;
        text-align: left;
        margin-right: 8px;
        display: flex;
        justify-content: flex-end;
        line-height: 16px;
    }

    // header.MIXALLUP .navigation-menu .pageName {
    //     min-width: 110px;
    //     max-width: 120px;
    // }


    .navigation-menu .show.dropdown.btn-group::before {
        width: 100vw;
        height: 100vh;
        content: '';
        position: fixed;
        display: block;
        background: black;
        z-index: 999;
        opacity: .3;
        inset: 0px;
        top: 0;
        left: 0;
    }

    .HR .navigation-menu .show.dropdown.btn-group::before {
        top: -60px;
    }

    .FB .navigation-menu .pageName {
        color: #ffffff;
        flex: 1;
    }

    .LY .navigation-menu .pageName, .LY .navigation-menu .pageName {
        color: #333333;
    }

    .FB .navigation-menu .dropdown-section {
        background-color: #569171;
    }

    .HR .navigation-menu .dropdown-section {
        background-color: #003C84;
    }

    .LY .navigation-menu .dropdown-section {
        background-color: #980b0b;
    }

    .HR .navigation-menu .dropdown-item.active {
        background-color: #173e96;
        color: #ffffff !important;
    }

    .FB .navigation-menu .dropdown-item.active {
        background-color: #00C06C;
        color: #ffffff !important;
    }

    .LY .navigation-menu .dropdown-item.active {
        background-color: #d62d2d;
        color: #ffffff !important;
    }

    .topMenuIcon {
        content: url(../../info/Include/images/lang_black.svg);
        // content: url(../../info/Include/images/icon-Language.png);
    }

    .topMenuSurveyIcon {
        content: url(../../info/Include/images/Survey_m.svg);
        // content: url(../../info/Include/images/icon-Language.png);
    }

    .mobile-block{
        display: block !important;
    }
    .mobile-hide{
        display: none !important;
    }
}
}