@media only screen and (max-width: 1355px) {
    .HR .masonry-col > td:last-child {
        min-width: 260px;
        width: 36%;
        flex: 0 1 auto !important;
    }
}

@media only screen and (max-width: 1222px) {
    .iwn-odds-table-FCT .iwn-odds-table-header .iwn-odds-table-title > div {
        padding: 0 4px;
    }
}


@media only screen and (max-width: 1193px) {

    .leftMenuMain li {
        padding: 8px 10px;
    }

    .main-content {
        .left-content {
            width: calc(100% - ((100% - 72px) / 4 + 16px));

            // flex: 1;
            .leftMenuMain {
                min-width: 108px;
                width: 108px;
            }
        }
    }

    .iwn-odds-table-FCT,
    .qin-odds-table {
        font-size: 11px;
    }

    .qin-odds-table.qin-odds-table-page1.qin-odds-table-QPL {
        aspect-ratio: 17 / 9.2;
    }

    .qin-odds-table.qin-odds-table-page1.qin-odds-table-QIN {
        aspect-ratio: 17 / 8;
    }

    .qin-odds-table.qin-odds-table-page2 {
        aspect-ratio: 17 / var(--row-count);
    }

    .qin-odds-table.qin-odds-table-page3 {
        aspect-ratio: 7 / var(--row-count);
    }
    .iwn-odds-table-FCT {
        aspect-ratio: var(--table-column) / var(--table-row);
    }

    .iwn-odds-table-FCT .iwn-odds-table-header .iwn-odds-table-title > div {
        height: unset !important;
        min-height: unset !important;
        margin: 2px 0;
    }

    .iwn-odds-table-FCT .left-number {
        height: unset !important;
    }

    .iwn-odds-table-FCT > tr > td,
    .qin-odds-table > tr > td {
        min-width: 21px;
        min-height: 21px;
        width: 5.882%;
        max-height: 26px;
        height: initial;
        &.disabled.scratched-SCR{
            font-size: 9px;
        }
    }

    .HR .masonry-col > td:last-child {
        width: 40%;
    }


}