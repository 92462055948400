.select {
    display: inline-block;
}

.select .show > .btn-primary.dropdown-toggle {
    background-color: #fff;
    color: #333;
    border-color: #666;
}

.select .dropdown .dropdown-toggle {
    width: 100px;
    height: 28px;
    display: flex;
    border-radius: 2px;
    justify-content: space-between;
    box-shadow: none;
    align-items: center;
    padding: 0 12px;
    background-color: #fff;
    border: 1px solid #666;
    color: #333;
    font-size: 13px;
}

.select .dropdown-toggle .dd-arrow {
    align-self: center;
    margin-top: -5px;
    border-color: #333;
}

.select .dropdown-menu {
    min-width: 100px;
    max-height: 200px;
    overflow-y: auto;
}

.select .dropdown-menu.show {
    inset: -2px auto auto auto !important;
    background-color: #ffffff;
    box-shadow: 0px 6px 14px 3px rgb(147 150 153 / 50%);
    border: none;
}

.select .dropdown-item:not(:first-child) {
    border-top: 1px #d2d2d2 solid;
    padding-bottom: 6px;
}

.select .dropdown-item {
    color: #333;
    font-size: 13px;
}

.select .dropdown-item:hover {
    background-color: #003c84;
    color: #fff;
}

.select .dropdown-item.active {
    background-color: #003c84;
    color: #fff;
}

.select .dropdown-item.active:hover {
    color: #fff;
}
