.fMenuTR-mobile .SpeedbetLogo {
    display: flex;
    width: auto;
    height: auto;
    float: right;
    margin-left: auto;
    margin-top: 3px;
    .speedBetLogoPNG {
        margin-top: 5px !important;
    }

    .hkjcpriority-m {
        position: relative;
        padding-top: 3px;
        color: white;
    }

    .hkjcpriority-logo-m {
        background-image: url('../../../info/Include/images/main-logo-white.svg');
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(0.8);
        padding-top: 28px;
        white-space: nowrap;
        margin-left: -5px;
        margin-right: 2px;
    }
}
