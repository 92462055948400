@import './common-mixin.scss';
/* The container */
.radio-container {
    display: block;
    position: relative;
    padding-left: 24px;
    margin-bottom: 0px;
    cursor: pointer;
    @include font13();
    height: 18px;
    min-width: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default radio button */
  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  /* Create a custom radio button */
  .radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 1.5px solid #000000;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  /* On mouse-over, add a grey background color */
  .radio-container input:disabled ~ .radio-checkmark,
  .radio-container:hover input ~ .radio-checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  .radio-container input:checked ~ .radio-checkmark {
    background-color: #FFFFFF;
  }

  .HR .radio-container input:checked ~ .radio-checkmark {
    border: 1.5px solid #173E96;
  }
  .FB .radio-container input:checked ~ .radio-checkmark {
    border: 1.5px solid #00c06c;
  }
  .LY .radio-container input:checked ~ .radio-checkmark {
    border: 1.5px solid #d62d2d;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the indicator (dot/circle) when checked */
  .radio-container input:checked ~ .radio-checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .radio-container .radio-checkmark:after {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #173E96;
  }

  .HR .radio-container .radio-checkmark::after{
    background: #173E96;
  }
  .FB .radio-container .radio-checkmark::after{
    background: #00C06C;
  }
  .LY .radio-container .radio-checkmark::after{
    background: #d62d2d;
  }
  .reactRadio{
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
  }