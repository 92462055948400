@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
.rc-page-footer-legend {
    padding-left: 10px;
}

.rc-page-footer-legend .left {
    width: 40px;
}

.rc-page-footer-legend .right {
    width: calc(100% - 40px);
}

.footer {
    padding: 19px 14px 0px 10px;
}





.footer-play-responsibly-content p {
    margin: 0px ;
    line-height: 20px;
}

.footer-top-m{
    .footer-top-tips{
        display: flex;
        justify-content: space-between;
        margin-bottom: 11px;
    }
}
.footer-buttom {
    display: block;
}

.footer-play-responsibly {
    font-size: 13px;
}

.footer-play-responsibly-content {
    font-size: 11px;
}

.cb-allup-container-cwa {
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    align-items: center;
    justify-items: start;
    gap: 12px;
}

.cb-allup-container-cwa>div{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    margin-right: 3px;
}
}
}