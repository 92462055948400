@import './common-mixin.scss';

div.date-picker-container.date_range_picker{
    font-family: "Noto Sans TC" !important;
    color: #333 !important;
    .react-datepicker{
        font-family: "Noto Sans TC" !important;
        color: #333 !important;
        border: none;
        box-shadow: 0px 0px 5px rgba(0,0,0, 0.297772);
    }
    .react-datepicker-popper{
        z-index: 9999;
    }
    .react-datepicker__header {
        background: #fff;
        box-shadow: var(--box-shadow);
        border-bottom: 0;
    }
    .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
        height: 24px;
        line-height: 24px;
    }
    .react-datepicker__day-name{
    }
    .react-datepicker__day{
        margin: 4px ;
        @include font15();
    }
    .react-datepicker__current-month{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;
        padding: 0 20px;
        @include font18();
        line-height: 32px;
        margin-bottom: 6px;
        user-select: none;
        .dateRangePickerMonth, .dateRangePickerYear {
            cursor: pointer;
        }
        .monthDropdownContainer{
            border-radius: 4px;
            overflow: hidden;
            position: absolute;
            z-index: 100;
            left: 28%;
            top: 24px;
            @include font15();
            font-weight: 400;
            box-shadow: var(--box-shadow);
            .monthDropdown{
                display: block;
                overflow-y: scroll;
                min-width: 220px;
                background: #f4f4f4;
                max-height: 432px;
                margin-right: -17px;
                >div{
                    height: 36px ;
                    border-bottom: 1px solid #ddd;
                    padding: 0 24px 0 12px;
                    text-align: left;
                    cursor: pointer;
                    &:hover, &.activeMonth{
                        color: #00C06C;
                    }
                    .marksixMain &:hover, .marksixMain &.activeMonth{
                        color: #d62d2d;
                    }
                }
            }
        }
        .prev-month{
            left: 33px;
            right: auto;
        }
        .next-month{
            right: 33px;
        }
        .dd-arrow{
            border-color: #333;
            border-width: 0 3px 3px 0;
            cursor: pointer;
        }
    }
    .react-datepicker__day-names{
        @include font18();
        font-weight: 700;
        padding: 0 16px;
        &>div{
            color: #333;
            font-weight: bold;
            margin: 4px;
            width: 36px;
            height: 36px;
        }
    }
    .datePickerConfirmBtn{
        background-color: #6176A0;
        width: 160px;
        &:hover{
            background: #003C84;
        }
    }

    .react-datepicker__week{
        overflow: hidden;
    }
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
        width: 36px;
        height: 36px ;
        line-height: 36px;
    }

    .react-datepicker__day--in-range{
        background-color: unset;
        position: relative;
        margin: 4px 0 ;
        border-radius: 0;
        color: #333;
        background-color: rgba( 18,44,104, 0.2);
        @include font15();
        border: 0;
        line-height: 36px;
        width: calc(36px + 8px);
    }
    .react-datepicker__day:not(.react-datepicker__day--in-range,.react-datepicker__day--selecting-range-start,.react-datepicker__day--disabled):hover,
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range{
        border-radius: 50% ;
        background: transparent;
        border: 0;
        color: #333;
        line-height: 36px,
    }
    .react-datepicker__day--today > div{
        font-weight: 400;
    }
    .react-datepicker__day > div.isToday{
        border-radius: 50%;
        line-height: calc(36px - 4px);
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        &>div{
            border-radius: 50%;
            border: 2px solid #ccc;
            line-height: 32px;
            width: 36px;
            height: 36px;
        }
    }
    .react-datepicker__day .isRangeDay{
        border-radius: 50% ;
        background: rgba( 18,44,104, 0.2);
        border: 0 ;
        color: #fff;
        line-height: 36px,
    }
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end{
        position: relative;
        margin: 4px;
        color: #fff;
        width: 36px;
        height: 36px;
        z-index: 10;
        background-color: #fff;
    }
    .react-datepicker__day .selectedDay {
        position: relative;
        border-radius: 50%;
        z-index: 1;
        border: 0;
        background-color: #003C84;
        color: #fff;
        line-height: 36px,
    }
    .react-datepicker__day--range-start::before{
        position: absolute;
        content: '';
        width: 20px ;
        height: 100%;
        right: -4px;
        top: 0;
        background-color: rgba( 18,44,104, 0.2);
    }
    .react-datepicker__day--range-end::before{
        position: absolute;
        content: '';
        width: 20px;
        height: 100%;
        left: -4px;
        top: 0;
        background-color: rgba( 18,44,104, 0.2);
    }
    .react-datepicker__day--range-start.react-datepicker__day--range-end::before{
        position: initial;
        content: '';
    }
    .dateRangePickerValueContainer{
        min-height: 36px;
        @include font18();
        line-height: 36px;
        margin-bottom: 8px;
        background: #fff;
        position: relative;
        padding: 0 15px;
        color: #003C84;
        font-weight: bold;
        text-align: left;
        .date-picker-top-label{
            color: #333;
            @include H5();
            font-weight: 100;
            padding-bottom: 10px;
        }
    }
    .dateRangePickerValueContainer::after{
        content: '';
        width: 90%;
        border-bottom: 1px solid  #dfdfdf;
        position: absolute;
        bottom: 0;
        left: 5%;
    }
    .react-datepicker__navigation{
        top: 38px;
    }
    .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
        border-color: #333;
    }
}
.date-picker-container .react-datepicker__portal {
    background-color: rgba($color: #000000, $alpha: 0.4);
}

.date-picker-container.date_range_football{
    .dateRangePickerValueContainer{
        color: #405A55 !important;
    }
    .datePickerConfirmBtn{
        background: #569171 !important;
        &:hover{
            background-color: #405A55 !important;
        }
    }
    div.react-datepicker__day--in-range:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--range-start, .react-datepicker__day--range-end ){
        background-color: rgba($color: #569171, $alpha: 0.2) ;
    }
    // .react-datepicker__day--selecting-range-start>div,
    // .react-datepicker__day--range-start>div,
    // .react-datepicker__day--range-end>div{
    //     background-color: #00c06c !important;
    // }
    .selectedDay {
        background-color: #00c06c !important;
    }
    .react-datepicker__day--range-end::before,
    .react-datepicker__day--range-start::before{
        background-color: rgba($color: #569171, $alpha: 0.2) !important;
    }

}


// mobile style
@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
    div.date-picker-container.date_range_picker{
        .datePickerConfirmBtn{
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
        }
        // .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
        //     width: 44px !important;
        //     height: 44px !important;
        //     line-height: 44px;
        // }
        // .react-datepicker__day--today > div{
        //     line-height: calc(44px - 8px);
        // }
        // .react-datepicker__day--in-range{
        //     line-height: 44px;
        //     width: calc(44px + 8px) !important;
        // }
        // .react-datepicker__day:not(.react-datepicker__day--in-range,.react-datepicker__day--selecting-range-start):hover,
        // .react-datepicker__day--selected,
        // .react-datepicker__day--in-selecting-range{
        //     line-height: 44px,
        // }
        // .react-datepicker__day .isRangeDay,.react-datepicker__day .selectedDay {
        //     line-height: 44px,
        // }
        .react-datepicker__day:not(.react-datepicker__day--in-range,.react-datepicker__day--selecting-range-start):hover{
            background-color: unset;
        }
        // .react-datepicker__day--selecting-range-start,
        // .react-datepicker__day--range-start,
        // .react-datepicker__day--range-end{
        //     width: 44px !important;
        //     height: 44px;
        // }
        // .react-datepicker__day--range-end::before,
        // .react-datepicker__day--range-start::before{
        //     width: 24px;
        // }
    }
    }
}