@media only screen and (max-width: 768px) {


.RACEBYRACE .m {
    display: contents !important;
}

.RACEBYRACE .d {
    display: none !important
}


.RACEBYRACE .titlebar .refreshsec {
    display: none !important
}

.RACEBYRACE .racebyrace_control .refreshsec {
    display: inline !important
}

.RACEBYRACE .racebyrace_control .refreshsec {
    padding: 5px 8px 0px 0px;
}

.racebyrace-table {
    min-width: calc(100vw - 16px);
}

}