@import '../common-mixin.scss';
#root {
    width: auto;
}

.RACEBYRACE .racebyrace_table{
    width: 100%;
}
.RACEBYRACE .table{
    display: table;
    width:auto;
    margin-bottom:auto;
}
.RACEBYRACE .cell {
    display: table-cell;
    border: #333333;
    border-width: thick;
    width: auto;
    text-align: center;
    vertical-align: middle;
}
.RACEBYRACE .m{
    display: none;
}
.RACEBYRACE .d{
    display: contents;
}

.RACEBYRACE .name{
    text-align: left;
    padding-left: 4px;
}

.RACEBYRACE .count1 {
    grid-column: 3 / 6;
    text-align: center;
    border-bottom: 1px solid #333333;
}
.RACEBYRACE .count2 {
    text-align: center;
    border-bottom: 1px solid #333333;
}

.RACEBYRACE .name a {
    color: #122C68;
    cursor: pointer;
}

.RACEBYRACE .odds {
    white-space: nowrap;
}

.RACEBYRACE .even {
    background: #F4F4F4;
    height: 100%;
}
.RACEBYRACE .odd {
    background-color: #FFFFFF;
    height: 100%;
}
.RACEBYRACE .titlebar{
    display: flex;
    height: 36px;
    color: #FFF;
    @include font15();
    font-weight: bold;
    line-height: 36px;
    padding-left: 10px;
    background-color: #122c68;
    justify-content: space-between;
}

.RACEBYRACE titlebar.space{
    display:flex;
}
.RACEBYRACE .titlebar .refreshtime{
    display:flex;
    margin-right: 4px;
}
.RACEBYRACE .titlebar .refrestbtn{
    display:flex;
}

.RACEBYRACE .titlebar .refreshsec{
    display: flex;
    padding-right: 8px;
}

.RACEBYRACE .titlebar .refresh-odds * {
    cursor: pointer;
    @include font11();
}

.RACEBYRACE .titlebar .refresh-odds div {
    height: 20px;
    width: 20px;
    margin-right: 6px;
    background-image: url(../../info/Include/images/ic_refresh_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &.rotate {
        animation: rotate 1s linear 1;
    }
}
.RACEBYRACE .racebyrace_control {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
}

.RACEBYRACE .racebyrace_control .checkbox-containter {
    margin: 6px;
    display: flex;
    gap: 8px;
}

.RACEBYRACE .racebyrace_control .refreshsec{
    display: none;
}

.RACEBYRACE .racebyrace_control input {
    margin: 4px 0px 4px 8px;
}

.RACEBYRACE .racebyrace_control .showpoint {
    padding: 4px;
    white-space: nowrap;
}

.racebyrace-table {
    display: grid;
    text-align: center;
    margin: 8px;
    @include font13();
    grid-template-columns: 7% 20% 9% 8% 8% 1% 8% 14% 15% 10%;
    align-items: center;
}

.racebyrace-table>div {
    padding: 4px 0px;
}

.foPlacePoint {
    @include font11();
}

.RACEBYRACE .minHeight {
    min-height: 42px;
}

.RACEBYRACE .rowHeight {
    line-height: 34px;
    vertical-align: middle;
}

.RACEBYRACE .racebyrace-rmk {
    margin: 8px;
}

.en .RACEBYRACE .header {
    @include font11();
}