.FBMatchTypeTabContainer {
    border-top: 2px solid #ddd;
    display: flex;
    width: 100%;
    padding: 8px;
    justify-content: center;
    .FBMatchTypeTab {
        user-select: none;
        cursor: pointer;
        flex: 1;
        background-color: #eee;
        font-weight: 500;
        padding: 8px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active {
            background-color: #00c06c;
            color: white;
        }
        &.disabled {
            opacity: 0.6;
            cursor: default;
        }
        &:first-child {
            border-radius: 4px 0 0 4px;
        }

        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
}

@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .FBMatchTypeTabContainer {
            border-top: none;
        }
    }
    :where(.ch.isDeviceVersion) {
        .FBMatchTypeTabContainer {
            .FBMatchTypeTab{
                white-space: nowrap;
                flex: 1 1 auto;
            }
        }
    }
}
