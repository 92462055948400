@media print {

    body, .ewinMain{
        width: 1024px !important;
    }
    .rcOddsTable, .fbOddsTable, .M6Middel > section{
        width: calc(1024px - 16px) !important;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
    .CHPRES {
        min-width: 1000px;
    }

    .StandardPage-scrollbars{
        height: auto !important;
        overflow: initial !important;
        width: 1024px !important;
        position: initial !important;
    }
    .StandardPage-scrollbars>div:first-child{
        overflow: initial !important;
        position: static !important;
        margin: 0 !important;
    }
    .fMenuTR, .fMenuTR-mobile, .topInfoMenu, .important-notice, .topBannerDiv, #info_top_block, .topMenu, .leftMenuMain, .betslip-container, .footer, #divTopBanner {
        display: none;
    }
    .rcMain #divTopBanner #info_top_promo, .fbMain #divTopBanner #info_top_promo, .marksixMain #divTopBanner #info_top_promo{
        display: none;
    }

    /*MarkSix*/
    .maraksx-results-table-noprint{
        display: none;
    }
    .marksix-results-table-container{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        margin-top: 0px;
    }
    .maraksx-results-table-print{
        display: contents;
    }
    .maraksx-results-table-print .maraksx-results-table .table-row{
        page-break-inside:avoid;
    }

    .marksixMain .result-lucky-number, .result-search-box, .marksix-results-table-container>header{
        display: none;
    }

    .my-favourites-table-container{
        /* display: none; */
    }
    .marksix-banker-middle{
        /* display: none; */
    }
    .pagination-box{
        display: none;
    }
    .fbOddsTable section.HIL .header .odds,
     .fbOddsTable section.CHL .header .odds,
     .fbOddsTable section.FHL .header .odds,
     .fbOddsTable section.FCH .header .odds{
        max-width: 25% !important;
    }
    .fbOddsTable section.HIL .match-row .oddsLine,
     .fbOddsTable section.CHL .match-row .oddsLine,
      .fbOddsTable section.FHL .match-row .oddsLine,
    .fbOddsTable section.FCH .match-row .oddsLine{
        max-width: 25% !important;
        width: 25% !important;
    }

    .fbOddsTable section.TTG .match-row{
        div.inplay-clock {
            gap: 8px 0;
            align-items: center;
            align-content: center;
            justify-items: center;
            justify-content: center;
            grid-template-columns: 100%;
            padding: 8px 12px;
            .corner-result{
                grid-column: 1/3;
                justify-items: center;
                align-items: center;
                height: auto;
            }
        }
    }
    .fb-bottom-betslip-sticky-outer-wrapper {
        display: none;
    }
}