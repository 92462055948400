.DragOnPC-DragWrap {
    width: 100%;
    overflow-x: hidden;
    display: flex;
}

.flex {
    display: flex;
}

.disable-pointer {
    pointer-events: none;
}
