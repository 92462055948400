.checkbox-container {
    display: inline-flex !important;
    max-width: var(--size, '20px');
    max-height: var(--size, '20px');
    position: relative;
}

.checkbox-container input[type='checkbox'] {
    position: absolute;
    width: var(--size, '20px') !important;
    height: var(--size, '20px') !important;
    z-index: 1;
    opacity: 0;
    inset: 0;
    top: 0;
    left: 0;
    margin: 0;
}

.checkbox-container input[type='checkbox']:checked + span {
    border: none;
}

.checkbox-container.checkbox-disabled span {
    opacity: 1 !important;
    background: #e7e7e7 !important;
    border: 1px solid #b4b4b4 !important;
}

// .checkbox-container.checkbox-disabled input[type='checkbox'] {
//     opacity: 1;
//     filter: invert(1%);
// }

.checkbox-container.checkbox-racing input[type='checkbox']:checked + span {
    background: #173e96; //rgb(0, 117, 225)
}

.checkbox-container.checkbox-football input[type='checkbox']:checked + span {
    background: #00c06c;
}

.checkbox-container.checkbox-marksix input[type='checkbox']:checked + span {
    background: #d62d2d;
}

.checkbox-container input[type='checkbox']:checked + span {
    background: var(--background) !important;
}

.checkbox-container input[type='checkbox'] + span {
    // display: inline-block;
    width: var(--size, '20px');
    height: var(--size, '20px');
    border-radius: 2px;
    background: #fff;
    cursor: pointer;
}

.checkbox-container input[type='checkbox']:checked + span:before {
    content: ' ';
    position: absolute;
    display: block;
    // display: inline-block;
    // border-bottom: 2px solid var(--tickColor, '#fff');
    // border-right: 2px solid var(--tickColor, '#fff');
    // transform: rotate(38deg);
    // width: 6px;
    // height: 12px;
    // top: 1px;
    // left: 6px;
    width: 20px;
    height: 20px;
    background-image: url(../../../../info/Include/images/icon_tick.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -1px;
}

.checkbox-container.checkbox-racing input[type='checkbox']:checked + span.reverse:before {
    background-image: url(../../../../info/Include/images/icon_tick_racing.svg);
}

.checkbox-container.checkbox-football input[type='checkbox']:checked + span.reverse:before {
    background-image: url(../../../../info/Include/images/icon_tick_football.svg);
}

.checkbox-container.checkbox-marksix input[type='checkbox']:checked + span.reverse:before {
    background-image: url(../../../../info/Include/images/icon_tick_marksix.svg);
}


.checkbox-container.size-16 input[type='checkbox']:checked + span:before {
    // transform: rotate(35deg);
    width: 16px;
    height: 16px;
    // top: 1px;
    // left: 5px;
}

.checkbox-container.size-18 input[type='checkbox']:checked + span:before {
    // transform: rotate(35deg);
    width: 18px;
    height: 18px;
    // top: 1px;
    // left: 6px;
}

.checkbox-container.size-20 input[type='checkbox']:checked + span:before {
    // transform: rotate(35deg);
    width: 20px;
    height: 20px;
    // top: 1px;
    // left: 7px;
}
