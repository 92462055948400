.expand-c {
    background-color: white;
    border-radius: 2px;
    .header {
        cursor: pointer;
        min-height: 48px;
        display: flex;
        align-items: center;
        .btn-expand {
            margin: 0 12px 0 24px;
        }
        span {
            font-size: 13px;
        }
    }
    .content {
        padding: 12px 24px 12px 24px;;
        background-color: rgba(244, 244, 244, 1);
    }
}

.footer {
    padding: 20px 12px;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: min(100%, 800px);
    gap: 16px;
    background-color: #e1e1e1;

    .button {
        border-radius: 16px;
        height: 32px;
        line-height: 32px;
        font-size: 13px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        border: none;
    }

    .restore {
        color: #ffffff;
        background-color: rgba(97, 118, 160, 1);
        width: 134px;
        &:hover {
            background-color: #003c84;
        }
    }

    .save {
        color: #122c68;
        background-color: #fecf13;
        &:hover {
            background-color: #e6bb11;
        }
        width: 80px;
    }
}

.submitBox {
    position: relative;
}
.popover {
    width: 276px;
    font-size: 14px;
    position: absolute;
    background: #fff;
    border-radius: 6px;
    left: -185px;
    top: -102px;

    .popoverArrow {
        display: block;
        width: 16px;
        height: 8px;
        position: absolute;
        right: 40px;
        bottom:-8px;
        &::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            border-width: 8px;
            border-bottom-width: 0px;
            bottom: 0px;
            top: -1px;
            border-top-color: rgba(0,0,0,0.18);
        }
        &::after {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
            border-width: 8px;
            border-top-color: #fff;
            bottom:1px;
            top: -1px;
            border-bottom-width: 0px;
        }
    }
    .popoverBody {
        padding: 16px;
        color: #212529;
    }
}




@media only screen and (max-width: 768px) and (pointer: coarse) {
    .footer {
        justify-content: space-between;
    }
}
