@media only screen and (min-width: 1441px) {
    .main-content{
        .rcOddsTable{
            .horse-racing-race-card.HOME{
                .rc-odds-table {
                    .draw, .rc-odd-draw {
                        min-width: 40px;
                        width: 40px;
                    }
                    .jocky {
                        min-width: 70px;
                        max-width: 70px;
                        width: auto;
                    }
                    .trainer {
                        min-width: 50px;
                        max-width: 50px;
                        width: auto;
                    }
                    .horseName {
                        min-width: 100px;
                        max-width: 100px;
                        padding: 0 5px;
                        width: auto;
                        word-wrap: break-word;
                    }
                    .last6, .rc-last6 {
                        min-width: 125px;
                        max-width: 125px;
                        width: 125px;
                        word-break:break-all;
                    }
                    .gear, .rc-gear{
                        min-width: 36px;
                        max-width: 36px;
                    }

                }
            }
        }
    }
}