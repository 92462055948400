@import '../common-mixin.scss';
/******************* title *******************/
.fbOddsTable {
    /* width: 100%; */
    border-radius: 5px;
    /* max-width: calc(100% - 236px); */
    flex: 1;
}


.printBtn-container {
    margin-right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.printBtn-image {
    height: 20px;
    width: 20px;
    background-image: url(../../info/Include/images/icon-print-white.svg);
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // margin-right: 8px;
    background-repeat: no-repeat;
}

.printBtn-container a {
    border: solid 8px transparent;
    @include font11();
}

.printBtn-container a:hover {
    text-decoration: underline;
}

.fbOddsTable header>.fbTitle {
    height: 40px;
    background-color: #405a55;
    color: white;
    border-radius: 4px 4px 0 0;
    padding: 6px;
    padding-left: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.fbOddsTable header>.fbTitle #mtgInfoDV {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.fbOddsTable header>.fbTitle #mtgInfoDV .form-check {
    display: inline-flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    -ms-user-select: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.fbOddsTable header>.fbTitle #mtgInfoDV .form-check * {
    color: #FFFFFF;
}

.fbOddsTable header>.fbTitle .fb-refresh-time {
    margin-right: 12px;
    @include font11();
}

.fbOddsTable header>.fbTitle #mtgInfoDV .form-check .refresh-odds * {
    cursor: pointer;
}

.fbOddsTable header>.fbTitle #mtgInfoDV #lblPageName {
    display: flex;
    align-items: center;
    @include H2();
}

.fbOddsTable header .refresh-odds div {
    height: 20px;
    width: 20px;
    margin-right: 6px;
    background-image: url(../../info/Include/images/ic_refresh_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @include font11;
    &.rotate {
        animation: rotate 1s linear 1;
    }
}

.fbOddsTable header .HelpBtn, .fbOddsTable .coupon .HelpBtn {
    height: 20px;
    width: 20px;
    min-width: 20px;
    margin-right: 12px;
    margin-left: 12px;
    background-image: url(../../info/Include/images/ic_help_white.svg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    cursor: pointer;
}

.icon-search {
    display: inline-block;
    background: url('../../info/Include/images/icon_results_search_white.svg') no-repeat;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
    margin: 0 8px 0 15px;
}

.icon-search-new {
    background: url('../../info/Include/images/icon_results_search_new.svg') no-repeat;
}
.icon-search-s {
    display: inline-block;
    background: url('../../info/Include/images/icon-search-s.svg') no-repeat;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}
.icon-history {
    display: inline-block;
    background: url('../../info/Include/images/history.svg') no-repeat;
    background-size: 18px 18px;
    width: 18px;
    height: 18px;
}

.hdcwarning {
    color: #cc0000;
}

.navigation-menu {
    display: none;
}

.fbMiddle .leftMenuMain .active {
    background-color: #00C06C;
}

.fbMiddle .fbOddsTable .nopool{
    width: 100%;
    height: 150px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
}

.fbOddsTable .nopool .nopoolmsg, .fbOddsTable .nopool .inplaymsg{
    position: relative;
    top: 40%;
}


.fbOddsTable .nopool .inplaymsg:hover{
    cursor: pointer;
    text-decoration-line: underline;
}

.fbOddsTable .lb-suspended, .refund-for-all{
    white-space: nowrap;
    color: #cc0000;
}

.fbOddsTable .lb-suspended {
    display: flex;
    justify-content: center;
    // padding: 2px 0;
    height: 24px;
    align-items: center;
    background-color: white;
}

.fbOddsTable .results-spc-table .lb-suspended {
    justify-content: flex-start;
    background-color: unset;
}

.fbOddsTable .lb-suspended-m {
    background: white;
    margin: 0 8px;
}

.fbOddsTable .refund-wrap {
    color: #cc0000;
}

.fbTitle .searchBtn-container{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.fbTitle .searchBtn-container:hover{
    text-decoration: underline;
}

div.CRSTopTips-content{
    border-radius: 8px;
    height: auto;
    width: 90%;
    max-width: 500px;
    .crsTips-msg-container{
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: #fff;
        padding: 24px;
        font-size: 13px;
        line-height: 18px;
    }
    .greenText{
        color: #405a55;
    }
    .betterMargin{
        margin-bottom: 18px;
    }
    .close-btn-box {
        display: flex;
        justify-content: center;
        .close{
            width: 200px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #405a55;
            font-size: 13px;
            font-weight: 500;
            background-color: #FECF13;
            border-radius: 16px;
            cursor: pointer;
            &:hover{
                background: #E4B910 !important;
            }
        }

    }
}