@media screen and (max-width: 1440px) {
    .main-content{
        .topMenu button{
            padding: 4px;
        }
        .rcOddsTable{
            .horse-racing-race-card.HOME{
                .rc-odds-table {
                    .jocky {
                        min-width: 70px;
                        max-width: 70px;
                    }
                    .trainer {
                        min-width: 70px;
                        max-width: 70px;
                    }
                    .horseName {
                        min-width: 100px;
                        max-width: 100px;
                        padding: 0 5px;
                        width: auto;
                        word-wrap: break-word;
                    }
                    .last6, .rc-last6 {
                        min-width: 100px;
                        max-width: 100px;
                        width: 100px;
                        word-break:break-word;
                    }
                    .gear, .rc-gear{
                        min-width: 36px;
                        max-width: 36px;
                    }
                    .rc-odds-table-header{
                        div{
                            padding: 0 4px;
                        }
                        .horseName{
                            padding: 0 5px;
                        }
                        .tp{
                            padding: 0;
                        }
                    }

                }
            }
        }
    }
}