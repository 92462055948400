$height: 40px;
$radius: 4px;
.ARInbox-scrollbars > div:last-child {
    z-index: 222;
}
.ARInbox {
    .current-session,
    .transaction-records {
        margin: 0 14px 24px;
        margin-bottom: calc(constant(safe-area-inset-bottom, 6px) + 24px );
        margin-bottom: calc(env(safe-area-inset-bottom, 6px) + 24px );
        .acctstmt-header {
            height: 50px;
            line-height: 50px;
            font-weight: bold;
            border-bottom: 1px solid #b6b6b6;
        }
        .balance {
            margin: 0 -15px;
            min-height: $height;
            background-color: #fff7d5;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-right: 5%;
            font-weight: bold;
            gap: 12px;
            box-shadow: var(--box-shadow);
            .amount {
                color: #003c84;
            }
        }

        .explain {
            min-height: $height;
            display: flex;
            align-items: center;
            border-bottom: #b6b6b6 1px solid;
            margin: 0 -15px;
            padding: 8px 15px;
            position: sticky;
            top:120px;
            background-color: #e7e7e7;
            .cur {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            z-index: 1;
        }

        .no-records {
            display: flex;
            justify-content: center;
            align-items: center;
            height: $height;
            margin-top: 100px;
        }

        .detail-tables-m {
            display: none;
        }
        .detail-table {
            display: table;
            width: 100%;
            padding-bottom: 48px;
            margin-top: -40px;
            // margin-bottom: 12px;
            thead {
                position: sticky;
                background-color: #e7e7e7;
                td {
                    white-space: nowrap;
                }
            }
            tbody {
                background: white;
                box-shadow: var(--box-shadow);
                tr:nth-of-type(even) {
                    background-color: #f4f4f4;
                }
                tr:first-child {
                    > td:first-child {
                        border-top-left-radius: $radius;
                    }

                    > td:last-child {
                        border-top-right-radius: $radius;
                    }
                }

                tr:last-child {
                    > td:first-child {
                        border-bottom-left-radius: $radius;
                    }

                    > td:last-child {
                        border-bottom-right-radius: $radius;
                    }
                }
            }
            tr {
                height: $height;
            }
            td {
                padding: 8px;
            }
            td.width300 {
                width: 380px;
            }
            td.w480 {
                width: 480px;
                white-space: pre-wrap;
            }
            td.mw74 {
                min-width: 74px;
            }
        }
        .ShowMoreBtn {
            padding-bottom: 0px;
        }
        .ARPagination {
            display: flex;
            justify-content: space-between;
            height: 48px;
            width: calc(100% - 28px);
            border-top: solid 1px #b6b6b6;
            position: fixed;
            bottom: 0;
            left: 0;
            margin: 0 14px;
            align-items: center;
            background-color: #e7e7e7;
            .paginationBtn {
                font-weight: bold;
                padding: 0 8px;
                cursor: pointer;
                .dd-arrow {
                    border-color: #333;
                }
            }
            .disabled {
                opacity: 0.5;
            }
        }
    }
    .ARtableTM-scrollbars{
         display: none;
    }

}

@media only screen and (max-width: 768px) /* and (pointer: coarse) */ {
    .ARInbox {
        .current-session,
        .transaction-records {
            margin: 0 12px 12px;
            .acctstmt-header {
                display: none;
            }
            .balance {
                margin: 0 -12px;
            }
            .detail-table {
                display: none;
            }
            .detail-tables-m {
                border-radius: $radius;
                overflow: hidden;
                display: block;
                // overflow-y: auto;
                // height: calc(100vh - 235px);
                box-shadow: var(--box-shadow);
                .detail-table-m:not(:last-child) {
                    margin-bottom: 12px;
                }
                .detail-table-m {
                    border-radius: $radius;
                    overflow: hidden;
                    background: white;
                    header {
                        height: 28px;
                        background-color: rgba(117, 117, 117, 1);
                        color: RGB(255, 255, 255);
                        line-height: 28px;
                        padding-left: 8px;
                    }
                    section {
                        color: RGB(51, 51, 51);
                        > div:first-child {
                            padding-top: 6px;
                            .title,
                            .content {
                                padding-top: 2px;
                            }
                        }
                        > div:last-child {
                            padding-bottom: 6px;
                            .title,
                            .content {
                                padding-bottom: 2px;
                            }
                        }
                        > div {
                            display: flex;
                            .title {
                                padding: 8px;
                                padding-right: 0;
                                width: 30%;
                            }
                            .content {
                                border-left: 1px solid #e4e4e4;
                                padding: 8px;
                                padding-left: 3%;
                                padding-right: 0;
                                flex: 1;
                                width: 70%;
                                // word-break: break-all;
                            }
                        }
                    }
                }
            }
            .explain {
                border-bottom: none;
                top: 168px;
                margin: 0 -12px;
                padding: 8px 12px;
                // justify-content: center;
            }
            .ARPagination {
                display: none;
            }
        }

        .transaction-records {
            .detail-tables-m{
                margin-top: 12px;
            }
        }

        &.hasScroll .explain {
            box-shadow: 0px 3px 3px rgb(0 0 0 / 20%);
        }
        .transaction-records{
            .detail-tables-m{
              height: auto;
            }
            .ARtableTM-scrollbars{
                display: block;
            }
        }
    }
}
