@import '../../../../css/common-mixin.scss';

.news-slider {
    width: 100%;
    border-radius: 4px;
    background-color: white;
    margin: 12px 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    @include font13();
    color: #333;
    padding: 12px 0;
    .news {
        @include font15();
        color: #003c84;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #b4b4b4;
    }
    .slider {
        width: calc(100% - 100px);
        flex: 1;
    }
    .slider-content {
        margin-left: 60px;
        margin-right: 60px;
    }

    .slider-item {
        .title {
            margin-bottom: 8px;
            @include font11();
        }
        .type {
            padding-right: 12px;
            border-right: solid 1px #b4b4b4;
            color: #003c84;
            &.Racing {
                color: #122c68;
            }
            &.Football {
                color: #405a55;
            }
            &.Marksix {
                color: #980b0b;
            }
        }
        .date {
            padding-left: 12px;
        }
        .content {
            line-height: 18px;
            cursor: pointer;
            @media only screen and (min-width: 1680px){
                line-height: 19px;
            }
        }
    }

    .slick-list {
        margin-left: 3px;
    }

    .slick-arrow.slick-prev {
        // background-color: #173e96;
        margin-left: initial;
        left: -36px;
        box-shadow: none;
        background-color:unset;
        background-image: url(../../../../info/Include/images/arrow-left.svg);
        width: 24px;
        height: 24px;
        &::before{
            content: unset;
        }
    }

    .slick-arrow.slick-next {
        // background-color: #173e96;
        background-color:unset;
        margin-left: initial;
        right: -36px;
        box-shadow: none;
        background-image: url(../../../../info/Include/images/arrow-right.svg);
        width: 24px;
        height: 24px;
        &::before{
            content: unset;
        }
    }
    // .slider-left,
    // .slider-right {
    //     display: flex;
    //     justify-content: center;
    //     width: 100px;
    //     cursor: pointer;
    // }
    // .arrow.arrow-right {
    //     border: solid #444;
    //     border-width: 0 3px 3px 0;
    // }
}

@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
    .news-slider {
        width: 100%;
        flex-direction: column;
        padding: 0 12px;
        .news {
            padding: 12px 0;
            border-bottom: 1px solid #b4b4b4;
            border-right: 0;
            height: auto;
            width: 100%;
            justify-content: left;
        }
        .slider {
            padding: 12px 0;
            width: 100%;
            flex: 1;
        }

        .slider-content {
            margin-left: 30px;
            margin-right: 30px;
        }
    }
}
}
