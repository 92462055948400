@import '../../../css/common-mixin.scss';
.RelatedMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    column-gap: 24px;
    margin-left: 24px;
    .RelatedMenu-item {
        color: #ffffff !important;
        cursor: pointer;
        white-space: pre-wrap;
        @include H6();
        &:hover {
            text-decoration: underline !important;
        }
    }
}

@media only screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .RelatedMenu {
            flex-direction: column;
            align-items: flex-start;
            margin-left: 0;
            .RelatedMenu-item {
                color: #333 !important;
                font-size: 13px;
                padding: 5px;
                font-weight: 400 !important;
                line-height: 15px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
}
