.arrow-icon{
    background-image: url(../../../../info/Include/images/arrow-right.svg);
    background-repeat: no-repeat;
    background-size: cover;
    &.arrow-icon-default-down{
        background-image: url(../../../../info/Include/images/arrow-down.svg);
    }
    &.arrow-icon-default-up{
        background-image: url(../../../../info/Include/images/arrow-up.svg);
    }
    &.arrow-icon-default-left{
        background-image: url(../../../../info/Include/images/arrow-left.svg);
    }
    &.arrow-icon-default-right{
        background-image: url(../../../../info/Include/images/arrow-right.svg);
    }
    &.arrow-icon-change-down{
        background-image: url(../../../../info/Include/images/arrow-down.svg);
    }
    &.arrow-icon-change-up{
        background-image: url(../../../../info/Include/images/arrow-up.svg);
    }
    &.arrow-icon-change-left{
        background-image: url(../../../../info/Include/images/arrow-left.svg);
    }
    &.arrow-icon-change-right{
        background-image: url(../../../../info/Include/images/arrow-right.svg);
    }

    &.arrow-icon-default-white-left{
        background-image: url(../../../../info/Include/images/arrow-left-white.svg);
    }
    &.arrow-icon-default-white-right{
        background-image: url(../../../../info/Include/images/arrow-right-white.svg);
    }

    &.arrow-icon-default-green-up{
        background-image: url(../../../../info/Include/images/arrow-up-green.svg);
    }
    &.arrow-icon-default-green-down{
        background-image: url(../../../../info/Include/images/arrow-down-green.svg);
    }
}