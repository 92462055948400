.GSTabTable{
    background-color: #fff;
}
.fbOddsTable section .GSTabTable .match-row-container.FGS  > .match-row-m{
    background-color: #fff !important;
    &:nth-child(even){
        background-color: #f4f4f4 !important;
    }

}
.GSCheckBoxCell{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1023px) and (pointer: coarse){
    :where(.isDeviceVersion) {
        .GSTable .oddsCheckboxGrid{
            display: flex;
            flex-direction: column-reverse;
            row-gap: 8px;
            flex-direction: column-reverse;
        }
    }
}
