@media only screen and (max-width: 1440px){
    .fb-results-match-results {
        .table-row{
            & > .table-cell{
                padding: 4px 12px;
                &.detail-cell,
                &.last-odds-cell{
                    padding:4px 18px;
                }
            }

        }
    }

    .sgaRemarks, .crsRemarks {
        padding: 8px 12px;
    }


    .fbOddsTable section.CRS .match-row,
    .fbOddsTable section.FCS .match-row {
        .team{
            min-height: 84px;
            align-self: flex-start;
        }
    }

    .fbOddsTable section{
        &.CRS,&.FCS{
            .match-row > div:not(.odds, .oddsLine, .team){
                min-height: 84px;
                align-self: flex-start;
            }
        }
    }

    .fbOddsTable section {
        .match-row {
            .oddsLine.CRS, .oddsLine.FCS, .oddsLine.ECS {
                .crs-odds-box{
                    gap: 14px 0;
                    grid-template-columns: repeat(2, 50%);
                    justify-items: center;
                    .suspended {
                        margin-bottom: -12px;
                    }
                }
                .crs-more-button {
                    align-items: flex-start;
                    padding-top: 13px;
                }
            }
        }
    }
}