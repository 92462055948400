@media only screen and (min-width: 1680px) {
    .fbOddsTable header.ALLODDS .match-info > div,
    .fbOddsTable section .match-info > div{
        grid-template-columns: 200px 1fr 200px;
    }

    .meeting-button .slider-container-venue h3 span {
        transform: translate(-0.3px, 0px);
    }
    .fbOddsTable section .match-row .oddsLine {
        &.HHA, &.HDC, &.FHH, &.CHD, &.FHC, &.EDC, &.EHH, &.ECD{
            .odds{
                >.hdcOddsItem{
                    .cond{
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
}