.homeTooltipsAlert-overlay div.homeTooltipsAlert-content{
    max-height: unset;
    max-width: 360px;
    div.MobileHomeTooltips{
        padding: 24px 16px;
        .msgContent{
            font-size: 18px !important;
            line-height: 26px !important;
        }

        div.close-btn-box{
            justify-content: center;
            .close{
                width: 200px;
                background: #003c84;
            }
        }
        .imageContent{
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}