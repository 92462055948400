.rc-checkbox input {
    /* margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    width: 18px;
    height: 18px;
    border: 1px #b3b3b3 solid;
    outline: none;
    border-radius: 2px;
    vertical-align: middle;
    padding-left: 10px;
    margin-right: 4px; */
}

.rc-checkbox div:not(.checkbox-container) {
    font-weight: 500;
    vertical-align: middle;
    display: inline;
}

.rc-odds-row-m:not(.full) .rc-checkbox.rc-odds-m {
    /* vertical-align: top; */
    padding-top: 2px;
}

.rc-checkbox.rc-odds-m div:not(.checkbox-container) {
    font-weight: 500;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px 6px;
    flex-wrap: wrap;
}