@media only screen and (max-width: 768px) /* and (pointer: coarse) */ {
    body:has(.ARInbox) {
        padding-bottom: 0 !important;
    }
  .ARInbox {
    width: min(800px, 100%);
    /* height: 100vh; */
    min-height: unset;
    .commom-header {
        position: sticky;
        top:0;
    }
    .ARInboxTitle{
      height: 60px !important;
      justify-content: center;
      position: relative;
      .controlContainer{
        position: absolute;
        right: 8px;
        .ARPrintBtn{
          display: none;
        }
        .icon-download-white{
          width: 24px;
          height: 24px;
        }
      }
    }
    .table-header-shadow {
        display: none;
    }
    .ARInboxHeader{
      display: flex;
    }
    .ARInboxHeaderCell{
      height: 52px;
      display: inline-flex;
      justify-content: center;
      padding: 0 12px;
      text-align: center;
    }
    .ARInboxHeaderCellInner{
      white-space: normal;
      height: 48px;
    }
    .firstCell{
      padding-left: 0;
    }

    &.ARInbox-tab-1.hasScroll {
        .acInfo {
            box-shadow: 0px 3px 3px rgb(0 0 0 / 20%);
        }
    }

    .acInfo{
      height: 56px;
      display: flex;
      .acInfoCell{
        width: 34%;
        display: flex;
        flex-direction: column;
        .labelText,span{
          padding-left: 5px;
          padding-right: 0;
        }
      }
      .acInfoCell1{
        width: 38%;
      }
      .acInfoCell2{
        width: 28%;
      }
      .borderLLine{
        height: auto;
      }
    }
    .ARSearchBoxOverlay {
        top: 168px !important;
        width: 100%;
        .ARSearchBox {
            top: 168px !important;
            width: 100%;
        }
    }

    .ARSearchBox-l {
      width:100%;
      padding:0 0 11px 0PX;
      .date-picker-container{
          width: 100%;

      }
    }
    .right-border::after{
      height: 0;
    }
    &.hasScroll {
        .ARContent{
            .acctstmt-header{
                box-shadow: 0px 3px 3px rgb(0 0 0 / 20%);
            }
        }
    }
    .ARContent{
      .search-result-container{
        width: 100vw;
        padding: 0 12px;
        .acctstmt-header{
          top: 168px;
          border: 0;
          margin: 0 -12px;
          padding: 0 12px;
          .header-r{
            .btn-arexport{
              display: block;
            }
          }
          .header-l{
            span:not(.ARDateRange){
              display: none;
            }

          }
        }
        .ARResultTable{
          display: none;
        }
        .ARResultTableM{
          display: block;
          // height:calc(100vh - 50px - 110px - 44px - 15px);
          // overflow-y: auto;
          // font-weight: bold;
          .ARResultTableRowM{
            border-radius: 4px;
            overflow: hidden;
            background-color: #FFF;
            margin-bottom: 12px;
            .ARResultTableRowM-header{
              background-color: #757575;
              color: #fff;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 12px 0 8px;
              height: 28px;
            }
            .ARResultTableRowM-main{
              padding: 0 12px 0 8px;
              >div{
                min-height: 28px;
                display: flex;
                .cell-l{
                  min-width: 100px;
                  max-width: 100px;
                  border-right: 1px solid #D8D8D8;
                  padding: 6px 0;
                  padding-right: 8px;
                }
                .cell-r{
                  padding: 6px 0;
                  padding-left: 12px;
                  white-space: pre-wrap;
                }
              }
            }
          }
        }
        .ARPagination{
          display: none;
        }
      }
    }
    .ARContent .search-result-container .wait,
    .wait{
      display: none;
    }
    .ARContent .search-result-container .wait-mobile,
    .wait-mobile{
      display: block;
      text-align: center;
      padding: 25px 0 ;
    }
    .ARSearchBox-l .statementSelection-lastday .speedbetStatementRange {
      width: 80px;
    }
    .ARSearchBox-r {
      border-left: none;
      border-top: #D9D5C4 1px solid;
      width: 100%;
      text-align: left;
      padding: 0px ;
    }
    .selectionLabel {
    //   width:100%;
      display: block;
    }
    .statementDropdown {
      width:100%;
    }
    .statementDropdown > .dropdown-toggle {
      width:100%;
      margin-left:0px;
    }
  }

  #eod .eod-msg {
    width:90%;
    padding: 0px 14px 0px 14px;

  }
}