.BtnExpand {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    background-color: var(--background-color, #003c84);
    color: white;
    cursor: pointer;
    &::before {
        content: '';
        width: 10px;
        height: 2px;
        position: absolute;
        top: 9px;
        left: 5px;
        background-color: #fff;
    }
    &:not(.isExpand)::after {
        content: '';
        width: 2px;
        height: 10px;
        position: absolute;
        top: 5px;
        left: 9px;
        background-color: #fff;
    }
}
