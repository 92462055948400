@import './common-mixin.scss';

.flex {
    display: flex;
}

input {
    font-weight: 500;
}
.scroll-none {
    scrollbar-width: none;
}

.scroll-none::-webkit-scrollbar {
    width: 0; height: 0;
    display: none;
}

.marginLeft16px{
    margin-left: 16px;
}

.marginRight8px{
    margin-right: 8px;
}

.marginRight16px{
    margin-right: 16px;
}

.marginTop12 {
    margin-top: 12px;
}

.v-center {
    display: flex;
    align-items: center;
}

.h-center {
    display: flex;
    justify-content: center;
}

.H2 {
    @include H2();
}

.H4 {
    @include H4();
}

.H5 {
    @include H5();
}

.H6 {
    @include H6();
}

.Body-Bold {
    @include Body-Bold();
}

.Body {
    @include Body();
}

.small-text {
    @include small-text();
}

.bg-gray {
    background-color: #F4F4F4;
}


.text-align-left {
    text-align: left !important;
}

.overflow-hidden {
    overflow: hidden;
}

.cta_yellow,  .cta_m6, .cta_hr, .cta_brand, .cta_fb{
    font-weight: 500 !important;
    &.dim, &.disabled, &:disabled{
        background-color: #ccc  !important;
        pointer-events: none;
        color: #FFF !important;
        opacity: .5  !important;
    }
}

.cta_yellow {
    background-color: #FECF13 !important;
    color: #333 !important;
    &:active{
        background-color: #e4b910 !important;
    }
}
.cta_brand{
    background-color: #003c84 !important;
    color: #FFF !important;
    &:active{
        background-color: #01316A !important;
    }
}
.cta_hr{
    background-color: #6176a0 !important;
    color: #FFF !important;
    &:active{
        background-color: #122C68 !important;
    }
}
.cta_fb{
    background-color: #569171 !important;
    color: #FFF !important;
    &:active{
        background-color: #405a55 !important;
    }
}
.cta_m6{
    background-color: #cb7b7b !important;
    color: #FFF !important;
    &:active{
        background-color: #980B0B !important;
    }
}


@media (any-hover: hover) {
    .cta_fb:hover{
        background-color: #405a55 !important;
    }
    .cta_brand:hover{
        background-color: #01316A !important;
    }
    .cta_yellow:hover{
        background-color: #e4b910 !important;
    }
    .cta_hr:hover{
        background-color: #122C68 !important;
    }
    .cta_m6:hover{
        background-color: #980B0B !important;

    }
    .cta_yellow,  .cta_m6, .cta_hr, .cta_brand, .cta_fb{
        &.dim:hover, &.disabled:hover,  &:disabled:hover{
            cursor: unset !important;
            background-color: #ccc !important;
        }
    }
}

.single-text-ellipsis {
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
}

.white-space-nowrap {
    white-space: nowrap;
}

.pointer-events--none {
    pointer-events: none;
}

.text-underline {
    text-decoration:underline;
}

.font-weight500 {
    font-weight: 500;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

input[type='text']:focus, input[type='password']:focus {
    background-color: #FFFBE6;
}

.lite-entry-body {
    padding-bottom: 0;
 }